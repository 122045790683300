import React from "react";
import "../Bussalescount/Bussalescount.scss";
import * as ANTD from "antd";
import { Link } from "react-router-dom";
// import { AntDesignOutlined, SettingFilled } from "@ant-design/icons";
// import { Link } from "react-router-dom";
// import Siteusers from "../../admin/Siteusers/Siteusers";
// import { Chart, LineAdvance } from "bizcharts";

const Bussalescount = ({ busData }) => {
  const columnsBuscount = [
    {
      title: "ReferenceID",
      dataIndex: "BookingRefNo",
      key: "BookingRefNo",
      render: (text, record) => renderReffrence(text, record),
    },
    {
      title: "Source",
      dataIndex: "SourceName",
      key: "SourceName",
    },
    {
      title: "Journey Date",
      dataIndex: "JourneyDate",
      key: "JourneyDate",
    },

    {
      title: "Amount ",
      dataIndex: "CollectedFare",
      render: (p) => (p ? Math.round(Number(p)) : ""),
      key: "Fares",
    },

    {
      title: "Status",
      dataIndex: "BookingStatus",
      render: (type) => getStatus(type),
    },
  ];

  const renderReffrence = (ref, rec) => {
    let url = "";

    if (ref.indexOf("OUTC-B") != -1) {
      url = "/admin/bus/ticket";
    }
    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };
  const getStatus = (type) => {
    switch (type) {
      case 1:
        return "Failed ";
      case 2:
        return "Confirmed ";
      case 3:
        return "Cancelled ";
      case 4:
        return "Pending ";
      case 5:
        return "Rejected  ";
      case 6:
        return "Hold ";
      case 7:
        return "CancellationRequest ";
      case 8:
        return "CancellationPending ";
      case 9:
        return "CancellationInProgress ";
      default:
        return "";
    }
  };

  return (
    <div>
      {/* bus--sales--open */}

      <div className="order-count-cards hotel-sales-new">
        <ANTD.Row>
          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-1">
              <span className="ad-span-col-1">
                <i className="fa fa-bar-chart"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Booking Count</p>
                <h5>{Number(busData?.booking_count).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>

          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-2">
              <span className="ad-span-col-2">
                <i className="fa fa-bookmark" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Cancelled Count</p>
                <h5>{Number(busData?.cancelled_count).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>

          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-3">
              <span className="ad-span-col-3">
                <i className="fa fa-ban" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Day Sales </p>
                <h5>{Number(busData?.day_sales).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>

          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-4">
              <span className="ad-span-col-4">
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Yesterday Sales </p>
                <h5>{Number(busData?.yesterday_sales).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>

          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-5">
              <span className="ad-span-col-5">
                <i className="fa fa-retweet" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>Week Sales </p>
                <h5>{Number(busData?.week_sales).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>

          <ANTD.Col xs={24} md={4}>
            <div className="ad-hom-box-6">
              <span className="ad-span-col-6">
                <i className="fa fa-bolt" aria-hidden="true"></i>
              </span>

              <div className="ad-hom-view-com">
                <p>month Sales </p>
                <h5>{Number(busData?.month_sales).toFixed(2)}</h5>
              </div>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        {/* --first--count-row--close */}
      </div>

      <div className="order-count-cards ">
        <ANTD.Row
          gutter={[
            { xs: 0, md: 16 },
            { xs: 8, md: 16 },
          ]}
          className="charts-bars card-count-balance"
        >
          <ANTD.Col xs={24} md={12}>
            <ANTD.Card className="side-bar-graph-1">
              <p className="month-wise-cart">Upcoming Bookings</p>
              {/* <p className="month-wise-cart">Month wise (Show in Graph)</p>
                <Chart
                  padding={[10, 20, 50, 40]}
                  autoFit
                  height={300}
                  data={data}
                >
                  <LineAdvance
                    shape="smooth"
                    point
                    area
                    position="month*temperature"
                    color="city"
                  />
                </Chart> */}
              <ANTD.Table
                className="table-scroll-none flight-new-pagenation"
                bordered
                dataSource={busData?.upcoming_bookings}
                columns={columnsBuscount}
              />
            </ANTD.Card>
          </ANTD.Col>

          <ANTD.Col xs={24} md={12}>
            <div className="site-card-border-less-wrapper">
              <ANTD.Card
                className="side-bar-graph-new-flight" /*  title="Bus recent 10 bookings" bordered={false} */
              >
                <p className="month-wise-cart">Recent Bookings</p>
                <ANTD.Row className="boder-count">
                  <ANTD.Col xs={24} md={24} className="total-cal-fl">
                    <ANTD.Table
                      className="table-scroll-none flight-new-pagenation"
                      bordered
                      dataSource={busData?.recent_bookings}
                      columns={columnsBuscount}
                    />
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Card>
            </div>
          </ANTD.Col>
        </ANTD.Row>
      </div>

      {/* charts--close */}
    </div>
  );
};
export default Bussalescount;
