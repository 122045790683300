// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   message,
//   Row,
//   Form,
//   Popconfirm,
//   Select,
//   Space,
//   DatePicker,
//   Input,
// } from "antd";

// import { useAuthContext } from "../../common/providers/AuthProvider";
// import ApiClient from "../../helpers/ApiClient";
// import moment from "moment";
// import {
//   ArrowLeftOutlined,
//   EditOutlined,
//   DeleteOutlined,
// } from "@ant-design/icons";
// // import CountryList from "../../common/CountryList";
// import "./TravellerDetails.scss";
// import Nav1 from "../../common/navbar/Nav1";

// const disabledFutureDate = (currentDate) => {
//   return currentDate > moment().startOf("day");
// };

// const { Option } = Select;
// const dateFormat = "DD-MM-YYYY";

// const TravellerDetails = () => {
//   const { user } = useAuthContext();
//   const userID = user ? (user.UserID ? user.UserID : null) : null;
//   const [formVisible, setFormVisible] = useState(false);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [updateID, setUpdateID] = useState(null);
//   const [travellersListResp, setTravellersListResp] = useState(null);
//   const [form] = Form.useForm();

//   const fetchTravellerDetails = () => {
//     ApiClient.get("admin/user/travellers/" + userID)
//       .then((resp) => resp)
//       .then((resp) => {
//         if (resp.status === 200) {
//           setTravellersListResp(resp.data);
//         } else {
//           setTravellersListResp([]);
//           // console.log("Unable to fetch Travellers List");
//         }
//       })
//       .catch((err) => err);
//   };

//   useEffect(() => {
//     fetchTravellerDetails();
//   }, []);

//   const submitForm = (value) => {
//     let data = {
//       UserID: userID,
//       DocumentType: "string",
//       DocumentSeriesAndNumber: "string",
//       DocumentExpireDate: "string",
//       ...value,
//     };
//     isEditMode ? updateDetails(data) : addDetials(data);
//   };

//   const addDetials = (data) => {
//     ApiClient.post("admin/user/traveller", data)
//       .then((resp) => resp)
//       .then((resp) => {
//         if (resp.status === 200) {
//           message.success(resp.message);
//           setFormVisible(false);
//           fetchTravellerDetails();
//         } else {
//           message.error("Something Went Wrong", 3);
//         }
//       })
//       .catch((err) => err);
//   };

//   const handleEdit = (traveller) => {
//     setUpdateID(traveller.TravellerID);
//     setIsEditMode(true);
//     setFormVisible(true);
//     form.setFieldsValue({
//       Title: traveller.Gender === 0 ? "mr" : "mrs",
//       FirstName: traveller.FirstName,
//       LastName: traveller.LastName,
//       Gender: traveller.Gender,
//       DOB: moment(traveller.DOB),

//       PassportNumber: traveller.PassportNumber ?? "",
//       PassengerNationality: traveller.PassengerNationality,
//       PassportDOI: traveller.PassportDOI ? moment(traveller.PassportDOI) : "",
//       PassportDOE: traveller.PassportDOE ? moment(traveller.PassportDOE) : "",
//       MealPreference: traveller.MealPreference ?? "",
//       SeatPreference: traveller.SeatPreference ?? "",
//     });
//   };

//   const updateDetails = (data) => {
//     ApiClient.put("admin/user/traveller/" + updateID, {}, data)
//       .then((resp) => resp)
//       .then((resp) => {
//         if (resp.status === 200) {
//           message.success(resp.message);
//           setUpdateID(null);
//           setFormVisible(false);
//           fetchTravellerDetails();
//         } else {
//           message.error("Something Went Wrong", 3);
//         }
//       })
//       .catch((err) => err);
//   };

//   const deleteTraveller = (travellerId) => {
//     ApiClient.delete("admin/user/traveller/" + travellerId)
//       .then((resp) => resp)
//       .then((resp) => {
//         if (resp.status === 200) {
//           fetchTravellerDetails();
//           message.success(resp.message);
//         } else {
//           message.error("Something Went Wrong", 3);
//         }
//       })
//       .catch((err) => err);
//   };

//   return (
//     <>
//     <Nav1/>
//     <div id="travelerDetails">
//       {formVisible && (
//         <div className="backToList">
//           <p style={{color:"#bd0c21"}}
//             onClick={() => {
//               setFormVisible(false);
//             }}
//           >
//             <ArrowLeftOutlined /> Back To Travellers List
//           </p>
//         </div>
//       )}
//       <div className="traveller-container">
//         {!formVisible ? (
//           <div className="passenger">
//             <Row gutter={16}>
//               <Col md={24} xs={24} className="gutter-class">
//                 <div>
//                   <div className="passengers-header">
//                     <h2>Passengers</h2>
//                     <div className="addTravellerBtn">
//                       <Button
//                         onClick={() => {
//                           setFormVisible(true);
//                           form.resetFields();
//                           if (updateID) {
//                             setUpdateID(null);
//                           }
//                         }}
//                       >
//                         Add Traveler
//                       </Button>
//                     </div>
//                   </div>
//                   <p className="description">
//                     Want to save time and avoid hassle? Make filling in booking
//                     forms quick and painless - save your personal details and
//                     the details of people you often travel with for easy future
//                     access.
//                   </p>
//                 </div>
//                 <div className="travellers-list">
//                   {travellersListResp !== null ? (
//                     <>
//                       {travellersListResp.Travellers.map((traveller) => (
//                         <Card
//                           className="traveler-custom-card retrieve-booking"
//                           bordered={false}
//                         >
//                           <div className="list-item">
//                             <h6>
//                               {traveller.FirstName + " " + traveller.LastName}
//                             </h6>
//                             <div className="actionbtn">
//                               <EditOutlined
//                                 onClick={() => handleEdit(traveller)}
//                               />
//                               <Popconfirm
//                                 title="Are u sure to delete?"
//                                 onConfirm={() =>
//                                   deleteTraveller(traveller.TravellerID)
//                                 }
//                               >
//                                 <DeleteOutlined />
//                               </Popconfirm>
//                             </div>
//                           </div>
//                         </Card>
//                       ))}
//                     </>
//                   ) : null}
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         ) : (
//           <div className="passenger">
//             <Row gutter={16}>
//               <Col md={24} xs={24} className="gutter-class">
//                 <div>
//                   <h2>{isEditMode ? "Edit" : "Add"} Traveller's Details</h2>
//                 </div>
//                 <div className="travellers-list">
//                   <Card className="traveler-custom-card" bordered={false}>
//                     <Form
//                       layout="vertical"
//                       form={form}
//                       onFinish={submitForm}
//                       className="passenger-form"
//                       requiredMark={false}
//                     >
//                       <Row gutter={[16, 8]}>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             name="Title"
//                             label="Title"
//                             rules={[{ required: true, message: "Required" }]}
//                           >
//                             <Select size={"large"} placeholder="Choose">
//                               <Option value="mr">Mr</Option>
//                               <Option value="mrs">Mrs/ Miss / Ms</Option>
//                             </Select>
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             name="FirstName"
//                             label="First Name"
//                             rules={[
//                               { required: true, message: "Required" },
//                               {
//                                 min: 2,
//                                 message:
//                                   "First Name must be minimum 2 characters.",
//                               },
//                               {
//                                 max: 32,
//                                 message:
//                                   "First Name must be maximum 32 characters.",
//                               },
//                             ]}
//                           >
//                             <Input size={"large"} placeholder="First Name" />
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             name="LastName"
//                             label="Last Name"
//                             rules={[
//                               { required: true, message: "Required" },
//                               {
//                                 min: 2,
//                                 message:
//                                   "Last Name must be minimum 2 characters.",
//                               },
//                               {
//                                 max: 32,
//                                 message:
//                                   "Last Name must be maximum 32 characters.",
//                               },
//                             ]}
//                           >
//                             <Input size={"large"} placeholder="Last Name" />
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Gender"
//                             rules={[{ required: true, message: "Required" }]}
//                             name="Gender"
//                           >
//                             <Select size={"large"} placeholder="Choose">
//                               <Option value={0}>Male</Option>
//                               <Option value={1}>Female</Option>
//                             </Select>
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Date of birth"
//                             rules={[{ required: true, message: "Required" }]}
//                             name="DOB"
//                           >
//                             <DatePicker
//                               disabledDate={disabledFutureDate}
//                               format={dateFormat}
//                               size={"large"}
//                               allowClear={false}
//                               style={{ width: "100%" }}
//                             />
//                           </Form.Item>
//                         </Col>

//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Passport Number"
//                             name="PassportNumber"
//                           >
//                             <Input
//                               size={"large"}
//                               placeholder="Passport Number"
//                             />
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Passenger Nationality"
//                             name="PassengerNationality"
//                             rules={[{ required: true, message: "Required" }]}
//                           >
//                             {/* <Select
//                               size={"large"}
//                               showSearch
//                               placeholder="Select Country"
//                               style={{ width: "100%" }}
//                               filterOption={(input, option) =>
//                                 option.children
//                                   .toLowerCase()
//                                   .indexOf(input.toLowerCase()) >= 0
//                               }
//                             >
//                               {CountryList.map((item) => (
//                                 <Option key={item.code} value={item.code}>
//                                   {item.name}
//                                 </Option>
//                               ))}
//                             </Select> */}
//                             <Input size={"large"} />
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item label="Passport DOI" name="PassportDOI">
//                             <DatePicker
//                               format={dateFormat}
//                               size={"large"}
//                               allowClear={false}
//                               style={{ width: "100%" }}
//                             />
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item label="Passport DOE" name="PassportDOE">
//                             <DatePicker
//                               format={dateFormat}
//                               size={"large"}
//                               allowClear={false}
//                               style={{ width: "100%" }}
//                             />
//                           </Form.Item>
//                         </Col>

//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Meal Preference"
//                             name="MealPreference"
//                           >
//                             <Select size={"large"} placeholder="Choose">
//                               <Option value="0">Yes</Option>
//                               <Option value="1">No</Option>
//                             </Select>
//                           </Form.Item>
//                         </Col>
//                         <Col md={8} sm={12} xs={24}>
//                           <Form.Item
//                             label="Seat Preference"
//                             name="SeatPreference"
//                           >
//                             <Select size={"large"} placeholder="Choose">
//                               <Option value="0">Yes</Option>
//                               <Option value="1">No</Option>
//                             </Select>
//                           </Form.Item>
//                         </Col>
//                       </Row>
//                       <Row justify="center" className="trv-env-21">
//                         <Space size="middle">
//                           <Button type="primary" htmlType="submit">
//                             {isEditMode ? "Update" : "Add"}
//                           </Button>
//                           <Button
//                             type="primary"
//                             danger
//                             onClick={() => {
//                               setIsEditMode(false);
//                               setFormVisible(false);
//                             }}
//                           >
//                             Cancel
//                           </Button>
//                         </Space>
//                       </Row>
//                     </Form>
//                   </Card>
//                 </div>
//               </Col>
//             </Row>
//           </div>
//         )}
//       </div>
//     </div>
//     </>
//   );
// };

// export default TravellerDetails;
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  message,
  Row,
  Form,
  Popconfirm,
  Select,
  Space,
  DatePicker,
  Input,
} from "antd";

import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./TravellerDetails.scss";
import Nav1 from "../../common/navbar/Nav1";

const disabledFutureDate = (currentDate) => {
  return currentDate > moment().startOf("day");
};

const { Option } = Select;
const dateFormat = "DD-MM-YYYY";

const TravellerDetails = () => {
  const { user } = useAuthContext();
  const userID = user ? (user.UserID ? user.UserID : null) : null;
  const [formVisible, setFormVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updateID, setUpdateID] = useState(null);
  const [travellersListResp, setTravellersListResp] = useState(null);
  const [form] = Form.useForm();

  const fetchTravellerDetails = () => {
    ApiClient.get("admin/user/travellers/" + userID)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          setTravellersListResp(resp.data);
        } else {
          setTravellersListResp([]);
        }
      })
      .catch((err) => err);
  };

  useEffect(() => {
    fetchTravellerDetails();
  }, []);

  const submitForm = (value) => {
    let data = {
      UserID: userID,
      DocumentType: "string",
      DocumentSeriesAndNumber: "string",
      DocumentExpireDate: "string",
      ...value,
    };
    isEditMode ? updateDetails(data) : addDetails(data);
  };

  const addDetails = (data) => {
    console.log(data)
    ApiClient.post("admin/user/traveller", data)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          message.success(resp.message);
          setFormVisible(false);
          fetchTravellerDetails();
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((err) => err);
  };

  const handleEdit = (traveller) => {
    setUpdateID(traveller.TravellerID);
    setIsEditMode(true);
    setFormVisible(true);
    form.setFieldsValue({
      Title: traveller.Gender === 0 ? "mr" : "mrs",
      FirstName: traveller.FirstName,
      LastName: traveller.LastName,
      Gender: traveller.Gender,
      DOB: moment(traveller.DOB),
      PassportNumber: traveller.PassportNumber ?? "",
      PassengerNationality: traveller.PassengerNationality,
      PassportDOI: traveller.PassportDOI ? moment(traveller.PassportDOI) : "",
      PassportDOE: traveller.PassportDOE ? moment(traveller.PassportDOE) : "",
      PanCard: traveller.PANCard ?? "",
    });
  };

  const updateDetails = (data) => {
    ApiClient.put("admin/user/traveller/" + updateID, {}, data)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          message.success(resp.message);
          setUpdateID(null);
          setFormVisible(false);
          fetchTravellerDetails();
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((err) => err);
  };

  const deleteTraveller = (travellerId) => {
    ApiClient.delete("admin/user/traveller/" + travellerId)
      .then((resp) => resp)
      .then((resp) => {
        if (resp.status === 200) {
          fetchTravellerDetails();
          message.success(resp.message);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((err) => err);
  };

  return (
    <>
      <Nav1 />
      <div id="travelerDetails">
        {formVisible && (
          <div className="backToList">
            <p
              style={{ color: "#bd0c21" }}
              onClick={() => {
                setFormVisible(false);
              }}
            >
              <ArrowLeftOutlined /> Back To Travellers List
            </p>
          </div>
        )}
        <div className="traveller-container">
          {!formVisible ? (
            <div className="passenger">
              <Row gutter={16}>
                <Col md={24} xs={24} className="gutter-class">
                  <div>
                    <div className="passengers-header">
                      <h2>Passengers</h2>
                      <div className="addTravellerBtn">
                        <Button
                          onClick={() => {
                            setFormVisible(true);
                            form.resetFields();
                            if (updateID) {
                              setUpdateID(null);
                            }
                          }}
                        >
                          Add Traveler
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="travellers-list">
                    {travellersListResp !== null ? (
                      travellersListResp.Travellers.map((traveller) => (
                        <Card
                          className="traveler-custom-card retrieve-booking"
                          bordered={false}
                        >
                          <div className="list-item">
                            <h6>
                              {traveller.FirstName + " " + traveller.LastName}
                            </h6>
                            <div className="actionbtn">
                              <EditOutlined
                                onClick={() => handleEdit(traveller)}
                              />
                              <Popconfirm
                                title="Are u sure to delete?"
                                onConfirm={() =>
                                  deleteTraveller(traveller.TravellerID)
                                }
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </div>
                          </div>
                        </Card>
                      ))
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div className="passenger">
              <Row gutter={16}>
                <Col md={24} xs={24} className="gutter-class">
                  <div>
                    <h2>{isEditMode ? "Edit" : "Add"} Traveller's Details</h2>
                  </div>
                  <div className="travellers-list">
                    <Card className="traveler-custom-card" bordered={false}>
                      <Form
                        layout="vertical"
                        form={form}
                        onFinish={submitForm}
                        className="passenger-form"
                        requiredMark={false}
                      >
                        <Row gutter={[16, 8]}>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              name="Title"
                              label="Title"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select size={"large"} placeholder="Choose">
                                <Option value="mr">Mr</Option>
                                <Option value="mrs">Mrs/ Miss / Ms</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              name="FirstName"
                              label="First Name"
                              rules={[
                                { required: true, message: "Required" },
                                {
                                  pattern: /^[A-Za-z]+$/,
                                  message: "Only alphabets are allowed.",
                                },
                                {
                                  min: 2,
                                  message:
                                    "First Name must be minimum 2 characters.",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder="First Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              name="LastName"
                              label="Last Name"
                              rules={[
                                { required: true, message: "Required" },
                                {
                                  pattern: /^[A-Za-z]+$/,
                                  message: "Only alphabets are allowed.",
                                },
                                {
                                  min: 2,
                                  message:
                                    "Last Name must be minimum 2 characters.",
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder="Last Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                         <Form.Item
                            label="Gender"
                            rules={[{ required: true, message: "Required" }]}
                            name="Gender"
                          >
                            <Select size={"large"} placeholder="Choose">
                              <Option value={0}>Male</Option>
                              <Option value={1}>Female</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Date of birth"
                            rules={[{ required: true, message: "Required" }]}
                            name="DOB"
                          >
                            <DatePicker
                              disabledDate={disabledFutureDate}
                              format={dateFormat}
                              size={"large"}
                              allowClear={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Passport Number"
                            name="PassportNumber"
                          >
                            <Input
                              size={"large"}
                              placeholder="Passport Number"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Passenger Nationality"
                            name="PassengerNationality"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            {/* <Select
                              size={"large"}
                              showSearch
                              placeholder="Select Country"
                              style={{ width: "100%" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option key={item.code} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select> */}
                            <Input size={"large"} />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item label="Passport DOI" name="PassportDOI">
                            <DatePicker
                              format={dateFormat}
                              size={"large"}
                              allowClear={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item label="Passport DOE" name="PassportDOE">
                            <DatePicker
                              format={dateFormat}
                              size={"large"}
                              allowClear={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="PAN Card"
                              name="PanCard"
                              rules={[
                                { required: true, message: "Required" },
                                {
                                  pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                  message: "Invalid PAN Card format.",
                                },
                              ]}
                            >
                              <Input size={"large"} placeholder="PAN Card" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Space size="middle">
                            <Button type="primary" htmlType="submit">
                              {isEditMode ? "Update" : "Add"} Details
                            </Button>
                            <Button
                              onClick={() => {
                                setFormVisible(false);
                              }}
                              style={{background:"red",color:"white"}}
                            >
                              Cancel
                            </Button>
                          </Space>
                        </Row>
                      </Form>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TravellerDetails;
