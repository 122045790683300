import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import { Collapse } from "antd";
import "./Refundpolicy.scss";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import Nav1 from "../../common/navbar/Nav1";
import Animation from "../AnimationBall/AnimationBalls";
const Refundpolicy = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;
  const [activeKeys, setActiveKeys] = useState([]);
  const [CMSList, setCMSList] = useState([]);
  useEffect(() => {
    const getCMSList = () => {
      const CMSId = 11;
      APIClient.get("admin/cms/" + `${CMSId}`)
        .then((response) => {
          if (response.status == 200) {
            // console.log(
            //   "retur description:",
            //   response.data.pages.map((se) => {
            //     return se.Description;
            //   })
            // );
            setCMSList(response.data);
            // setDescription(response.data.pages.map(se=>{return se.Description}))
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getCMSList();
  }, []);

  const handlePanelChange = (keys) => {
    // Limit the number of active panels to 2
    if (keys.length > 1) {
      keys.shift(); // Remove the oldest active panel to keep the most recent 2
    }
    setActiveKeys(keys);
  };
  return (
    <>
      <Nav1 />
      <div className="collapseCustomWhite">

        <div className="policy-container-txt">
          <h2 className="policy-title">Cancellation & Refund Policy</h2>
          <p className="policy-intro">
            OutC is committed to providing seamless travel experiences and offers a customer-friendly cancellation policy to ensure flexibility and convenience for all travelers.
          </p>
          <ul className="policy-list">
            <li>
              <span className="highlight">Flight Cancellations</span> will be considered only if the request is made within the specified timeframe set by the airline. Cancellations outside this period may not be entertained, as airlines may impose strict cancellation rules and fees.
            </li>
            <li>
              OutC does not accept cancellation requests for <span className="highlight">non-refundable flights</span> or for bookings that are within the airline’s <span className="highlight">24-hour departure window</span>. However, refunds or changes may be possible if the flight is canceled by the airline.
            </li>
            <li>
              For <span className="highlight">Hotel Cancellations</span>, the policy depends on the specific hotel’s terms. Generally, cancellations made up to 48 hours before check-in are eligible for a full refund. Cancellations after this period may incur charges, and no refund will be given for no-shows.
            </li>
            <li>
              If the <span className="highlight">hotel room does not match the description</span> on our website or if there is a quality issue with the accommodation, please contact our customer service within <span className="highlight">7 days</span> of check-in. The team will investigate and offer a resolution.
            </li>
            <li>
              For <span className="highlight">Bus Bookings</span>, cancellations must be made up to 24 hours before departure for a full or partial refund, based on the bus service provider's terms. Cancellations made closer to the departure time may incur cancellation fees or may not be refundable.
            </li>
            <li>
              In case of a <span className="highlight">service disruption</span> (e.g., flight delay, hotel overbooking, or bus cancellation by the provider), OutC will assist in providing alternative arrangements or a full refund. Refunds will be processed within <span className="highlight">7-14 business days</span>.
            </li>
            <li>
              Refunds for cancellations are typically processed within <span className="highlight">7-15 business days</span> after the request is confirmed. The refund will be credited to the Wallet or the original payment method used for booking by the user.
            </li>
          </ul>
        </div>

        <Animation />
        <ANTD.Layout className="ourteam-bg">
          <Content className="admin-container cms-pages-width">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="">
                  <div className="">
                    <h2 className="common-headings">Refund Policy</h2>

                    {/* <div>
                  {CMSList.map((res) => (
                      <div key={res.Id}>
                        <h3>{res.Title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: res.Description }} />
                      </div>
                    ))}
                  </div> */}
                  </div>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row>
              <ANTD.Col span={24}>
                {/* <ANTD.Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                  expandIconPosition="right"
                >
                  {CMSList.map((res) => (
                    <Panel
                      header={res.Title}
                      key={res.Id}
                      className="site-collapse-custom-panel"
                    >
                      <div dangerouslySetInnerHTML={{ __html: res.Description }} />
                    </Panel>
                  ))}
                </ANTD.Collapse> */}
                <Collapse
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                  expandIconPosition="right"
                  activeKey={activeKeys}
                  onChange={handlePanelChange}
                >
                  {CMSList.map((res) => (
                    <Panel
                      header={res.Title}
                      key={res.Id}
                      className="site-collapse-custom-panel"
                      style={{ background: "#fff", fontWeight: 600, color: "#000" }}
                    >
                      <div style={{fontFamily:"Nunito"}} dangerouslySetInnerHTML={{ __html: res.Description }} />
                    </Panel>
                  ))}
                </Collapse>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </ANTD.Layout>
      </div>
    </>
  );
};

export default Refundpolicy;
