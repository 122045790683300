import React, { useEffect, useState, useRef } from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import { Form, Input, Button, Select, Upload, message, Modal } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import APIClient from "../../helpers/ApiClient";
import './Careers.scss';
import {
  CloseOutlined,
  StarOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftSquareOutlined,
  PlusCircleFilled,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ActivitiesImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);

      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);

      // setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);

      return;
    }
    if (info.file.status === "done") {
      // setFileList(info.fileList);

      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
const Careers = () => {
  const [resumeFile, setResumeFile] = useState(null)
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const onFinish = (values) => {

    if (values?.resume?.fileList?.[0].response.status === 200) {
      // console.log(values?.resume?.fileList?.[0].response?.data?.filepaths[0], "path");

      let data = {

        fullname: values.fullName,
        email: values.email,
        phoneNo: values.phone,
        position: values.position,
        coverLetter: values.coverLetter,
        resume: values?.resume?.fileList?.[0].response?.data?.filepaths[0],

      }
      APIClient.post("admin/sendapplication", data)
        .then((response) => {
          if (response.status == 200) {
            message.success(`Dear ${data.fullName}, Thank you for submitting your application with Outc. Our concerned team will review and get back to you shortly.`);
            // console.log(data, "post");
            form.resetFields();
            setResumeFile(null);
          } else {
            message.error(response.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    }
    if (!resumeFile) {
      message.error('Please upload your resume before submitting the application.');
      return;
    }



    setShowForm(false);
  };





  const handleApplyButtonClick = () => {
    setShowForm(true);
  };
  return (
    <>
      {/* <CmsPages cmsId={8} pageTitle="Career Application Form" />; */}
      <div className="career-form-container">
        <CmsPages cmsId={8} pageTitle="Career Application Form" />
        <div className="career-form-container-1">
          {showForm ? (
            <Form
              form={form}
              name="careerApplication"
              onFinish={onFinish}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item
                label="Full Name"
                name="fullName"
                rules={[{ required: true, message: "Please enter your full name!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email address!" }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  { required: true, message: "Please enter your phone number!" },
                  {
                    //  pattern: "^[0-9]{10}$",
                    message: "Please enter a valid phone number!"
                  }
                ]}
              >
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} />
              </Form.Item>

              <Form.Item
                label="Applying for Position"
                name="position"
                rules={[{ required: true, message: "Please enter a position for you apply" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Cover Letter"
                name="coverLetter"
              // rules={[
              //   { required: true, message: "Please enter your cover letter!" }
              // ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item
                label="Resume"
                name="resume"
                rules={[
                  { required: true, message: 'Please provide your resume <1MB' },
                ]}
              >
                <div>
                  <ActivitiesImageUpload
                    category="resume"
                    fileList={resumeFile}
                    setFileList={setResumeFile}
                    formName="resume"
                    limit={true}
                    limitSize={1}
                  // deleteBanner={DeleteBannerImage}
                  />
                </div>
                {/* <Upload
                  customRequest={customRequest}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  accept=".pdf,.doc,.docx"
                  onRemove={onRemove}
                  fileList={resumeFile ? [{ ...resumeFile, name: resumeFile.name }] : []}
                >
                  <Button icon={<UploadOutlined />}>Upload Resume</Button>
                </Upload> */}
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit Application
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Button type="primary" onClick={handleApplyButtonClick} className="carrers-btn">
              Apply Now
            </Button>
          )}
        </div>
      </div>
    </>
  )
};

export default Careers;
