import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
} from "antd";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import arrowImg from "../../assets/images/flight-arrow.png";
import AirportAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectByCategory";
import { useSytContext } from "../../common/providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import { OriDesValidate } from "../../helpers/CustomValidators";
import { useLocalStorage, useSessionStorage } from "../../helpers/useStorage";
import "../flight-search/FlightSearch.scss";
import queryString from "query-string";
import MultiCityList from "./MultiCityList";
// import MultiCityList from "./MultiCityList";
import {
  createQueryParamsFromRequest,
  createSearchFromForm,
  getSearchRequestFromQuery,
  MapRequestToForm,
} from "./SearchRequestHelper";

const FlightSearch1 = (props) => {
  const dateFormat = "YYYY-MM-DD";
  let history = useHistory();
  const origin = useRef();
  const destination = useRef();

  const dateBox = useRef();
  const returnDateBox = useRef();
  const paxBox = useRef();
  const searchBtn = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();

  const [FlightRecentSearchesResults, setFlightRecentSearchesResults] =
    useSessionStorage("recentSearchesResults", []);

  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());
  const [priceForTrip, setPriceForTrip] = useState("REGULAR");
  const [travel_type, setTravelType] = useState("oneWay");
  const [passengers, setPassengers] = useState({
    adults: 1,
    childs: 0,
    infants: 0,
  });

  const [toggle, setTogle] = useState(false);
 
  // const [flightType, setFlightType] = useState(1)
  const [noofroutes, setnoofroutes] = useState(1);

  const setUpFormInitial = () => {
    let results = false;
    if (props.modify) {
      let query_request = getSearchRequestFromQuery();
      results = MapRequestToForm(query_request);
    } else {
      let stored_request = JSON.parse(
        sessionStorage.getItem("flight_search_request")
      );
     
      results = MapRequestToForm(stored_request);
    }

    if (results) {
      setTravelType(results.airTravelType);
      // setCabinClass(results.cabinClass);
      form.setFieldsValue(results);
      setPriceForTrip(results.priceForTrip);
      setPassengers({
        adults: results.adults,
        childs: results.childs,
        infants: results.infants,
      });
    }
  };

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);
      if (FlightRecentSearchesResults.length > 0) {
        let array = [];
        array = [...FlightRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setFlightRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) =>
                item.origin !== searchObj.origin &&
                item.destination !== searchObj.destination
            ),
          ]);
        }

        return;
      }
    }

    setFlightRecentSearchesResults([searchObj]);
  };
  let intitial = {
    cabinClass: "Economy",
    priceForTrip: "REGULAR",
    departure: departureDate,
    //  arrivalDate: arrivalDate,

    originDestinationFields: [{}, {}],
  };

  useEffect(() => {
    setUpFormInitial();
    let origin = form.getFieldValue("origin");
    let destination = form.getFieldValue("destination");
    if (!origin && !destination) {
      form.setFieldsValue({
        origin: "HYD",
        destination: "MAA",
      });
      setDataFlip((prev) => !prev);
    }
  }, [window.location.search]);
  const [cabinClass, setCabinClass] = useState("Economy");

  const { topCities } = useSytContext();
  const [FlightTopCities, setFlightTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 1);
      if (data.length) {
        setFlightTopCities(data);
      } else {
        setFlightTopCities([]);
      }
    }
  }, [topCities]);

  const updateTravelType = (val) => {
    if (val) {
      if (val === "oneWay") {
        form.setFieldsValue({
          arrival: "",
        });
      } else {
        form.setFieldsValue({
          arrival: moment().add(1, "days"),
        });
      }

      setTravelType(val);
    }
  };


  const onFormSubmit = (formData) => {
    // console.log(priceForTrip, "priceForTrip")

    if (formData?.originDestinationFields?.length > 0) {
      for (let i = 0; i < formData?.originDestinationFields?.length; i++) {
        if (formData?.originDestinationFields[i].origin === formData?.originDestinationFields[i].destination) {
          message.error("Origin and Destination cannot be same", 3);
          return false;
        }
      }
    }
    else {
      if (formData.origin === formData.destination) {
        message.error("Origin and Destination cannot be same", 3);
        return false;
      }
    }

    let includeCarrier = "";
    let stopOver = "none";
    const searchRequest = createSearchFromForm(
      formData,
      travel_type,
      cabinClass,
      passengers,
      includeCarrier,
      stopOver,
      priceForTrip,
    );
   

    const params = createQueryParamsFromRequest(searchRequest);
    localStorage.setItem("FlightSearchBar", JSON.stringify(params));
    recentSearches(params);

    if (props.modify) {
      history.replace("/flight/results?" + params);
      props.onFormSubmit();
    } else {
      history.push("/flight/results?" + params);
    }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  const handleFocus = () => {
    searchBtn.current.focus();
  };

  const updatePaxCount = (opr) => {
    if (passengers.adults + passengers.childs + passengers.infants == 9) {
      if (opr == "adultInc" || opr == "infantInc" || opr == "childInc") {
        return;
      }
    }
    switch (opr) {
      case "adultInc":
        setPassengers({ ...passengers, adults: passengers.adults + 1 });
        break;
      case "adultDec":
        if (passengers.adults > 1) {
          setPassengers({ ...passengers, adults: passengers.adults - 1 });
        }
        break;
      case "childInc":
        setPassengers({ ...passengers, childs: passengers.childs + 1 });
        break;
      case "childDec":
        if (passengers.childs >= 1) {
          setPassengers({ ...passengers, childs: passengers.childs - 1 });
        }
        break;
      case "infantInc":
        setPassengers({ ...passengers, infants: passengers.infants + 1 });
        break;
      case "infantDec":
        if (passengers.infants >= 1) {
          setPassengers({ ...passengers, infants: passengers.infants - 1 });
        }
        break;
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate && currentDate < moment(arrivalDate).startOf("day");
  };

  const PaxInput = () => (
    <Form.Item className="flight-passenger" rules={[{ required: true, message: "Add Travellers" }]}>
      <Input

        // required



        placeholder="Add Travellers"
        ref={paxBox}
        size="large"
        readOnly
        value={(passengers.adults + passengers.childs + passengers.infants) > 0 ? (passengers.adults + passengers.childs + passengers.infants) : "Add Travellers"}
        style={{ cursor: "pointer" }}
        onClick={() => setTogle(toggle ? false : true)}
      />
      <div
        style={{
          display: toggle ? "block" : "none",
        }}
        className="pax-modal"
      >
        <div className="pax-modal-wrapper">
          <div className="pax-modal-arrow"></div>
          <li>
            <div className="pax-label">
              <p>Adults</p>
              <span>over 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("adultDec")}
              ></i>
              <span>{passengers.adults}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("adultInc")}
              ></i>
            </div>
          </li>

          <li>
            <div className="pax-label">
              <p>Children</p>
              <span>2 - 12 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("childDec")}
              ></i>
              <span>{passengers.childs}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("childInc")}
              ></i>
            </div>
          </li>
          <li>
            <div className="pax-label">
              <p>Infants</p>
              <span>upto 2 years old</span>
            </div>
            <div className="pax-count">
              <i
                className="fa fa-minus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("infantDec")}
              ></i>
              <span>{passengers.infants}</span>
              <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={(e) => updatePaxCount("infantInc")}
              ></i>
            </div>
          </li>
          {passengers.infants > passengers.adults ? (
            <li>
              <span
                style={{
                  fontSize: "11px",
                  color: "red",
                }}
              >
                The number of infants cannot be grater than the number of
                adults.
              </span>
            </li>
          ) : (
            ""
          )}
          {passengers.adults + passengers.child > 9 ? (
            <li>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                style={{ fontSize: "30px" }}
              ></i>
              &nbsp;&nbsp;
              <span
                style={{
                  fontSize: "11px",
                  color: "blue",
                }}
              >
                Booking for more than 9 persons will be procced as group
                booking.
              </span>
            </li>
          ) : (
            ""
          )}
          <div>
            <Button
              block
              className="pax-ready-btn"
              onClick={() => {
                setTogle((prev) => !prev);
                if (toggle) {
                  handleFocus();
                }
              }}
            >
              Ready
            </Button>
          </div>
        </div>
      </div>
    </Form.Item>
  );

  const [dataFlip, setDataFlip] = useState(false);

  const handleSwap = () => {
    const origin = form.getFieldValue("origin");
    const destination = form.getFieldValue("destination");

    if (origin && destination) {
      form.setFieldsValue({
        origin: destination,
        destination: origin,
      });
      setDataFlip((prev) => !prev);
    }
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setArrivalDate(momentdate);
    const toDate = form.getFieldValue("arrival");
    if (travel_type === "roundTrip" && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          arrival: newTodate,
        });
      }
    }
  };
  const getnoofroutes = (count) => {
    // console.log("getno", count)
    setnoofroutes(count - 1)
    updateinputfield(count - 2, count);
  }

  const updateinputfield = (text, no) => {

    const fiel = form.getFieldValue("originDestinationFields");

    if (fiel?.[text]?.destination && (text < noofroutes || text < no)) {
      fiel[text + 1] = { ...fiel?.[text + 1], ["origin"]: fiel?.[text]?.destination };
      form.setFieldsValue("originDestinationFields", fiel)
    }

    //form.setFieldsValue(Object.assign(originDestinationFields?.[2],{origin:"HYD"}))
  }
  const getMulticityDates = (date, datestring, key) => {
    //console.log("date", date, datestring, key, form.getFieldValue("originDestinationFields"))
  }
  const [docTitle, setDocTitle] = useState("Book Best Flights");

  useEffect(() => {
    const handleBlur = () => {
      document.title = "Come Back, We Got Best Deals";
    };

    const handleFocus = () => {
      document.title = docTitle + " - " + AppConstants.DOMAIN_NAME;
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [docTitle]);

  // const [toggle, setTogle] = useState(false);
  // const paxBox = useRef();

  const handleToggle = () => {
    setTogle((prev) => !prev);
  };

  const handleConfirm = () => {
    const cabinClass = form.getFieldValue("cabinClass");

    setTogle(false);
  };

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent click from bubbling up to the parent div
  };

  const handleOutsideClick = () => {
    if (toggle) {
      setTogle(false); // Close the modal on clicking outside
    }
  };

  return (
    <div className="hotels_search_box" onClick={handleOutsideClick}>
      <Helmet>
        <title>
          {docTitle} - {" "}
          {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content=" Get cheap flight tickets, hotels, holiday packages with a best tour & travel company of India- OUTC.in  Avail complete travel solutions for a lifetime journey. "
        />
      </Helmet>
      <div className="hotels-wrapper">
        {/* <h2 className="lowest-ne-p">Find Next Place To Visit</h2> */}
        {/* <h6 className="lowest-ne-p1">Discover amzaing places at exclusive deals</h6> */}
        <div className="flight-bg-target">
          {/* <div className="class-container">
            <div>
              <Radio.Group
                onChange={(e) => updateTravelType(e.target.value)}
                value={travel_type}
              >
                <Radio id="oneWay"
                  className={`bdr-icon-f ${travel_type === "oneWay" ? "tripTypeActive" : "normalhighlight"
                    }`}
                  value={"oneWay"}
                >
                  One Way
                </Radio>
                <Radio id="roundTrip"
                  className={`bdr-icon-f ${travel_type === "roundTrip" ? "tripTypeActive" : "normalhighlight"
                    }`}
                  value={"roundTrip"}
                >
                  Round Trip
                </Radio>
                <Radio id="multiDestination" className={`bdr-icon-f ${travel_type === "multidestination" ? "tripTypeActive" : "normalhighlight"
                  }`}
                  value={"multidestination"}>Multi-City</Radio>
              </Radio.Group>

            </div>
            
          </div> */}

          <div id="oneWayForm">
            {props.modify && (
              <Row className="modifySelect" justify="space-between">
                <Col md={12} xs={12}>
                  <Radio.Group
                    onChange={(e) => updateTravelType(e.target.value)}
                    value={travel_type}
                  >
                    <Radio
                      id="oneWay"
                      className={`bdr-icon-f ${travel_type === "oneWay" ? "tripTypeActive" : ""
                        }`}
                      value={"oneWay"}
                    >
                      One way
                    </Radio>
                    <Radio
                      id="roundTrip"
                      className={`bdr-icon-f ${travel_type === "roundTrip" ? "tripTypeActive" : ""
                        }`}
                      value={"roundTrip"}
                    >
                      Round trip
                    </Radio>
                    <Radio id="multiDestination" className={`bdr-icon-f ${travel_type === "multidestination" ? "tripTypeActive" : ""
                      }`}
                      value={"multidestination"}>Multi-city</Radio>
                  </Radio.Group>

                </Col>

              </Row>
            )}
            <div className="outer-div-flight-searchfields" >
              <Form
                form={form}
                onFinish={onFormSubmit}
                className="flight-search flight-bg-side"
                layout="vertical"
                initialValues={intitial}
              >
                <Row className="flight-alert-sec">
                  {travel_type.toUpperCase() != "MULTIDESTINATION" ? (
                    <>
                      <Col md={4} xs={24} className="originwrapper" style={{ marginRight: 5 }}>
                        <span className="input-names">From</span>
                        <AirportAutoCompleteSelectByCategory
                          formItemProps={{
                            label: "From",
                            name: "origin",

                            rules: [
                              {
                                required: true,
                                message: "Please Specify The City",
                              },
                              {
                                validator: (_, value) => {
                                  let destination =
                                    form.getFieldValue("destination");
                                  return OriDesValidate(value, destination);
                                },
                              },
                            ],
                          }}
                          selectProps={{

                            size: "large",

                          }}
                          refName={origin}
                          focusRef={destination}
                          handleOnSubmit={handleOnSubmit}
                          dataFlip={dataFlip}
                          topCities={FlightTopCities}
                          recentKey={"originRecent"}
                        />
                        {/* <i class='fa fa-map-marker mapmap-mapm-map' ></i> */}

                        <img
                          className="arrow-bus-inr"
                          src={arrowImg}
                          alt="Arrow img"
                          onClick={handleSwap}
                        />
                      </Col>

                      <Col md={4} xs={24} className="destination" style={{ marginRight: 5 }}>
                        <span className="input-names">To</span>
                        <AirportAutoCompleteSelectByCategory
                          formItemProps={{
                            name: "destination",
                            rules: [
                              {
                                required: true,
                                message: "Please Specify The City",
                              },
                              {
                                validator: (_, value) => {
                                  let origin = form.getFieldValue("origin");
                                  return OriDesValidate(origin, value);
                                },
                              },
                            ],
                          }}
                          selectProps={{
                            size: "large",
                            placeholder: "Enter city or Airport",
                          }}
                          refName={destination}
                          focusRef={dateBox}
                          handleOnSubmit={handleOnSubmit}
                          dataFlip={dataFlip}
                          topCities={FlightTopCities}
                          recentKey={"destinationRecent"}
                        />
                        {/* <i class='fa fa-map-marker mapmap-mapm-map' ></i> */}

                      </Col>
                      <Col md={3} xs={24} style={{ marginRight: 5 }}>
                        <span className="input-names">Departure</span>
                        <Form.Item
                          name="departure"
                          rules={[
                            {
                              required: true,
                              message: "Please Select a Date",
                            },
                          ]}
                        >

                          <DatePicker
                            allowClear={false}
                            style={{ width: "100%" }}
                            className="train-search-btn "
                            size="large"
                            format={"DD MMM'YY"}
                            disabledDate={disabledOriginDate}
                            onChange={(date, dateString) => {
                              onChangeOriginDate(date, dateString)


                              if (travel_type === "roundTrip") {
                                setShowDate((prev) => !prev);
                                setShowReturnDate(true);
                                returnDateBox.current.focus();
                              }
                            }
                            }
                            ref={dateBox}
                            open={showDate}
                            onOpenChange={() => {
                              setShowDate((prev) => !prev);
                            }}
                            placeholder="Departure Date"
                            dropdownClassName="custom-mob-calendar"
                            inputReadOnly={true}
                            panelRender={(originalPanel) => {
                              return (
                                <div className="original-panel">
                                  <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                    Departure Date
                                  </p>
                                  {originalPanel}
                                </div>
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col md={4} xs={24} style={{ marginRight: 5 }}>
                        <span className="input-names">Departure</span>
                        <Form.Item
                          name="departure"
                          rules={[
                            {
                              required: true,
                              message: "Please Select a Date",
                            },
                          ]}
                        >
                          <DatePicker
                            allowClear={false}
                            style={{ width: "100%" }}
                            className="train-search-btn"
                            size="large"
                            format={"DD MMM'YY"}
                            disabledDate={props.disabledOriginDate}
                            onChange={handleDateChange}
                            ref={props.dateBox}
                            open={props.showDate}
                            onOpenChange={() => {
                              props.setShowDate((prev) => !prev);
                            }}
                            placeholder="Departure Date"
                            dropdownClassName="custom-mob-calendar"
                            inputReadOnly={true}
                            renderExtraFooter={() => renderCustomDate(selectedDate)}
                            panelRender={(originalPanel) => (
                              <div className="original-panel">
                                <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                  Departure Date
                                </p>
                                {originalPanel}
                              </div>
                            )}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col md={3} xs={24} style={{ marginRight: 5 }}>
                        <span className="input-names">Return</span>
                        <Form.Item
                          className="returnDate"

                          name="arrival"
                          rules={[
                            {
                              required:
                                travel_type === "roundTrip" ? true : false,
                              message: "Please Select a Date",
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder="Return Date"
                            dropdownClassName="custom-mob-calendar"
                            inputReadOnly={true}
                            style={{ width: "100%" }}
                            className="train-search-btn"
                            size="large"
                            format={"DD MMM'YY"}
                            defaultPickerValue={arrivalDate}
                            disabledDate={disabledDestDate}
                            onChange={(date) => {
                              if (!date) {
                                form.setFieldsValue({
                                  arrival: "",
                                });
                                setTravelType("oneWay");
                              }
                            }}
                            ref={returnDateBox}
                            open={showReturnDate}
                            onOpenChange={() => {
                              setShowReturnDate((prev) => !prev);
                            }}

                            disabled={travel_type !== "roundTrip" ? true : false}
                            onClick={() => {
                              if (travel_type !== "roundTrip") {
                                setTravelType("roundTrip");
                                form.setFieldsValue({
                                  // arrival: moment().add(1, "days"),
                                });
                              }
                            }}
                            panelRender={(originalPanel) => {
                              return (
                                <div className="original-panel">
                                  <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6">
                                    Return Date
                                  </p>
                                  {originalPanel}
                                </div>
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <MultiCityList /* setTogle={setTogle} */ updateinputfield={updateinputfield} getnoofroutes={getnoofroutes} props={props} />
                  )}
                  <Col md={5} xs={24} className="class-target-e trav-class" style={{ marginRight: 5 }} onClick={handleOutsideClick}>
                    <span className="input-names">Travellers & Class</span>
                    <Form.Item
                      name="cabinClass"
                      rules={[
                        {
                          required: true,
                          message: "Please select cabin class and travellers",
                        },
                      ]}
                    >
                      <Input
                        ref={paxBox}
                        size="large"
                        readOnly
                        value={
                          passengers.adults + passengers.childs + passengers.infants > 0
                            ? ""
                            : "Select Cabin Class and Travellers"
                        }
                        className="hidden-input"
                        style={{ cursor: "pointer" }}
                        onClick={handleToggle} // Toggle on input click
                      />
                      {passengers.adults + passengers.childs + passengers.infants > 0 && (
                        <div className="custom-display-value" onClick={handleToggle}>
                          <div className="travellers-count">
                            <span className="big-number">{passengers.adults + passengers.childs + passengers.infants}</span> Traveller
                          </div>
                          <div className="cabin-class">
                            {form.getFieldValue("cabinClass")}
                          </div>
                        </div>
                      )}
                      {toggle && (
                        <div className="flight-passenger">
                          <div className="pax-modal" onClick={handleModalClick}>
                            <div className="pax-modal-wrapper">
                              <div style={{ borderBottom: "1px solid #dcdee3" }}>
                                <li>
                                  <div className="pax-label">
                                    <p>Adults</p>
                                    <span>over 12 years old</span>
                                  </div>
                                  <div className="pax-count">
                                    <span className="countfor">{passengers.adults}</span>
                                    <div className="left-minus">
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("adultDec")}
                                      ></i>
                                    </div>
                                    <div className="right-plus">
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("adultInc")}
                                      ></i>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="pax-label">
                                    <p>Children</p>
                                    <span>2 - 12 years old</span>
                                  </div>
                                  <div className="pax-count">
                                    <span className="countfor">{passengers.childs}</span>
                                    <div className="left-minus">
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("childDec")}
                                      ></i>
                                    </div>
                                    <div className="right-plus">
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("childInc")}
                                      ></i>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="pax-label">
                                    <p>Infants</p>
                                    <span>up to 2 years old</span>
                                  </div>
                                  <div className="pax-count">
                                    <span className="countfor">{passengers.infants}</span>
                                    <div className="left-minus">
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("infantDec")}
                                      ></i>
                                    </div>
                                    <div className="right-plus">
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                        onClick={() => updatePaxCount("infantInc")}
                                      ></i>
                                    </div>
                                  </div>
                                </li>
                              </div>

                              <span className="cab-hdr">Cabin Class</span>
                              <li>
                                <div className="pax-count-type">
                                  <Radio.Group
                                    onChange={(e) => setCabinClass(e.target.value)} value={cabinClass}
                                    className="cabinclassss"
                                  >
                                    <Radio value="Economy" className={`${cabinClass === "Economy" ? "tripTypeAs" : ""}`}>Economy</Radio>

                                    <Radio value="Business" className={`${cabinClass === "Business" ? "tripTypeAs" : ""}`}>Business</Radio>
                                    <Radio value="First" className={`${cabinClass === "First" ? "tripTypeAs" : ""}`}>First Class</Radio>
                                  </Radio.Group>
                                </div>
                              </li>

                              {passengers.infants > passengers.adults && (
                                <li>
                                  <span style={{ fontSize: "11px", color: "red" }}>
                                    The number of infants cannot be greater than the number of adults.
                                  </span>
                                </li>
                              )}

                              {passengers.adults + passengers.childs > 9 && (
                                <li>
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                  &nbsp;&nbsp;
                                  <span style={{ fontSize: "11px", color: "blue" }}>
                                    Booking for more than 9 persons will be processed as group booking.
                                  </span>
                                </li>
                              )}

                              <div>
                                <Button
                                  block
                                  className="pax-ready-btn "
                                  onClick={handleConfirm}
                                >
                                  Ready
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </Col>

                  {/* <Col md={3} xs={24} className="class-target-e" style={{marginRight:5}}>
                  <span className="input-names">class</span>
                    <Form.Item
                      name="cabinClass"
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Cabin Class"
                        size="large"
                      >
                        <Option value="Economy">Economy</Option>
                        <Option value="premiumEconomy">Premium Economy</Option>
                        <Option value="Business">Business</Option>
                        <Option value="first">First Class</Option>
                        <Option value="premiumFirst">Premium First Class</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={2} xs={24} className="class-target-e"style={{marginRight:5}}>
                  <span className="input-names">Travellers</span>
                    <PaxInput />
                  </Col> */}


                  <Col md={2} xs={24} className="class-target-e" >
                    <Button
                      ref={searchBtn}
                      className="primary-btn sarchbtn"
                      htmlType="submit"

                    >
                      <SearchOutlined />
                      Search
                    </Button>
                  </Col>
                  <Col md={24} xs={24} className="class-target-e fres" style={{ marginTop: "6px" }} >

                    <div style={{
                      display: "flex", marginLeft: 0, background: "linear-gradient(135deg, #ffa80a 0%, rgba(255, 255, 255, 0) 100%)",
                      padding: "4px 12px", borderRadius:"6px"
                    }}>
                      <div >
                        <div style={{ fontSize: 16, fontWeight: "600", fontFamily: "Nunito" }}>Special Fares for </div>
                        <div style={{
                          background: "linear-gradient(to right, rgb(48 150 201), rgb(47 11 107))", color: "white", padding: "0px 6px",
                          borderRadius: "8px", width: 105, fontSize: 15, fontWeight: "600", fontFamily: "Nunito"
                        }}><span>Extra savings</span> </div>
                      </div>
                      <div style={{ marginTop: 8 }}>
                        <Radio.Group value={priceForTrip} onChange={(e) => setPriceForTrip(e.target.value)} >
                          <Radio value="REGULAR" className={`radiobtn ${priceForTrip === "REGULAR" ? "tripTypeA" : ""}`}>Regular Fare</Radio>
                          <Radio value="Student" className={`radiobtn ${priceForTrip === "Student" ? "tripTypeA" : ""}`}>Student Fare</Radio>
                          <Radio value="SeniorCitizen" className={`radiobtn ${priceForTrip === "SeniorCitizen" ? "tripTypeA" : ""}`}>Senior Citizen Fare</Radio>
                          <Radio value="ArmedForce" className={`radiobtn ${priceForTrip === "ArmedForce" ? "tripTypeA" : ""}`}>Defense Fare</Radio>

                        </Radio.Group>
                      </div>
                    </div>




                  </Col>
                </Row >
              </Form >
            </div >
          </div >
        </div >
      </div >
    </div >
  );
};

export default FlightSearch1;
