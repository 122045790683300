// import React, { useState, useEffect } from "react";
// import { Row, Col, Modal, Tabs, Popconfirm, Skeleton } from "antd";
// import { Link, useHistory } from "react-router-dom";
// import Slider from "react-slick";
// import ApiClient from "../../helpers/ApiClient";
// import moment from "moment";
// import img1 from "../../assets/images/OutcLogo.png";
// // import img2 from "../../assets/images/Offers/img2.jpg";
// // import img3 from "../../assets/images/Offers/img3.jpg";
// import "./HotelCities.scss";
// import Banner from "../banner/Banner";
// import { useSytContext } from "../../common/providers/SytProvider";
// // import { AppConstants } from "../../helpers/constants";
// import './HotelCities.scss';
// import queryString from "query-string";
// // import CustomImg from "../../assets/images/hotels/no_img.png";
// import CustomImg from "../../common/CustmImage";
// import hotelImg from "../../assets/images/hotels/ht-1.png";
// // import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
// const BASE = process.env.REACT_APP_BASE_URL;
// const MobileSlidersettings = {
//     arrows: true,
//     dots: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     infinite: false,
//     autoplay: true,
//     responsive: [
//         {
//             breakpoint: 1024,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//                 infinite: true,
//                 arrows: false,
//             },
//         },
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 initialSlide: 1,
//                 arrows: false,
//             },
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 arrows: false,
//             },
//         },
//     ],
//     appendDots: dots => (
//         <div className="slick-top">

//             {dots.slice(0, 4)}
//         </div>
//     ),
// };

// const HotSkeleton = () => {
//     return (
//         <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>
//             <div className="coupon-card">
//                 <div className="images-slider">
//                     <div
//                         style={{
//                             height: 200,
//                             borderRadius: "35px",
//                             backgroundColor: "#f2f2f2",
//                             display: "grid",
//                             placeItems: "center",
//                         }}
//                     >
//                         <Skeleton.Image />
//                     </div>
//                 </div>
//                 <div className="coupon-content">
//                     <div className="coupon-code">
//                         <div className="codes">
//                             <Skeleton.Input style={{ width: 50 }} active size="small" />
//                         </div>

//                         <div className="copy-code">
//                             <Skeleton.Input style={{ width: 50 }} active size="small" />
//                         </div>
//                     </div>
//                     <hr />
//                     <Skeleton active paragraph={{ rows: 1 }} />
//                     <hr />
//                     <div className="coupon-expiry-details">
//                         <div className="countdown">
//                             <Skeleton.Input style={{ width: 50 }} active size="small" />
//                         </div>
//                         <div className="expiry-date">
//                             <Skeleton.Input style={{ width: 50 }} active size="small" />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </Col>
//     );
// };

// const HotelCities = (props) => {
//     let dateFormat = "YYYY-MM-DD";
//     let activeTab = 1;

//     const BASE = process.env.REACT_APP_BASE_URL;
//     const [cityHotelData, setCityHotelData] = useState([]);
//     const [cityHotel, setCityHotel] = useState([]);
//     const [recentSearchResults, setRecentSearchResults] = useState([]);
//     useEffect(() => {

//         getCityHotel();

//     }, []);
//     let tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD");
//     let dayafter = moment().add(2, "days").format("YYYY-MM-DD");
//     const getCityHotel = () => {
//         ApiClient.get("admin/dynamic/hotel")
//             .then((res) => {
//                 if (res.status === 200) {
//                     console.log(res, "respcities");
//                     let data = res?.data?.map((item) => {
//                         return (
//                             item?.result
//                         );
//                     }
//                     );
//                     setIsLoading(false);
//                     setCityHotel(data);

//                 }
//             })
//             .catch((e) => console.log("api error", e));
//     };
//     function tabsChange(key) {
//         activeTab = key;
//     }

//     const goto = useHistory();
//     const { TabPane } = Tabs;
//     const [details, setDetails] = useState(false);
//     const [isLoading, setIsLoading] = useState(true);

//     function getUniqueCities(data) {
//         const citiesSet = new Set();

//         // Traverse through each city's hotel array
//         data.forEach(cityHot => {
//             cityHot.forEach(hotel => {
//                 citiesSet.add(hotel.city); // Add each city to the Set to keep it unique
//             });
//         });

//         return Array.from(citiesSet); // Convert Set to an array
//     }

//     // Example usage
//     const uniqueCities = getUniqueCities(cityHotel);
//     console.log(uniqueCities, "sggg");

//     const StarRating = ({ rating }) => {
//         const numStars = parseFloat(rating);
//         const starsArray = Array.from({ length: numStars }, (_, index) => index);

//         return (
//             <div className="str-top-ht">
//                 {starsArray?.map((_, index) => (
//                     <span
//                         key={index}
//                         role="img"
//                         aria-label="star"
//                         style={{
//                             textShadow: "3px 2px 6px grey",
//                             marginRight: "1px"
//                         }}
//                     >
//                         ⭐
//                     </span>
//                 ))}
//             </div>
//         );
//     };


//     const getHotelSer = (url) => {

//         let formData = {
//             checkInDate: tomorrowDate,
//             checkOutDate: dayafter,
//             hotelCityCode: url?.hotelCityCode,
//             hotelId: url?.hotelId,
//             roomGuests: JSON.stringify([{
//                 noOfAdults: 1,
//                 noOfChilds: 0,
//                 childAge: [],
//             },]),
//             nationality: "IN",
//             currency: "INR",
//             countryCode: "IN",
//             traceId: "string",
//         };
//     };




//     return (

//         <div className="HotelsCitys">
//             <div className="HotelsCity-wrapper">
//                 <div className="container">
//                     <div className="HotelsCitys-section">
//                         <div className="service-type-tabs">
//                             <Tabs defaultActiveKey={1} onChange={tabsChange}>
//                                 {uniqueCities.map((CityName, ind) => (
//                                     <>
//                                         {console.log(ind + 1, CityName, "india")}
//                                         {isLoading ? (
//                                             <HotSkeleton />
//                                         ) : cityHotel ? cityHotel?.map((item, index) => (

//                                             <TabPane tab={CityName} key={ind + 1}>
//                                                 <div className="tab1-content">
//                                                     <Row gutter={[32, 32]}>

//                                                         <div className="cont-top-hotel">
//                                                             {console.log(cityHotel, CityName, ind, index, "cityy")}


//                                                             <Slider {...MobileSlidersettings}>
//                                                                 {item?.map((city, i) => (
//                                                                     <div key={i} className="slick-slide-item">
//                                                                         <div className="main-head-img-1">
//                                                                             <Link
//                                                                                 to={getHotelSer(city)}

//                                                                             >{city?.Images?.length > 1 ?
//                                                                                 <CustomImg
//                                                                                     className="top-hotel-imag-lik"
//                                                                                     src={city?.Images?.[1]}
//                                                                                     alt='Hotel'
//                                                                                 />
//                                                                                 :
//                                                                                 <img
//                                                                                     className="top-hotel-imag-lik"
//                                                                                     src={hotelImg}
//                                                                                     alt="Hotel Image" />}
//                                                                                 <div className="hotel-info-card">
//                                                                                     <div className="property-nm">
//                                                                                         <p className="pr-name">{city?.propertyName}</p>
//                                                                                         <StarRating rating={city?.starRating} />
//                                                                                     </div>
//                                                                                     <div className="add-prop">
//                                                                                         <p className="add-prop-1">{city?.addresses?.address}</p>
//                                                                                     </div>
//                                                                                 </div>

//                                                                             </Link>
//                                                                         </div>


//                                                                     </div>
//                                                                 ))}
//                                                             </Slider>
//                                                         </div>
//                                                     </Row>
//                                                 </div>
//                                             </TabPane>
//                                         )) : "im"}
//                                     </>

//                                 ))}
//                             </Tabs >
//                         </div>
//                     </div>
//                 </div >
//             </div >
//         </div >
//     );
// };

// export default HotelCities;

import React, { useState, useEffect } from "react";
import { Tabs, Card, Carousel, Spin } from "antd";
import { EnvironmentOutlined, HeartOutlined, BankOutlined, StarTwoTone } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import Slider from 'react-slick';
import "./TripPlanner.scss";
import queryString from "query-string";
import {Link} from "react-router-dom";
import moment from "moment";

const TripPlanner = () => {
    const { TabPane } = Tabs;
    const [cityHotels, setCityHotels] = useState([]);
    const [activeTab, setActiveTab] = useState(null); // Initialize as null
    const [tabs, setTabs] = useState([]); // Dynamic tabs
    const [isLoading, setIsLoading] = useState(true);

    const MobileSlidersettings = {
        arrows: false,
        dots: false,
        slidesToShow: 4,
        speed: 500,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };

  
    const tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
    const dayafter = moment().add(6, "days").format("YYYY-MM-DD");
  
    const getHotelSer = (hotel) => {
        
        const cityName=`${hotel.city},${hotel.country}`   
        
      const formData = {
        checkInDate: tomorrowDate,
        checkOutDate: dayafter,
        hotelCityCode: `cityName=${cityName}&&cityId=${hotel.cityId}`,
        roomGuests: JSON.stringify([{ noOfAdults: 1, noOfChilds: 0, childAge: [] }]),
        nationality: "IN",
        currency: "INR",
        countryCode: "IN",
        traceId: "string",
      };
      const query = queryString.stringify(formData);
      return "/hotels/listing?" + query;
    };
    const StarRating = ({ rating }) => {
        const numStars = parseFloat(rating);
        const starsArray = Array.from({ length: numStars }, (_, index) => index);

        return (
            <div className="str-top-ht" style={{fontsize: "10px",marginRight:50}}>
                {starsArray?.map((_, index) => (
                    <span
                        key={index}
                        role="img"
                        aria-label="star"
                        style={{
                            textShadow: "3px 2px 6px grey",
                            marginRight: "1px"
                        }}
                    >
                        <StarTwoTone />
                        {/* ⭐ */}
                    </span>
                ))}
            </div>
        );
    };

    // Fetch city hotel data
    const getCityHotel = () => {
        ApiClient.get("admin/dynamic/hotel")
            .then((res) => {
                if (res.status === 200) {
                    const citiesWithHotels = res.data.map((cityHotel) => {
                        return {
                            cityName: cityHotel.cityName,
                            hotels: cityHotel.result.map((hotel) => ({
                                name: hotel.propertyName,
                                city: hotel.city,
                                country: hotel.countryName,
                                cityId: hotel.cityId,
                                image: hotel.Images[0],
                                address: hotel.addresses[0]?.address,
                                starRating: hotel.starRating,
                            })),
                        };
                    });

                    const allHotels = citiesWithHotels.flatMap((city) => city.hotels);
                    const dynamicTabs = citiesWithHotels.map((city) => ({
                        key: city.cityName,
                        label: (
                            <>
                                <EnvironmentOutlined /> {city.cityName}
                            </>
                        ),
                    }));

                    setCityHotels(allHotels);
                    setTabs(dynamicTabs);

                    // Set default active tab after tabs are created
                    if (dynamicTabs.length > 0) {
                        setActiveTab(dynamicTabs[0].key); // Set the first tab as default
                    }

                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.error("API error:", e);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getCityHotel();
    }, []);

    // Filter hotels based on the active tab (city name)
    const filteredHotels = cityHotels.filter((hotel) => hotel.city === activeTab);

    console.log(tabs, activeTab, filteredHotels);

    return (
        <div className="trip-planner">
            <h2>Quick and easy trip planner</h2>
            <p>Pick a vibe and explore the top destinations in India</p>
            {isLoading ? (
                <Spin size="large" />
            ) : (
                <>
                    {activeTab && (
                        <Tabs activeKey={activeTab} onChange={setActiveTab}>
                            {tabs?.map((tab) => (
                                <TabPane tab={tab.label} key={tab.key}>
                                    {/* You can add any additional content inside each TabPane */}
                                    <Slider {...MobileSlidersettings}>
                                        {filteredHotels.map((hotel, index) => (
                                            <Link to={getHotelSer(hotel)}>
                                            <Card
                                                style={{ width: 200, height: 210,  }}
                                                key={index}
                                                hoverable
                                                cover={<img alt={hotel.name} src={hotel.image} />}
                                               
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div
            style={{
                maxWidth: "120px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize:20,
                fontWeight:700,
                fontFamily:"Nunito",
                marginTop: "10px",
                marginLeft: "10px",
            }}
          
        >
            {hotel.name}
        </div>
        <StarRating rating={hotel.starRating} />
    </div>
                                                {/* <p style={{ marginTop: 8 }}>{hotel.address}</p> */}
                                            </Card>
                                            </Link>
                                        ))}
                                    </Slider>
                                </TabPane>
                            ))}
                        </Tabs>
                    )}
                </>
            )}
        </div>
    );
};

export default TripPlanner;




