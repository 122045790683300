import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useState, useRef, } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ApiClient from "../../helpers/ApiClient";
import CountryList from "../../common/CountryList";
import Login from "../Login/Login";
import OTP from "../OTP/Otp";
import "./UserRegistration.scss";
import { useHistory } from "react-router-dom";
import logo1 from "../../assets/images/inner-bg.jpg";
import right from "../../assets/images/double-right-96.png";
import { auth, provider, fbAuthProvider } from "./firebas.js";
import { signInWithPopup } from "firebase/auth";
import GoogleButton from 'react-google-button';
import { useAuthContext } from "../../common/providers/AuthProvider";
import Nav1 from "../../common/navbar/Nav1.jsx";
import Animation from "../AnimationBall/AnimationBalls.jsx";
import setimage from "../../assets/images/filterno.jpg";

const UserRegistration = ({ location, }) => {
  const {
    extraUserInfo,
    setExtraUserInfo,
    setUser,
    setAccessToken,
    setAdminAsUser,
    setAccessList,
    updateAgentLoginFlag,
    updateLoginFlag,
    updateDashboardFlag,

    resetIsLogin,
  } = useAuthContext();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [isloading, setisloading] = useState(false);
  const [userId, setUserId] = useState(-1);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const mobile = useRef();
  const history = useHistory();
  // const areaCode = useRef();
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const handleAreaCodeChange = () => {
    // When the area code changes, set the focus on the Mobile Number input field
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };


  const onFinish = (values) => {
    //console.log(values,"fff")
    let regObj = {
      Name: values.username,
      Email: values.email,
      DialingCode: values.DialingCode,
      Mobile: values.phone,
      Password: values.Password,
      isloginType: values?.providerId === "google.com" ? 2 : 1,
      Role: 2,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj).then((response) => {
      if (response?.status == 200) {
        setUserId(response.data.userDetails.UserId);
        setShowSuccessMessage(true);
        message.success(response.message);
      } else if (response?.status == 409) {
        message.error(response.message);
      }
    });
  };


  const prefixSelector = (
    <Form.Item name="DialingCode" noStyle>
      <Select style={{ width: 80 }} showSearch
        placeholder="+91"
        onChange={handleAreaCodeChange}
        ref={areaCodeRef}
        focusRef={mobile}>
        {CountryList.map((item) => (
          <Option key={item.dial_code} value={item.dial_code}>
            {item.dial_code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        // console.log(data, "data");
        request(data);
      })
      .catch((error) => {
        console.log("Error signing in with popup:", error);
      });
  };

  const request = (data) => {
    if (data && data.user && data._tokenResponse) {
      let regObj = {
        Name: data.user.displayName ? data.user.displayName : '',
        Email: data.user.email ? data.user.email : '',
        Mobile: data.user.phoneNumber ? data.user.phoneNumber : '',
        Password: "",
        isloginType: data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,
        Role: 2,
        DeviceToken: "string",
        DeviceType: "Web",
        FirBaseToken: "string",
      };

      // console.log(regObj, " regobj");

      if (data?._tokenResponse?.isNewUser) {
        ApiClient.post("admin/user/register", regObj)
          .then((response) => {
            if (response?.status === 200) {
              message.success(response.message);
              verifyOtp1(response.data.userDetails.UserId, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
            } else if (response?.status === 409) {
              message.error(response.message);
            }
          })
          .catch((error) => {
            console.log("Error registering user:", error);
          });
      } else {
        attemptLogin(data.user.email, "", true, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
      }
    } else {
      console.log("User data is undefined or missing _tokenResponse");
    }
  };
  const verifyOtp1 = (userId, logintype) => {
    let verifyObj = {
      UserID: userId,
      Otp: "",
      OtpType: 2,
      isloginType: logintype,
    };

    ApiClient.post("admin/verifyotp", verifyObj)
      .then((resp) => {
        handleVerifyOtpResponse(resp, logintype);
      })
      .catch((error) => {
        console.log("Error verifying OTP:", error);
      });
  };
  const handleAdminAsUser = (id) => {
    setAdminAsUser({
      status: true,
      id,
    });
    setExtraUserInfo(null);
  };

  const handleVerifyOtpResponse = (resp, logintype) => {
    if (resp.status === 202) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);
      updateLoginFlag(true);
      attemptLogin(resp.data.userDetails.Email, "", true, logintype);
    } else if (resp.status === 201) {
      setModalVisible(false);
      message.success(resp.message, 3);
      history.push("/");
    } else if (resp.status === 400) {
      message.error("Invalid Otp", 3);
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    }
  };

  const attemptLogin = (username, password, redirect, logintype) => {
    setisloading(false);
    let obj = {
      UserName: username,
      Password: password,
      Role: 2,
      isloginType: logintype,
    };

    ApiClient.post("admin/login", obj)
      .then((resp) => {
        handleLoginResponse(resp, redirect);
      })
      .catch((e) => {
        setisloading(false);
      });
  };

  const handleLoginResponse = (resp, redirect) => {
    setisloading(false);
    if (resp.status === 200) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);

      if (resp.data.userDetails.Role.RoleLevel === 1) {
        let screens = [];
        resp.data.userDetails.Screens.forEach((element) => {
          screens.push(element.Name);
        });
        setAccessList(screens);
        setModalVisible(false);
        updateDashboardFlag(true);
        if (redirect) {
          history.push("/");
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 3) {
        setModalVisible(false);
        updateAgentLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 2) {
        setModalVisible(false);
        updateLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else {
        resetIsLogin();
      }
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    } else if (resp.status === 400 || resp.status === 404) {
      message.error(resp.message, 3);
      let errorMsg = resp?.message ? resp?.message : "Wrong Email or Password";
      form.setFields([
        {
          name: "username",
          errors: [" "],
          value: "",
        },
        {
          name: "password",
          errors: [errorMsg],
          value: "",
        },
      ]);
    }
  };
  const handleOk = (e) => {
    setShowSuccessMessage(false);
  };

  const handleCancel = (e) => {
    setShowSuccessMessage(false);
  };

  const openlogin = () => {
    setLoginModal(true);
  };

  const handleOkLogin = (e) => {
    setLoginModal(false);
  };

  const handleCancelLogin = (e) => {
    setLoginModal(false);
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const handleRedirect = () => {
    let path = "/";
    if (location) {
      if (location?.state?.login) path = location.state?.from?.pathname ?? path;
    }

    history.push(path, {});
  };

  return (
    <>
      <Nav1 />
      {/* <div className="register-section">
        <div className="reg-container" >
          <h2 style={{ textAlign: "start" }}>Register </h2>
          <p style={{ textAlign: "start", color: "black" }}> sign in</p>
          <div className="scial">
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
              <GoogleButton type="light" onClick={handleClick} style={{ marginBottom: 10, borderRadius: 5 }} />
            </div>

            <div className="line-with-or-container" style={{ textAlign: 'center' }}>
              <div className="line" />
              <div className="or"> OR </div>
              <div className="line" />
            </div>
          </div>
          <Row className="register-row" style={{ marginRight: "432px" }}>
            <Col md={7} xs={24}></Col>
            <Col md={10} xs={24} className="right-panel">
              <Card className="register-card" style={{ background: "none", border: "none" }}>
                <div className="register-card-body" style={{ width: 400 }}>
                  <Form
                    name="basic"
                    form={form}
                    initialValues={{ DialingCode: "" }}
                    onFinish={onFinish}
                    size="large"
                  >

                    <Form.Item
                      label="User Name"
                      name="username"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: "Required" },
                        {
                          pattern: "^[a-zA-Z_ ]*$",

                          message: "Must be Alphabet",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Your Full Name" />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="email"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                    <Form.Item
                      label="Phone Number"
                      name="phone"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Valid Phone Number  ",
                        },

                      ]}
                    >
                      <Input
                        ref={mobileRef}
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Enter Your Number"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="Password"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Password is required"
                        },
                        {
                          validator: (_, value) => {
                            if (value.length < 8 || value.length > 24) {
                              return Promise.reject(new Error('Password must be greater than 8 characters and include special characters & numbers'));
                            }
                            if (!/\d/.test(value)) {
                              return Promise.reject(new Error('Password must contain at least one number'));
                            }
                            if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                              return Promise.reject(new Error('Password must contain at least one special character'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.Password placeholder="Enter Password" />
                    </Form.Item>


                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Required"),
                        },
                      ]}
                    >
                      <Checkbox>
                        <p className="mb-0">
                          I agree with{" "}
                          <Link to="/termsofconditions" target="_blank">
                            {" "}
                            Terms & conditions
                          </Link>{" "}
                          and{" "}
                          <Link to="/privacypolicy" target="_blank">
                            {" "}
                            Privacy Policy
                          </Link>
                        </p>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item className="register-btn-container">
                      <Button
                        //disabled={buttonDisabled}
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Register
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="otp-modal-container">
                  <Modal
                    visible={showSuccessMessage}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={false}
                    className="otp-modal"
                  >
                    <OTP
                      userId={userId}
                      setUserId={setUserId}
                      setModalVisible={(val) => setShowSuccessMessage(val)}
                    />
                  </Modal>
                </div>
              </Card>
            </Col>
            <Col md={7} xs={24}></Col>
          </Row>
          <Col span={24} className="acc-section">
            <div className="have-account register-user">
              <div className="acc-text">
                <p>
                  <strong style={{ color: "black" }}>Already have an account ? <span style={{ cursor: "pointer", color: "blue" }} onClick={handleRedirect}>Sign in</span></strong>
                </p>
              </div>

            </div>
          </Col>

        </div>
      </div> */}
      <img src={logo1} alt="Background" style={{ width: "100%" }} />
      <div className="login-header">
        <h3 style={{ color: "white", padding: "14px 36px" }}>Register</h3>
        {/* <Link to="/">Home <img src={right} alt="right" style={{width:"18px"}}/> Register</Link>  */}
        <Link className="login-Hlink" to="/">Home</Link>
      </div>
      <Row className="img-pop-login-reg">
        <Col md={24} xs={24} style={{ margin: 46, display: "flex", justifyContent: "space-evenly" }}>
          <div className="info-box">
            {/* <h3 className="info-heading">Why Choose Us?</h3>
            <p className="info-text">
              We offer the best deals for your travel needs, secure payments, and exclusive promotions.
            </p> */}
            <img
              src={setimage}
              alt="Promotion"
              className="promo-image"
            />
            <ul className="info-list">
              <li>Exclusive travel deals</li>
              <li>24/7 customer support</li>
              <li>Hassle-free booking</li>
            </ul>
            <ul className="benefits-list" style={{ color: "black" }}>
              <li>
                <strong>Secure Transactions:</strong> Your information is protected with advanced encryption and secure systems.
              </li>
              <li>
                <strong>Exclusive Promotions:</strong> Unlock special discounts and offers available only to signed-in users.
              </li>
              <li>
                <strong>Save Preferences:</strong> Keep track of your bookings, preferences, and previous searches for a seamless experience.
              </li>
              <li>
                <strong>Faster Checkouts:</strong> Autofill your saved details for quicker booking.
              </li>
              {/* <li>
                <strong>Personalized Recommendations:</strong> Get suggestions tailored to your interests and preferences.
              </li> */}
            </ul>
          </div>
          <Animation />
          <div className="login-user-box">
            <Form form={form} name="basic" onFinish={onFinish} size="large" className="custom-form" requiredMark={false}>
              <div className="login-text mb-3" style={{ textAlign: 'start' }}>
                <h3>To avail benefits, please Register !</h3>
                <p>Signing in ensures you enjoy a safe and personalized experience</p>
              </div>


              <Form.Item
                label="User Name"
                name="username"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: "^[a-zA-Z_ ]*$",

                    message: "Must be Alphabet",
                  },
                ]}
              >
                <Input placeholder="Enter Your Full Name" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please enter your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Enter Your Email" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  ref={mobileRef}
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Your Number"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="Password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Password is required"
                  },
                  {
                    validator: (_, value) => {
                      if (value.length < 8 || value.length > 24) {
                        return Promise.reject(new Error('Password must be greater than 8 characters and include special characters & numbers'));
                      }
                      if (!/\d/.test(value)) {
                        return Promise.reject(new Error('Password must contain at least one number'));
                      }
                      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                        return Promise.reject(new Error('Password must contain at least one special character'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password placeholder="Enter Password" />
              </Form.Item>


              <Form.Item
                name="remember"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject("Required"),
                  },
                ]}
                style={{ display: "flex", justifyContent: "center" }}

              >
                <Checkbox style={{ display: "flex", justifyContent: "center" }}>

                  <p className="mb-0">
                    I agree with{" "}
                    <Link to="/termsofconditions" target="_blank">
                      {" "}
                      Terms & conditions
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacypolicy" target="_blank">
                      {" "}
                      Privacy Policy
                    </Link>
                  </p>
                </Checkbox>
              </Form.Item>

              <Form.Item className="register-btn-container">
                <Button
                  //disabled={buttonDisabled}
                  type="primary"
                  onClick={() => form.submit()}
                  className="loginnn"
                >
                  Register
                </Button>
              </Form.Item>
            </Form>

            <div className="scial">
              <div className="line-with-or-container" style={{ textAlign: 'center' }}>
                <div className="line" />
                <div className="ors"> OR </div>
                <div className="line" />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: "20px" }}>
                <GoogleButton type="light" onClick={handleClick} style={{ marginBottom: 10, borderRadius: 5 }} />
              </div>

            </div>
            <div className="have-account register-user">
              <div className="acc-text">
                <p style={{ fontFamily: "nunito", color: "black" }}>
                  If you’re already registered, click here to <span className="login-hov" onClick={openlogin}>Sign in</span>
                </p>
              </div>

            </div>
          </div>

        </Col>
        {/* <Col span={24} className="acc-section" style={{ marginTop: -62 }}>
          <div className="have-account register-user">
            <div className="acc-text">
              <p style={{ fontFamily: "nunito", color: "black" }}>
                If you’re already registered, click here to <span className="login-hov" onClick={openlogin}>Sign in</span>
              </p>
            </div>

          </div>
        </Col> */}
      </Row>
      <div className="otp-modal-container">
        <Modal
          visible={showSuccessMessage}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
          className="otp-modal"
        >
          <OTP
            userId={userId}
            setUserId={setUserId}
            setModalVisible={(val) => setShowSuccessMessage(val)}
          />
        </Modal>
      </div>
      <Modal
        visible={loginModal}
        onOk={handleOkLogin}
        onCancel={handleCancelLogin}
        footer={false}
        className="login-modal"
        centered
      >
        <Login setModalVisible={(value) => setLoginModal(value)} />
      </Modal>

    </>
  );
};

export default UserRegistration;
