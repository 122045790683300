import React, { useState, useEffect } from "react";
import { Col, Row, Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import bann7 from "../../../assets/images/bg/1 (9).png";
import Hotels from "../../../components/Hotels/Hotels";
import SubscribeN from "../../../components/subscribe/SubscribeN";
import { DiscountBan } from "../../../components/HomeBanners/DiscountBan";
// import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import hot from "../../../assets/images/hotels/ban-icon (2).png";
import Animation from "../../../components/AnimationBall/AnimationBalls";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import moment from "moment";
import bann2 from "../../../assets/images/bg/1 (3).png";
import bann1 from "../../../assets/images/hotels/bann2.png";
import bann3 from "../../../assets/images/hotels/hot-ban3.png";
import bann4 from "../../../assets/images/hotels/hot-ban4.png";
import bann5 from "../../../assets/images/hotels/hot-ban5.png";
import bann6 from "../../../assets/images/bg/1 (6).png";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";
import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
import Nav from "../../../common/navbar/Nav";
import TopDestination from "./TopDestination";
import PopularDestinations from "./PopularDestination";
import ImageGrid from "./ImageGrid";
import HotelCities from "../../../components/Hotels/HotelCities";
import TripPlanner from "../../../components/Hotels/HotelCities";
import QueryContainer from "./QueryContainer";
const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [deal, setDeal] = useState([])
  const [recentSearchResults, setRecentSearchResults] = useState([]);

  const { promoDataSource } = useSytContext();

  const getCityHotelList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        // {console.log(res,"ht-res")}
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 2
          );
          let result = data.reduce(function (obj, key) {
            obj[`${key.CityName}, ${key.CountryName}`] =
              obj[`${key.CityName}, ${key.CountryName}`] || [];
            obj[`${key.CityName}, ${key.CountryName}`].push(key);
            return obj;
          }, {});

          setCityHotelData(result);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  useEffect(() => {
    // getCityHotelList();
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 2);
      setDataSource(data);
    }
  }, [promoDataSource]);

  // const [background, setBackground] = useState("radial-gradient(circle, #004cff, #0097ff)");

  // let timeout;

  // function handleMouseMove(event) {
  //   clearTimeout(timeout);
  //   timeout = setTimeout(() => {
  //     const x = event.clientX; // get the X coordinate of the cursor
  //     const y = event.clientY; // get the Y coordinate of the cursor

  //     const width = window.innerWidth; // get the width of the viewport
  //     const height = window.innerHeight; // get the height of the viewport

  //     const centerX = width / 2; // calculate the X coordinate of the center of the viewport
  //     const centerY = height / 2; // calculate the Y coordinate of the center of the viewport

  //     const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2); // calculate the distance between cursor and center

  //     const maxDistance = Math.sqrt(centerX ** 2 + centerY ** 2); // calculate the maximum distance from the center to the corner

  //     const gradientRadius = (distance / maxDistance) * 100; // calculate the radius of the gradient circle

  //     const newBackground = `radial-gradient(circle at ${x}px ${y}px, #004cff,  #0097ff ${gradientRadius}%, transparent ${gradientRadius + 5}%, transparent 100%)`;

  //     setBackground(newBackground); // update the background color
  //   }, 10); // Adjust debounce time as needed for smoother transition
  // }
  useEffect(() => {
   // getRecentSearches();
    // getDeal();
  }, []);
  // const getRecentSearches = () => {
  //   let data = sessionStorage.getItem("hotelRecentSearchesResults");

  //   if (data.length) {
  //     setRecentSearchResults(JSON.parse(data));
  //   }
  // };
  const [docTitle, setDocTitle] = useState("Book Best Luxury Hotels Online");

  useEffect(() => {
    const handleBlur = () => {
      document.title = "Come Back, We Got Best Hotels";
    };

    const handleFocus = () => {
      document.title = docTitle + " - " + AppConstants.DOMAIN_NAME;
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [docTitle]);



  const getHotelUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.checkInDate = moment().add(1, "days").format("YYYY-MM-DD");
      params.checkOutDate = moment().add(2, "days").format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/hotels/listing?${params}`;
    } else {
      return "";
    }
  };
  const getDeal = () => {
    ApiClient.get("admin/dealsimages")
      .then((res) => {
        if (res.status === 200) {
          // console.log(resServiceType, "ddd");

          let data = res.data.filter((item) => item.ServiceType === 2);




          setDeal(data);

        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };

  return (
    <>
      <Nav />
      <div className="hotels_search_container">
        <Helmet>
          <title>
            {docTitle} - {AppConstants.DOMAIN_NAME}

          </title>
          <meta
            name="description"
            content="Best hotel deals are available at Outc. Book budget hotels, luxury hotels or resorts at cost effective rates."
          />
        </Helmet>
        <section className="hotel_banner">
          <div className="head" >
            <Banner banner={hotelBanners} size={"400px"} />
            {/* <Banner banner={2} /> */}

          </div>

          <div className="heading_text ">
            <h2>
              {/* Hotels and Accommodation. Book safely with the option of
            cancellation. */}
              {/* Find Next Best Place To Stay */}
            </h2>
          </div>
          <div className="hotel_search">
            <CombineServLink activetab={1} />
          </div>
        </section>

        {recentSearchResults.length ? (
          <section className="home-best-24 recentSearchesRow">
            {/* <div className="container">
            <Row gutter={16} className="justify-content-end align-items-center">
              <Col md={4} xs={24}>
                <p className="m-0 text-right">Recent Searches: </p>
              </Col>
              {recentSearchResults.map((item, i) => {
                item.checkInDate = moment(item.checkInDate, "YYYY-MM-DD")
                  .add(2, "days")
                  .format("YYYY-MM-DD");
                item.checkOutDate = moment(item.checkOutDate, "YYYY-MM-DD")
                  .add(2, "days")
                  .format("YYYY-MM-DD");
                let newString = queryString.stringify(item);

                return (
                  <Col md={4} xs={24}>
                    <div key={i + "recentresults"}>
                      <Link to={`hotels/listing?${newString}`}>
                        <div
                          style={{
                            boxShadow: "0 1px 5px black",
                            borderRadius: "6px",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div className="ducarosuel">
                            <div className="city-due ">
                              <i
                                className="fa fa-building iconsDiv pr-2"
                                aria-hidden="true"
                              ></i>

                              {item.hotelCityCode.split("=")[1].split(",")[0]}
                            </div>
                            <div className="time-due">
                              {moment(item.checkInDate).format("DD, MMM YYYY")}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div> */}
          </section>
        ) : (
          ""
        )}

        {/* <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <Link
                exact={true}
                to="/hotelOfflineRequest"
                target={"_blank"}
                className="hovered-link"
              >
                <div className="offers-bal-cart hovered-text ">
                  <img
                    className="deals-coloums-24"
                    src={
                      require("../../../assets/images/one-icon-ts.png").default
                    }
                    alt="icon"
                  />
                  &nbsp;
                  <div className="price-deals-24 animate__flash ">
                    <p className="font-weight-bold ">
                      FOR INDEPENDENT TRAVELERS (FIT)
                    </p>
                    <p>
                      Please submit your details here to get discounted fares .
                    </p>
                  </div>
                </div>{" "}
              </Link>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <Link
                exact={true}
                to="/hotelGroupEnquiry"
                target={"_blank"}
                className="hovered-link"
              >
                <div className="offers-bal-cart hovered-text  ">
                  <img
                    className="deals-coloums-24"
                    src={
                      require("../../../assets/images/one-icon-ts1.png").default
                    }
                    alt="icon hotel"
                  />
                  &nbsp;
                  <div className="price-deals-24 animate__flash">
                    <p className="font-weight-bold">
                      GROUP INCLUSIVE TOURS (GIT)
                    </p>
                    <p>Please submit your group pax details here.</p>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section> */}
        {/* <section className="home-best-24">
          <div className="container">
            <Row>
              <Col md={12} xs={24}>
                <div className="offers-bal-cart">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/one-icon-ts.png").default}
                    alt=""
                  />
                  &nbsp;
                  <div className="price-deals-24">
                    <p>BEST PRICE GUARANTEED</p>
                    <p>
                      Trying our level best to fetch lower price than others, try
                      us once!!
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={12} xs={24} className="support-24">
                <div className="offers-bal-cart">
                  <img
                    className="deals-coloums-24"
                    src={require("../../../assets/images/one-icon-ts1.png").default}
                    alt=""
                  />
                  &nbsp;
                  <div className="price-deals-24">
                    <p>24×7 SUPPORT</p>
                    <p>
                      We’re always here for you – reach us 24 hours a day, 7 days
                      a week.{" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section> */}
        {/* <div className="container">
          

          <Row className="heading-hotel">
            <Col md={24} xs={24}>
              <h3 style={{ color: "#bd0c21" }}>Only here! New, larger accommodation base</h3>
              <p className="mb-0">
                Hotels, apartments, bungalows &amp; homestays - just the way you
                like it
              </p>
            </Col>
          </Row>

          {Object.keys(cityHotelData).length > 0
            ? Object.keys(cityHotelData).map((cityHotel) => (
              <>
                <Row gutter={[6, 6]}>
                  <Col md={24} xs={24}>
                    <p style={{ background: "radial-gradient(#bd0c2157, transparent)", borderRadius: "10px" }} className="text-center font-weight-bold mb-0">
                      {cityHotel}
                    </p>
                  </Col>
                  {cityHotelData[cityHotel].map((item) => (
                    <Col md={6} xs={24}>
                      <Link
                        to={getHotelUrl(item.SearchURL)}
                        className="d-block"
                      >
                        <img
                          className="cart-shopping-img"
                          src={
                            item.HotelImage1
                              ? `${BASE}${item.HotelImage1.substring(1)}`
                              : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                .default
                          }
                          alt="Hotel Image"
                        />
                      </Link>
                      <p className="text-center font-weight-bold mb-0">
                        {item.Title}
                      </p>
                    </Col>
                  ))}
                </Row>{" "}
              </>
            ))
            : null}
        </div> */}
        <section className="home-best-24" style={{ marginTop: "-140px" }}>
          <div className="container" style={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" classname="bann1" src={bann1} alt="" style={{ borderRadius: "6px" }} />

          </div>
        </section>
        <section className="home-st" >
          <div className="mark-st-banner">
            <h6 style={{ marginBottom: "20px" }}>Offers for You</h6>
            {/* <img width="100%" src={bann5} alt="" /> */}
            <OffersSlider dataSource={dataSource} />
          </div>
        </section>

        <section className="home-st" style={{ marginTop: "-70px" }}>
          <div className="mark-st-banner1">
            <h6>Top Cities For You</h6>
            <TopDestination />
            {/* <img width="100%" src={bann4} alt="" /> */}
          </div>
        </section>
        <section className="cont-bann-sec">
          <div className="comm-ban">
            <div className="banner-wrapper">
              <div data-testid="wcu_bh_banner-desktop" className="banner-container">
                <a target="_blank" href="#" className="banner-link">
                  <div className="circle-containerM1">
                    <div className="heartbeat-circle1"></div>
                    <div className="blue-circle1"></div>
                  </div>
                  <div className="circle-containerM">
                    <div className="heartbeat-circle"></div>
                    <div className="blue-circle"></div>
                  </div>
                  <div className="text-and-button">
                    <div className="text-container">
                      <span className="main-text">Find </span>
                      <div className="scrolling-list-wrapper">
                        <ul className="scrolling-list">
                          <li className="list-item">apartments</li>
                          <li className="list-item">villas</li>
                          <li className="list-item">hostels</li>
                          <li className="list-item">holiday homes</li>
                          <li className="list-item">cottages</li>
                          <li className="list-item">homes</li>
                          {/* <li className="list-item">apartments</li> */}
                        </ul>
                      </div>
                      <div className="main-text1"> for your next trip</div>
                    </div>
                    <button className="discover-button">Discover homes</button>
                  </div>
                  <div className="image-container1">
                    <img src={hot} alt="Banner" className="banner-image-icon" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="home-st">
          <div className="mark-st-banner">
            {/* <h6>Top Hotels For You</h6> */}
            <PopularDestinations />
            {/* <img width="100%" src={bann5} alt="" /> */}
          </div>
        </section>



        {/* <section className="hotels_advantages">
          <div className="hotels-advantages-wrapper">
            <div className="hotels-adv-box left-box">
              <img

                src={
                  require("../../../assets/images/hotels/save_up_to.png").default
                }
                alt="hotel icon"
              />
              <div className="hotels-adv-box-wrapper">
                <p style={{ color: "#35459c" }}> SAVE UP TO 30%!</p>
                <small>
                  We've made deals with over 1.3 M hotels so you can book up to
                  30% cheaper
                </small>
              </div>
            </div>
            <div className="hotels-adv-box right-box">
              <img
                src={require("../../../assets/images/hotels/no_tf.png").default}
                alt="hotel icon"
              />
              <div className="hotels-adv-box-wrapper">
                <p style={{ color: "#35459c" }}> NO HIDDEN CHARGES</p>
                <small>
                  We tell you exactly what you'll pay from the very beginning -
                  transparent reservation policies mean no nasty surprises
                </small>
              </div>
            </div>
          </div>
          {deal.length > 1 ?
            <div className="imgbanner-ht">
              <HomeCarousel deal={deal} />
            </div> : null}
        </section> */}


        <section className="home-st">
          <div className="mark-st-banner">
            {/* <h6>Top Hotels For You</h6> */}
            {/* <img width="100%" src={bann3} alt="" /> */}
          </div>
        </section>
        <section className="home-st" style={{width:1200, margin:"4% 9%"}}>
          <TripPlanner />
        </section>

        <DiscountBan page={"h"} />
        <section className="home-st">
          <div className="mark-st-banner">
            <h6>Trending Destinations</h6>
            <p>Most Popular Destinations For Travellers From India</p>
            <ImageGrid />
            {/* <img width="100%" src={bann2} alt="" /> */}
          </div>
        </section>
        <section className="queries_about_hotels">
          <Animation />
          
          <QueryContainer/>
        </section>
       
        {/* <section className="top-route-bb">
          <Reviews serviceType={2} />
        </section> */}

        {/* <section className="home-st">
        <div className="mark-st-banner">
          <h6>News & Travel Stories</h6>
          <img width="100%" src={bann6} alt="" />
        </div>
      </section> */}

        {/* <Subscribe /> */}
        <SubscribeN />

      </div >
    </>
  );
};

export default HotelSearch;
