import React from 'react';
import { Link } from 'react-router-dom';
import './PopularDestinations.scss';
import moment from "moment";
import goa from '../../../assets/images/hotels/goa1.jpg';
import mumbai from '../../../assets/images/hotels/Mumbai1.jpg';
import bangalore from '../../../assets/images/hotels/bangalore.webp';
import hyderabad from '../../../assets/images/hotels/hyderabad.jpg';
import pune from '../../../assets/images/hotels/pune.jpg';
import chennai from '../../../assets/images/hotels/chennai.jpg';
import kolkata from '../../../assets/images/hotels/kolkata1.jpg';
import delhi from '../../../assets/images/hotels/delhibg.webp';
import queryString from "query-string";
import Slider from 'react-slick';

const destinations = [
  { name: "New Delhi", state: "Delhi", image: delhi, properties: 2528, cityName: "Delhi,India", cityId: 22846 },
  { name: "Mumbai", state: "Maharashtra", image: mumbai, properties: 1177, cityName: "Mumbai,India", cityId: 22798 },
  { name: "Goa", state: "Goa", image: goa, properties: 184, cityName: "Goa,India", cityId: 22506 },
  { name: "Chennai", state: "Tamil Nadu", image: chennai, properties: 949, cityName: "Chennai,India", cityId: 22401 },
  { name: "Kolkata", state: "West Bengal", image: kolkata, properties: 742, cityName: "Kolkata,India", cityId: 22681 },
  { name: "Hyderabad", state: "Telangana", image: hyderabad, properties: 1025, cityName: "Hyderabad,India", cityId: 22555 },
  { name: "Bangalore", state: "Karnataka", image: bangalore, properties: 357, cityName: "Bangalore,India", cityId: 22327 },
  { name: "Pune", state: "Maharashtra", image: pune, properties: 777, cityName: "Pune,India", cityId: 22918 },
];

const tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
const dayafter = moment().add(6, 'days').format("YYYY-MM-DD");

const getHotelSer = (cityName, cityId) => {
  const formData = {
    checkInDate: tomorrowDate,
    checkOutDate: dayafter,
    hotelCityCode: `cityName=${cityName}&&cityId=${cityId}`,
    roomGuests: JSON.stringify([{ noOfAdults: 1, noOfChilds: 0, childAge: [] }]),
    nationality: "IN",
    currency: "INR",
    countryCode: "IN",
    traceId: "string",
  };
  const query = queryString.stringify(formData);
  return "/hotels/listing?" + query;
};

const PopularDestinations = () => {
  const MobileSlidersettings = {
    arrows: false,
    dots: false,
    // slidesToShow:2,
    slidesToShow: 5,
    speed: 600,
    smooth: true,
    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          // slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  //   const sliderSettings = {
  //     dots: false,
  //     arrows: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 5,
  //     slidesToScroll: 1,
  //     autoplay: true,
  //     autoplaySpeed: 3000, // Autoplay interval in milliseconds
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 5,
  //           slidesToScroll: 1,
  //           infinite: true,
  //           arrows: false,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 1,
  //           arrows: false,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //           arrows: false,
  //         },
  //       },
  //     ],
  //   };

  return (
    <div className="popular-destinations">
      <h2 className="title">Popular Destinations</h2>
      <Slider {...MobileSlidersettings} className="destinations-slider">
        {destinations.map((destination, index) => (
          <Link
            key={index}
            to={getHotelSer(destination.cityName, destination.cityId)}
            className="destination-card"
          >
            <div className="destination-image-wrapper">
              <img src={destination.image} alt={destination.name} className="destination-image" />
            </div>
            <div className="destination-overlay">
              <h3 className="destination-name">{destination.name}</h3>
              <p className="destination-state">{destination.state}</p>
            </div>
            <div>
              <p className="destination-properties">{destination.properties} Properties &rarr;</p>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );

};

export default PopularDestinations;
