// import React from "react";
// import { useState } from "react";
// import "./FlightsCheckout.scss";
// import { Card, Col, Row, Modal, Tabs } from "antd";
// import moment from "moment";
// import travel from "../../assets/images/travel.png";
// import plane from "../../assets/images/plane.png";
// import fliy from "../../assets/images/Icons/fli8.png"
// import { useHistory } from "react-router-dom";
// import dateFormat from "dateformat";
// import ReactHtmlParser from "react-html-parser";
// import { FlightTimings } from "../SearchResults/Flight/Flight";
// import { useAuthContext } from "../../common/providers/AuthProvider";
// import ApiClient from "../../helpers/ApiClient";
// import CustomFliImg from "../../common/CustomImg/CusFliLogo"

// const DetailsCard = (props) => {
//   const {
//     isLogin: { agent },
//     user,
//   } = useAuthContext();
//   let travelType = localStorage.getItem("airTravelType");
//   let history = useHistory();
//   const { TabPane } = Tabs;
//   const goTo = () => {
//     history.goBack();
//   };
//   const [baggage, setBaggage] = useState(false);
//   const toggleBaggage = () => {
//     setBaggage((prevState) => !prevState);
//   };

//   const FlightCard = ({ flightInfo, flightSearchObj, type, travelType }) => {
//     const [fareRulesResp, setFareRulesResp] = useState("");
//     const [cmsFareRules, setCmsFareRules] = useState({});
//     const [showFareRules, setShowFareRules] = useState(false);

//     const showModal = () => {
//       let obj = {
//         airlineCode: flightInfo.airLine,
//         fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
//         roleID: user?.Role?.RoleId ?? 4,
//         membershipID: user?.Membership ?? 1,
//       };
//       setShowFareRules(true);
//       //getCmsFareRules(obj);
//       fetchFlightFares(obj);
//     };

//     const getCmsFareRules = (obj) => {
//       ApiClient.get("admin/getFlightFareRules", obj)
//         .then((res) => {
//           if (res.status == 200) {
//             setCmsFareRules(res.data);
//           } else {
//             setCmsFareRules({});
//           }
//         })
//         .catch((e) => {
//           setCmsFareRules({});
//         });
//     };

//     // const fetchFlightFares = (str, event) => {
//     //   //console.log(str,airSearchData,flightInfo ,"ffffff")
//     //   if (str === "2") {
//     //     // let fareReqObj = {
//     //     //   traceId: airSearchData.traceId,
//     //     //   flightId: flightInfo.flightId,
//     //     //   airTravelType: airSearchData.airTravelType,
//     //     //   mappingType: airSearchData.resultsType,
//     //     //   itineraryViewType: "1",
//     //     //   flowType: "SEARCH",
//     //     //   priceId: ""
//     //     // };

//     //     let FareRuleObj = {
//     //       flowType: "SEARCH",
//     //       traceId: airSearchData.traceId,
//     //       flightId: flightInfo.flightId,
//     //       airTravelType: airSearchData.airTravelType,
//     //       priceId: flightInfo.fareFamilies.fareFamilies[0].fareId,
//     //       mappingType: airSearchData.resultsType,
//     //       itineraryViewType: "1",

//     //     }
//     //     //console.log(FareRuleObj,"FareRuleObj")
//     //     ApiClient.post("flights/airFareRules", FareRuleObj)
//     //       .then((result) => {
//     //         return result;
//     //       })
//     //       .then((resp) => {
//     //         if (resp.statusCode === 200) {
//     //           if (resp.data) {
//     //             if (resp.data.fareRules) {
//     //               setFareRulesResp(resp);
//     //             }
//     //           }

//     //           // setFareRulesResp(resp.data.errors[0].errorDetail);
//     //           // props.setFlightFareRules(resp.data.errors[0].errorDetail);
//     //         } else {
//     //           let error_resp = {
//     //             data: {
//     //               fareRules: [
//     //                 {
//     //                   ruleDetails:
//     //                     "Something went wrong , Fare Rules not Available",
//     //                 },
//     //               ],
//     //             },
//     //           };
//     //           setFareRulesResp(error_resp);
//     //         }
//     //       })
//     //       .catch((err) => console.log(err));
//     //   }
//     // };

//     const fetchFlightFares = (obj) => {
//       //if (str === "2") {
//       // let fareReqObj = {
//       //   traceId: airSearchData.traceId,
//       //   flightId: flightInfo.flightId,
//       //   airTravelType: airSearchData.airTravelType,
//       //   mappingType: airSearchData.resultsType,
//       //   itineraryViewType: "1",
//       //   flowType: "SEARCH",
//       //   priceId: ""
//       // };

//       let FareRuleObj = {
//         flowType: "SEARCH",
//         traceId: flightSearchObj.traceId,
//         flightId: flightInfo.flightId,
//         airTravelType: flightSearchObj.airTravelType,
//         priceId: flightInfo.fareFamilies.fareFamilies[0].fareId,
//         mappingType: flightSearchObj.resultsType,
//         itineraryViewType: "1",
//       };
//       //console.log(FareRuleObj,"FareRuleObj")
//       ApiClient.post("flights/airFareRules", FareRuleObj)
//         .then((result) => {
//           return result;
//         })
//         .then((resp) => {
//           if (resp.statusCode === 200) {
//             if (resp.data) {
//               if (resp.data.fareRules) {
//                 setFareRulesResp(resp);
//               }
//             }

//             // setFareRulesResp(resp.data.errors[0].errorDetail);
//             // props.setFlightFareRules(resp.data.errors[0].errorDetail);
//           } else {
//             let error_resp = {
//               data: {
//                 fareRules: [
//                   {
//                     ruleDetails:
//                       "Something went wrong , Fare Rules not Available",
//                   },
//                 ],
//               },
//             };
//             setFareRulesResp(error_resp);
//           }
//         })
//         .catch((err) => console.log(err));
//       // }
//     };

//     return (
//       <div className="flight-details-card checkout_details_card">
//         {/* <div className="airline-details">
//           <Row className="airline-row-mobile">
//             <Col md={16}>
//               <div className="airline-details-left">
//                 <Row className="airline-row-mobile">
//                   <Col md={6} xs={12}>
//                     <div className="
//                     ">
//                       <img src={flightInfo.airLineLogo} alt="" />
//                     </div>
//                   </Col>
//                   <Col md={12} className="src-dest-mobile">
//                     <div className="airline-src-dest">
//                       {/* <p>{flightInfo.origin}</p> *
//                       <p>{flightInfo?.originCity}</p>
//                       <span className="airline-line"></span>
//                       {flightInfo.flightSegments?.length > 1 ? (
//                         <>
//                           {flightInfo.flightSegments.map((segment, index) =>
//                             index !== 0 ? <p>{segment.origin}</p> : null
//                           )}
//                           <span className="airline-line"></span>
//                         </>
//                       )
//                         : null}
//                       <p>
//                         {/* {
//                           flightInfo.flightSegments[
//                             flightInfo.flightSegments.length - 1
//                           ].destination
//                         } *
//                         {flightInfo.destinationCity}
//                       </p>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </Col>
//             <Col md={8} className="direct-text">
//               <p>
//                 Journey Date:{" "}
//                 {dateFormat(
//                   flightInfo.flightSegments?.[0].departureDateTime,
//                   "dd mmm (ddd)"
//                 )}{" "}
//               </p>
//               <p>
//                 {flightInfo.flightSegments?.length === 1 ? (
//                   "Direct"
//                 ) : (
//                   <>
//                     {flightInfo.flightSegments?.length - 1}
//                     stop(s)
//                   </>
//                 )}
//               </p>
//               {agent ? (
//                 <p>
//                   Fare Type:{" "}
//                   {flightInfo.fareFamilies.fareFamilies[0].coupanType}
//                 </p>
//               ) : null}
//             </Col>
//           </Row>
//         </div>
//         <div className="trip-details">
//           <Row>
//             <FlightTimings
//               flightInfo={flightInfo}
//               airSearchData={props.flightSearchObj}
//               fareFamilies={flightInfo.fareFamilies}
//             />
//           </Row>
//         </div> */}
//         <Card className="inner-card">
//           {/* {console.log(type,travelType,"typ")} */}
//           <div className="type-flight">
//             <p>
//               {type === 1 && travelType != "multidestination"
//                 ? "RETURN"
//                 : "DEPART"}
//             </p>
//           </div>
//           {/* <div className="type-flight"><p>{travelType == "multidestination" && "DEPART"}</p></div> */}
//           <div className="inner-flight-card">
//             {/* {console.log(flightInfo, travelType, "info")} */}
//             <Row>
//               <div className="travel-info">
//                 {type === 0 ? (
//                   <img src={plane} alt="" />
//                 ) : travelType != "multidestination" && type === 1 ? (
//                   <img className="return-plane" src={plane} alt="" />
//                 ) : (
//                   <img src={plane} alt="" />
//                 )}
//                 {/* { travelType!= "multidestination" ? <img src={plane} alt="" /> : null } */}
//                 <span className="flightcheckout-mob-elepsis">
//                   {flightInfo.originCity}-
//                 </span>
//                 <span className="flightcheckout-mob-elepsis">
//                   {flightInfo.destinationCity}
//                 </span>
//                 <div className="travel-date">
//                   <span>
//                     |
//                     {dateFormat(
//                       flightInfo.flightSegments[0].departureDateTime,
//                       "ddd-ddmmmyyyy"
//                     )}{" "}
//                   </span>
//                 </div>
//                 {/* mobile */}
//                 <div className="travel-date1">
//                   <br />{" "}
//                   <span>
//                     |
//                     {dateFormat(
//                       flightInfo.flightSegments[0].departureDateTime,
//                       "ddd-ddmmmyyyy"
//                     )}{" "}
//                   </span>
//                 </div>
//               </div>
//             </Row>
//             {flightInfo.flightSegments.map((flight, id) => {
//               return (
//                 <Row className="row-of-det-flight">
//                   <Col md={24} xs={24}>
//                     <Row>
//                       <Col className="col-icos-flight">
//                         <div className="flight-logo">
//                           <CustomFliImg

//                             className="img-hot"
//                             // style={{ width: "50px", height: "50px" }}
//                             src={flightInfo.airLineLogo}
//                             alt=""
//                           />
//                           {/* <img
//                             style={{ width: "50px", height: "50px" }}
//                             src={flightInfo.airLineLogo}
//                             alt=""
//                           ></img> */}
//                           {/* {flightInfo.fareFamilies.fareFamilies[0].coupanType} */}
//                         </div>
//                         <div className="flight-name">
//                           <div className="airlinename">{flight.airLineName}</div>
//                           <div className="airlinenumber">
//                             {flight.marketingAirline}-{flight.flightNumber}
//                           </div>
//                         </div>
//                       </Col>

//                       <Col className="mobmobmobmobmob">
//                         <div className="departure-start">
//                           <div className="departure-time">
//                             {moment(flight.departureDateTime).format("HH:mm")}
//                           </div>
//                           <div className="departure-info">
//                             <h4>
//                               {flight.originCity}({flight.origin})
//                             </h4>
//                             <div>
//                               {dateFormat(
//                                 flight.departureDateTime,
//                                 "ddd-ddmmmyyyy"
//                               )}{" "}
//                             </div>
//                             {flight.departureTerminal != "" ? (
//                               <div>
//                                 <p style={{ color: "#35459c" }}>
//                                   Terminal -{flight.departureTerminal}
//                                 </p>
//                               </div>
//                             ) : (
//                               "N/a"
//                             )}
//                           </div>
//                         </div>
//                       </Col>
//                       <Col className="col-flight-det-pointsfromto">
//                         <div className="travel-duration">
//                           <div className="time">
//                             <p>
//                               {flight.journeyDuration}
//                               {/* {flightInfo?.duration} */}
//                             </p>
//                             <img src={plane} alt="" />
//                             {/* <img src={fliy} alt="" /> */}
//                             <div className="line-l">
//                               <div className="cir1"></div>
//                               <div className="cir2"></div>
//                             </div>
//                             {flightInfo.isRefundable === true ? (
//                               <div className="status">Refundable</div>
//                             ) : (
//                               <div className="status1">Non-Refundable</div>
//                             )}
//                           </div>
//                         </div>
//                       </Col>
//                       <Col className="arrival-col mobmobmobmobmob">
//                         <div className="departure-start">
//                           <div className="departure-time">
//                             {moment(flight.arrivalDateTime).format("HH:mm")}
//                           </div>
//                           <div className="departure-info">
//                             <h4>
//                               {flight.destinationCity}({flight.destination})
//                             </h4>
//                             <div>
//                               {dateFormat(flight.arrivalDateTime, "ddd-ddmmmyyyy")}{" "}
//                             </div>
//                             {flight?.arrivalTerminal != "" ? (
//                               <div>
//                                 <p style={{ color: "#35459c" }}>
//                                   Terminal -{flight?.arrivalTerminal}
//                                 </p>
//                               </div>
//                             ) : (
//                               "N/a"
//                             )}
//                           </div>
//                         </div>
//                       </Col>

//                       <div className="layOver-time">
//                         {id === flightInfo.flightSegments.length - 1 ? null : (
//                           <p>
//                             {moment
//                               .utc(
//                                 moment(
//                                   flightInfo.flightSegments[id + 1]
//                                     ?.departureDateTime
//                                 ).diff(moment(flight.arrivalDateTime))
//                               )
//                               .format("HH:mm")}{" "}
//                             hrs Layover at {flight.destinationCity}(
//                             {flight.destination})
//                           </p>
//                         )}
//                       </div>
//                       {id === flightInfo.flightSegments.length - 1 ? null : (
//                         <div className="line-layOver"></div>
//                       )}
//                     </Row>
//                   </Col>
//                 </Row>
//               );
//             })}
//             {/* <div>
//               <div className="policy">
//                 <div className="fares">
//                   <h4 onClick={toggleBaggage}>
//                     <a>Baggage</a>
//                   </h4>
//                 </div>
//               </div>

//               {baggage ? (
//                 <div className="baggage-table">
//                   <Row className="baggage-header">
//                     <div className="header">
//                       <h4>AirLine</h4>
//                       <h4>Check-in Baggage</h4>
//                       <h4>Cabin Baggage</h4>
//                     </div>
//                   </Row>
//                   {console.log(flightInfo, "fi")}
//                   {flightInfo?.fareFamilies?.fareFamilies?.map(
//                     (flight, id) => {
//                       return (
//                         <Row className="baggage-info">
//                           <Col md={4} xs={24}>
//                             <div className="flight-logo-1">
//                               <img
//                                 style={{ width: "35px", height: "35px" }}
//                                 src={flightInfo?.airLineLogo}
//                                 alt=""
//                               ></img>
//                             </div>
//                           </Col>

//                           <Col md={7} xs={24}>
//                             <div className="flight-name-bagg">
//                               <div className="airname">
//                                 <p>{flightInfo.airLineName}</p>
//                               </div>
//                               <div className="airnumber">
//                                 {/* {flightInfo?.flightSegments.map((segments, id) => {
//                             return (
//                               <p>{segments?.marketingAirline}-{segments?.flightNumber}</p>
//                             )
//                           })} */}
//             {/* <p>{flight.marketingAirline}-{flight.flightNumber}</p> *
//                               </div>
//                             </div>
//                           </Col>
//                           <Col md={8} xs={24}>
//                             <div className="Check-in-Bag">
//                               <p>{flight?.baggage[0]?.baggageInfo}</p>
//                             </div>
//                           </Col>
//                           <Col md={5} xs={24}>
//                             <div className="Cabin-in-Bag">
//                               <p>{flight?.baggage[0]?.cabinBaggageInfo}</p>
//                             </div>
//                           </Col>
//                         </Row>
//                       );
//                     }
//                   )}
//                 </div>
//               ) : null}

//               {/* <div className="fares-1"><h4 onClick={() => setFareRules(true)}>Fare Rules</h4></div>
//                 {fareRules ?
//                 <div>
//                   <div>{flightInfo?.fareFamilies?.fareFamilies[0]?.fareRules.map((data, id) => {
//                     return (<div>
//                       <div>{id + 1}Flight</div>
//                       {/* {console.log(data,"fdt")} *
//                       <div> <pre>{ReactHtmlParser(data.ruleDetails)}</pre></div>
//                     </div>
//                     )
//                   })}</div>
//                 </div> : null} *
//             </div> */}

//             <div className="policy">
//               <div className="fares" >
//                 <h4 onClick={showModal}>
//                   <a>Flight Details</a>
//                 </h4>
//               </div>
//               <div>
//                 <Modal
//                   title="Flight Details"
//                   visible={showFareRules}
//                   footer={[null]}
//                   onOk={(e) => setShowFareRules(false)}
//                   onCancel={(e) => setShowFareRules(false)}
//                   className="flight-details-modal"
//                 >
//                   <div className="all-times-local">
//                     <Tabs
//                       defaultActiveKey="1"
//                       onTabClick={(str, e) => fetchFlightFares(str, e)}
//                     >
//                       <TabPane tab="Baggage" key="1">

//                         <div className="baggage-table">

//                           <div className="headerbg">
//                             <h4>AirLine</h4>
//                             <h4>Check-in Baggage</h4>
//                             <h4>Cabin Baggage</h4>
//                           </div>

//                           {console.log(flightInfo, "fi")}
//                           {/* {flightInfo?.fareFamilies?.fareFamilies?.map( */}
//                           {flightInfo?.flightSegments?.map(
//                             (flight, id) => {
//                               return (
//                                 <Row className="baggage-info">
//                                   {/* <Col md={4} xs={24}>
//                                     <div className="flight-logo-1">
//                                       <img
//                                         style={{ width: "35px", height: "35px" }}
//                                         src={flightInfo?.airLineLogo}
//                                         alt=""
//                                       ></img>
//                                     </div>
//                                   </Col> */}

//                                   <Col md={8} xs={24}>
//                                     <div className="airtype">
//                                       <div className="flight-logo-1">
//                                         <img
//                                           style={{ width: "35px", height: "35px" }}
//                                           src={flight?.airLineLogo}
//                                           alt={flight?.marketingAirline}
//                                         ></img>
//                                       </div>
//                                       <div className="flight-name-bagg">
//                                         <div className="airname">
//                                           <p>{flight?.airLineName}</p>
//                                         </div>
//                                         <div className="airnumber">
//                                           {/* {flightInfo?.flightSegments.map((segments, id) => {
//                             return (
//                               <p>{segments?.marketingAirline}-{segments?.flightNumber}</p>
//                             )
//                           })} */}
//                                           {/* <p>{flight.marketingAirline}-{flight.flightNumber}</p> */}
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </Col>
//                                   <Col md={8} xs={24}>
//                                     <div className="Check-in-Bag">
//                                       <p>{flight?.checkInBaggage}
//                                         {/*  baggage[0]?.baggageInfo} */}
//                                       </p>
//                                     </div>
//                                   </Col>
//                                   <Col md={8} xs={24}>
//                                     <div className="Cabin-in-Bag">
//                                       <p>{flight?.cabinBaggage}
//                                         {/* baggage[0]?.cabinBaggageInfo} */}
//                                       </p>
//                                     </div>
//                                   </Col>
//                                 </Row>
//                               );
//                             }
//                           )}
//                         </div>

//                       </TabPane>
//                       {cmsFareRules?.cancelPolicyDescription ? (
//                         <TabPane tab="Fare Rules" key="2">
//                           <div className="fareRule-container">
//                             <p>
//                               {ReactHtmlParser(
//                                 cmsFareRules.cancelPolicyDescription
//                               )}
//                             </p>
//                           </div>
//                         </TabPane>
//                       ) : (
//                         <TabPane tab="Fare Rules" key="3">
//                           <div className="fareRule-container">
//                             {!fareRulesResp ? (
//                               <p>Loading...</p>
//                             ) : (
//                               fareRulesResp.data.fareRules.map((rule, i) => {
//                                 return (
//                                   <p key={i}>
//                                     {ReactHtmlParser(rule.ruleDetails)}
//                                   </p>
//                                 );
//                               })
//                             )}
//                           </div>
//                         </TabPane>
//                       )}
//                       {cmsFareRules?.reschedulePolicyDescription && (
//                         <TabPane tab="Reschedule Policy" key="4">
//                           <div className="fareRule-container">
//                             <pre>
//                               {ReactHtmlParser(
//                                 cmsFareRules.reschedulePolicyDescription
//                               )}
//                             </pre>
//                           </div>
//                         </TabPane>
//                       )}

//                       {cmsFareRules?.covidRulesDescription && (
//                         <TabPane tab="Covid Rules Policy" key="5">
//                           <div className="fareRule-container">
//                             <pre>
//                               {ReactHtmlParser(
//                                 cmsFareRules.covidRulesDescription
//                               )}
//                             </pre>
//                           </div>
//                         </TabPane>
//                       )}
//                       {cmsFareRules?.otherRulesDescription && (
//                         <TabPane tab="Other Rules Policy" key="6">
//                           <div className="fareRule-container">
//                             <pre>
//                               {ReactHtmlParser(
//                                 cmsFareRules.otherRulesDescription
//                               )}
//                             </pre>
//                           </div>
//                         </TabPane>
//                       )}
//                     </Tabs>
//                   </div>
//                 </Modal>
//               </div>
//             </div>

//           </div >

//         </Card >

//       </div >
//     );
//   };

//   return (
//     <Card className="flight-details-container">
//       {/* <div className="change-flight-details">
//         <h3>Flight Details</h3>
//         <p
//           onClick={() => {
//             goTo();
//           }}
//         >
//           Change Flights
//         </p>
//       </div> */}

//       {props.selectedFlight.length > 0 ? (
//         travelType === "oneWay" ? (
//           <FlightCard
//             flightInfo={props.selectedFlight[0]}
//             flightSearchObj={props.flightSearchObj}
//             travelType={props.travelType}
//           />
//         ) : (
//           props.selectedFlight.map((flight, id) => (
//             <FlightCard
//               flightInfo={flight}
//               type={id}
//               travelType={props.travelType}
//               flightSearchObj={props.flightSearchObj}
//             />
//           ))
//         )
//       ) : null}
//     </Card>
//   );
// };

// export default DetailsCard;

import React from "react";
import { useState } from "react";
import "./FlightsCheckout.scss";
import { Card, Col, Row, Modal, Tabs } from "antd";
import moment from "moment";
import travel from "../../assets/images/travel.png";
import plane from "../../assets/images/7.png";
import plane2 from "../../assets/images/5.png";
import plane1 from "../../assets/images/plane.png";
import fliy from "../../assets/images/Icons/fli8.png";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import { FlightTimings } from "../SearchResults/Flight/Flight";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import CustomFliImg from "../../common/CustomImg/CusFliLogo";
import CustomFlightimg from "../../common/CustomImg/CustomFlightimg";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";

const DetailsCard = (props) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  let travelType = localStorage.getItem("airTravelType");
  let history = useHistory();
  const { TabPane } = Tabs;
  const goTo = () => {
    history.goBack();
  };

  const [baggage, setBaggage] = useState(false);
  const toggleBaggage = () => {
    setBaggage((prevState) => !prevState);
  };

  const FlightCard = ({
    flightInfo,
    flightSearchObj,
    type,
    travelType,
    flights,
    baggage,
    farerules,
    isLoadi,
  }) => {
    const [showFareRules, setShowFareRules] = useState(false);

    const handleToggleFareRules = () => {
      showModal();
      setShowFareRules(!showFareRules);
    };
    const [fareRulesResp, setFareRulesResp] = useState("");
     const [fareRulesResp1, setFareRulesResp1] = useState("");
    const [cmsFareRules, setCmsFareRules] = useState({});
   // const [showFareRules, setShowFareRules] = useState(false);
   
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const [flightInfoResp, setFlightInfoResp] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    // console.log(flightSearchObj, type, travelType, "tyee");
    const showModal = () => {
      if (flightInfo?.supplierKey === "AKBAR") {
        setFareRulesResp(farerules);
        setShowFareRules(true);
      } else {
        let obj = {
          airlineCode: flightInfo.airLine,
          fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
          roleID: user?.Role?.RoleId ?? 4,
          membershipID: user?.Membership ?? 1,
        };
        setShowFareRules(true);

        fetchFlightFares(obj);
      }
    };

   
    const getFlightIds = () => {
      if (flightSearchObj?.airTravelType === "oneWay") {
        return [flightInfo?.flightId];
      } else {
        if (
          flightSearchObj?.airTravelType === "roundTrip" &&
          flightSearchObj?.resultsType === "SEPARATE"
        ) {
          return [flightInfo?.flightId];
        } else {
          const flightIds = flights.selectedFlight.map(
            (flight) => flight.flightId
          );
          // console.log( flightIds, "greatnews");
          return flightIds;
        }
      }
    };
    const flightBaggageInfo = () => {
      const baggageDetails = baggage;

      const groupBaggageDetailsByCityPair = () => {
        return baggageDetails.reduce((acc, baggage) => {
          baggage?.BAGGAGE?.forEach((bag) => {
            const { cityPair, weight, paxType } = bag;
            if (!acc[cityPair]) {
              acc[cityPair] = [];
            }
            acc[cityPair].push({ weight, paxType });
          });
          return acc;
        }, {});
      };

      const groupedBaggageDetails = groupBaggageDetailsByCityPair();

      const formatWeightWithPaxType = (weight, paxType) => {
        switch (paxType) {
          case "ADT":
            return `${weight} (Adult)`;
          case "CHD":
            return `${weight} (Child)`;
          case "INF":
            return `${weight} (Infant)`;
          default:
            return weight;
        }
      };

      return (
        <div className="fare-summary-container">
          <div>
            <p>Baggage Allowed </p>
          </div>
          {/* {Object.keys(groupedBaggageDetails).map((cityPair, index) => (
            <table key={index} className="tab-flight-Info">
              <thead>
                <tr>
                  <th>Sector Route</th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Check-in Baggage</div>
                  </th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Cabin Baggage</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{cityPair}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag, i) => (
                        <div key={i}>{formatWeightWithPaxType(bag?.weight?.split(',')[0], bag?.paxType)}</div>
                      )).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag, i) => (
                        <div key={i}>{formatWeightWithPaxType(bag?.weight?.split(',')[1], bag?.paxType)}</div>
                      )).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ))} */}
          {Object?.keys(groupedBaggageDetails)?.map((cityPair, index) => {
            // Using Set to keep track of unique baggage details
            const uniqueCheckinBaggage = new Set();
            const uniqueCabinBaggage = new Set();

            // Helper function to format weight and handle "0 kg"
            const formatWeight = (weight, paxType) => {
              const formattedWeight = formatWeightWithPaxType(weight, paxType);
              return formattedWeight === "0 kg" ? "N/A" : formattedWeight;
            };

            return (
              <table key={index} className="tab-flight-Info">
                <thead>
                  <tr>
                    <th>Sector Route</th>
                    <th>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Check-in Baggage
                      </div>
                    </th>
                    <th>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Cabin Baggage
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{cityPair}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {groupedBaggageDetails[cityPair]
                          ?.map((bag) => {
                            const checkinWeight = formatWeight(
                              bag?.weight?.split(",")[0],
                              bag?.paxType
                            );
                            if (!uniqueCheckinBaggage.has(checkinWeight)) {
                              uniqueCheckinBaggage.add(checkinWeight);
                              return (
                                <div key={checkinWeight}>{checkinWeight}</div>
                              );
                            }
                            return null;
                          })
                          .filter(Boolean)
                          .reduce((prev, curr) => [prev, " | ", curr])}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {groupedBaggageDetails[cityPair]
                          ?.map((bag) => {
                            const cabinWeight = formatWeight(
                              bag?.weight?.split(",")[1],
                              bag?.paxType
                            );
                            if (!uniqueCabinBaggage.has(cabinWeight)) {
                              uniqueCabinBaggage.add(cabinWeight);
                              return <div key={cabinWeight}>{cabinWeight}</div>;
                            }
                            return null;
                          })
                          .filter(Boolean)
                          .reduce((prev, curr) => [prev, " | ", curr])}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}

          <div className="fare-inf">
            <p style={{ color: "grey", fontSize: "13px" }}>
              <i className="fa fa-circle-o" aria-hidden="true"></i> The
              information presented above is as obtained from the airline
              reservation system.
              <br />
              <span style={{ color: "#bd0c21" }}>
                {" "}
                Outc.com{" "}
              </span>{" "}
              does not guarantee the accuracy of this information.
            </p>
            <p style={{ color: "grey", fontSize: "13px" }}>
              <i className="fa fa-circle-o" aria-hidden="true"></i> The baggage
              allowance may vary according to stop-overs, connecting flights and
              changes in airline rules.
            </p>
          </div>
        </div>
      );
    };

    const groupedFareRules = fareRulesResp?.fareRules?.reduce(
      (acc, current) => {
        const cityPair = current?.cityPair;
        if (!acc[cityPair]) {
          acc[cityPair] = [];
        }
        acc[cityPair]?.push(current);
        return acc;
      },
      {}
    );
    const formatText = (text) => {
      return text
        .replace(/\n/g, "<br>") // Convert new lines to <br>
        .replace(/(NOTE.*?:)/g, "<strong>$1</strong>") // Bold any lines that start with 'NOTE'
        .replace(
          /(CHANGES|CANCELLATIONS|ANY TIME|NO-SHOW)/g,
          "<strong>$1</strong>"
        ) // Bold specific keywords
        .replace(/(\d+ AED)/g, "<strong>$1</strong>") // Bold amounts (e.g., 150 AED)
        .replace(/(\-+\n)/g, "<hr>") // Replace multiple dashes with a horizontal rule
        .replace(/^\s+|\s+$/gm, ""); // Trim leading/trailing whitespace from each line
    };

    const fetchFlightFares = (obj) => {
      // let FareRuleObj = {
      //   flowType: "SEARCH",
      //   traceId: flightSearchObj.traceId,
      //   flightId: flightInfo.flightId,
      //   airTravelType: flightSearchObj.airTravelType,
      //   priceId: flightInfo.fareFamilies.fareFamilies[0].fareId,
      //   mappingType: flightSearchObj.resultsType,
      //   itineraryViewType: "1",
      // };
      //console.log(FareRuleObj,"FareRuleObj")
      let FareRuleObj;
      // if (flightInfo?.supplierKey === 'AKBAR') {
      //   FareRuleObj = {
      //     flowType: "SEARCH",
      //     traceId: flightSearchObj.traceId,
      //     // flightId: [flightInfo.flightId],
      //     flightId: getFlightIds(),
      //     airTravelType: flightSearchObj.airTravelType,
      //     priceId: flightInfo.fareFamilies.fareFamilies[0].fareId,

      //     mappingType: flightSearchObj.resultsType,
      //     itineraryViewType: "1",
      //     TripType: flightInfo?.isReturn ? "Return" : "Onward",
      //     Supplier: flightInfo.supplierKey,
      //   };
      // } else {
      FareRuleObj = {
        flowType: "SEARCH",
        traceId: flightSearchObj.traceId,
        flightId: flightInfo.flightId,
      
        airTravelType: flightSearchObj.airTravelType,
        priceId: flightInfo.fareFamilies.fareFamilies[0].fareId,

        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
        Supplier: flightInfo.supplierKey,
      };
      // }
      ApiClient.post("flights/airFareRules", FareRuleObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp?.data?.fareRules) {
                setFareRulesResp1(resp.data);
              }
            }

            // setFareRulesResp(resp.data.errors[0].errorDetail);
            // props.setFlightFareRules(resp.data.errors[0].errorDetail);
          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));
    };

    return (
      <div className="flight-details-card checkout_details_card">
       
        <Card className="inner-card">
         
         
         
          <div className="inner-flight-card" style={{padding:10, background:"white"}}>
        
              <div className="travel-info">
                {type === 0 ? (
                  <img src={plane} alt="" />
                ) : travelType != "multidestination" && type === 1 ? (
                  <img style={{transform:"scaleX(-1)"}} src={plane} alt="" />
                ) : (
                  <img src={plane} alt="" />
                )}
                {/* { travelType!= "multidestination" ? <img src={plane} alt="" /> : null } */}
                <span className="flightcheckout-mob-elepsis" style={{fontFamily:"Nunito",fontWeight:700}}>
                  {flightInfo.originCity}-
                </span>
                <span className="flightcheckout-mob-elepsis" style={{fontFamily:"Nunito",fontWeight:700}}>
                  {flightInfo.destinationCity}
                </span>
                <div className="travel-date" style={{fontFamily:"Nunito",fontWeight:600}}>
                  <span style={{fontFamily:"Nunito",fontWeight:600}}>
                    |
                    {dateFormat(
                      flightInfo.flightSegments[0].departureDateTime,
                      "ddd-ddmmmyyyy"
                    )}{" "}
                  </span>
                </div>
                {/* mobile */}
                <div className="travel-date1" style={{fontFamily:"Nunito",fontWeight:600,}}>
                  <br />{" "}
                  <span style={{fontFamily:"Nunito",fontWeight:600}}>
                    |
                    {dateFormat(
                      flightInfo.flightSegments[0].departureDateTime,
                      "ddd-ddmmmyyyy"
                    )}{" "}
                  </span>
                </div>
              </div>
           
      {flightInfo.flightSegments.map((flight, id) => {
        return (
          <Row className="row-of-det-flight" key={id}>
            <Col md={24} xs={24}>
              <Row className="flight-segment-row">
                
                <Col className="col-icos-flight" md={6}>
                  <div className="flight-logo">
                    <CustomFlightimg
                      className="img-hot"
                      style={{ width: "50px", height: "50px" }}
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLine}
                    />
                    <div>
                      <p style={{marginBottom:0,fontWeight: 700,
    fontFamily: 'Nunito',
    fontSize: "18px"}}>{flight.airLineName}</p>
                      <p style={{marginBottom:0,fontWeight: 600,
    fontFamily: 'Nunito',
    fontSize: "14px"}}>{flight.marketingAirline}-{flight.flightNumber}</p>
                    </div>
                  </div>
                </Col>

                {/* Departure Information */}
                <Col className="departure-start" md={5}>
                  <div className="departure-time">
                 <h6 style={{fontSize: "calc(14px + 2*(100vw - 320px) / 1600)", fontWeight: "700",marginBottom: "5px",fontFamily: "Nunito"}}> {flight.origin}  <span style={{color: "#3e3e3f"}}> {moment(flight.departureDateTime).format("HH:mm")}</span></h6>  
                  </div>
                  <div className="departure-info">
                   
                    <div style={{fontFamily:"Nunito",fontSize:12,fontWeight:600}}>{dateFormat(flight.departureDateTime, "ddd, dd mmm yyyy")}</div>
                    {/* {flight.departureTerminal ? (
                      <div>
                        <p style={{ color: "#35459c" }}>Terminal {flight.departureTerminal}</p>
                      </div>
                    ) : (
                      "N/A"
                    )} */}
                  </div>
                </Col>

                {/* Flight Duration */}
                <Col className="col-flight-det-pointsfromto" md={3}>
                  <div className="travel-duration">
                  <img src={plane1} alt="" style={{marginLeft:25, position:"absolute",marginTop:-11}}/>
                    <div className="line-l"></div>
                    {/* {flightInfo.isRefundable ? (
                      <div className="status">Refundable</div>
                    ) : (
                      <div className="status1">Non-Refundable</div>
                    )} */}
                  </div>
                </Col>

                {/* Arrival Information */}
                <Col className="arrival-col" md={5}>
                  <div className="departure-start">
                    <div className="departure-time">
                     <h6 style={{fontSize: "calc(14px + 2*(100vw - 320px) / 1600)", fontWeight: "700",marginBottom: "5px",fontFamily:"Nunito"}}>{flight.destination} <span style={{color: "#3e3e3f"}}> {moment(flight.arrivalDateTime).format("HH:mm")}</span></h6>
                    </div>
                    <div className="departure-info">
                     
                      <div style={{fontFamily:"Nunito",fontSize:12,fontWeight:600}}>{dateFormat(flight.arrivalDateTime, "ddd, dd mmm yyyy")}</div>
                      {/* {flight.arrivalTerminal ? (
                        <div>
                          <p style={{ color: "#0c1d80" }}>Terminal {flight.arrivalTerminal}</p>
                        </div>
                      ) : (
                        "N/A"
                      )} */}
                    </div>
                  </div>
                </Col>
                <Col className="col-flight-det-pointsfromto" md={2}>
                  <div className="travel-duration1">
                    <p style={{    marginBottom: "0px",
    marginTop: 16,
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'Nunito'}}>Duration</p>
                    <p style={{ 
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Nunito'}}>{flight.journeyDuration}</p>
                  
                   
                  </div>
                </Col>

                {/* Layover Information */}
                {id < flightInfo.flightSegments.length - 1 && (
                  <div className="layOver-time">
                    <p>
                      {moment
                        .utc(
                          moment(flightInfo.flightSegments[id + 1]?.departureDateTime)
                            .diff(moment(flight.arrivalDateTime))
                        )
                        .format("HH:mm")}{" "}
                      hrs Layover at {flight.destinationCity} ({flight.destination})
                    </p>
                    <div className="line-layOver"></div>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        );
      })}
       <div className="policy" style={{background:"white",padding:0,}}>
      <div className="fares">
        <h4 onClick={handleToggleFareRules}>
          <a  style={{ color: "#003b95"}}>Flight Details</a>
        </h4>
      </div>

      {showFareRules && (
        <div className="flight-details-dropdown">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Baggage" key="1">
              {flightInfo?.supplierKey === "TBO" ? (
                <div className="baggage-table">
                  <div className="headerbg">
                    <h4>AirLine</h4>
                    <h4>Check-in Baggage</h4>
                    <h4>Cabin Baggage</h4>
                  </div>

                  {flightInfo?.flightSegments?.map((flight, id) => (
                    <Row className="baggage-info" key={id}>
                      <Col md={8} xs={24}>
                        <div className="airtype">
                          <div className="flight-logo-1">
                            <img
                              style={{ width: "35px", height: "35px" }}
                              src={flight?.airLineLogo}
                              alt={flight?.marketingAirline}
                            />
                          </div>
                          <div className="flight-name-bagg">
                            <div className="airname">
                              <p>{flight?.airLineName}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={8} xs={24}>
                        <div className="Check-in-Bag">
                          <p>{flight?.checkInBaggage}</p>
                        </div>
                      </Col>
                      <Col md={8} xs={24}>
                        <div className="Cabin-in-Bag">
                          <p>{flight?.cabinBaggage}</p>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </div>
              ) : (
                <div>{!baggage ? <p>Loading ...</p> : <div>{flightBaggageInfo()}</div>}</div>
              )}
            </TabPane>

            {cmsFareRules?.cancelPolicyDescription ? (
              <TabPane tab="Fare Rules" key="2">
                <div className="fareRule-container">
                  <p>{ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}</p>
                </div>
              </TabPane>
            ) : (
              flightSearchObj?.airTravelType !== "multidestination" && (
                <TabPane tab="Fare Rules" key="3">
                  <div className="fareRule-container" style={{height:200, overflow:"scroll"}}>
                    {!fareRulesResp && !fareRulesResp1 ? (
                      <p>Loading...</p>
                    ) : fareRulesResp?.provider === "AK" && fareRulesResp?.fareRules ? (
                      <div className="farerule-space">
                        {fareRulesResp?.fareRules?.length >= 1 ? (
                          Object?.keys(groupedFareRules)?.map((cityPair, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                              <h1>{cityPair}</h1>
                              {groupedFareRules[cityPair]?.map((fareRestriction, restrictionIndex) => (
                                <div key={restrictionIndex}>
                                  {fareRestriction?.fareRestriction.length > 100 ? (
                                    ReactHtmlParser(formatText(fareRestriction.fareRestriction))
                                  ) : (
                                    <h3>{fareRestriction?.fareRestriction}</h3>
                                  )}

                                  <table style={{ border: "1px solid black", borderCollapse: "collapse", width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Description</th>
                                        <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Adult Price</th>
                                        {fareRestriction?.ruleDetails?.[0].childPrice !== null && (
                                          <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Child Price</th>
                                        )}
                                        {flightSearchObj?.infantCount > 0 && (
                                          <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>Infant</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {fareRestriction?.ruleDetails.length > 0 ? (
                                        fareRestriction.ruleDetails.map((flight, flightIndex) => (
                                          <tr key={`${restrictionIndex}-${flightIndex}`}>
                                            <td style={{ border: "1px solid black", padding: "8px" }}>{flight.Description}</td>
                                            <td style={{ border: "1px solid black", padding: "8px" }}>
                                              {flight?.adultPrice !== null ? `${activeCurrency} ${currencyValue(flight?.adultPrice.toFixed(2))}` : "N/A"}
                                            </td>
                                            {flightSearchObj?.childCount > 0 && (
                                              <td style={{ border: "1px solid black", padding: "8px" }}>
                                                {flight?.childPrice !== null ? `${activeCurrency} ${currencyValue(flight?.childPrice.toFixed(2))}` : "N/A"}
                                              </td>
                                            )}
                                            {flightSearchObj?.infantCount > 0 && (
                                              <td style={{ border: "1px solid black", padding: "8px" }}>
                                                {flight?.InfantPrice !== null ? `${activeCurrency} ${currencyValue(flight.InfantPrice.toFixed(2))}` : "N/A"}
                                              </td>
                                            )}
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan={4} style={{ border: "1px solid black", padding: "8px", textAlign: "center" }}>
                                            No data available
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          fareRulesResp.fareRules.map((rule, i) => (
                            <div key={i}>
                              {rule.fareRestriction.length > 100 ? ReactHtmlParser(formatText(rule.fareRestriction)) : <h3>{rule.fareRestriction}</h3>}
                            </div>
                          ))
                        )}
                      </div>
                    ) : (
                      fareRulesResp1.fareRules.map((rule, i) => (
                        <div key={i}>
                          {rule?.ruleDetails ? (
                            <p>{ReactHtmlParser(formatText(rule?.ruleDetails))}</p>
                          ) : (
                            <p>Something went wrong, please try again</p>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </TabPane>
              )
            )}
          </Tabs>
        </div>
      )}
    </div>
      
    </div>
        </Card>
       
      </div>
    );
  };

  return (
    <Card className="flight-details-container">
      <div className="change-flight-details">
        <h3>Flight Details</h3>
        {/* <p
          onClick={() => {
            goTo();
          }}
        >
          Change Flights
        </p> */}
      </div>

      {props.selectedFlight.length > 0 ? (
        travelType === "oneWay" ? (
          <FlightCard
            flightInfo={props.selectedFlight[0]}
            flightSearchObj={props.flightSearchObj}
            travelType={props.travelType}
            baggage={props.baggage}
            farerules={props.farerules}
            isLoadi={props.isLoadi}
          />
        ) : (
          props.selectedFlight.map((flight, id) => (
            <FlightCard
              flightInfo={flight}
              type={id}
              travelType={props.travelType}
              flightSearchObj={props.flightSearchObj}
              flights={props}
              baggage={props.baggage}
              farerules={props.farerules}
              isLoadi={props.isLoadi}
            />
          ))
        )
      ) : null}
    </Card>
  );
};

export default DetailsCard;
