import React, { useContext, useState, useEffect, useReducer } from "react";
import { Button, Row, Card, Col, Form, message, Spin, Input } from "antd";

import { useHistory } from "react-router-dom";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import FareDetails, { getBusGrandTotal } from "../Checkout/FareDetails";
import BusDetails from "../Checkout/BusDetails";
import ApiClient from "../../../helpers/ApiClient";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import { LoadingOutlined } from "@ant-design/icons";
import "../Checkout/Checkout.scss";

import PayGateway from "../../../helpers/PayGateway";
import CashFreeNewVersionCheckout from "../../../helpers/CashFreeNewVersionpayCheckout";
import Nav1 from "../../../common/navbar/Nav1";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const BusReview = () => {
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const {
    state: {
      busPersist: { selectedBusData },
      busPassengerData,
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount },
    },
  } = useContext(GlobalStatesContext);

  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);

  let history = useHistory();
  const [form] = Form.useForm();
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  const [passengersData, setPassengersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
  const [pgData, setPgData] = useState({});
  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
      
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          
        }
      })
      .catch((e) => {
        
      });
  }, []);

  useEffect(() => {
    if (busPassengerData.length > 0) {
      busPassengerData.map((paxData) => {
        if (paxData.passengerDetails) {
          let buildDataForPassenger = paxData.passengerDetails.map((pData) => ({
            Title: pData.title,
            Age: pData.age,
            Name: pData.name,
            Gender: pData.gender,
            SeatNo: pData.seatNo,
            EmailId: busPassengerData.emailId,
            MobileNo: busPassengerData.mobileNo,
          }));
          setPassengersData([...passengersData, ...buildDataForPassenger]);
        }
      });
    } else {
      history.go(-1);
    }
  }, [busPassengerData]);

  const userReg = (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj)
      .then(() => { })
      .catch();
  };

  const busBlocking = (busPassengerDataObj, type, pgType = null) => {
    setIsLoading(true);

    if (!user) {
      userReg(
        passengersData[0].Name,
        busPassengerData[0].emailId,
        busPassengerData[0].mobileNo
      );
    }

    const allAmount = getBusGrandTotal(
      selectedBusData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );

    let data = {
      ...busPassengerDataObj,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      // promoData: {
      //   ...promoData,
      //   tripType: type,
      // },
      // convienenceData: ConvFee,
      // insuranceData: selectedInsuranceData,
      insuranceRequired: selectedInsuranceData.status,
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      pgType: pgType ? pgType : pgDetails.pgType,
      currency: user?.Currency ? user?.Currency : activeCurrency || "INR",
      currencyRatio: user?.Role?.RoleId === 5 ? Number(currencies[user?.Currency]) : Number(currencyRatio),
     
      totalPrice: Number(allAmount.totalFareCalculations),
      isCouponReedem: redeemAmount.status ?? false,
    };
    setLoadingSpin(true);
    console.log(data,"data")
    ApiClient.post("buses/blockticket", data)
      .then((resp) => {
        console.log(resp,"hhhh")
        setIsLoading(false);
        setLoadingSpin(false);
        if (resp.statusCode === 200) {
         
          if (resp.message === "SUCCESS") {
            if (resp.data.pgType === 1) {
             
              CashFreeNewVersionCheckoutData(resp.data);    // 1 means NewCashfree

            } else {
              window.location.href = resp.data.payment_link;
            }

          } else {
            message.error("Unable To Book Ticket: " + resp.data.Message);
          }
        } else if (resp.statusCode === 500) {
          message.error(resp.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setLoadingSpin(false);
      });
  };
  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: busPassengerData[0].mobileNo,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          busBlocking(busPassengerData[0], "oneWay", pgDetails.pgType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: busPassengerData[0].mobileNo,
      DialingCode: "+91",
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const goTo = () => {
    form
      .validateFields()
      .then(() => {
        dispatchPgDetails({ type: PGDISPLAY, payload: true });
      })
      .catch((e) => { });
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      // if (agent) {
      busBlocking(busPassengerData[0], "oneWay", pgType);
      // } else {
      //   ValidateBookingLimit();
      // }
      // goTo();
    }
  };
  const ValidateBookingLimit = () => {
    const { totalFareCalculations } = getBusGrandTotal(
      selectedBusData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );

    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 3,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: totalFareCalculations ? totalFareCalculations : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <>
      <Nav1 />
      <div className="buscheckout_bg">
        <div className="buscheckout_wrapper">
          <Row gutter={[16, 16]} style={{ marginTop: 100 }}>
            <Col md={24} sm={24} xs={24}>
              <Form form={form} layout="vertical">
                {/* <h3 className="title">Bus Details</h3> */}
                <Row gutter={[16, 16]}>
                  <Col md={17} sm={16} xs={24}>
                    <div className="left_wrapper">
                      <BusDetails selectedBusData={selectedBusData} />
                      <div className="user-details">
                        {/* <p className="title">Passengers</p> */}

                        <Card className="flight-cards-details">
                          <div style={{ background: "#f9f9f9", padding: 12 }}>
                            <h3 className="title">Passenger Details</h3>
                          </div>
                          {busPassengerData.length === 0
                            ? null
                            : busPassengerData[0].passengerDetails.length > 0 && (
                              <>

                                <Form
                                  layout="vertical"
                                  className="passenger-form user-details "
                                >
                                  {busPassengerData[0].passengerDetails.map(
                                    (paxData) => (
                                      <Row gutter={[16, 8]} style={{ padding: 12 }}>
                                        <Col md={8} sm={8} xs={24}>
                                          <Form.Item label="Name">
                                            <Input
                                              className="inputbg"
                                              value={
                                                paxData.title +
                                                "." +
                                                " " +
                                                paxData.name
                                              }
                                              readOnly
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col md={6} sm={8} xs={24}>
                                          <Form.Item label="Age">
                                            <Input
                                              value={paxData.age}
                                              className="inputbg"
                                              readOnly
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col md={6} sm={8} xs={24}>
                                          <Form.Item label="Gender">
                                            <Input
                                              value={
                                                paxData.gender == "M"
                                                  ? "Male"
                                                  : "Female"
                                              }
                                              readOnly
                                              className="inputbg"
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </Form>
                              </>
                            )}
                        </Card>
                      </div>
                    </div>
                  </Col>

                  <Col md={7} sm={8} xs={24}>
                    <div className="right_wrapper">
                      <FareDetails
                        selectedBusData={selectedBusData}
                        isPromoVisible={false}
                      />
                    </div>

                    <Card style={{ boxShadow: "none", background: "none", border: "none", textAlign: "center" }}>
                      {pgDetails.pgDisplay == false ? (
                        <div className="btn_wrapper">
                          <button
                            type="primary"
                            onClick={() => {
                              goTo();
                            }}
                            style={{ background: "linear-gradient(90deg, #09b0dd, #026ba5)" }}
                          >
                            {isLoading ? (
                              <Spin indicator={antIcon} />
                            ) : (
                              "Book and Pay "
                            )}
                          </button>
                        </div>
                      ) : (
                        <PayGateway
                          blockApiReq={blockApiReq}
                          loadingSpin={loadingSpin}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
        {verifyModalVisible && (
          <VerifyOtpModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyOtp={handleVerifyOtp}
            handleResendOtp={handleSendOTP}
            mobile={busPassengerData[0].mobileNo}
          />
        )}
        {
          openCashfreeNewVersionCheckout && (
            <CashFreeNewVersionCheckout
              pgData={pgData}
              loadingSpin={loadingSpin}
            />
          )
        }
      </div>
    </>
  );
};
export default BusReview;
