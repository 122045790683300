import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";
import icon from "../../../assets/images/Icons/plane.png"
import icon1 from "../../../assets/images/mumbai.webp"
import icon2 from "../../../assets/images/delhi.png"
import icon3 from "../../../assets/images/kolkata.png"
import icon4 from "../../../assets/images/chennai.png"
import icon5 from "../../../assets/images/hyderabad.png"
import icon6 from "../../../assets/images/bangalore.png"
import "./topDomesticRoutes.scss";
import FlightExtranetAirlineName from "../../../common/AutoCompleteSelect/FlightExtranetAirlinName";

const { TabPane } = Tabs;
const MobileSlidersettings = {
  arrows: true,
  dots: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const TopDomesticRoutes = ({ deals, type }) => {

  const flightData = [
    {
      city: "Hyderabad",
      cityCode: "HYD",
      destinations: [
        { name: "Mumbai", code: "BOM" },
        { name: "Goa", code: "GOI" },
        { name: "Bangalore", code: "BLR" },
        { name: "Delhi", code: "DEL" }
      ],
      image: icon5,
    },
    {
      city: "Delhi",
      cityCode: "DEL",
      destinations: [
        { name: "Mumbai", code: "BOM" },
        { name: "Goa", code: "GOI" },
        { name: "Bangalore", code: "BLR" },
        { name: "Pune", code: "PNQ" }
      ],
      image: icon2, // Replace with correct image path
    },
    {
      city: "Bangalore",
      cityCode: "BLR", // Add city airport code
      destinations: [
        { name: "Goa", code: "GOI" },
        { name: "Delhi", code: "DEL" },
        { name: "Kolkata", code: "CCU" },
        { name: "Ahmedabad", code: "AMD" }
      ],
      image: icon6,
    },
    {
      city: "Chennai",
      cityCode: "MAA",
      destinations: [
        { name: "Mumbai", code: "BOM" },
        { name: "Delhi", code: "DEL" },
        { name: "Madurai", code: "IXM" },
        { name: "Goa", code: "GOI" }
      ],
      image: icon4, // Replace with correct image path
    },
    {
      city: "Mumbai",
      cityCode: "BOM",
      destinations: [
        { name: "Goa", code: "GOI" },
        { name: "Delhi", code: "DEL" },
        { name: "Bangalore", code: "BLR" },
        { name: "Chennai", code: "MAA" }
      ],
      image: icon1,
    },

    {
      city: "Kolkata",
      cityCode: "CCU",
      destinations: [
        { name: "Mumbai", code: "BOM" },
        { name: "Delhi", code: "DEL" },
        { name: "Goa", code: "GOI" },
        { name: "Chennai", code: "MAA" }
      ],
      image: icon3, // Replace with correct image path
    },


    // {
    //   city: "Ahmedabad",
    //   cityCode: "AMD",
    //   destinations: [
    //     { name: "Mumbai", code: "BOM" },
    //     { name: "Delhi", code: "DEL" },
    //     { name: "Bangalore", code: "BLR" },
    //     { name: "Goa", code: "GOI" }
    //   ],
    //   image: icon6, // Replace with correct image path
    // },
  ];
  return (
    <div className="flight-routes-container">
      {flightData.map((route, index) => (
        <div key={index} className="flight-route-card">
          <div style={{ display: "flex", marginRight: 110, width: "100%" }}>
            <div className="image-container">
              <img src={route.image} alt={`${route.city} Flights`} />
            </div>
            <div style={{ alignContent: "center" }} className="flit-infoRts">
              <h3>{route.city} </h3>
              <p className="fly-txt-top">
                <span style={{ color: "black" }}>To:</span>{" "}
                {route.destinations.map((destination, i) => (
                  <Link style={{ paddingLeft: "4px" }} key={i} to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&flightType=undefined&priceForTrip=REGULAR&departureDateTime=${moment()
                    .add(10, "days")
                    .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${route.cityCode
                    }&destination=${destination.code}`
                  }>
                    {destination.name}
                    {i < route.destinations.length - 1 ? " • " : ""}
                  </Link>
                ))}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopDomesticRoutes;



