import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImagesLightbox.scss";
import { Skeleton } from 'antd';
import nohotelimage from "../../../src/assets/images/hotels/no_img.png";

const ImagesLightbox = ({ hotelImages }) => {
  const [images, setImages] = useState(hotelImages);
  const [isLoading, setIsLoading] = useState(hotelImages.map(() => true));

  const onImageError = (ui) => {
    setImages(images?.filter(e => e !== ui));
  };

  const handleImageLoad = (index) => {
    setIsLoading((prev) => {
      const newLoadingState = [...prev];
      newLoadingState[index] = false;
      return newLoadingState;
    });
  };

  return (
    <div className="carous-wrapper">
      {images?.length >= 1 ? (
        <Carousel
          showStatus={false}
          showIndicators={false}
          dynamicHeight={false}
        >
          {images.map((hotelImage, i) => (
            <div key={i + "hotelimg"}>
              {isLoading[i] && (
                <Skeleton.Image
                  active
                  style={{
                    width: '865px',
                    height: '376px',
                    borderRadius: '8px'
                  }}
                />
              )}
              <img
                src={hotelImage}
                alt="image"
                className="carousel-ima"
                onLoad={() => handleImageLoad(i)}
                onError={() => onImageError(hotelImage)}
                style={{ display: isLoading[i] ? 'none' : 'block' }}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel
          showStatus={false}
          showIndicators={false}
          dynamicHeight={false}
          centerMode={true}
          centerSlidePercentage={80}
          selectedItem={1}
        >
          {images
            .filter(hotelImage => (
              hotelImage?.includes("max500") || hotelImage?.includes("_P") || hotelImage?.includes("_z") || hotelImage?.includes("_b")
            ))
            .map((filteredImage, i) => (
              <div key={"hotel" + i}>
                {isLoading[i] && (
                  <Skeleton.Image
                    active
                    style={{
                      width: '865px',
                      height: '376px',
                      borderRadius: '8px'
                    }}
                  />
                )}
                <img
                  src={filteredImage}
                  alt="hotel"
                  className="carousel-ima"
                  onLoad={() => handleImageLoad(i)}
                  onError={() => onImageError(filteredImage)}
                  style={{ display: isLoading[i] ? 'none' : 'block' }}
                />
              </div>
            ))
          }
        </Carousel>
      )}
    </div>
  );
};

export default ImagesLightbox;
