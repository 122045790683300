//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import { Button, Card, Col, Form, Input, Row, Radio, message, Modal } from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";
import APIClient from "../../../helpers/ApiClient";
import Login from "../../../components/Login/Login";
import { CloseOutlined } from "@ant-design/icons";

export const getBusGrandTotal = (
  selectedBusData,
  ConvFee,
  promoData,
  selectedInsuranceData,
  redeemAmount
) => {
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let discount = 0;
  let convamount = 0;
  let redeemTotal = 0;
  let fareInfoData =
    selectedBusData.length > 0
      ? selectedBusData.map((selectBusObj) => selectBusObj.fareInfo)
      : null;

  fareInfoData = fareInfoData.filter((i) => i != undefined);

  let totalBaseFare = fareInfoData.reduce(
    (acc, value) => Number(value.baseFare) + Number(acc),
    0
  );

  let totalGst = fareInfoData.reduce(
    (acc, value) => Number(value.gst) + Number(acc),
    0
  );

  let totalCommission = fareInfoData.reduce(
    (acc, value) => Number(value.commission) + Number(acc),
    0
  );

  let total = Number(totalBaseFare) + Number(totalGst);

  convamount = getConvFee(ConvFee, total);

  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 3
  ) {
    insuranceTotal =
      selectedBusData[0].seatsInfo.length *
      Number(selectedInsuranceData.amount);
  }

  total = total + Number(convamount);

  discount = getPromoDiscount(promoData, total);
  total -= discount;
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  total -= redeemTotal;
  let totalFareCalculations = Number(
    Number(total) + Number(insuranceTotal)
  ).toFixed(2);

  agentMarkup = fareInfoData.reduce(
    (acc, value) => Number(value.agentMarkup) + Number(acc),
    0
  );

  adminCommission = fareInfoData.reduce(
    (acc, value) => Number(value.adminCommission) + Number(acc),
    0
  );
  markup = fareInfoData.reduce(
    (acc, value) => Number(value.markup) + Number(acc),
    0
  );

  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount: Number(discount).toFixed(2),
    totalFareCalculations,
    totalServiceAmount: Number(total).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    totalCommission: totalCommission ? Number(totalCommission) : 0,
    markup,
    agentMarkup,
    adminCommission,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
  };
};

const FareDetails = ({ selectedBusData, isPromoVisible, location }) => {
  const [form] = Form.useForm();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    validateRedeemCoupon,
    RemovePromo,
    getInsuranceByServiceType,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
    totalCommission,
    redeemTotal,
  } = getBusGrandTotal(
    selectedBusData,
    ConvFee,
    promoData,
    selectedInsuranceData,
    redeemAmount
  );
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);

  useEffect(() => {
    getInsuranceByServiceType(3);
  }, []);

  useEffect(() => {
    if (!agent) {
      AddConvFee(3);
      getpromo();
    }
  }, [agent]);

  useEffect(() => {
    if (user) {
      getRedeemCoupon(user.UserID);
    }
  }, [user]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment(), 'day')
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 3 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const getRedeemCoupon = (userID) => {
    APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };

  const handleApply = (code) => {
    if (user != null) {
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      setLoginModal(true);
      // message.error("please Sign-In to Avail Offers")
    }
  };
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {

    setModalVisible({ visible: true, type: type });

  };

  return (
    <>
      <div className="busFareDetail_wrapper">
        <Card>
          <div style={{ background: "#f9f9f9", padding: 12 }}>
            <h3 className="title">Fare Details</h3>
          </div>
          <div style={{ padding: 12 }}>
            <div className="price_wrapper">
              <p className="text">Base Fare</p>
              <p className="price">
                <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "}
                {totalBaseFare ? currencyValue(totalBaseFare) : ""}
              </p>
            </div>

            <div className="price_wrapper">
              <p className="text">GST Amount</p>
              <p className="price">
                <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "} {totalGst ? currencyValue(totalGst) : ""}
              </p>
            </div>
            {!agent && user?.Role?.RoleLevel !== 3 && (
              <>
                <div className="price_wrapper">
                  <p className="text">Convenience Fee</p>
                  <p className="price">
                    <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "} {currencyValue(convamount)}
                  </p>
                </div>
                {promoData.status && (
                  <div className="price_wrapper">
                    <p className="text">Discount</p>
                    <p className="price">
                      - <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "}{" "}
                      {promoData.status ? currencyValue(discount) : " 0.00"}
                    </p>
                  </div>
                )}
              </>
            )}

            {selectedInsuranceData.status === 1 &&
              selectedInsuranceData?.serviceType === 3 ? (
              <div className="price_wrapper">
                <p className="text">Insurance Amount</p>
                <p className="price">
                  <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "} {currencyValue(insuranceTotal)}
                </p>
              </div>
            ) : null}

            <div className="price_wrapper total_price">
              <p className="text">Total:</p>
              <p className="price">
                <span style={{ fontSize: "12px" }}>{activeCurrency === "INR" ? "₹" : activeCurrency}</span>{" "}{" "}
                {totalFareCalculations
                  ? currencyValue(totalFareCalculations)
                  : ""}
              </p>
            </div>
          </div>
        </Card>
      </div>

      {/* <div className="busFareDetail_wrapper">
        <Card>
          {selectedInsuranceData.status === 1 &&
          selectedInsuranceData?.serviceType === 3 ? (
            <div className="price_wrapper">
              <p className="text">Insurance Amount</p>
              <p className="price">
                {activeCurrency} {currencyValue(insuranceTotal)}
              </p>
            </div>
          ) : null}

          <div className="price_wrapper total_price">
            <p className="text">Grand Total:</p>
            <p className="price">
              {activeCurrency}{" "}
              {totalFareCalculations
                ? currencyValue(totalFareCalculations)
                : ""}
            </p>
          </div>
          {agent ? (
            <div className="price_wrapper total_price">
              <p className="price-comm">Commission Earned:</p>
              <p className="price-comm">
                {activeCurrency}{" "}
                {totalCommission ? currencyValue(totalCommission) : ""}
              </p>
            </div>
          ) : null}
        </Card>
      </div> */}

      <div style={{ background: "white", boxShadow: "0 2px 14px #c9c9c9" }}>
        {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
          <div className="buspromo_wrapper">
            {promoData.status == false ? (

              <div className="promo-hot">
                <div style={{ background: "#f9f9f9", padding: 10 }}>
                  <p className="name">Apply Promo</p>
                </div>
                {user != null ?
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={(d) => {
                      validatePromoCode({
                        ServiceType: 3,
                        Amount: Number(totalServiceAmount),
                        PromoCode: d.promo,
                        UserId: user?.UserID ?? 1,
                        userMail: user?.Email,
                      });
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col md={12} sm={12} xs={12}>
                        <Form.Item
                          name="promo"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input
                            className="inputbg"
                            placeholder="Enter Your Promo code"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={12}>
                        <Form.Item>
                          <Button className="btn-pro" type="primary" onClick={() => form.submit()}>
                            Apply
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form> : <div className="promo-input" style={{ padding: 10 }}>
                    <p>Please <span style={{ color: "#023d96", cursor: "pointer", fontSize: 16, fontWeight: 700, fontFamily: "Nunito" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                  </div>
                }
                <div className="pr-card-cn">
                  {promoCode.length ? (
                    promoCode.map((item, key) => {
                      return (
                        <>
                          <div className="promo-cp-coupons" key={key}>
                            <div className="inline-cp-promo">
                              <Form>
                                <Form.Item>
                                  <Radio
                                    onClick={() => handleApply(item.Code)}
                                    key={item.Code}
                                  ></Radio>
                                </Form.Item>
                              </Form>
                              <div style={{ display: "flex", justifyContent: "space-between", width: "-webkit-fill-available" }}>
                                <p className="promo-key-cp">{item.Code}</p>
                                {item.DiscountType === 1 ? (
                                  <p className="save-cp-offer">
                                    Save {Math.floor(item.Discount) + " %"}
                                  </p>
                                ) : (
                                  <p className="save-cp-offer">
                                    Save {activeCurrency === "INR" ? "₹" : activeCurrency}&nbsp;
                                    {currencyValue(item.Discount)}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="promo-percentage-cp">
                              <p>{item.Description}</p>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="promo-cp-coupons" style={{ padding: 10 }}>
                      <div className="promo-percentage-cp pl-0 pt-0" >
                        <p style={{ fontSize: 14, fontWeight: 700, fontFamily: "Nunito" }}>No Promo Code Available</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              // </Card>
            ) : (
              <Card bordered={false} className="hotel-card-wrapper">
                <div className="promo-card-header">
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="name"
                  >
                    Promo Coupon{" "}
                    <CloseOutlined
                      onClick={() => {
                        RemovePromo();
                        form.resetFields();
                      }}
                    />{" "}
                  </p>
                </div>
                <div className="promo-input">
                  <p className="mb-0">
                    <span className="applied"> {promoData.Code} </span> Promo Code
                    Applied
                  </p>
                </div>
              </Card>
            )}
          </div>
        ) : null}
      </div>
      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {!isPromoVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : !isPromoVisible ? null : (
          <Card>
            <p className="font-weight-bold mb-1">
              Coupon Wallet: {/* {activeCurrency} */}
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>

            <div className="btn_wrapper">
              {" "}
              <Button
                type="primary"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
      <Modal
        centered
        visible={loginModal}
        onOk={(e) => setModalVisible(true)}
        onCancel={(e) => setLoginModal(false)}
        className="login-modal"
        footer={false}
      >
        <div className="login-infi-det">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex libero atque aliquam expedita sit. At ullam a neque aspernatur perspiciatis sint soluta. Fugiat natus magni ullam eius laudantium sunt quidem?
          Perspiciatis nulla assumenda earum voluptatibus, vero voluptas ipsa quam vel facere mollitia deserunt dignissimos vitae unde qui doloremque saepe laboriosam repellendus reprehenderit nihil cumque. Dolores et enim ad nisi aut!
          Beatae mollitia quaerat a nulla culpa, voluptate tempora corrupti labore non facere quam reprehenderit dicta nihil dolores placeat excepturi ipsam ea cum itaque, aspernatur soluta maiores atque. Illum, praesentium totam!
          Accusamus provident fugit repudiandae harum explicabo enim molestiae cupiditate placeat magni voluptas eum quae itaque laborum quod iste numquam, vitae dignissimos rerum omnis corporis. Reprehenderit asperiores nihil deleniti fugit dolorum?
          Nihil unde inventore a ab facere neque placeat facilis molestiae at cumque! Enim similique maxime beatae aliquid repellendus doloribus. Reiciendis eum doloremque adipisci dolor dignissimos, dolores asperiores saepe incidunt totam.
          Veritatis accusantium dolorem sed beatae! Cum maxime quae eaque reprehenderit amet omnis reiciendis ea aspernatur, pariatur hic! Tempore, voluptate odio ipsam nulla asperiores natus, repellat in minima eos provident saepe?
          Illo fugiat, ratione temporibus reiciendis sapiente quia voluptatem minima, laborum corporis dolore sunt aspernatur deserunt dolorum impedit aperiam perferendis dolores voluptas ut. Esse autem asperiores suscipit praesentium, explicabo minus excepturi!
          Nisi, dignissimos et veritatis quaerat illum blanditiis. Odio non sint, placeat odit ipsam fugit, architecto, et incidunt harum accusantium voluptatem veniam mollitia beatae quo doloremque vitae sequi dolores quis similique!
          Libero obcaecati ipsum amet magni dicta soluta expedita impedit adipisci perspiciatis dolorem eius nulla repellendus voluptatum eligendi quisquam tempore aspernatur, architecto hic itaque ab temporibus animi dolorum eveniet. Itaque, assumenda!
          Odio possimus aliquam, ratione quibusdam consequuntur itaque repellendus temporibus accusantium voluptatum quo laudantium illo minus iusto delectus? Aliquid quos mollitia tenetur nam magni tempore porro, nostrum, voluptatum vel at sunt.
          Blanditiis nisi sed cumque sapiente mollitia aperiam quis, praesentium nulla. Tempora rem labore rerum corporis laborum minus architecto ea molestias cumque, delectus ex sint fugiat incidunt sit qui voluptas ullam.
          Commodi quos modi, labore quas cumque expedita placeat magni. Vitae quae quam ex est sequi, at perspiciatis saepe. Quidem magnam rerum voluptatibus minus maxime soluta repudiandae autem facere doloremque ipsa.
          At tempore aspernatur non, recusandae veniam, accusamus molestias aliquam vel ea, rerum sequi aperiam obcaecati officiis nobis fugiat veritatis pariatur quisquam est quidem eveniet quam esse provident ut mollitia? Ipsam.
          Error incidunt, fugiat facere vero repudiandae quos harum voluptatibus. Sunt sequi ipsa laborum voluptas saepe nisi, iste quibusdam rem neque odit ab dolore officiis est accusamus quod aspernatur expedita. Id!
          Consequatur, id ad cupiditate illum quidem quaerat dolores sint neque dolorem voluptatibus ex cum fugit illo! Modi beatae unde aperiam esse cumque officia inventore iure ut numquam. Ullam, architecto quam.
          Fuga in autem id quo labore vero consectetur beatae non numquam debitis rerum voluptatem similique maxime doloremque quasi accusamus, ab cupiditate velit atque incidunt blanditiis corporis molestias. Iste, unde maiores!
          Aliquam numquam eum eius repellendus asperiores consequatur cumque accusantium ullam ipsam a neque sequi omnis sed blanditiis harum, quidem adipisci deserunt exercitationem autem alias consequuntur? Aperiam nemo unde ducimus repellat.
          Ullam, beatae amet? Sit, culpa. Molestias reprehenderit, ipsam quisquam ipsum explicabo modi, alias rerum accusantium nisi, sequi consequuntur expedita provident inventore. Molestiae ullam praesentium cumque iure perferendis, debitis atque fuga.
          Facilis fuga aliquid nisi alias! Maiores, dicta? Sit beatae incidunt magni, cumque iusto nulla iure ipsam reiciendis fugit praesentium animi modi consequatur exercitationem sequi amet esse, quam consectetur quaerat itaque.
          Necessitatibus officia numquam labore consectetur eum repudiandae ut doloremque aliquid dolorem. Sequi, et fuga sunt quo, quis nostrum quidem vel cum facere vitae debitis consequatur voluptatem, itaque corporis vero? Fugiat!
          Quod hic iusto aperiam eius modi, accusantium doloremque pariatur? Soluta iure, illo maxime hic et doloremque assumenda itaque autem possimus ipsum ratione reiciendis dignissimos magni, placeat ab vel eius provident.
          Unde aliquam vero, molestiae laborum placeat est! Quam, magni? Quos earum et nisi. Sed eius possimus, omnis nemo quam totam, nostrum exercitationem delectus vitae expedita asperiores dignissimos deleniti quo ullam?
          Est nemo praesentium in ut! Atque pariatur quam nisi consequuntur nostrum adipisci totam qui minima, deleniti provident ex temporibus impedit? Ipsam officia totam dolor corrupti dignissimos! Qui ullam neque debitis!
          Reprehenderit aliquid recusandae voluptate cum et? Quidem, et soluta, officiis nesciunt accusantium blanditiis nobis adipisci iusto ratione, temporibus inventore reiciendis vero hic perspiciatis ab nam error. Doloribus earum facilis cumque.
          Dolore, sed explicabo ea voluptatibus ullam quasi, eius quis velit eos omnis molestiae iste, amet earum. Voluptatem, minima nostrum! Ducimus ipsum recusandae, nisi quia at nemo! Suscipit et excepturi expedita!
          Quidem deleniti reiciendis quo harum dolorem facilis, ipsam odio fuga quae amet voluptatum, excepturi modi aspernatur veritatis veniam laborum. Ex pariatur nam recusandae officiis maiores ab aliquam molestiae. Qui, veniam.
          Molestias officiis molestiae minus neque perferendis repellat error. Asperiores, quam aliquid cumque architecto atque corrupti ratione totam, reprehenderit vero eos illum commodi vitae odit quia suscipit eaque aut? Voluptatum, eaque!
          Repudiandae quos quam repellendus accusamus, ratione pariatur fugit blanditiis? Repellendus rem repellat natus, unde ducimus quos ipsam debitis voluptas quaerat officia quas doloribus inventore nam harum illo temporibus, mollitia in.
          Sit unde ducimus consequatur fugit dicta culpa dolor laudantium at saepe fugiat vero error nobis obcaecati pariatur inventore atque, dignissimos quidem est accusamus soluta deserunt incidunt repellat! Maxime, ab explicabo?
          Ea id similique, sint facilis dolorum repudiandae doloremque tempore officia cupiditate eligendi nemo ipsum nesciunt animi at itaque illo voluptas enim suscipit fugit aperiam, assumenda dicta optio deserunt! Quasi, possimus.
          Laboriosam, facilis culpa. Inventore illo quas mollitia voluptates libero corporis quaerat dignissimos numquam culpa et officia dolorem, adipisci aliquid omnis laborum dicta veniam, excepturi distinctio architecto quasi quam reprehenderit. Ratione.
          Laudantium at eum possimus vel modi dolor voluptate, quasi sapiente debitis nemo cupiditate quia laborum iste voluptatem vitae quas ex fugit dolorem assumenda, porro velit reiciendis? Soluta fugiat sit dolorem?
          Dolorem odio omnis dignissimos! Quia laboriosam debitis aliquid ad at alias distinctio voluptatem, recusandae cumque vero ratione id animi aliquam illum amet totam ipsa! Consequatur nam laboriosam suscipit vel culpa?
          Amet beatae debitis quae quis id nobis quia incidunt ipsum vero magni veritatis, pariatur rem quam numquam laborum recusandae possimus dolores impedit ipsa accusantium. Optio velit cumque sequi culpa? Voluptas.
          Aut a iste est fuga ratione ullam earum minima molestias quaerat, error fugit magni excepturi quam delectus, porro consequuntur quod animi asperiores quae soluta totam cumque. Laboriosam, obcaecati dolor! Similique?
          Eum quia fuga, aliquam delectus laborum voluptatem at, neque sint nesciunt culpa minus cumque qui est ea. Est accusamus, officia dolore blanditiis aperiam quae rem at sed placeat ea ipsam.
          Illum eaque incidunt eos. Quibusdam optio voluptates, id architecto praesentium aspernatur vitae quisquam iure ipsa aliquid velit consequuntur ea suscipit maiores aperiam itaque sit minima ratione delectus accusamus inventore mollitia?
          Cumque nam distinctio quia corrupti animi praesentium maiores, fugiat nobis dicta assumenda nesciunt non obcaecati cum? Animi quaerat iste, voluptate obcaecati officia sunt debitis doloribus enim ullam atque! Unde, inventore.
          Magni adipisci repudiandae beatae soluta sint. Laudantium repudiandae incidunt dignissimos iste, eaque alias placeat error esse, aspernatur et velit, suscipit tempore nostrum! Itaque, incidunt laudantium. Amet quam eum maxime nihil.
          Quo, eum cumque. Dicta consequuntur voluptatibus quia dolores est alias ullam corporis? Aut esse corrupti, debitis ea facilis reiciendis error assumenda, accusantium voluptates aliquid quidem quis unde fuga natus. Quod.
          Modi labore temporibus corrupti vitae quis. Maiores similique harum quibusdam voluptatem deserunt deleniti accusamus doloremque vitae sapiente incidunt quae sint quo aliquam ut corrupti provident, voluptate recusandae. Temporibus, ipsam voluptates.
          Sapiente laboriosam, eum quisquam magni quasi pariatur corporis eos facilis dolore, consectetur quo quae dignissimos ea qui tempora ipsum totam reprehenderit? Dolor rem libero temporibus cumque voluptate non sint sequi!
          Quaerat voluptate exercitationem fugit, odit nesciunt hic autem, consectetur aperiam ipsum ab iure cumque totam maiores voluptatum numquam illum voluptatibus voluptatem. Repellendus voluptatum officia totam quam enim in reiciendis voluptatem.
          Velit aspernatur ullam eligendi, repellat necessitatibus esse asperiores earum ipsam accusantium labore repellendus, ipsum alias? Sapiente ex quo, consequatur magnam velit itaque eaque, aliquid nisi expedita voluptate, doloremque optio maxime!
          Suscipit pariatur porro consequatur? Velit dolores expedita porro dolorem, doloremque deleniti ad eaque deserunt unde laborum voluptatem numquam qui in asperiores esse consequuntur ipsum sit nihil voluptas sequi recusandae ab?
          Pariatur ad ipsam saepe, animi ullam corrupti optio eum dolore exercitationem similique consequatur nesciunt. Nulla doloremque incidunt, sint odio, voluptatum fuga deleniti inventore molestias distinctio magnam eum, odit eligendi sapiente.
          Nemo ipsum perspiciatis suscipit rerum, accusantium at modi, in necessitatibus illum obcaecati non omnis beatae, aperiam incidunt ad iure sit. Vero nam veniam, quis quisquam beatae iste. Sequi, ducimus soluta?
          Minus, consequatur aut quo vero consequuntur labore voluptatem, voluptas aliquid perspiciatis omnis dolor saepe dicta quos quia libero excepturi vel. Minima labore eveniet incidunt facilis autem eaque consequuntur cupiditate eum.
          Quaerat quod doloribus culpa qui, voluptas in. Suscipit sunt illum a numquam deleniti, ex similique! Inventore deleniti suscipit distinctio quas eligendi voluptate placeat. Eveniet maiores aliquid, unde doloribus veniam a.
          Libero cum quis corrupti officia eos eligendi hic porro earum consequuntur culpa maiores quisquam ullam exercitationem blanditiis iste eaque voluptate, temporibus ducimus aliquam officiis soluta debitis architecto. Totam, incidunt debitis.
          Sit cum est quisquam ab laborum qui commodi ipsam culpa fugiat eum eos quibusdam et perspiciatis nobis voluptate, quia nesciunt exercitationem vero. Reprehenderit, rem voluptas! Ea, ratione! Tenetur, ipsam praesentium?
          Placeat corporis harum magni ea. Beatae ducimus explicabo dicta aspernatur maxime quod dignissimos vel aperiam sed quae recusandae magnam, sint debitis, dolorum, amet vero voluptatum. Odit velit ipsum obcaecati debitis!
          Eveniet doloribus quas ducimus illo ut molestias, hic deleniti quis est fugit eaque mollitia harum ad! Tenetur, numquam a dignissimos facilis modi temporibus illo autem dolorem officiis sequi, porro rem.
          Debitis neque, sequi modi veritatis delectus omnis dolores deserunt laboriosam unde dignissimos nulla sit fugit inventore magni voluptate vitae ea numquam minus consectetur! Corporis, odio. Explicabo doloribus aliquid ad quibusdam.
          Id modi consequuntur commodi consequatur ullam mollitia reprehenderit quas voluptate, vero, quisquam deleniti corporis. Laborum totam dolore dicta in adipisci rerum illum eaque a? Soluta eaque molestiae eveniet minima laboriosam.
          Ducimus dolorum ut similique sapiente veniam reprehenderit ullam saepe quis molestiae rerum blanditiis tempore pariatur delectus dolor optio cumque dolore itaque est nam, mollitia, fugiat repudiandae ipsum. Tempora, aut perferendis?
          Consectetur natus quasi quod adipisci ipsam fugit asperiores tempora eveniet vitae laudantium incidunt, aliquam perspiciatis illum odio blanditiis nostrum! Deserunt, saepe exercitationem odit impedit fuga officiis incidunt hic suscipit quae?
          Laboriosam dicta, laborum assumenda eos numquam, exercitationem enim asperiores ipsa, ad nostrum culpa dolores eum beatae nisi iusto dolor ea. Sit deserunt, nihil expedita consequatur nostrum delectus rerum distinctio vero?
          Autem pariatur earum velit magnam labore maxime, distinctio quam excepturi sint placeat perspiciatis a sequi odit voluptates ducimus suscipit itaque quos iure iste accusamus perferendis sapiente! Velit voluptate impedit voluptates.
          Animi sapiente, voluptatum eaque, rem quaerat esse aliquam itaque perferendis vel minus minima, ipsam nihil iste architecto sint magni excepturi inventore dicta! Aspernatur placeat praesentium inventore accusamus assumenda deleniti. Magni!
          Recusandae cupiditate, sapiente et cumque odit, aliquid eius ipsum, beatae veniam minus itaque ab quos facere. Hic rem, tempora eaque, blanditiis repellat ipsum natus illum aut omnis, aliquid quas alias!
          Dolorum, saepe! Ut nihil, nostrum facere iure minus fugiat nulla ducimus deserunt dolore corporis aut repellat vero minima id a totam nobis? Itaque ea excepturi minima esse animi, dicta sapiente?
          Molestiae amet mollitia id consectetur? Ipsum facilis accusantium, maxime voluptas eaque hic totam, soluta repellendus veniam ea perferendis! Culpa amet consectetur esse quo autem libero quae ratione error dignissimos officia!
          Et, animi? Asperiores nihil voluptas unde veniam error praesentium accusamus. Dignissimos ex iure laudantium harum. Ducimus, dignissimos inventore aperiam autem qui laudantium cum harum, dolore a labore nulla voluptatum alias?
          Quam eum, accusantium nam ipsam magnam sed, officiis voluptas iusto nesciunt facilis dolorem vero accusamus nemo! Deserunt impedit culpa, est expedita error atque blanditiis praesentium dicta porro, voluptates animi? Officiis.
          In, natus sunt reprehenderit molestias sequi perspiciatis consectetur distinctio, repudiandae, non nostrum eius. Quo, inventore. Nulla non sunt autem nesciunt pariatur labore placeat dolorum illo vero voluptatum. Incidunt, atque debitis!
          Dolorem optio cumque commodi est labore dignissimos excepturi rem repellendus enim vero facere, similique provident maxime quidem? Dolorem error enim magni quisquam placeat ipsam omnis ratione. Enim sunt laudantium porro.
          Alias doloribus iusto maxime aliquam sequi magni nisi rem unde in tempora officiis vel dolores saepe labore aut accusantium voluptatem, quae neque. Mollitia obcaecati vel ab ad cupiditate provident qui?
          Atque eligendi corporis esse fugiat dolore animi quo facilis ad ea necessitatibus quod illo dicta quae rerum reiciendis, veniam amet provident quas mollitia! Illum iusto rerum at, voluptatem quod distinctio.
          Consequuntur optio quibusdam, animi ipsam recusandae, explicabo vitae soluta magni neque cupiditate maxime. Quae, quos omnis sed architecto quis mollitia quaerat, doloremque quam vitae cum eligendi qui quia, laboriosam enim?
          Mollitia in officia autem ipsa molestias maiores similique libero ut officiis porro ratione vel veritatis, ipsam necessitatibus, a corrupti magni dolorum nihil possimus. Aut veritatis quo doloremque dolor repellat corporis.
          Quaerat praesentium necessitatibus delectus! Obcaecati ab vero excepturi eum magnam, tenetur commodi voluptatibus animi beatae, quae temporibus minus nesciunt laudantium modi error optio maiores expedita, cum cumque ipsum facilis dolorum.
          Culpa porro eius hic earum omnis laboriosam, incidunt officia vitae similique, quis enim voluptas saepe possimus expedita. Laudantium, voluptates eveniet saepe quasi est odit corporis at temporibus optio, sed iste!
          Porro molestias earum, reiciendis dolorem assumenda aut quas ducimus? Impedit, explicabo facilis? Minus itaque vel expedita magnam reprehenderit, repudiandae ipsam modi, odit quasi optio consequuntur maiores nam cum numquam? Quas!
          Mollitia ducimus itaque qui ut nobis, sequi beatae quibusdam odio natus, accusamus soluta culpa, distinctio excepturi harum suscipit eligendi quisquam aut libero omnis? Rerum eum excepturi quas. Quidem, ducimus dignissimos!
          Pariatur aut saepe magnam tempora hic, asperiores natus labore aliquid minima blanditiis nesciunt, dolores distinctio eum voluptatibus dolor ipsa velit quisquam rem a iste ullam minus reprehenderit! Aperiam, ad error?
          Laboriosam tempora aliquid sapiente minus atque doloremque voluptate non nihil, deleniti maiores provident vitae dicta quae. Necessitatibus dolor totam harum, explicabo dolorum saepe reprehenderit cupiditate. Dignissimos eaque molestias labore? Possimus.
          Nostrum doloribus ea perferendis optio itaque ipsum debitis obcaecati magni officia omnis, accusantium magnam soluta totam id porro dolorum nam quisquam. Repellendus facere fuga nihil sequi nisi consectetur quas officia?
          Explicabo ratione delectus dolores mollitia reprehenderit sequi molestiae sapiente deleniti eos laborum, cumque quibusdam beatae. Animi laborum veniam perferendis quo accusantium praesentium nisi, reiciendis quia soluta quaerat, dignissimos, perspiciatis qui?
          Consequuntur, quo? Quae exercitationem, maiores facere dolorem magni error dignissimos. Consequatur, quisquam corrupti possimus voluptatum non error molestiae doloribus enim distinctio expedita, quibusdam unde asperiores aperiam eligendi. Expedita, quasi eaque.
          Quisquam ad nihil ex eos est quibusdam rerum odio dolorum accusantium dignissimos distinctio officiis rem nisi earum explicabo atque, recusandae ipsam nobis obcaecati esse? Doloremque, mollitia. Maxime delectus accusamus temporibus!
          Accusantium soluta porro eum? Nobis laboriosam natus omnis veniam perspiciatis quisquam repellendus odit vero distinctio pariatur consectetur sint, voluptatum itaque perferendis dolorem quidem aperiam harum adipisci eligendi voluptatem excepturi? Doloribus.
          Distinctio doloremque deserunt nesciunt ratione reiciendis, qui sed iste, eaque ipsum facilis quae molestias reprehenderit voluptates eos pariatur, delectus vel et officiis provident eius? Illo a praesentium optio nam cumque.
          Modi debitis facilis voluptatem, vitae omnis, eum ullam laudantium amet inventore neque nulla, dolores praesentium autem in aspernatur et soluta! Debitis facere ad non. Aperiam iusto sequi perspiciatis dolor laborum.
          Hic aut eligendi blanditiis quos voluptate repudiandae incidunt. Accusamus rerum necessitatibus eos! Quos omnis sunt cum, quaerat a perspiciatis aspernatur? Odio quaerat est architecto, incidunt voluptate illum ab debitis nostrum.
          Facilis iure, maiores dolore porro dignissimos, eveniet, amet dolorem molestiae accusantium reiciendis vero. Soluta sunt quia dolorem laborum saepe maxime, molestiae magni dolore laudantium quisquam sit, corporis cumque magnam velit.
          Repudiandae quam vel ipsum nam sed. Debitis minima error fugiat assumenda vero doloribus dignissimos veniam laboriosam maiores rem itaque in pariatur, odit at eligendi impedit reprehenderit aperiam aspernatur? Harum, quas.
          Nostrum tempore quas facere tenetur incidunt! Et, cum dicta ullam voluptatibus laudantium consequuntur, illum quisquam, magnam odit obcaecati laborum iusto alias dolore. Minima modi placeat pariatur odit earum fugit excepturi!
          Aliquam, natus voluptates molestias voluptas qui asperiores in ratione cupiditate nam minima aperiam tempore perferendis sapiente earum fuga nulla possimus, rem officia, ad corrupti accusantium officiis rerum soluta. Facilis, nisi?
          Accusamus iste aliquam perferendis repudiandae harum! Totam voluptatum quisquam quod, neque animi molestias, hic repellendus quos atque rem iste consequatur ad? Nihil delectus eos necessitatibus accusamus temporibus modi nulla culpa!
          Unde cumque provident repudiandae dolore, veritatis soluta dolores at sequi, adipisci quae numquam perspiciatis nam eos rerum odio ratione non vitae aspernatur voluptatem pariatur aliquid laudantium deleniti eum recusandae. Aperiam.
          Voluptas labore laudantium totam inventore illum consequuntur in sit, doloremque laborum voluptates explicabo facere sunt enim vitae veritatis animi beatae eaque libero minima autem hic placeat recusandae. Labore, exercitationem repellendus.
          Earum fugiat atque repellat est doloremque ipsum commodi adipisci incidunt, illo temporibus itaque facere, amet animi. Odit, voluptates quae deserunt suscipit fugiat, aperiam laboriosam eos magni similique sunt nesciunt neque?
          Possimus fugiat et voluptas quaerat asperiores eum. Nesciunt animi ad cupiditate et, eligendi saepe sint harum alias, fugiat dolores aut amet. Enim voluptatum fuga eveniet beatae, quod autem cumque necessitatibus.
          Delectus amet id, quam doloremque architecto qui fugiat eligendi pariatur soluta exercitationem molestiae quisquam harum ipsam ipsa sit repellat laborum enim? Esse quidem reprehenderit eum veniam odio! Vitae, molestias placeat!
          Libero debitis explicabo deserunt deleniti maxime eum corporis rem praesentium veritatis commodi, ad quibusdam iusto autem quo magnam fuga dignissimos officia. Nostrum harum quas repellendus ipsa iusto optio, blanditiis voluptate?
          Consequuntur itaque nostrum aliquam ea totam dolore aut ad aliquid consectetur illum temporibus sed, at a qui nesciunt? Natus eos unde eum quos officia asperiores labore. Dolorum repellendus amet recusandae!
          Itaque accusamus rem fugiat voluptatibus harum iure quae in, debitis maiores exercitationem inventore possimus provident eaque aspernatur est voluptatum maxime eius reprehenderit labore ea. Sapiente officiis itaque sit neque quae.
          Recusandae neque perferendis dolor possimus dolore in iure nihil reiciendis exercitationem debitis! Laboriosam laborum blanditiis, quia iste, perferendis, saepe eum adipisci praesentium delectus aliquid doloribus eius commodi aperiam. Libero, modi?
          Exercitationem tempore ratione voluptate harum placeat nam qui accusantium totam, rem dolore asperiores repellat fuga facilis pariatur quod blanditiis quos. Atque soluta magni debitis, quae sed consectetur velit neque fuga?
        </div>
        {/* <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        /> */}
      </Modal>
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </>
  );
};
export default FareDetails;
