import React, { useEffect } from "react";
import queryString from "query-string";
import "../../TicketSuccessPages/HolidayTicketSuccess/HolidayOfflineSuccess.scss";
import { Row, Col, Layout, Card } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const visaOfflineSuccess = () => {
  const params = queryString.parse(document.location.search);
  // useEffect(() => {

  // }, []);
  //console.log(params, "visda");
  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi">
        <Layout className="ourteam-bg2">
          <div className="thankyou-panel">
            <div className="page-width-1">
              <Card>
                <Row justify="center">
                  <Col className="visaOff" md="24" xs={24}>
                    <h5 className="hdr-thnk">Thank you for Choosing {" "}<span style={{ color: "#bd0c21", marginLeft:"6px" }}> Outc.com</span></h5>
                    <img
                      className="img-thanku"
                      src={
                        require("../../../assets/images/thank-you-img.jpg")
                          .default
                      }
                      alt="search-img"
                    />

                    <h5>
                      Your Application as been Submitted Successfully with Reference No. <span className="ref-visa" style={{ color: "#bd0c21", fontWeight: "600" }}>{params?.ref} </span> <br />


                    </h5>

                    <div className="sucessModal-v3">
                      <CheckCircleOutlined className="modalIcon-v3" />

                      <p>One of our Executive will Contact you soon on this.</p>
                    </div>
                    <p className="subtitle">
                      Please call or write to our customer care team for more details{" "}
                      <span className="mail"><i className="fa fa-envelope-o">  </i>{" "}support@Outc.com </span>
                      {/* <a onClick={sendQueryEmail} style={{ cursor: 'pointer' }}>support@Outc.com</a></span>. */}
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default visaOfflineSuccess;
