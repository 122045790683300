import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Offers.scss";
import { Card, Row, Col, Popconfirm, Tooltip, Badge, Modal, Button } from "antd";
import noImage from "../../assets/images/image-not-found-scaled-1150x647.png";
import {useHistory} from 'react-router-dom';
const moment = require('moment');
function OffersSlider(props) {

  const history = useHistory();
//  console.log(props,"ooo")
  let currentDate = new Date()
  let Slides = props?.dataSource;
  const currentTodayDate = moment(currentDate).format('YYYY-MM-DD');
  // { console.log(props.dataSource, filterPromo, Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length, "fffamil") }
  const MobileSlidersettings = {
    arrows: false,
    dots: false,
    // slidesToShow:2,
    slidesToShow: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length > 4 ? 5 : Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length,
    speed: 500,

    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length > 4 ? 5: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length,
          // slidesToShow: 3,
          slidesToScroll: 1,
          infinite: Slides.length > 4,
          centerMode: Slides.length > 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCode, setCurrentCode] = useState("");

  const openModal = (code) => {
    setCurrentCode(code);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    Modal.success({
      content: "Code Copied!",
    });
  };
  

  const handleRedirect = () => {
    history.push("/offers");
  };
 
  // function copyToClipboard(text) {
  //   const elem = document.createElement("textarea");
  //   elem.value = text;
  //   document.body.appendChild(elem);
  //   elem.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(elem);
  // }
 const filterPromoData = Slides.filter((p) => currentTodayDate <= p.ValidTill)

  return (
  
    filterPromoData.length > 0 ? (<div className="offerSlider">
     
      
      <div className="col-12">
        <div className="offer-wrapper1 mb-4  " style={{width:1230}}>
          {/* <h3>Offers</h3> */}
          <Slider {...MobileSlidersettings}>
            {filterPromoData.map((item, i) => {
              return (
                <div key={"offerslider" + i} className="card-wrapper" md={4}>
                  <div className="card card-offer" key={i} style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap' }}>
                    
                
                        <img
                          src={
                            item.Image
                              ? `${process.env.REACT_APP_BASE_URL +
                              item.Image.substring(1)
                              }`
                              : noImage
                          }
                          alt="book-img"
                          loading="lazy"
                          style={{width:240,height:150,borderRadius:14}}
                          // onClick={() => openModal(item.Code)}
                          onClick={handleRedirect}
                        />
                       {/* <Popconfirm
                          title="Code Copied, Click on OK"
                          onConfirm={() => copyToClipboard(item.Code)}
                          onCancel={false}
                          okText="Ok"
                          cancelText="No"
                        ><h4 className="card-offer-title card-title" style={{color:"white"}}>
                            CODE : <span style={{ color: '#fafafa' }} >{item.Code} <i class="fa fa-copy"></i></span>
                          </h4>
                        </Popconfirm> */}
                    
                  </div>
                </div>
              );
            })}


          </Slider>
        </div>

        <Modal
        title="Offer Code"
        visible={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        // okText="Close"
        cancelText="Close"
      >
        <p style={{ fontSize: "16px", fontWeight: "bold" }}>{currentCode} <i class="fa fa-copy"  onClick={() => copyToClipboard(currentCode)}></i></p>
        {/* <Button type="primary" onClick={() => copyToClipboard(currentCode)}>
          Copy Code
        </Button> */}
      </Modal>
      </div>
    </div>
    ) : null
  );
}

export default OffersSlider;
