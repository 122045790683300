import React, { useState, useRef, useEffect } from "react";
import { Button, Col, DatePicker, Form, Row, message } from "antd";
import { useHistory } from "react-router-dom";

import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";

import moment from "moment";
import queryString from "query-string";
import arrowImg from "../../assets/images/flight-arrow.png";
import { SearchOutlined } from "@ant-design/icons";
import "../buses-search/Buses.scss";
import { OriDesValidate } from "../../helpers/CustomValidators";
import { useSytContext } from "../../common/providers/SytProvider";
import BusAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/BusAutoCompleteSelectByCategory";
import { useLocalStorage, useSessionStorage } from "../../helpers/useStorage";

const Buses1 = ({ modifySearch = false, busesSearchAPI }) => {
  const city = useRef(null);
  const searchBtn = useRef();

  let history = useHistory();
  const tocityBox = useRef(null);
  const fromDateBox = useRef(null);

  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const validateMessages = {
    required: "",
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const [showDate, setShowDate] = useState(false);
  const [dataFlip, setDataFlip] = useState(false);
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === fromDateBox) {
      setShowDate(true);
    }
  };

  const submitForm = (values) => {
    let source = queryString.parse(values.sourceId);
    let destination = queryString.parse(values.destinationId);

    if (source.cityId === destination.cityId) {
      message.error("Origin and Destination cannot be same", 3);
      return false;
    }

    let formData = {
      sourceId: source.cityId,
      destinationId: destination.cityId,
      journeyDate: moment(values.journeyDate).format("DD-MM-YYYY"),
      sourceName: source.cityName,
      destinationName: destination.cityName,
    };
    const query = queryString.stringify(formData);
    recentSearches(query);

    history.push("/bus/results?" + query);

    if (modifySearch) {
      busesSearchAPI();
    }
  };

  const [BusRecentSearchesResults, setBusRecentSearchesResults] =
    useSessionStorage("busRecentSearchesResults", []);

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);
      if (BusRecentSearchesResults.length > 0) {
        let array = [];
        array = [...BusRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setBusRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) =>
                item.sourceName !== searchObj.sourceName &&
                item.destinationName !== searchObj.destinationName
            ),
          ]);
        }

        return;
      }
    }

    setBusRecentSearchesResults([searchObj]);
  };

  useEffect(() => {
    if (modifySearch) {
      let param = queryString.parse(document.location.search);
      form.setFieldsValue({
        sourceId: `cityName=${param.sourceName}&cityId=${param.sourceId}`,
        destinationId: `cityName=${param.destinationName}&cityId=${param.destinationId}`,
        journeyDate: moment(param.journeyDate, "DD-MM-YYYY"),
      });
    }

    let source = form.getFieldValue("sourceId");
    let destination = form.getFieldValue("destinationId");

    if (!source && !destination) {
      form.setFieldsValue({
        sourceId: "cityName=Delhi&cityId=101",
        destinationId: "cityName=Mumbai&cityId=122",
      });
    }
  }, [window.location.search]);

  const { topCities } = useSytContext();
  const [BusTopCities, setBusTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 3);
      if (data.length) {
        setBusTopCities(data);
      } else {
        setBusTopCities([]);
      }
    }
  }, [topCities]);

  const handleSwap = () => {
    const sourceId = form.getFieldValue("sourceId");
    const destinationId = form.getFieldValue("destinationId");

    if (sourceId && destinationId) {
      form.setFieldsValue({
        sourceId: destinationId,
        destinationId: sourceId,
      });
      setDataFlip((prev) => !prev);
    }
  };

  return (
    <div className="bus-search-wrapper" style={{marginTop: "20px"}}>
    <Form
      form={form}
      className="bus-panel-bg"
      initialValues={{ journeyDate: moment().add(1, "days") ,
      
      }}
      name="busForm"
      validateMessages={validateMessages}
      onFinish={submitForm}
    >
      <Row className="search-row bus-alert-sec bus-cr-search">
        <Col md={9} xs={24} className="from-to-inputs bus-cols-info"  style={{ marginRight: 3 }}>
        <span className="input-names">From</span>
          <BusAutoCompleteSelectByCategory
            formItemProps={{
              name: "sourceId",
              // initialValue: `cityName=Hyderabad&cityId=2862`, 
              rules: [
                { required: true, message: "Please Specify The City" },
                // {
                //   validator: (_, value) => {
                //     console.log(value,"valid")
                //     let destinationId = form.getFieldValue("destinationId");
                //     return OriDesValidate(destinationId, value);
                //   },
                // },
              ],
            }}
            selectProps={{
              size: "large",
              placeholder: "Origin",
            }}
            api={"buses/searchBusCities/"}
            refName={city}
            focusRef={tocityBox}
            handleOnSubmit={handleOnSubmit}
            modifySearch={modifySearch ? modifySearch : false}
            dataFlip={dataFlip}
            topCities={BusTopCities}
            recentKey={"recentBusOrigins"}
          />

          <img
            className="arrow-bus-inr"
            src={arrowImg}
            alt="Arrow img"
            onClick={handleSwap}
          />
        </Col>
        <Col
          md={9}
          xs={24}
          className="from-to-inputs bus-cols-info "
          style={{ marginRight: 3 }}
        >
          <span className="input-names">To</span>
          <BusAutoCompleteSelectByCategory
            formItemProps={{
              name: "destinationId",
              // initialValue: `cityName=Vijayawada&cityId=8464`, 
              rules: [
                { required: true, message: "Please Specify The City" },
                // {
                //   validator: (_, value) => {
                //     console.log(value,"valid1")
                //     let sourceId = form.getFieldValue("sourceId");
                //     return OriDesValidate(sourceId, value);
                //   },
                // },
              ],
            }}
            selectProps={{
              size: "large",
              placeholder: "Destination",
            }}
            api={"buses/searchBusCities/"}
            refName={tocityBox}
            focusRef={fromDateBox}
            handleOnSubmit={handleOnSubmit}
            modifySearch={modifySearch ? modifySearch : false}
            dataFlip={dataFlip}
            topCities={BusTopCities}
            recentKey={"recentBusDestinations"}
          />
        </Col>
        <Col md={5} xs={24} className="from-to-inputs bus-cols-info"  style={{ marginRight: 3 }}>
        <span className="input-names">Travel Date</span>
          <Form.Item name="journeyDate" rules={[{ required: true }]}>
            <DatePicker
              allowClear={false}
              size="large"
              className="search-inputs from-return-date"
              placeholder="Journey Date"
              ref={fromDateBox}
              format={"DD MMM'YY"}
              open={showDate}
              disabledDate={disabledOriginDate}
              onOpenChange={() => {
                setShowDate((prev) => !prev);
              }}
              style={{ width: "100%" }}
              dropdownClassName="custom-mob-calendar"
              inputReadOnly={true}
              defaultPickerValue={moment()}
              panelRender={(originalPanel) => {
                return (
                  <div className="original-panel">
                    <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                      Journey Date
                    </p>
                    {originalPanel}
                  </div>
                );
              }}
            />
          </Form.Item>
        </Col>

        <Col md={24} xs={24} className="bus-cols-info">
          <Button
            size="large"
            className="primary-btn  search-buses"
            ref={searchBtn}
            htmlType="submit"
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
      </Row>
    </Form>
  </div>
  );
};

export default Buses1;
