import React, { useState, useEffect, useRef } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Collapse } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import { useHistory } from "react-router-dom";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import CountryList from "../../common/CountryList";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";
import "../Hotels/Hotels.scss";
import ActiveTabs from "../../pages/Hotels/HotelSearch/ActiveTabs";
import { useSytContext } from "../../common/providers/SytProvider";
import HotelAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/HotelAutoCompleteSelectByCategory";
import { useLocalStorage, useSessionStorage } from "../../helpers/useStorage";
import { useHotelContext } from "../../common/providers/Hotels/HotelProvider";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Hotels1 = (props) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";
  const { Panel } = Collapse;
  const returnDateBox = useRef();
  const [showDate, setShowDate] = useState(false);
  const [HotelsHide, setHotelsHide] = useState(true);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [checkInDate, setCheckInDate] = useState(moment());
  const [checkOutDate, setCheckOutDate] = useState(moment());
  const { getsessiontimeout } = useHotelContext();
  const city = useRef(null);
  const dateBox = useRef(null);
  const ccode = useRef(null);
  const roomLimit = 6;
  const defHotelPaxInfo = [
    {
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    },
  ];

  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];
  const validateMessages = {
    required: "",
  };

  const [rooms, setRooms] = useState(false);
  const searchBtn = useRef();
  const [roomlength, setroomlength] = useState(1);
  const toggleCount = () => {
    setRooms((prev) => !prev);
  };

  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    });
    setroomlength(paxInfo?.length);
    setHotelPaxInfo(paxInfo);
  };

  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };
  const departureDate = moment();
  const submitForm = (values) => {
   
    let formData = {
      checkInDate: moment(values.checkInDate).format("YYYY-MM-DD"),
      checkOutDate: moment(values.checkOutDate).format("YYYY-MM-DD"),
      hotelCityCode: values.hotelCityCode,
      roomGuests: JSON.stringify(hotelPaxInfo),
      nationality: values.nationality,
    
      countryCode: "AE",
      isHotelDescriptionRequried: false,
      currency: "INR",
      traceId: "string",
      userId: 1,
      roleType: 4,
      membership: 1
    };
    localStorage.setItem("HotelSearchBar", JSON.stringify(formData));
    const query = queryString.stringify(formData);

    recentSearches(query);

    history.push("/hotels/listing?" + query);

    if (props.modifySearch) {
      props.hotelSearchAPI();
      setHotelsHide(false);
    }
  };

  const [HotelRecentSearchesResults, setHotelRecentSearchesResults] =
    useSessionStorage("hotelRecentSearchesResults", []);

  const recentSearches = (searchObj) => {
    if (searchObj) {
      searchObj = queryString.parse(searchObj);

      if (HotelRecentSearchesResults.length > 0) {
        let array = [];
        array = [...HotelRecentSearchesResults];
        if (array.length > 4) {
          array.pop();
        }

        if (searchObj) {
          setHotelRecentSearchesResults([
            searchObj,
            ...array.filter(
              (item) => item.hotelCityCode !== searchObj.hotelCityCode
            ),
          ]);
        }

        return;
      }
    }

    setHotelRecentSearchesResults([searchObj]);
  };
  useEffect(() => { getsessiontimeout() }, [])
  useEffect(() => {
    if (props.modifySearch) {
      setHotelsHide(false);
      const hotelSearchParams = queryString.parse(window.location.search);
      
      if (hotelSearchParams?.roomGuests) {
        var roomDetails = JSON.parse(hotelSearchParams?.roomGuests);
      } else {
        return;
      }
      form.setFieldsValue({
        hotelCityCode: hotelSearchParams.hotelCityCode,
        checkInDate: moment(hotelSearchParams.checkInDate, "YYYY-MM-DD"),
        checkOutDate: moment(hotelSearchParams.checkOutDate, "YYYY-MM-DD"),
        nationality: hotelSearchParams.nationality,
      });
      setHotelPaxInfo(roomDetails);
    }

   
  }, [window.location.search]);

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return ((currentDate < moment(checkInDate).add(1, 'days')) || (currentDate.valueOf() > moment(checkInDate).add(30, 'days')));
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = "";

    if (momentdate) {
      originDate = momentdate ? moment(momentdate).startOf("day") : "";
      setCheckInDate(momentdate);
    }
    setCheckOutDate(momentdate);
    const toDate = form.getFieldValue("checkOutDate");
    if (toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays <= 0) {
        let newTodate = moment(momentdate).add(1, "days");
        form.setFieldsValue({
          checkOutDate: newTodate,
        });
      }
    }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  

  const { topCities } = useSytContext();
  const [HotelTopCities, setHotelTopCities] = useState([]);
  useEffect(() => {
    if (topCities.length) {
      let data = topCities.filter((item) => item.ServiceType === 2);
      if (data.length) {
      
        setHotelTopCities(data);
      } else {
        setHotelTopCities([]);
      }
    }
  }, [topCities]);
  let ondelete = (i) => {
    paxInfo.splice(i, 1)
    setHotelPaxInfo(paxInfo);
    setroomlength(i)
  }
  const onupdate = (i) => {
    setroomlength(i);
  }
  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (rooms && !document.getElementById("pax-modal").contains(e.target)) {
        setRooms(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [rooms]);
  return (
    <div className="hotels_search_box">
    <div className="hotels-wrapper">
      <div className="outer-div-flight-searchfields"  style={{height:"136px"}}>
        <Form
          form={form}
          
          className="hotel-search-form hotel-bg-panel-all"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row className="search-row">
            <Col md={7} xs={24} className="from-to-inputs hotel-select-jun" style={{ marginRight: 3 }}>
            <span className="input-names">Search by City</span>
              <HotelAutoCompleteSelectByCategory
                formItemProps={{
                  name: "hotelCityCode",
                  rules: [
                    { required: true, message: "Please Specify The City" },
                  ],
                }}
                selectProps={{ size: "large", placeholder: "Enter City Name" }}
                api={"hotels-v2/searchhotelcity/"}
                refName={city}
                focusRef={dateBox}
                handleOnSubmit={handleOnSubmit}
                modifySearch={props.modifySearch ? props.modifySearch : false}
                topCities={HotelTopCities}
                recentKey="HotelRecentSearches"
              />
            </Col>
           
            <Col md={4} xs={24} style={{ marginRight: 3 }}>
            <span className="input-names">CheckIn</span>
              <Form.Item
                name="checkInDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                 allowClear={false}
                 style={{ width: "100%" }}
                 className="train-search-btn"
                 size="large"
                 format={"DD MMM'YY"}
                 disabledDate={disabledOriginDate}
                 onChange={(date, dateString) => {
                   setShowDate((prev) => !prev);
                   if (date) {
                     onChangeOriginDate(date, dateString);
                     setShowReturnDate(true);
                     returnDateBox.current.focus();
                   }
                 }}
                 ref={dateBox}
                 open={showDate}
                 onOpenChange={() => {
                   setShowDate((prev) => !prev);
                 }}
                 placeholder="Check In"
                 dropdownClassName="custom-mob-calendar"
                 inputReadOnly={true}
                 panelRender={(originalPanel) => {
                    return (
                      <div className="original-panel">
                        <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                          Check In Date 
                        </p>
                        {originalPanel}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={4} xs={24} style={{ marginRight: 3 }}>
            <span className="input-names">CheckOut</span>
              <Form.Item
                className="returnDate"
                name="checkOutDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                   style={{ width: "100%" }}
                   allowClear={false}
                   className="train-search-btn"
                   size="large"
                   format={"DD MMM'YY"}
                   disabledDate={disabledDestDate}
                   ref={returnDateBox}
                   open={showReturnDate}
                   onOpenChange={() => {
                     setShowReturnDate((prev) => !prev);
                   }}
                   defaultPickerValue={checkOutDate}
                   placeholder="Check Out"
                   dropdownClassName="custom-mob-calendar"
                   inputReadOnly={true}
                   panelRender={(originalPanel) => {
                    return (
                      <div className="original-panel">
                        <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                          Check Out Date 
                        </p>
                        {originalPanel}
                      </div>
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={4} xs={24} className="from-to-inputs hotel-select-jun" style={{ marginRight: 3 }}>
            <span className="input-names">Nationality</span>
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "Please Select Nationality",
                  },
                ]}
              >
                <Select
                  ref={ccode}
                  showSearch
                  size="large"
                  placeholder="Nationality"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {CountryList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={4} xs={24} className="from-to-inputs hotel-select-jun" style={{ marginRight: 3 }}>
            <span className="input-names">Rooms & Guests</span>
              <Form.Item>
                <Input
                  value={
                    "Room: " + paxInfo.length + ", Guest: " + getPaxCount()
                  }
                  size="large"
                  onClick={toggleCount}
                />
                <div className="add-room-block">
                  <div
                    className="pax-modal"
                    id="pax-modal"
                    style={{
                      display: rooms ? "block" : "none",
                    }}
                  >
                    <div className="pax-modal-wrapper">
                      <div className="pax-modal-arrow"></div>
                      <ul className="first-item">
                        <Collapse bordered={false} activeKey={(roomlength)} destroyInactivePanel={true}  >
                          {paxInfo.map((pax, index) => (

                            <Panel showArrow={false} collapsible={"header"} header={<div><span style={{ fontSize: "15px", fontWeight: "bold" }}>Room {index + 1}</span>    <br /><span>{pax.noOfAdults} Adults  </span><span>  {pax.noOfChilds} Children </span></div>} key={index + 1} extra={(index + 1) != 1 ? <div className="icons-hotel-addes"> <EditOutlined onClick={() => onupdate(index + 1)} style={{ marginLeft: "-10px", color: "blue" }} /><DeleteOutlined style={{ color: "red" }} onClick={() => ondelete(index)} /></div> : <div className="icons-hotel-addes" ><EditOutlined style={{ color: "blue" }} onClick={() => onupdate(index + 1)} /></div>}>
                              <HotelPassengers
                                pax={pax}
                                index={index}
                                updatePaxInfoFromChild={updatePaxInfoFromChild}
                                paxInfo={paxInfo}

                              /></Panel>
                          ))}

                        </Collapse>
                      </ul>

                      <div>
                        {roomlength < roomLimit ? (<a style={{ color: "red", fontWeight: "700", fontSize: "15px" }} onClick={addRoom} >+ Add Room</a>) : null}
                        <Button
                        block
                        className="pax-ready-btn"
                        onClick={() => {
                          toggleCount();
                          if (rooms) searchBtn.current.focus();
                        }}
                      >
                        Ready
                      </Button> 
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </Col>
           
            <Col md={24} xs={24}>
            {HotelsHide ?
      <ActiveTabs /> : null}
            </Col>
            <Col md={4} xs={24}>
              <Button
                size="large"
                className="primary-btn searchhhij"
                ref={searchBtn}
                htmlType="submit"
               
              >
                <SearchOutlined /> Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  </div>
  );
};

export default Hotels1;
