import React, { useState } from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import Nav1 from "../../common/navbar/Nav1";
import "./Termsofconditions.scss";
import img1 from "../../assets/images/terms-new.jpg";
import Animation from "../AnimationBall/AnimationBalls";

const Termsofconditions = () => {
  const [expanded, setExpanded] = useState("flights");

  const toggleSection = (section) => {
    setExpanded(expanded === section ? null : section);
  };
  return (
    <>
      <div>
        <Nav1 />
      </div>
      <div style={{ paddingTop: "80px" }} className="collapseCustomWhite">
        {/* <img src={img1} alt="outc" className="img-respe" /> */}

        <div className="company-tc-cont">
          <h1 className="company-title">Terms and Conditions</h1>
          <p className="subtitle">At outc.in (i2space Web Technologies Pvt. Ltd.), we provide a seamless platform for booking flights, hotels, and buses, connecting travelers with trusted service providers. Please note that we act solely as a facilitator and do not operate flights, hotels, or buses ourselves. All bookings are subject to the terms of the respective service providers.

            We are not responsible for delays, cancellations, or disruptions caused by airlines, hotels, or bus operators, including changes in schedules, quality of service, or loss of belongings. Travelers are advised to verify booking details, carry valid identification, and comply with all policies outlined by the respective providers.

            Cancellations and refunds are governed by the provider’s policies and may involve additional charges. Refund timelines are subject to the provider’s discretion. outc.in’s liability is limited to facilitating bookings and does not extend to operational issues or unforeseen circumstances.

            For assistance or queries, our support team is here to help at <span style={{ color: "blue", cursor: "pointer" }}>outcinfo@gmail.com</span>.</p>
          <div className="info-container">
            <Animation />
            <div className="info-sec-img">
              {/* <img src={img1} alt="outc" className="img-respe" width={"80%"} /> */}
            </div>
            <div className="info-sec">
              <div className="info-section">
                <div className="section-header" onClick={() => toggleSection("flights")}>
                  <h2>Flights</h2>
                  <span>{expanded === "flights" ? "-" : "+"}</span>
                </div>
                {expanded === "flights" && (
                  <div className="airlines-terms-cont">
                    <h2 className="airlines-title">Airlines Terms and Conditions</h2>
                    <p className="airlines-intro">
                      OutC provides online travel search services and other travel-related services to travellers via our website and apps. The airline tickets available through the website are subject to the terms and conditions of the concerned airline, including but not limited to cancellation and refund policies.
                    </p>
                    <div className="section">
                      <h3 className="section-title">General Terms</h3>
                      <ul className="section-list">
                        <li>OutC acts as a facilitator, enabling users to book flight tickets. The service contract is always between the user and the airline.</li>
                        <li>Airlines may reschedule, change, or cancel flights without prior notice to OutC. OutC is not liable for any loss incurred due to such changes.</li>
                        <li>Baggage allowances depend on the airline's terms. Some fares only include hand baggage and require separate payment for check-in baggage.</li>
                        <li>Different tickets on the same airline may carry varying restrictions or services.</li>
                      </ul>
                    </div>

                    <div className="section">
                      <h3 className="section-title">Travel Documents</h3>
                      <ul className="section-list">
                        <li>Users must possess valid travel documents such as identity proof, passport, and visas (including transit visas).</li>
                        <li>OutC is not responsible for inability to travel due to missing or invalid travel documents.</li>
                        <li>Information provided by OutC is advisory in nature. Users should confirm document requirements with the respective airline and jurisdiction.</li>
                      </ul>
                    </div>

                    <div className="section">
                      <h3 className="section-title">Check-in Terms</h3>
                      <ul className="section-list">
                        <li>International flights: Check-in at least 75 minutes prior to departure to avoid forfeiting fare and surcharges.</li>
                        <li>Flight timings are subject to change. Reconfirm with the carrier before departure.</li>
                        <li>Refer to fare rules and cancellation policies set by the carrier.</li>
                        <li>Ensure baggage tags are prominently displayed with the PNR and passenger name if a printed tag is unavailable.</li>
                        <li>Arrive at least two hours before departure. Boarding gates close 20 minutes prior to departure.</li>
                      </ul>
                    </div>

                    {/* <div className="images-section">
                      <img
                        src="https://via.placeholder.com/300x200.png?text=Airline+Booking"
                        alt="Airline Booking"
                        className="terms-image"
                      />
                      <img
                        src="https://via.placeholder.com/300x200.png?text=Flight+Check-In"
                        alt="Flight Check-In"
                        className="terms-image"
                      />
                    </div> */}
                  </div>
                )}
              </div>

              <div className="info-section">
                <div className="section-header" onClick={() => toggleSection("hotels")}>
                  <h2>Hotels</h2>
                  <span>{expanded === "hotels" ? "-" : "+"}</span>
                </div>
                {expanded === "hotels" && (
                  <div className="hotel-terms-container">
                    <h2 className="hotel-terms-title">Hotel Terms and Conditions</h2>
                    <p className="hotel-terms-intro">
                      outc.in (i2space Web Technologies Pvt. Ltd.) provides a technology platform for online hotel bookings that connects intending travelers with hotel operators.
                      <strong> OutC</strong> doesn't own or operate any hotels or provide lodging services to the User and doesn’t act as an agent of any hotel operator in the process of providing the technology platform services.
                    </p>
                    <div className="section">
                      <h3 className="section-title">Limitation of Liability</h3>
                      <ul className="section-list">
                        <li>Timely check-in or check-out at the hotel;</li>
                        <li>The conduct of hotel staff, representatives, or agents;</li>
                        <li>The condition of the hotel room, amenities, or facilities not meeting expectations;</li>
                        <li>Cancellation of the booking due to any reasons;</li>
                        <li>Loss or damage of belongings during the stay;</li>
                        <li>Allocation of a different room due to operational issues;</li>
                        <li>Changes to room category or unavailability of promised facilities;</li>
                        <li>Disruption of services due to unforeseen events or technical issues.</li>
                      </ul>
                    </div>

                    <div className="section">
                      <h3 className="section-title">Responsibilities of the Users</h3>
                      <ul className="section-list">
                        <li>Carry a <strong>valid booking confirmation</strong> (SMS or Email). Some hotels may require a printed voucher.</li>
                        <li>Present a valid <strong>proof of identity</strong> such as Driving License, Aadhar Card, Passport, PAN Card, or Voter ID during check-in.</li>
                        <li>Follow the <strong>hotel's policies and guidelines</strong>, including check-in and check-out times, guest behavior, and cancellation policies.</li>
                        <li>Report any issues with the room or services promptly to the hotel management.</li>
                      </ul>
                    </div>

                    {/* <div className="images-section">
                      <img
                        src="https://via.placeholder.com/300x200.png?text=Hotel+Stay"
                        alt="Hotel Stay"
                        className="terms-image"
                      />
                      <img
                        src="https://via.placeholder.com/300x200.png?text=Hotel+Check-In"
                        alt="Hotel Check-In"
                        className="terms-image"
                      />
                    </div> */}
                  </div>

                )}
              </div>

              <div className="info-section">
                <div className="section-header" onClick={() => toggleSection("busses")}>
                  <h2>Busses</h2>
                  <span>{expanded === "busses" ? "-" : "+"}</span>
                </div>
                {expanded === "busses" && (
                  <div className="bus-terms-container">
                    <h2 className="bus-terms-title">Bus Terms and Conditions</h2>
                    <p className="bus-terms-intro">
                      outc.in (i2space Web Technologies Pvt. Ltd.) provides a technology platform for online ticketing that connects intending travellers with bus operators.
                      <strong> OutC</strong> doesn't operate any bus or offer the service of transportation to the User and doesn’t act as an agent of any bus operator in the process of providing the technology platform services.
                    </p>
                    <div className="section">
                      <h3 className="section-title">Limitation of Liability</h3>
                      <ul className="section-list">
                        <li>Timely departure or arrival of the bus;</li>
                        <li>The conduct of bus operator's employees, representatives, or agents;</li>
                        <li>The condition of the bus, seats, etc., not meeting expectations;</li>
                        <li>Cancellation of the trip due to any reasons;</li>
                        <li>Loss or damage of the baggage of the customer;</li>
                        <li>The bus operator changing a customer's seat for any reason;</li>
                        <li>Wrong boarding point or last-minute changes to the boarding point;</li>
                        <li>Separate pick-up vehicles used by the bus operator to reach the actual departure point.</li>
                      </ul>
                    </div>

                    <div className="section">
                      <h3 className="section-title">Responsibilities of the Users</h3>
                      <ul className="section-list">
                        <li>Carry an <strong>SMS or Email ticket</strong>. Some partners require a printed ticket; details are provided during booking.</li>
                        <li>Present a valid <strong>proof of identity</strong> such as Driving License, Aadhar Card, Passport, PAN Card, or Voter ID.</li>
                      </ul>
                    </div>

                    {/* <div className="images-section"> */}
                      {/* <img
                        src="https://via.placeholder.com/300x200.png?text=Bus+Journey"
                        alt="Bus Journey"
                        className="terms-image"
                      />
                      <img
                        src="https://via.placeholder.com/300x200.png?text=Bus+Boarding"
                        alt="Bus Boarding"
                        className="terms-image"
                      />
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>



        {/* <CmsPages cmsId={5} pageTitle="Terms and Conditions" /> */}

      </div>
    </>

  );
};

export default Termsofconditions;
