import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../BusSearch/BusSearch.scss";
import Animation from "../../../components/AnimationBall/AnimationBalls";
import Banner from "../../../components/banner/Banner";
import Buses from "../../../components/buses-search/Buses";
import bann1 from "../../../assets/images/bg/busban.png";
import TopDomesticRoutes from "../../../components/home/TopDomesticRoutes/topDomesticRoutes";
import { DiscountBan } from "../../../components/HomeBanners/DiscountBan";
import * as ANTD from "antd";
import TopBuses from "../TopBuses/TopBuses";
import { useSytContext } from "../../../common/providers/SytProvider";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import bann2 from "../../../assets/images/bg/busReview1.png";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import queryString from "query-string";
import SubscribeN from "../../../components/subscribe/SubscribeN";
import moment from "moment";
import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
import Nav from "../../../common/navbar/Nav";
import BusEnquiry from "../BusComponents/BusEnquiry";
import TopBuses1 from "../../../components/home/TopDomesticRoutes/TopBuses1";

function BusSearch() {
  const { busBanners, promoDataSource } = useSytContext();

  const [dataSource, setDataSource] = useState([]);

  const [recentSearchResults, setRecentSearchResults] = useState([]);

  useEffect(() => {
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 3);
      setDataSource(data);
    }
  }, [promoDataSource]);

  useEffect(() => {
    let data = sessionStorage.getItem("busRecentSearchesResults");

    if (data && data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);

  return (
    <>
      <Nav />
      <div>
        <Helmet>
          <title>Book Bus Tickets Online- {AppConstants.DOMAIN_NAME}</title>
          <meta
            name="description"
            content="Book bus ticket with Outc and get huge discounts. Save more on your online bus booking. Volvo bus booking, sleeper, AC, NON AC, Business Class, Semi Sleeper."
          />
        </Helmet>
        <div className="bus-block">
          <div className="bus-banner">
            <Banner banner={busBanners} size={"300px"} />
          </div>
          {/* <div className="bus-search-header">
          <h2>Book bus tickets with cancellation option</h2> */}
          {/* </div> */}
          <div className="buses-search">
            <CombineServLink activetab={2} />
            {/* <Link
            className="text-right text-white w-100 d-block links  m-0 h6"
            exact={true}
            to={"/bus-hire"}
            target="_blank"
          >
            HIRE A BUS
          </Link> */}
          </div>
        </div>
        <section className="home-best-24" style={{ marginTop: "-220px" }}>
          <div className="container" style={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" classname="bann1" src={bann1} alt="" style={{ borderRadius: "6px" }} />

          </div>
        </section>

        {/* {recentSearchResults.length ? (
        <section className="home-best-24   recentSearchesRow">
          <div className="container">
            <Row gutter={16} className="justify-content-end align-items-center">
              <Col md={4} xs={24}>
                <p className="m-0 text-right">Recent Searches: </p>
              </Col>
              {recentSearchResults.map((item, i) => {
                let newstringObj = {
                  ...item,
                  journeyDate: moment(item.journeyDate, "DD-MM-YYYY")
                    .add(1, "days")
                    .format("DD-MM-YYYY"),
                };
                let newString = queryString.stringify(newstringObj);

                return (
                  <Col md={5} xs={24}>
                    <div key={i + "recentresults"}>
                      <Link to={`bus/results?${newString}`}>
                        <div
                          style={{
                            boxShadow: "0 1px 5px black",
                            borderRadius: "6px",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div className="ducarosuel">
                            <div className="city-due">{item.sourceName}</div>
                            <div className="iconsDiv city-due">
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="city-due">
                              {item.destinationName}
                            </div>
                            <div className="time-due">
                              {moment(item.journeyDate, "DD-MM-YYYY").format(
                                "DD, MMM YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      ) : (
        ""
      )} */}

        <section className="home-st" >
          <div className="mark-st-banner">
            <h6 style={{ marginBottom: "20px" }}>Offers for You</h6>
            {/* <img width="100%" src={bann5} alt="" /> */}
            <OffersSlider dataSource={dataSource} />
          </div>
        </section>
        <div className="mark-st-banner">
          <h6>Top Bus Routes</h6>
          {/* <img width="100%" src={bann2} alt="" /> */}
          <TopBuses1/>
        </div>
        <DiscountBan page={"f"}/>
        <section style={{marginTop:"30px"}}>
          <Animation />
          <BusEnquiry />
        </section>
        
        {/* <section className="top-route-bb new-bus-top">
          <OffersSlider dataSource={dataSource} serviceType="Bus" />
        </section> */}
        {/* <section className="top-route-bb new-bus-top">
          <TopBuses />
        </section>
        <section className="top-route-bb new-bus-top">
          <Reviews serviceType={3} />
        </section> */}
        <section className="home-st">
       
          <div className="mark-st-banner">
            {/* <h6>News & Travel Stories</h6> */}
            <img width="100%" src={bann2} alt="" style={{ borderRadius: "8px", boxShadow: "2px 3px 17px #a4a4a4", zIndex:"12", position:"relative" }} />
          </div>
        </section>
        {/* <section className="home-best-24" style={{ marginTop: "-220px" }}>
          <div className="container" style={{ display: "flex", justifyContent: "center" }}>
            <img width="80%" classname="bann2" src={bann2} alt="" style={{ borderRadius: "6px" }} />

          </div>
        </section> */}
        <SubscribeN />
      </div>
    </>
  );
}

export default BusSearch;
