import React, { useState } from "react";
import "../NewTicket.scss";
import { Col, Row, Layout, Card, Image } from "antd";
import moment from "moment";
import { getHotelPrice, getStatus } from "./hotelhelper";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";
import { useCurrencyContext } from "../../providers/CurrencyProvider";
import LogoImg from "../../../assets/images/OutcL1.png";
import { useSytContext } from "../../providers/SytProvider";
import { stringify } from "query-string";
let dateFormat = "MMM, DD-YYYY";

const TicketHotel = ({ ticketData, cmsFareRules }) => {
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    grandTotal,
    insuranceTotal,
    noOfNights,
  } = getHotelPrice(ticketData);
  const [curr, setCurr] = useState(ticketData?.Currency || 'INR');
  const [value, setValue] = useState(ticketData?.CurrencyRatio || '1');

  const { activeCurrency, currencyValue } = useCurrencyContext();
  
  // console.log(curr, value, "currr1");
  
  return (
    <>

      {/* <span className="tic-gredient-f"></span> */}

      {/* <Image style={{ width: 255, heigh: 200, marginTop:10,borderRadius:20 }} src={LogoImg} /> */}

      <Layout className="cms-pages-width-hot">

        <Row>
          <Col md={24} xs={24}>
            <Row>
              <Col md={24} xs={24}>
                <img style={{ width: 255, marginTop: 10 }} src={LogoImg} alt="OutC" />
                {/* <p className="tic-city-name">{ticketData.HotelName}</p> */}
                <Card className="card-tic-f3">
                  <Row >
                    <Col md={12} sm={24} xs={24}>
                      <div className="city-f3">
                        <p>{ticketData.HotelName}</p>
                        <span>{ticketData.CityName}</span>
                      </div>
                    </Col>
                    <Col md={8} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {ticketData.ConfirmationNumber}
                        </p>
                        <span>Reference No</span>
                      </div>
                    </Col>
                    {/* <Col md={6} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {ticketData.SupplierConfirmationNo}
                        </p>
                        <span>Confirmation Id</span>
                      </div>
                    </Col> */}
                    <Col md={4} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {moment(ticketData.BookingDate).format("DD-MM-YYYY")}
                        </p>
                        <span>Booking Date</span>
                      </div>
                    </Col>
                  </Row>
                  <div className="border-hr"></div>
                  <Row className="row-top-f4">
                    <Col md={7} xs={24} className="city-f4">
                      <p>{ticketData.RefNumber}</p>
                      <span>Booking Id</span>
                    </Col>
                    <Col md={7} xs={24} className="city-f4">
                      <p className="cnfrom-f3">
                        {getStatus(ticketData.BookingStatus)}
                      </p>
                      <span>Status</span>
                    </Col>
                    
                    {ticketData.SupplierConfirmationNo ===  null ?
                    <Col md={6} xs={24} className="city-f4">
                    <p>{ticketData.NoOfRooms}</p>
                    <span>Room(s)</span>
                  </Col> :
                      <Col md={6} sm={24} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">
                          {ticketData.SupplierConfirmationNo}
                        </p>
                        <span>Confirmation Id</span>
                      </div>
                      </Col> 
                       
                     }
                    <Col md={4} xs={24} className="city-f4">
                      <p>{ticketData.Status === "Hold" ? "Pending" :
                        ticketData.Status === "Confirmed" ? "Paid" :
                          ticketData.Status === "Cancelled" ? "Refund " : ""}</p>
                      <span>Payment Status</span>
                    </Col>
                  </Row>
                </Card>

                <Card className="card-tic-f1">
                  <Row className="info-flight-t22">
                    <Col md={24} xs={24} className="city-tt-f4">
                      <p>Hotel Details</p>
                      <span>
                        Here are the details of your Hotel booked from{" "}
                        {moment(ticketData.CheckInDate).format(dateFormat)} to{" "}
                        {moment(ticketData.CheckOutDate).format(dateFormat)}
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={8} xs={24} className="city-tt-f5">
                      <div className="logo-f3-text">
                        <div className="sg-logo-flight">
                          <p>
                            <strong>{ticketData.HotelName}</strong>
                          </p>

                          <p>{ticketData.CityName}</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={16} xs={24} className="city-tt-f4">
                      <div className="city-from-d3">
                        <div className="city-f55">
                          <p>
                            {moment(ticketData.CheckInDate).format(dateFormat)}
                          </p>
                          <span>Check In</span>
                        </div>
                        <div className="city-f55">
                          <p className="dotted-line-flight"></p>
                          <span>{ticketData.CityName}</span>
                        </div>
                        <div className="city-f55">
                          <p>
                            {moment(ticketData.CheckOutDate).format(dateFormat)}
                          </p>
                          <span>Check Out</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[2, 8]}>
                    <Col md={10} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Hotel Address</span>
                        <p>
                          <strong> {ticketData?.HotelAddress.address}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={8} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>Star Rating</span>
                        <p>
                          <strong> {ticketData.StarRating}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} xs={24} className="city-tt-f5">
                      <div className="sg-logo-flight">
                        <span>No. of Nights</span>
                        <p>
                          <strong> {noOfNights}</strong>
                        </p>
                      </div>
                    </Col>
                    {ticketData.Rooms.map((item, i) => (
                      <Col
                        key={i + "room"}
                        md={10}
                        xs={24}
                        className="city-tt-f5"
                      >
                        <div className="sg-logo-flight">
                          <span>Room Name</span>
                          <p>
                            <strong> {item.roomName}</strong>
                          </p>
                        </div>
                      </Col>
                    ))}
                    {ticketData?.Supplier === "AKBAR" ?
                      <Col md={8} xs={24} className="city-f4">
                        <div className="sg-logo-flight">
                          <span>Inclusions</span>
                          <p style={{ fontSize: 14 }}>
                            <strong> {ticketData?.Inclusions[0]?.boardBasis?.description ?? 'Room Only'}</strong>
                          </p>
                        </div>

                      </Col>

                      :
                      <Col md={8} xs={24} className="city-f4">
                        <div className="sg-logo-flight">
                          <span>Inclusions</span>
                          <p style={{ fontSize: 14 }}>
                            <strong> {ticketData?.Inclusions[0]?.inclusions ?? 'Room Only'}</strong>
                          </p>
                        </div>

                      </Col>
                    }
                    {ticketData.SupplierConfirmationNo == "" ? null :
                      <Col md={6} xs={24} className="city-f4">
                        <span>Room(s)</span>
                        <p>{ticketData.NoOfRooms}</p>
                      </Col>}
                  </Row>
                </Card>

                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Contact Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Name</span>
                      <p>
                        {" "}
                        {ticketData.guests.length > 0
                          ? `${ticketData?.guests[0]?.FirstName} 
                        ${ticketData?.guests[0]?.LastName}`
                          : null}
                      </p>
                    </Col>
                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Mobile Number</span>
                      <p>{ticketData.phoneNo}</p>
                    </Col>

                    <Col md={8} sm={12} xs={24} className="contact-person-d4">
                      <span>Email</span>

                      <p>{ticketData.email}</p>
                     
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Passengers Details</p>
                    </Col>
                  </Row>
                  {ticketData.guests.length > 0
                    ? ticketData.guests.map((item) => (
                      <Row className="details-airlines-contact">
                        <Col md={8} xs={24} className="contact-person-d4">
                          <span>Name</span>
                          <p>
                            {item.FirstName} {item.LastName}
                          </p>
                        </Col>
                        <Col md={8} xs={24} className="contact-person-d4">
                          <span>Age</span>
                          <p>{item.Age}</p>
                        </Col>
                        {/*  {item.Pan ? */}
                        {/*  <Col md={8} xs={24} className="contact-person-d4">
                             <span>Pan No.</span>
                             <p>{item.Pan}</p>
                           </Col> : null} */}
                        <Col md={8} xs={24} className="contact-person-d4">
                          <span>Guest Type</span>
                          <p>{item.GuestType}</p>
                        </Col>
                        {/* <Col md={6} xs={24} className="contact-person-d4">
                            <span>Passport No.</span>
                            <p>{item.PassportNo}</p>
                          </Col> */}
                      </Row>
                    ))
                    : null}
                </Card>

                {ticketData?.insuranceRequired === 1 &&
                  ticketData.insuranceData &&
                  ticketData?.insuranceData?.serviceType === 2 ? (
                  <Card className="insurance-section card-tic-f2">
                    <h5 style={{ fontSize: "16px" }}>
                      THE SMART INSURANCE COVER
                    </h5>
                    <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                    <Row gutter={[16, 16]} align="stretch" className="mt-4">
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_1}
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_2}
                        </div>
                      </Col>
                      <Col md={8} sm={24} xs={24}>
                        <div className="insurance-box">
                          {ticketData?.insuranceData.description_box_3}
                        </div>
                      </Col>
                    </Row>

                    <div className="insurance-coverage">
                      <SafetyCertificateOutlined />
                      <span>
                        Insurance Coverage Amount :{" "}
                        {ticketData?.insuranceData.insuranceCoverage}
                      </span>
                    </div>
                  </Card>
                ) : null}

                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p>Fare Details</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact">
                    <Col md={18} xs={8} sm={12} className="contact-person-d4">
                      <p>Base Fare</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>{curr} {parseFloat((Number(baseAmount) / Number(value)).toFixed(2))}</p>
                      {/* <p>{baseAmount}</p> */}
                    </Col>
                  </Row>
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p>Taxes</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p>{curr} {parseFloat((Number(taxAmount) / Number(value)).toFixed(2))}</p>
                    </Col>
                  </Row>
                  {convienenceFee > 0 ?
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Convenience Fee</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>{curr} {parseFloat((Number(convienenceFee) / Number(value)).toFixed(2))}</p>
                      </Col>
                    </Row>
                    : null}
                  {ticketData?.insuranceRequired === 1 &&
                    ticketData.insuranceData ? (
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Insurance Amount</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>{curr} {parseFloat((Number(insuranceTotal) / Number(value)).toFixed(2))}</p>
                      </Col>
                    </Row>
                  ) : null}
                  {discount > 0 ?
                    <Row className="details-airlines-contact">
                      <Col md={18} xs={12} className="contact-person-d4">
                        <p>Discount</p>
                      </Col>
                      <Col md={6} xs={12} className="contact-person-d4">
                        <p>{curr} {parseFloat((Number(discount) / Number(value)).toFixed(2))}</p>
                      </Col>
                    </Row>
                    : null}
                  <Row className="details-airlines-contact">
                    <Col md={18} xs={12} className="contact-person-d4">
                      <p style={{fontSize: "18px",color:" #2a3b95"}}>Total</p>
                    </Col>
                    <Col md={6} xs={12} className="contact-person-d4">
                      <p style={{marginLeft:"-22px",fontSize: "18px",color:" #2a3b95"}}>{curr} {parseFloat(((Number(grandTotal)) / Number(value)).toFixed(2))}</p>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-tic-f2">
                  <Row>
                    <Col md={20} xs={24} className="city-tt-f4">
                      <p style={{ borderBottom: "1px solid grey", }}>Essential Information :</p>
                    </Col>
                  </Row>

                  <Row className="details-airlines-contact booking-flight-details-info pt-0">
                    <Col md={24} xs={24} className="contact-person-d4">
                      {cmsFareRules?.cancelPolicyDescription ?
                        <div className="termCon_wrapper">
                          <div className="description">
                            <p className="title">Cancel Policy</p>
                            {ReactHtmlParser(
                              cmsFareRules?.cancelPolicyDescription
                            )}
                            {cmsFareRules?.covidRulesDescription ? (
                              <>
                                <p className="title">Covid Policy</p>

                                {ReactHtmlParser(
                                  cmsFareRules?.covidRulesDescription
                                )}
                              </>
                            ) : null}

                            {cmsFareRules?.otherRulesDescription ? (
                              <>
                                <p className="title">Other Policy</p>

                                {ReactHtmlParser(
                                  cmsFareRules?.otherRulesDescription
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                        :
                        // <div>
                        // {ticketData?.Inclusions[0]?.combineRooms[0].refundable ?
                        //     <div>
                        // <h6 style={{ marginBottom: "0.2rem" }}>Cancellation Policy :</h6>
                        // <p style={{ color: "grey", margin: 0 }}>Cancellation charges from {moment(ticketData?.Inclusions?.[0]?.combineRooms?.[0]?.cancellationPolicy?.[0]?.fromDate).format("DD-MM-YYYY")}  to {moment(ticketData?.Inclusions?.[0]?.combineRooms?.[0].cancellationPolicy[0]?.toDate).format("DD-MM-YYYY , hh:mm A")}  is {curr} {ticketData?.Inclusions[0]?.combineRooms[0]?.cancellationPolicy[0]?.penaltyAmount} {ticketData?.Inclusions[0]?.combineRooms[0]?.cancellationPolicy[0]?.chargeType}, Which is Refundable</p>
                        // <p style={{ color: "grey", marginTop: "0.2rem" }}>{ticketData?.Inclusions[0]?.combineRooms[0]?.cancellationPolicyDescription}</p>
                        // </div> : null}
                        //   {ticketData?.Supplier === "AKBAR" ?
                        //     <div className="pol-hot">
                        //       {ticketData?.Inclusions[0]?.combineRooms[0]?.policies?.map((policy, index) => {
                        // return (
                        // <div key={index} className="pol">
                        // <h6 style={{ marginBottom: "0.2rem", fontSize:14 }}>{policy.type} {" :"}</h6>
                        // <p style={{ color: "grey", margin: 0 }}>{policy.text}</p>
                        // {/* {console.log(policy.type, policy.text, "test")} */}
                        // </div>
                        // );
                        //       })}
                        //     </div> :
                        //     <>
                        // <div className="termCon_wrapper">
                        // <p>
                        // * Early check in and late check out is subject to
                        // availability.
                        // </p>

                        //      <p>
                        //          * The passenger has to furnish a photo identity and
                        //        address proof at the time of verification failing to
                        //        which all passenger on that voucher shall not be
                        //        entertained with any refunds or restitution.
                        //      </p>
                        //      <p>
                        //         * Standard Check in time is 1400 hrs / check out
                        //        time 1200 hrs unless specified.
                        //        </p>

                        //        <p>
                        //          * The refund for early check out or unutilized night
                        //         or service is subject to discretion of the hotel &
                        //          the supplier, we strongly recommend to get a letter
                        //          from the hotel favoring no charges for early check
                        //          out.
                        //        </p>

                        //        <p>
                        //          * All additional charges other than the room charges
                        //          and inclusions as mentioned in the booking voucher
                        //          are to be borne and paid separately during
                        //          check-out. Please make sure that you are aware of
                        //          all such charges that may comes as extras. Some of
                        //          them can be WiFi costs, Mini Bar, Laundry Expenses,
                        //          Telephone calls, Room Service, Snacks etc.
                        //        </p>

                        //        <p>
                        //          * Voucher covers only those taxes which are included
                        //          in the booking price (if included), all various
                        //          other applicable taxes needs to be settled directly
                        //          by guest .
                        //        </p>
                        //        <p>
                        //          * We are not liable for any loss or damage caused
                        //          during your stay in the hotel or while availing any
                        //          service.
                        //        </p>

                        //        <p>
                        //          * We have no relevance with the room service or food
                        //          menu or any other service as these are all as per
                        //          the hotel standards.
                        //        </p>

                        //        <p>
                        //          * The hotel reserves the right of admission.
                        //          Accommodation can be denied to guests posing as a
                        //          'couple' if suitable proof of identification is not
                        //          presented at the time of check in. we will not be
                        //          responsible for any check in denied by the hotel due
                        //          to aforesaid reason.
                        //        </p>
                        //      </div> 
                        //      </>
                        //          }
                        //  </div>}
                        <div>
                        {ticketData?.Inclusions[0]?.combineRooms[0].refundable ? (
                          <div>
                            <h6 style={{ marginBottom: "0.2rem" }}>Cancellation Policy :</h6>
                            {ticketData?.Inclusions[0]?.combineRooms[0]?.cancellationPolicy?.map((policy, index) => (
                              <div key={index}>
                                <p style={{ color: "grey", margin: 0 }}>
                                  Cancellation charges from {moment(policy?.fromDate).format("DD-MM-YYYY")} to {moment(policy?.toDate).format("DD-MM-YYYY , hh:mm ")}  is {curr} {policy?.penaltyAmount} {policy?.chargeType}.
                                </p>
                              </div>
                            ))}
                            <p style={{ color: "grey", marginTop: "0.2rem" }}>
                              {ticketData?.Inclusions[0]?.combineRooms[0]?.cancellationPolicyDescription}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <h6 style={{ marginBottom: "0.2rem" }}>Cancellation Policy :</h6>
                            <p style={{ color: "grey", margin: 0 }}>Non Refundable</p>
                          </div>
                        )}
                      
                        {ticketData?.Supplier === "AKBAR" ? (
                          <div className="pol-hot">
                            {ticketData?.Inclusions[0]?.combineRooms[0]?.policies?.map((policy, index) => (
                              <div key={index} className="pol">
                                <h6 style={{ marginBottom: "0.2rem", fontSize: 14 }}>{policy.type} :</h6>
                                <p style={{ color: "grey", margin: 0 }}>{policy.text}</p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="termCon_wrapper">
                            <p>* Early check in and late check out is subject to availability.</p>
                            <p>* The passenger has to furnish a photo identity and address proof at the time of verification failing to which all passenger on that voucher shall not be entertained with any refunds or restitution.</p>
                            <p>* Standard Check in time is 1400 hrs / check out time 1200 hrs unless specified.</p>
                            <p>* The refund for early check out or unutilized night or service is subject to discretion of the hotel & the supplier, we strongly recommend to get a letter from the hotel favoring no charges for early check out.</p>
                            <p>* All additional charges other than the room charges and inclusions as mentioned in the booking voucher are to be borne and paid separately during check-out. Please make sure that you are aware of all such charges that may come as extras. Some of them can be WiFi costs, Mini Bar, Laundry Expenses, Telephone calls, Room Service, Snacks etc.</p>
                            <p>* Voucher covers only those taxes which are included in the booking price (if included), all various other applicable taxes need to be settled directly by guest.</p>
                            <p>* We are not liable for any loss or damage caused during your stay in the hotel or while availing any service.</p>
                            <p>* We have no relevance with the room service or food menu or any other service as these are all as per the hotel standards.</p>
                            <p>* The hotel reserves the right of admission. Accommodation can be denied to guests posing as a 'couple' if suitable proof of identification is not presented at the time of check-in. we will not be responsible for any check-in denied by the hotel due to the aforesaid reason.</p>
                          </div>
                        )}
                      </div>
                      
                      }
                      

                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col md={6} xs={24}></Col>
        </Row>
      </Layout>
    </>
  );
};

export default TicketHotel;
