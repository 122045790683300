import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Collapse,
  Grid,
  Skeleton,
  Modal,
  Button,
  message,
  Form,
} from "antd";
import { useHistory } from 'react-router-dom';
import PageLoader from "../../common/PageLoader/PageLoader";
import FlightsList from "./FlightsList";
import FlightIcon from "../../assets/images/Flighticons/icons8-airplane-96.png";
import Clouds from "../../assets/images/Flighticons/Clouds.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import ApiClient from "../../helpers/ApiClient";
import queryString from "query-string";
import moment from "moment";
import Animation from "../AnimationBall/AnimationBalls";
import ScrollToTopButton from "../ScrollToTop";
import FlightFilters from "../Flight-Filters/FlightFilters";
import FlightSearch from "../flight-search/FlightSearch";
import FlightSearch1 from "../flight-search/FlightSearch1";
import FilterSkeletonLayout from "../FilterSkeleton/FilterSkeleton";
import FlightResultsCardSkeleton from "../FlightResultsCardSkeleton/FlightResultsCardSkeleton";
import NoResultFound from "../ErrorPages/NoResultFound";
import { getSearchRequestFromQuery } from "../flight-search/SearchRequestHelper";
import FlightRoundFilter from "../Flight-Filters/FlightRoundFilter";
import Sort from "./SortBy/Sort";

import "./SearchResults.scss";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import OnSitePopUp, { PackagePopUP } from "../SitePopups/onsitePopup";
import FlightDates, { AirlineSlider } from "./FlightNearDates/FlightNearDates";
import { Page } from "@react-pdf/renderer";

import Nav1 from "../../common/navbar/Nav1";

const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const SearchResults = () => {
  const { user } = useAuthContext();
  const {
    setFlightSearchObj,
    resetFlightSelectedData,
    updateFlightAirSearchRespObj,
    updateSelectedFlight,
    updateFlightFares,
    state: { flightAirSearchResp, flightSearchObj, selectedFlight },
  } = useFlightContext();

  const {
    state: { airlineMatrixReset, flightFilters },
    ResetAirlineMatrix,
  } = useContext(GlobalStatesContext);

  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [promodataSource, setPromoDataSource] = useState([]);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [MultiCityIndex, setMultiCityIndex] = useState(0);
  const [istopset, setistopset] = useState(-740);
  const history = useHistory();
  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }
  const dateFormat = "DD-MM-YYYY";
  const getMultiCityIndex = (index) => {
    setMultiCityIndex(index)
  }
  const getPromoCodes = () => {
    setPromoDataSource([]);

    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const SetUpFlightFromResponse = (resp, requestData) => {
    setFlightSearchObj({
      ...requestData,
      traceId: resp.data.traceId,
      resultsType: resp.data.resultsType,
    });
    // console.log("SetUp");
    updateFlightAirSearchRespObj({
      ...resp.data,
      tripType: 1,
      flightDetails: resp?.data?.flightDetails?.map((flight) => {
        return { ...flight, isVisible: true };
      }),
      ibFlightDetails: resp?.data?.ibFlightDetails?.map((flight) => {
        return { ...flight, isVisible: true };
      }),
      multicityFlightDetails: resp.data?.multicityFlightDetails?.map((flights) => {
        return flights?.map((flight) => {
          return { ...flight, isVisible: true };
        })
      })
    });
  };

  const getFlightResults = () => {
    setIsLoading(true);
    resetFlightSelectedData();
    const requestData = getSearchRequestFromQuery();

    if (
      requestData?.originDestinations[0]?.destination ===
      requestData?.originDestinations[0]?.origin
    ) {
      message.error("Origin and Destination cannot be same", 3);
      setIsLoading(false);
      return false;
    }

    let reqData = {
      ...requestData,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,

    };
    const maxRetryCount = 1;
    // console.log("reqData", reqData)

    ApiClient.post("flights/airSearch", reqData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        // { console.log(resp.data.flightDetails.length, "resp") }
        if (resp.statusCode === 200) {
          if (resp.data.flightDetails.length > 0 || resp.data.multicityFlightDetails.length > 0) {
            // console.log("not comming");

            SetUpFlightFromResponse(resp, reqData);
          }
          else {
            let retryCount = JSON.parse(localStorage.getItem('retryCount')) || 0;

            if (retryCount < maxRetryCount) {
              retryCount += 1;
              localStorage.setItem('retryCount', JSON.stringify(retryCount));
              let cleanedMessage = "Fetching Best Prices For your Flights, ";
              let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
              message.loading({
                content: cleanedMessage,
                key: 'loadingMessage',
                duration: 0 // Duration 0 means it will display until manually closed
              });

              // history.push("/flight/results?" + new URLSearchParams(queryParams).toString());
              setTimeout(() => {
                message.destroy('loadingMessage');
                window.location.reload();
              }, 2000);
            } else {
              localStorage.setItem('retryCount', JSON.stringify(0));
              let cleanedMessage = "We're sorry, but we couldn't retrieve the information you requested. Please try again later.";
              Modal.confirm({
                title: 'Please retry',
                content: cleanedMessage,
                className: "promo-modal-header modal-header-bg12",
                onOk() {
                  // let queryParams = JSON.parse(localStorage.getItem('FlightSearchBar'));
                  // history.push("/flight/results?" + new URLSearchParams(queryParams).toString());
                  history.push("/");
                },
                okText: '< Back',
                // cancelButtonProps: { style: { display: 'none' } },
              });
            }

          }
        }
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  const onFormSubmit = () => {
    getFlightResults();
  };

  useEffect(() => {
    getPromoCodes();

    /** Commented For Temporary */

    // if (
    //   !localStorage.getItem("popupShown") &&
    //   JSON.parse(localStorage.getItem("popupShown")) !== true
    // ) {
    //   onInactive(15000, function () {
    //     setPopUpVisibility(true);
    //   });
    // }

  }, []);

  useEffect(() => {
    getFlightResults();
  }, [window.location.search]);


  const LoadingSkelTon = () => {
    const [primaryScale, setPrimaryScale] = useState(0);
    const [secondaryScale, setSecondaryScale] = useState(1); // Initial scale for secondary image

    useEffect(() => {
      const interval = setInterval(() => {
        if (isLoading && primaryScale < 1) {
          setPrimaryScale(prevScale => prevScale + 0.01); // Increment primary scale gradually
        } else if (isLoading && secondaryScale > 0) {
          setSecondaryScale(prevScale => prevScale - 0.01); // Decrement secondary scale gradually
        } else {
          clearInterval(interval); // Stop scaling when isLoading is false
        }
      }, 22); // Adjust interval as needed for desired speed

      return () => clearInterval(interval);
    }, [isLoading, primaryScale, secondaryScale]);

    // useEffect(() => {
    //   // Simulating loading completion after 3 seconds
    //   const timeout = setTimeout(() => {
    //     setIsLoading(false);
    //   }, 3000);

    //   return () => clearTimeout(timeout);
    // }, []);


    return (
      <div className="loading-skeleton-container">
        <div className="image-container">
          <div className="flight-icon-container" style={{ transform: `scale(${primaryScale})` }}>
            <img className="flight-icon" src={FlightIcon} alt="Airplane Front View" />
          </div>
          <div className="second-image-container" style={{ transform: `scale(${secondaryScale})` }}>
            <img className="second-image" src={Clouds} alt="Second Image" />
          </div>
        </div>

        <div className="flight-results-skeleton-container">

          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={6} xs={0}>
              <FilterSkeletonLayout />

            </Col>
            <Col md={18} xs={24}>
              <div className="flights-skeleton-card">
                <div className="nearbydates-skeleton">
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <div className="flights-sort-skeleton">
                  <Row className="flight-sort-skeleton-row">
                    <Col md={3} xs={0} className="sort-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                    <Col md={4} xs={0}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={4} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={3} xs={6} className="airline-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                  </Row>
                </div>
                <PageLoader />
                {/* <FlightResultsCardSkeleton /> */}
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const getPrevNextsearch = (type) => {
    const searchRequest = queryString?.parse(window.location.search);
    let start_params = `adultCount=${searchRequest.adultCount}&airTravelType=${searchRequest.airTravelType}&cabinClass=${searchRequest.cabinClass}&childCount=${searchRequest.childCount}&infantCount=${searchRequest.infantCount}&stopOver=${searchRequest.stopOver}&priceForTrip=${searchRequest.priceForTrip}&includeCarrier=${searchRequest.includeCarrier}&`;
    let one_way_params =
      start_params +
      `departureDateTime=${moment(searchRequest.departureDateTime)
        .add(type, "day")
        .format("YYYY-MM-DD")
        .toString()}&flightDateFlex=${searchRequest.flightDateFlex}&origin=${searchRequest.origin
      }&destination=${searchRequest.destination}`;

    if (searchRequest.airTravelType != "oneWay") {
      one_way_params =
        one_way_params +
        `&returnDateTime=${searchRequest.originDestinations[1].departureDateTime}`;
    }
    history.replace("/flight/results?" + one_way_params);
  };

  return (

    <div>
      <Nav1 />
      <div className="modify-search-container">
        <div className="modify-search-container-fluid">
          <Collapse
            activeKey={md ? ["1"] : key}
            showArrow={false}
            onChange={(val) => {
              setKey(val);
            }}
          >
            <Panel
              showArrow={false}
              header={<span className="hotels-hide-search">Modify Search</span>}
              key="1"
            >
              {/* <FlightSearch modify={true} onFormSubmit={onFormSubmit} /> */}
              <FlightSearch1 modify={true} onFormSubmit={onFormSubmit} />
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="result_div">
        {isLoading ? (
          <LoadingSkelTon />
          // <PageLoader />
        ) : (
          <div>
            {/* <Animation /> */}
            {/* {console.log(Object.keys(flightAirSearchResp).length, flightAirSearchResp.flightDetails.length,"checkkk")} */}
            {Object.keys(flightAirSearchResp).length === 0 ||
              flightAirSearchResp.flightDetails.length === 0 && flightAirSearchResp.multicityFlightDetails.length == 0 ? (
              <div className="flight-results-skeleton-container">
                <NoResultFound />
              </div>
            ) : (
              <>
                <div className="result_div-container">

                  <Row gutter={{ xs: 8, md: 16 }} >
                    <Col md={6}
                      xs={0} className="flight-filters-outc-fun" style={{ background: "white" }} >
                      <div
                        style={{
                          position: "sticky",
                          zIndex: "0",
                          top: istopset,
                        }}
                      >
                        <div className="filter-section">
                          {flightAirSearchResp.airTravelType === "roundTrip" &&
                            flightAirSearchResp.resultsType === "SEPARATE" ? (

                            <FlightRoundFilter
                              updateFlightAirSearchRespObj={
                                updateFlightAirSearchRespObj
                              }
                              ResetAirlineMatrix={ResetAirlineMatrix}
                              selectedTripType={selectedTripType}
                              flightAirSearchResp={flightAirSearchResp}
                              MultiCityIndex={MultiCityIndex}
                            />

                          ) : (



                            <FlightFilters
                              updateFlightAirSearchRespObj={
                                updateFlightAirSearchRespObj
                              }
                              ResetAirlineMatrix={ResetAirlineMatrix}
                              selectedTripType={selectedTripType}
                              flightAirSearchResp={flightAirSearchResp}
                              MultiCityIndex={MultiCityIndex}
                            />



                          )}
                        </div>
                      </div>
                      <div>
                        <ScrollToTopButton />
                      </div>
                    </Col>
                    <Col className="flight-resut-Outc-fun" style={{ background: "white" }}>
                      {/* {console.log(flightAirSearchResp?.airTravelType, MultiCityIndex, "type-6")} */}
                      {/* {flightAirSearchResp?.airTravelType?.toUpperCase() != "MULTIDESTINATION" ?
                        <div className="flights-nearby-dates-container hidden-sm hidden-xs">
                          <AirlineSlider
                            airlineFilters={flightFilters?.airlines}
                            updateFlightAirSearchRespObj={
                              updateFlightAirSearchRespObj
                            }
                            // ResetAirlineMatrix={ResetAirlineMatrix}
                            selectedTripType={selectedTripType}
                            flightAirSearchResp={flightAirSearchResp}
                          // MultiCityIndex={MultiCityIndex}
                          />
                        </div> : null} */}
                      {flightAirSearchResp.airTravelType === "oneWay" &&
                        <div className="flights-nearby-dates-container hidden-sm hidden-xs">
                          <FlightDates
                            flights_all={flightAirSearchResp}
                            airSearchData={flightSearchObj}
                            MultiCityIndex={MultiCityIndex}
                          // onFlexiDateClicked={onFlexiDateClicked}
                          />
                        </div>
                      }
                      <div className="flight-sort-container">
                        {flightAirSearchResp.airTravelType === "roundTrip" &&
                          flightAirSearchResp.resultsType === "SEPARATE" ? "" : (
                          <Sort
                            updateFlightAirSearchRespObj={
                              updateFlightAirSearchRespObj
                            }
                            flightAirSearchResp={flightAirSearchResp}
                            airlineMatrixReset={airlineMatrixReset}
                            ResetAirlineMatrix={ResetAirlineMatrix}
                            selectedTripType={selectedTripType}
                            showNetFare={showNetFare}
                            setShowNetFare={setShowNetFare}
                            MultiCityIndex={MultiCityIndex}
                          />
                        )}
                      </div>
                      <div className="flight-search-results-sec">
                        <div>
                          <div style={{ display: "inline-block", width: "100%" }}>
                            <FlightsList
                              setSelectedTripType={setSelectedTripType}
                              flightAirSearchResp={flightAirSearchResp}
                              updateSelectedFlight={updateSelectedFlight}
                              flightSearchObj={flightSearchObj}
                              updateFlightFares={updateFlightFares}
                              selectedFlight={selectedFlight}
                              showNetFare={showNetFare}
                              promoData={promodataSource}
                              setMultiCityIndex={getMultiCityIndex}
                              MultiCityIndex={MultiCityIndex}
                              updateFlightAirSearchRespObj={
                                updateFlightAirSearchRespObj
                              }
                              airlineMatrixReset={airlineMatrixReset}
                              ResetAirlineMatrix={ResetAirlineMatrix}
                              setShowNetFare={setShowNetFare}
                              selectedTripType={selectedTripType}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
        {/* <Col md={8} xs={8} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1">
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;Map
          </h5>
        </Col> */}
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Flight Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              {/* {flightAirSearchResp.flightDetails && (
                <FlightFilters
                  updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  flightAirSearchResp={flightAirSearchResp}
                />
              )} */}
              {flightAirSearchResp.airTravelType === "roundTrip" &&
                flightAirSearchResp.resultsType === "SEPARATE" ? (

                <FlightRoundFilter
                  updateFlightAirSearchRespObj={
                    updateFlightAirSearchRespObj
                  }
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  flightAirSearchResp={flightAirSearchResp}
                  MultiCityIndex={MultiCityIndex}
                />

              ) : (



                <FlightFilters
                  updateFlightAirSearchRespObj={
                    updateFlightAirSearchRespObj
                  }
                  ResetAirlineMatrix={ResetAirlineMatrix}
                  selectedTripType={selectedTripType}
                  flightAirSearchResp={flightAirSearchResp}
                  MultiCityIndex={MultiCityIndex}
                />



              )}
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              <div>
                {flightAirSearchResp.airTravelType === "roundTrip" &&
                  flightAirSearchResp.resultsType === "SEPARATE" ? "" : (
                  <Sort
                    updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                    flightAirSearchResp={flightAirSearchResp}
                    airlineMatrixReset={airlineMatrixReset}
                    ResetAirlineMatrix={ResetAirlineMatrix}
                    selectedTripType={selectedTripType}
                    showNetFare={showNetFare}
                    setShowNetFare={setShowNetFare}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* {popUpVisibility ? (
        <OnSitePopUp
          visibility={popUpVisibility}
          setPopUpVisibility={setPopUpVisibility}
          serviceType={1}
          searchData={getSearchRequestFromQuery()}
        />
      ) : (
        ""
      )} */}
    </div >

  );
};

export default SearchResults;
