import React from 'react'
import place from "../../assets/images/place.png";
import imap from "../../assets/images/map.png";
import "./DiscountBan.scss";
import cloud from "../../assets/images/bg/cloud.png";
import cloudreal from "../../assets/images/bg/cloud-real.png";
import zigZag from "../../assets/images/bg/zig-zag-1.png";
import hmap from "../../assets/images/Wrldmap1.png";


export const DiscountBan = (page) => {
 
    const p = page.page
    return (
        <section className="p-0 banner-section full-banner zindex-1">
            <img src={zigZag} alt="" className="zigZag" />
            <div className="section-b-space section-t-space black-layer">
                {/* <img src={place} className="bg-img" /> */}
                <div className="cloud">
                    <img src={cloudreal} alt="" className="clod-img-1" />
                </div>
                {/* <div className="cloud">
                    <img src={cloud} alt="" className="bg-img" />
                </div> */}
                <div className="container">
                    <div className="row-disc-ban">
                        <div className="col-lg-6">
                            <div className="place-image">
                                {p === "h" ?
                                    <img src={hmap} alt="" className="img-fluid  wow zoomIn" /> :
                                    <img src={imap} alt="" className="img-fluid  wow zoomIn" />}

                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            {/* <BannerContent /> */}
                            <div className="banner-content">
                                <div>
                                    {/* {type === "tour" ? (
                                        <> */}
                                    <div className="offer-text">
                                        {/* <span className="offer">Offer</span> */}
                                        <h6>
                                            Best Deals Across the World
                                            {/* <TextSplit text="limited time" /> */}
                                        </h6>
                                    </div>
                                    <h5 style={{ fontSize: "26px" }}>
                                        Special Discounts and Rates Exclusively on OutC
                                    </h5>

                                    {/* ) : (
                                        <h5>special nature tour offer</h5>
                                    )} */}


                                    <p>Our goal is to give every traveler the best value, and with our loyalty rewards, frequent users can unlock even more savings, cashback offers, and special promotions, making it your go-to platform for all your travel needs.</p>
                                    {/* <h2 className="price">
                                        {type === "tour" && (
                                            <del>
                                                {symbol}
                                                {(currencyValue * 600).toFixed(0)}
                                            </del>
                                        )} {symbol}{(currencyValue * 420).toFixed(0)}
                                    </h2> */}
                                    <div className="bottom-section">
                                        {/* <Link href="/tour/booking/booking-page" className="btn btn-rounded btn-sm color1">
                                            {/* {BuyNow} *
                                        </Link> */}
                                        <div className="info-btn"><h6>Flights &nbsp; | &nbsp; Buses | &nbsp; Hotels</h6></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cloud">
                    <img src={cloud} alt="" className="clod-img" />
                </div>
            </div>
            <img src={zigZag} alt="" className="zigZagB" />
        </section>
    )
}
