import React from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import "../subscribe/Subscribe.scss";
import APIClient from "../../../src/helpers/ApiClient";
import zigZag from "../../assets/images/bg/zig-zag-1.png";

const Subscribe = () => {
  const [subsribeForm] = Form.useForm();

  const subsribeData = {
    DeviceOS: 3,
    ApplicationType: 1,
    Email: "",
    // Name: "",
    Status: 0,
    CreatedBy: 0,
    DeviceOSVersion: "string",
    DeviceToken: "string",
    FirBaseToken: "string",
  };
  const handleSubscibe = (value) => {
    let data = {
      ...subsribeData,
      ...value,
    };
    APIClient.post("admin/subscriberReport", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Subscibed SuccessFully", 3);
          subsribeForm.resetFields();
        } else if (response.status == 400) {
          message.error("This Email Address Has Already Subscribed.", 3);
        } else if (response.status == 404) {
          message.error("Failed", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section className="stay-best-email">
      <div className="container">
        {/* <Row>
          <Col md={24} xs={24}>
            <h2 className="top-fl-route-email">Subscribe Today</h2>
          </Col>
        </Row> */}

        <Form
          form={subsribeForm}
          onFinish={handleSubscibe}
          className="home-from-sub"
        >
          <Row style={{display:"flex", flexWrap:'wrap', justifyContent:'space-evenly'}}>
            <div className="sub-text-up">
              <img
                className="news-sub-tt"
                style={{color:"white"}}
                src={require("../../assets/images/Icons/newsletter1.png").default}
                alt=""
              />
              <div className="date-news-travel">
                <p>Your Travel Journey Starts Here</p>
                <span>Sign up and we`'ll send the best deals to you</span>
              </div>
            </div>
            {/* <Col md={6} xs={24}>
              <Form.Item name="Name">
                <Input className="name-sub-date" placeholder="Your Name" />
              </Form.Item>
            </Col> */}
            <div className="form-css-work-subscribe">
              <Form.Item
                name="Email"
                className="Formdata-subscribe-main"
                rules={[
                  { required: true, message: "Required" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input className="name-sub-date" placeholder="Your Email" />
              </Form.Item>
              <Button style={{border:"1px solid white"}} htmlType="submit" className="cribe-btn-email">
                Subscribe
              </Button>
            </div>

            {/* <div   className="subscribe-btn-work-css">
              <Button htmlType="submit" className="cribe-btn-email">
                Subscribe
              </Button>
            </div> */}
          </Row>
        </Form>
      </div>
      <img src={zigZag} style={{filter:"invert(0.9)", position:"relative",bottom:"-38px", width:"100%"}} alt="" className="zigZagD" />
    </section>
  );
};

export default Subscribe;
