import React, { createContext, useContext, useState, useEffect } from "react";



const defFlightSearchObj = null;
export const FlightContext = createContext();

export function useFlightContext() {
  return useContext(FlightContext);
}

const FlightProvider = (props) => {
  //Hydrating Flight Data from localstorage
  const flightPersistJson =
    JSON.parse(localStorage.getItem("flightPersist")) || {};

  let initFlightSearchObj =
    flightPersistJson.flightSearchObj || defFlightSearchObj;
  let initSelectedFlight = flightPersistJson.selectedFlight || [];

  const [flightSearchObj, setFlightSearchObj] = useState(initFlightSearchObj);

  const [originDestinationsArr, setOriginDestinationsArr] = useState([
    {
      departureDateTime: "2020-09-25T00:00:00",
      origin: "LON",
      destination: "BOM",
    },
  ]);

  const [flightSearch, setFlightSearch] = useState({
    originDestinations: originDestinationsArr,
    adultCount: 1,
    childCount: 0,
    infantCount: 0,
    cabinClass: "Economy",
    includeCarrier: null,
    excludeCarrier: null,
    stopOver: "None",
    airTravelType: "oneWay",
    flightDateFlex: 0,
    itineraryViewType: "1",
  });

  const [mOriginDest, setMOriginDest] = useState([
    {
      origin: "",
      destination: "",
      departureDateTime: "",
    },
    {
      origin: "",
      destination: "",
      departureDateTime: "",
    },
  ]);

  const [flightFareRulesResp, setFlightFareRulesResp] = useState("");

  const [selectedFlight, setSelectedFlight] = useState(initSelectedFlight);

  const [airBookStateObj, setAirBookStateObj] = useState({});

  const [flightBookingResp, setFlightBookingResp] = useState({});

  const [flightAirSearchResp, setFlightAirSearchResp] = useState({});

  const [flightAirPriceResp, setFlightAirPriceResp] = useState({});

  /* Update Flight Search obj  */
  const updateFlightSearchObj = (attribute, value) => {
    setFlightSearchObj({ ...flightSearchObj, [attribute]: value });
  };
  /* Update Flight Search obj  */
  const updateFlightSearch = (attribute, value) => {
    setFlightSearch({ ...flightSearch, [attribute]: value });
  };

  /* Update Origin and Destination Obj */
  const updateOriginDest = (value) => {
    originDestinationsArr.map(() => setOriginDestinationsArr(value));
    setFlightSearch({ ...flightSearch, originDestinations: value });
  };

  const updateMOriginDest = (value) => {
    setMOriginDest(value);
  };

  /* Update Flight fare Rules data */
  const updateFlightFares = (FareRulesResp) => {
    setFlightFareRulesResp(FareRulesResp);
  };

  /* Update selected flight Obj */
  const updateSelectedFlight = (flightObj) => {
    setSelectedFlight(flightObj);
    // console.log(flightObj,"obj");
  };

  /* Update airbook request Obj */
  const updateAirBookState = (airBookReq) => {
    setAirBookStateObj(airBookReq);
  };

  /* Update airbook resp Obj */
  const updateAirBookRespState = (airBookResp) => {
    setFlightBookingResp(airBookResp);
  };

  /* Update Flight Search obj  */
  const updateFlightAirSearchRespObj = (flightAirResponse) => {
    setFlightAirSearchResp(flightAirResponse);
  };

  /* Update Airprice resp Obj */
  const updateFlightAirPrice = (airPriceRespObj) => {
    setFlightAirPriceResp(airPriceRespObj);
  };

  const resetFlightSearchObj = (flightSearchObj) => {
    setFlightSearchObj(flightSearchObj || defFlightSearchObj);
  };

  useEffect(() => {
    localStorage.setItem(
      "flightPersist",
      JSON.stringify({
        flightSearchObj: flightSearchObj,
        selectedFlight: selectedFlight,
      })
    );
  }, [flightSearchObj, selectedFlight]);
// {console.log(selectedFlight,flightPersistJson,"context");}
  return (
    <FlightContext.Provider
      value={{
        state: {
          flightSearch,
          originDestinationsArr,
          mOriginDest,
          flightSearchObj,
          flightFareRulesResp,
          selectedFlight,
          airBookStateObj,
          flightBookingResp,
          flightAirPriceResp,
          flightAirSearchResp,
        },
        setFlightSearchObj: setFlightSearchObj,
        updateFlightSearch: updateFlightSearch,
        updateOriginDest: updateOriginDest,
        updateMOriginDest: updateMOriginDest,
        updateFlightSearchObj: updateFlightSearchObj,
        updateFlightFares: updateFlightFares,
        updateSelectedFlight: updateSelectedFlight,
        updateAirBookState: updateAirBookState,
        updateAirBookRespState: updateAirBookRespState,
        updateFlightAirSearchRespObj: updateFlightAirSearchRespObj,
        updateFlightAirPrice: updateFlightAirPrice,
        resetFlightSearchObj: resetFlightSearchObj,
        resetFlightSelectedData: () => {
          setSelectedFlight([]);
          setFlightAirSearchResp({});
        },
      }}
    >
      {props.children}
    </FlightContext.Provider>
  );
};

export default FlightProvider;
