import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import "./cmspages.scss";
import ReactHtmlParser from "react-html-parser";

import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import { useSytContext } from "../providers/SytProvider";
import Animation from "../../components/AnimationBall/AnimationBalls";

const { Panel } = Collapse;
const CmsPages = ({ cmsId, pageTitle }) => {
  const [CMSList, setCMSList] = useState([]);
let page = pageTitle;
  const getCMSList = () => {
    APIClient.get(`admin/cms/${cmsId}`)
      .then((response) => {
        if (response?.status == 200) {
          setCMSList(response.data);
        } else {
          setCMSList([]);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getCMSList();
  }, []);

  return (
    <div style={{paddingTop:"60px"}} className="collapseCustomWhite">
      <div className="promo providerapi ourteam-values"></div>
<Animation />
      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <h2 className="common-headings">{pageTitle}</h2>


          {CMSList.map((res) => (
            <div style={{fontFamily:"Nunito"}} key={res.Id} className="cms-records-wrapper">
              <p className="title">{res.Title}</p>

              <div className="des-wrapper">
                {ReactHtmlParser(res.Description)}
              </div>
            </div>
            
            ))}
           
        </div>
      </div>
    </div>
  );
};

export const VisaGuideLines = () => {
  const { visaGuideFaqs } = useSytContext();
  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi ourteam-values"></div>

      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <h2 className="common-headings">Visa Guildelines</h2>

         
          {visaGuideFaqs.length
            ? visaGuideFaqs.map((item, index) => {
                return (
                  <div
                    key={"visaGuide" + index}
                    className="cms-records-wrapper mb-3"
                  >
                    <p className="title">
                      {index + 1}. {item.Type}
                    </p>
                    <p className="statement mb-1">{item.Statement}</p>

                    <div className="des-wrapper">
                      <p className="title">Description</p>
                      {ReactHtmlParser(item.Description)}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default CmsPages;
