
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCZpw5gVBUYuyJ0yDWTnK8iaG0Q1tCHFJ4",
  authDomain: "b2c-project-3dda8.firebaseapp.com",
  projectId: "b2c-project-3dda8",
  storageBucket: "b2c-project-3dda8.firebasestorage.app",
  messagingSenderId: "354854157542",
  appId: "1:354854157542:web:60b4945fdd8e59b0d54f27"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const fbAuthProvider = new FacebookAuthProvider();
export {auth,provider,fbAuthProvider};