import React from "react";
import * as ANTD from "antd";
import "../Bussalescount/Bussalescount.scss";

const Bussalescount = () => {
  return (
    <div className="dashboardSkeleton">
      <div className="dashboard">
        <div className="dash-board-header"></div>
        <div className="order-count-cards order-line-bar-fhb"></div>
        <div className="order-count-cards hotel-sales-new">
          <ANTD.Row>
            <ANTD.Col xs={24} md={24}>
              <h4 className="flights-sales-card">Bus Sales</h4>
            </ANTD.Col>
          </ANTD.Row>
          <ANTD.Row>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
          </ANTD.Row>
          <ANTD.Row className="first-chart-count">
            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>

            <ANTD.Col xs={24} md={4}>
              <ANTD.Card>
                <ANTD.Skeleton active paragraph={{ rows: 0 }} />
              </ANTD.Card>
            </ANTD.Col>
          </ANTD.Row>
        </div>
        <div className="order-count-cards ">
          <ANTD.Row className="charts-bars card-count-balance">
            <ANTD.Col xs={24} md={12}>
              <ANTD.Card className="side-bar-graph-1">
                <ANTD.Skeleton active paragraph={{ rows: 4 }} />
              </ANTD.Card>
            </ANTD.Col>

            <ANTD.Col xs={24} md={12}>
              <div className="site-card-border-less-wrapper">
                <ANTD.Card className="side-bar-graph-new-flight">
                  <ANTD.Row className="boder-count">
                    <ANTD.Col xs={24} md={24} className="total-cal-fl">
                      <ANTD.Skeleton active paragraph={{ rows: 4 }} />
                    </ANTD.Col>
                  </ANTD.Row>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </div>
    </div>
  );
};
export default Bussalescount;
