import React, { useContext, useState, useEffect } from "react";

import Flight, { FlightSelection, FlightSe, FlightCom } from "../Flight/Flight";

import { Col, Row } from "antd";
import { useSytContext } from "../../../common/providers/SytProvider";
import InfiniteScroll from "react-infinite-scroll-component";
const CombinedView = ({
  changeFlightResultsView,
  selectedFlight,
  flightSearchObj,
  setFlightFareRules,
  combinedOnewayFlightList,
  combinedTwowayFlightList,
  showNetFare,
  promoData,
  traceId
}) => {
  const { flightSearchBanners } = useSytContext();
  const flightResultLength = 10;
  let isOneway = (flightSearchObj.airTravelType.toUpperCase() === "ONEWAY") /*|| flightSearchObj.airTravelType === "multidestination")*/ ? true : false

  const [flightResult, updateFlightResult] = useState([])
  const [activeCollapse, setActiveCollapse] = useState("");
  const fetchMoreBuses = () => {
    setTimeout(() => {
      // const addMoreFlight = isOneway ? (combinedOnewayFlightList.slice(prev.length, prev.length + flightResultLength)) : (combinedTwowayFlightList.slice(prev.length, prev.length + flightResultLength))
      updateFlightResult((prev) => [...prev, ...(isOneway ? (combinedOnewayFlightList.slice(prev.length, prev.length + flightResultLength)) : (combinedTwowayFlightList.slice(prev.length, prev.length + flightResultLength)))])
      // updateDisplayFlightResult((prev) => [...prev, ...flightList.slice(prev.length, prev.length + flightResultLength)])

    }, 100)
  }
  useEffect(() => {
  

    const filterData = isOneway
      ? combinedOnewayFlightList?.slice(0, flightResultLength)
      : combinedTwowayFlightList?.slice(0, flightResultLength)
    updateFlightResult(filterData)

  }, [combinedOnewayFlightList, combinedTwowayFlightList])
  let bannreIndex = -1;
  //  console.log(combinedTwowayFlightList,flightResult,isOneway,updateFlightResult,"jhgjgjhg")
  return (
    <>
      {flightSearchObj?.airTravelType?.toUpperCase() === "ONEWAY" ? (
        // ||
        // flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" 

        flightResult?.length > 0 ? (
          <InfiniteScroll
            dataLength={flightResult.length}
            next={fetchMoreBuses}
            hasMore={flightResult.length >= combinedOnewayFlightList.length ? false : true}
            endMessage={"End Of Flight Result"}
            loader={<h4>Loading...</h4>}
          >
            {/* {console.log(flightResult,"gghghfgh")} */}
            {flightResult?.map((flight, i) => {
              let filteredPromoData =
                promoData?.length > 0
                  ? promoData?.filter(
                    (item) => item?.airlineCode === flight.airLine
                  ).length > 0
                    ? promoData?.filter(
                      (item) =>
                        item.airlineCode?.toUpperCase() ===
                        flight.airLine?.toUpperCase()
                    )
                    : promoData?.filter((item) => item.airlineCode === "ALL")
                  : null;

              return (
                <>
                  {i != 0 && i % 9 == 0 && flightSearchBanners[++bannreIndex] && (
                    <div className="combine-roundtrip-view">
                      <img
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "fill",
                        }}
                        src={
                          process.env.REACT_APP_BASE_URL +
                          flightSearchBanners[bannreIndex].path.substring(1)
                        }
                        data={
                          flightSearchBanners[bannreIndex + 1]
                            ? null
                            : (bannreIndex = -1)
                        }
                      />{" "}
                    </div>
                  )}

                  <div className="combine-roundtrip-view">
                    <Row>
                      <Col md={24} xs={24}>
                        <Flight
                          flightInfo={flight}
                          flightInfo1={flight}
                          changeFlightResultsView={changeFlightResultsView}
                          key={flight.flightId}
                          flightSearchObj={flightSearchObj}
                          setFlightFareRules={setFlightFareRules}
                          fareFamilies={flight.fareFamilies}
                          showNetFare={showNetFare}
                          selectedFlight={selectedFlight}
                          promoData={filteredPromoData}
                          index={`FLC-${i + 1}`}
                          activeCollapse={activeCollapse}
                          setActiveCollapse={setActiveCollapse}
                          ind={i}
                        />
                      </Col>
                      {/* <Col className="price-tab-fli" md={6} xs={24}>
                        <div className="choose-btn-wrapper">
                          <FlightSelection
                            flightInfo={flight}
                            changeFlightResultsView={changeFlightResultsView}
                            flightSearchObj={flightSearchObj}
                            selectedFlight={selectedFlight}
                            showNetFare={showNetFare}
                            MultiCityIndex={null}
                            index={`FLC-${i + 1}`}
                            activeCollapse={activeCollapse}
                            setActiveCollapse={setActiveCollapse}
                          />
                        </div>
                      </Col> */}
                    </Row>
                    <Row><Col>
                      <FlightSe
                        flightInfo={flight}
                        changeFlightResultsView={changeFlightResultsView}
                        flightSearchObj={flightSearchObj}
                        selectedFlight={selectedFlight}
                        showNetFare={showNetFare}

                        MultiCityIndex={null}
                        index={`FLC-${i + 1}`}
                        activeCollapse={activeCollapse}
                        setActiveCollapse={setActiveCollapse}
                        traceId={traceId}
                      />
                    </Col></Row>
                  </div>
                </>
              );
            })}
          </InfiniteScroll>
        ) : (
          <div>No flights available</div>
        )
      ) : flightResult.length > 0 ? (
        <InfiniteScroll
          dataLength={flightResult.length}
          next={fetchMoreBuses}
          hasMore={flightResult.length >= combinedTwowayFlightList.length ? false : true}
          endMessage={"End Of Flight Result"}
          loader={<h4>Loading...</h4>}
        >
          {flightResult.map((flights, i) => {
            return (
              <>
                {i != 0 && i % 6 == 0 && flightSearchBanners[++bannreIndex] && (
                  <div className="combine-roundtrip-view" key={"banner" + i}>
                    <img
                      style={{
                        width: "100%",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      src={
                        process.env.REACT_APP_BASE_URL +
                        flightSearchBanners[bannreIndex].path.substring(1)
                      }
                      data={
                        flightSearchBanners[bannreIndex + 1]
                          ? null
                          : (bannreIndex = -1)
                      }
                    />{" "}
                  </div>
                )}
                <div className="combine-roundtrip-view txt-cob-fli8">

                  {/* {console.log(flights, flightResult, "combined")} */}
                  {flights.flightDetails.map((flight, z) => (
                    <FlightCom
                      flightInfo={flight}
                      flightInfo1={flights}
                      changeFlightResultsView={changeFlightResultsView}
                      key={flight.flightId}
                      flightSearchObj={flightSearchObj}
                      setFlightFareRules={setFlightFareRules}
                      fareFamilies={flight.fareFamilies}
                      showNetFare={showNetFare}
                      selectedFlight={selectedFlight}

                      index={`FLC-${i + 1}`}
                      activeCollapse={activeCollapse}


                      MultiCityIndex={null}
                      setActiveCollapse={setActiveCollapse}
                      ind={i}
                      isLastItem={z === flights.flightDetails.length - 1}
                    />
                  ))}


                  {/* <div className="choose-btn-wrapper">
                        <FlightSelection
                          flightInfo={flights}
                          changeFlightResultsView={changeFlightResultsView}
                          flightSearchObj={flightSearchObj}
                          selectedFlight={selectedFlight}
                          showNetFare={showNetFare}
                          MultiCityIndex={null}
                          index={`FLC-${i + 1}`}
                          activeCollapse={activeCollapse}
                          setActiveCollapse={setActiveCollapse}
                        />
                      </div>
                     */}

                  <Row><Col>
                    <FlightSe
                      flightInfo={flights}
                      changeFlightResultsView={changeFlightResultsView}
                      flightSearchObj={flightSearchObj}
                      selectedFlight={selectedFlight}
                      showNetFare={showNetFare}

                      MultiCityIndex={null}
                      index={`FLC-${i + 1}`}
                      activeCollapse={activeCollapse}
                      setActiveCollapse={setActiveCollapse}
                      traceId={traceId}
                    />
                  </Col></Row>
                </div>{" "}
              </>
            );
          })}</InfiniteScroll>
      ) : (
        <div>No flights available</div>
      )}

      {/* {flights
          .filter((f) => f[0].isVisible)
          .map((flight_list, ItemIndex) => {
            return !checkIfDisplayAdd(ItemIndex) ? (
              <div className="combine-roundtrip-view">
                <Row>
                  <Col md={18} xs={24}>
                    {flight_list.map((flight, key_index) => (
                      <Flight
                        flightInfo={flight}
                        key={key_index + "_" + flight.flightId}
                        airSearchData={airSearchData}
                        styleclassName={
                          flight_list.length == 1 ? "full_height" : ""
                        }
                      />
                    ))}
                  </Col>
                  <Col md={6} xs={24}>
                    <div className="choose-btn-wrapper">
                      <FlightSelection
                        flights={flight_list}
                        airSearchData={airSearchData}
                        onFlightsSelected={onFlightsSelected}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <BannerAdDisplay src={getBannerAdsSrc(ItemIndex)} />
            );
          })} */}
    </>
  );
};

export default CombinedView;
