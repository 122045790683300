import { Button, Col, Collapse, Modal, Row, Grid } from "antd";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Cars from "../../../components/car-search/Car";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import ApiClient from "../../../helpers/ApiClient";
import CarsFilter from "./CarsFilter/CarsFilter";
import CarsCard from "./CarsResultCards/CarsCard";
import CarSort from "./../Carsort/Carsort";
import CarSkeleton from "./Skeleton/CarSkeleton";
import { useSytContext } from "../../../common/providers/SytProvider";
import cartr from "../../../assets/images/transport/icons8-london-cab-64.png";
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const CarResults = () => {
  const {
    state: { carSearchResultObj },
    updateSelectedCarData,
    updateCarBookingData,
    updateCarSearchResultObj,
  } = useContext(GlobalStatesContext);
  const { user } = useAuthContext();
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  let CarInitial = {
    cars: [],
    filtersObj: {
      price: {
        minPrice: 0,
        maxPrice: 0,
        maxPriceRange: 0,
        minPriceRange: 0,
      },
      timings: [
        {
          id: "day",
          start: "06:00",
          end: "17:59",
          label: "Day",
          isChecked: false,
        },
        {
          id: "night",
          start: "18:00",
          end: "05:59",
          label: "Night",
          isChecked: false,
        },
      ],

      carType: [],
      pickUpLocation: [],
      droppingLocation: [],
    },
    origin: "",
    destination: "",
  };
  const { carSearchBanners } = useSytContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);

  const showModalbusfilters = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalbussort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };
  const [carResultsList, setCarResultsList] = useState(CarInitial);

  const [isLoading, setIsLoading] = useState(true);

  /* Car Search Availability */
  const getCarsAvailability = () => {
    let param = queryString.parse(document.location.search);
   
    if (param.TravelType != 3) {
      let formData = {
        sourceID: param?.sourceID,
        destinationID: param?.destinationID,
        fromDate: param?.fromDate,
        toDate: param?.toDate,
        pickUpLocation: param?.pickUpLocation,
        dropLocation: param?.dropLocation,
        returnDate: "",
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        TravelType: param.TravelType,
        TripType: param.TripType,
      };
      let origin = param?.sourceName;
      let destination = param?.destinationName;

      fetchCarAvailability(formData, origin, destination, 1);
    } else {
      fetchTransferAvaility(param);
    }
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const fetchCarAvailability = (formData, origin, destination, type) => {
    setIsLoading(true);
    updateCarSearchResultObj({});
    setCarResultsList(CarInitial);
    ApiClient.get(
      "carextranet/getCarAvailabilityBySearch?" +
      "sourceID=" +
      formData.sourceID +
      "&destinationID=" +
      formData.destinationID +
      "&fromDate=" +
      formData.fromDate +
      "&toDate=" +
      formData.toDate +
      "&TravelType=" +
      formData.TravelType +
      "&TripType=" +
      formData.TripType
    )
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data != {}) {
            let filterData = filterObjectResponse({
              formData: formData,
              response: resp.data,
              origin: origin,
              destination: destination,
              type: type,
            });
            updateCarSearchResultObj(filterData);
            setCarResultsList(filterData.data);
          }
          // {console.log(filterData.data,filterData,"filtercars");}
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("api er", e);
        setIsLoading(false);
      });
  };

  const fetchTransferAvaility = (param) => {
    setIsLoading(false);
    setCarResultsList(CarInitial);
  };
  const filterObjectResponse = ({
    formData,
    response,
    origin,
    destination,
    type,
  }) => {
    let filtersObj = { ...CarInitial.filtersObj };

    let cars = response.map((car, i) => {
      let startingFare = Number(car.BaseFare);

      if (i === 0) {
        filtersObj.price.minPrice = startingFare;
      }
      let minFare = startingFare;
      if (startingFare > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
          startingFare;
      }

      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;

      const { CarType } = car.carType;
      checkIfExist(filtersObj.carType, CarType) &&
        filtersObj.carType.push({
          id: CarType,
          label: CarType,
          isChecked: false,
        });

      const { PickUpLocation } = car;
      checkIfExist(filtersObj.pickUpLocation, PickUpLocation) &&
        filtersObj.pickUpLocation.push({
          id: PickUpLocation,
          label: PickUpLocation,
          isChecked: false,
        });

      const { DroppingLocation } = car;
      checkIfExist(filtersObj.droppingLocation, DroppingLocation) &&
        filtersObj.droppingLocation.push({
          id: DroppingLocation,
          label: DroppingLocation,
          isChecked: false,
        });

      return {
        ...car,
        isVisible: true,
        pickUpLocation: formData?.pickUpLocation ? formData.pickUpLocation : "",
        dropLocation: formData?.dropLocation ? formData.dropLocation : "",
      };
    });
    return (response = {
      data: {
        ...response,
        cars: cars,
        origin: origin,
        destination: destination,
        journeyDate: formData.fromDate,
        filtersObj: filtersObj,
      },
      tripType: type,
    });
  };

  useEffect(() => {
    getCarsAvailability();
  }, []);

  let bannreIndex = -1;
  const [leftPosition, setLeftPosition] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading && leftPosition < 86) {
        setLeftPosition(prevPosition => prevPosition + 1); // Increment left position gradually
      } else {
        clearInterval(interval); // Stop moving when isLoading is false or at the end position
      }
    }, 24); // Adjust interval as needed for desired speed

    return () => clearInterval(interval);
  }, [isLoading, leftPosition]);
  return (
    <>
      <div className="combined-results-container car-body-res">
        <section className="hotels_modify  buses-div-bg">
          <div className="hotel-modify-container">
            <Collapse
              activeKey={md ? ["1"] : key}
              showArrow={false}
              onChange={(val) => {
                setKey(val);
              }}
            >
              <Panel
                showArrow={false}
                header={
                  <span className="hotels-hide-search">Modify Search</span>
                }
                key="1"
              >
                <div className="search-modify-block bus-res-cc2">
                  <Cars
                    modifySearch={true}
                    carSearchAPI={getCarsAvailability}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </section>

        <div className="fbt-tabs-bg tabs-fbt-space">
          <div className="tabs-container filters-number-tab">
            <Row
              gutter={16}
              className="margins-alignments"
              style={{ padding: "14px 14px" }}
            >
              {isLoading ? (
                <>
                  <Col md={6} className="filter-section-bus">
                    <FilterSkeleton />
                  </Col>
                  <Col md={18} sm={24} xs={24}>
                    {leftPosition <= 85 ?
                      <div className="car-im" style={{ left: `${leftPosition}%` }}>
                        <img className="car-tr" src={cartr} alt="" />
                      </div> : null}
                    <CarSkeleton />
                  </Col>
                </>
              ) : Object.keys(carSearchResultObj).length > 0 &&
                carSearchResultObj?.data &&
                carSearchResultObj?.data?.cars?.length > 0 ? (
                <>
                  <Col className="filter-section-bus flight-filters-Outc-fun">
                    <CarsFilter
                      carSearchResultObj={carSearchResultObj}
                      setCarResultsList={setCarResultsList}
                    // type={"extranet"}
                    />
                  </Col>
                  <Col className="flight-resut-Outc-fun">
                    <Row className="sorting-car-v1">
                      <Col md={24} xs={24}>

                        <CarSort
                          carResultObj={carSearchResultObj}
                          setCarResultsList={setCarResultsList}
                        // type={"extranet"}
                        />
                      </Col>
                    </Row>
                    {carResultsList.cars.length > 0 ? (
                      carResultsList.cars.map((carObj, key) => {
                        return (
                          <>
                            {key != 0 &&
                              key % 3 === 0 &&
                              carSearchBanners[++bannreIndex] && (
                                <div className="combine-roundtrip-view">
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "90px",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      carSearchBanners[
                                        bannreIndex
                                      ].path.substring(1)
                                    }
                                    data={
                                      carSearchBanners[bannreIndex + 1]
                                        ? null
                                        : (bannreIndex = -1)
                                    }
                                  />{" "}
                                </div>
                              )}

                            <CarsCard
                              key={key}
                              carObj={carObj}
                              updateSelectedCarData={updateSelectedCarData}
                              updateCarBookingData={updateCarBookingData}
                            />
                          
                          </>
                        );
                      })
                    ) : (
                      <Col md={24} sm={24} xs={24}>
                        <CustomNoResultFound title={"No Cars Available"} />
                      </Col>
                    )}
                  </Col>

                </>
              ) : (
                <Col md={24} sm={24} xs={24}>
                  <CustomNoResultFound title={"No Cars Available"} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbusfilters}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
          <div></div>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalbussort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Car Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
          </div>,
        ]}
      > <Row>
          <Col md={24} xs={24}>
            <CarsFilter
              carSearchResultObj={carSearchResultObj}
              setCarResultsList={setCarResultsList}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <CarSort
              carResultObj={carSearchResultObj}
              setCarResultsList={setCarResultsList}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CarResults;
