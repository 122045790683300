import React, { useEffect, useRef, useState } from "react";
import * as ANTD from "antd";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import "../Promo/Promo.scss";
import queryString from "query-string";
import {
    EditOutlined,
    PlusCircleFilled,
    LoadingOutlined,
    PlusOutlined,
    CloseOutlined,
    DeleteOutlined,
    InfoCircleTwoTone,
} from "@ant-design/icons";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import AirportAutoCompleteSelectForCharterFlights from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectForCharterFlights";

const DealsImages = (props) => {
    const city = useRef(null);
    const tocityBox = useRef(null);
    const BASE = process.env.REACT_APP_BASE_URL;
    const uploadActionUrl = BASE + "media/upload/singleImage";
    const { Option } = ANTD.Select;
    const { Content } = ANTD.Layout;
    const [modalVisible, setModalVisible] = useState(false);
    const [formLayout, setFormLayout] = useState("vertical");
    const [dataSource, setDataSource] = useState([]);
    const [countryLists, setCountryList] = useState([]);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dealID, setDealID] = useState(null);
    const [form] = ANTD.Form.useForm();

    const from = useRef();
    const to = useRef();
    const initalFormData = {
        // FromCountryID: 1,
        // FromCountry: "",
        // ToCountryID: 1,
        // ToCountry: "",
        ServiceType: "",
        // source: "",
        // destination: "",
        // FromAirportCode: "",
        // ToAirportCode: "",
        // FromAmount: 0,
        // Currency: 1,
        Image: "string",
        // CreatedBy: 0,
        // DeviceOS: 0,
        // DeviceOSVersion: "string",
        // DeviceToken: "string",
        // ApplicationType: 0,
        // FirBaseToken: "string",
    };
    const [formData, setFormData] = useState(initalFormData);

    const [serviceType, setServiceType] = useState('');

    const formItemLayout =
        formLayout === "vertical"
            ? {
                labelCol: { span: 24 },
                wrapperCol: { span: 23 },
            }
            : null;

    const showModal = () => {
        if (isEdit) {
            setIsEdit(false);
            setImageUrl(null);
            setFormData(initalFormData);
            form.resetFields();
        }
        setModalVisible({
            visible: true,
        });
    };

    const EditDealModel = (record) => {
        setIsEdit(true);
        // console.log(record, "edi");
        setServiceType(record.ServiceType);

        setDealID(record.DealID);
        setFormData(record);
        form.setFieldsValue({
            ServiceType: record.ServiceType,
            //   Currency: record.Currency,
            //   FromAirportCode: record.FromAirportCode,
            //   FromAmount: record.FromAmount,
            //   FromCountryID: record.FromCountryID,
            //   ToAirportCode: record.ToAirportCode,
            //   ToCountryID: record.ToCountryID,
            Image: record.Image,
            //   source: record?.source ? record.source : "",
            //   destination: record?.destination ? record.destination : "",
        });
        if (
            record.Image != "" &&
            record.Image != "string" &&
            record.Image != null
        ) {
            const imgUrl = BASE + record.Image.substring(1);
            setImageUrl(imgUrl);
        }
        setModalVisible(true);
    };

    /********** Uploading Image **********/

    const [upLoadImage, setUpLoadImage] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    function handleChange(info) {
        // console.log(info, "iiiii");
        if (info.file.status === "error") {
            ANTD.message.error(" Error Uploading Image", 3);
            setUpLoadImage(false);
            return;
        }
        if (info.file.status === "uploading") {
            setUpLoadImage(true);
            return;
        }
        if (info.file.status === "done") {
            if (info.file.response.status == 200) {
                setFormData({
                    ...formData,
                    Image: info.file.response.data.filepath,
                });
                // Get this url from response in real world.
                getBase64(info.file.originFileObj, (imageUrl) => {
                    setImageUrl(imageUrl);
                    setUpLoadImage(false);
                });
            }
            setUpLoadImage(false);
        }
    }

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    // function beforeUpload(file) {
    //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    //   if (!isJpgOrPng) {
    //     ANTD.message.error("You can only upload JPG/PNG file!");
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     ANTD.message.error("Image must smaller than 2MB!");
    //   }
    //   return isJpgOrPng && isLt2M;
    // }
    const uploadButton = (
        <div>
            {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                    className="edit-icon"
                    style={{ textAlign: "center" }}
                    onClick={() => {
                        EditDealModel(rec);
                    }}
                >
                    <EditOutlined
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <ANTD.Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => {
                            deleteDeal(rec.DealID);
                        }}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </ANTD.Popconfirm>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: "S.No.",
            dataIndex: "Sno",
        },
        {
            title: "Service Type",
            dataIndex: "ServiceType",
            render: (value) =>
                value == 1 ? "Deals" : value == 2 ? "Hotels" : "",
        },
        // {
        //   title: "From Country",
        //   dataIndex: "FromCountry",
        //   sorter: (a, b) => a.FromCountry.localeCompare(b.FromCountry),
        // },
        // {
        //   title: "To Country",
        //   dataIndex: "ToCountry",
        //   sorter: (a, b) => a.ToCountry.localeCompare(b.ToCountry),
        // },
        // {
        //   title: "From Airport",
        //   dataIndex: "FromAirportCode",
        //   sorter: (a, b) => a.FromAirportCode.localeCompare(b.FromAirportCode),
        // },
        // {
        //   title: "To Airport",
        //   dataIndex: "ToAirportCode",
        //   sorter: (a, b) => a.ToAirportCode.localeCompare(b.ToAirportCode),
        // },
        // {
        //   title: "Source",
        //   dataIndex: "source",
        // },
        // {
        //   title: "Destination",
        //   dataIndex: "destination",
        // },
        // {
        //   title: "From Amount",
        //   dataIndex: "FromAmount",
        //   sorter: (a, b) => a.FromAmount.localeCompare(b.FromAmount),
        // },

        {
            title: "Actions",
            render: (currentDeal) => tableActions(currentDeal),
        },
    ];

    const selectCustomFilter = (input, option) => {
        let istext =
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        if (istext) {
            return true;
        }
    };
    /*==========Api Calls=====*/

    // useEffect(() => {
    //     getDeals();
    //     // getCountriesLists();
    // }, []);

    const getDeals = () => {
        ApiClient.get("admin/dealsImages")
            .then((res) => {
                if (res.status == 200) {
                    let data = res.data.map((item, index) => {
                        return {
                            ...item,
                            Sno: index + 1,
                            //   FromCountryID: item.FromCountryID.CountryID,
                            //   FromCountry: item.FromCountryID.Country,
                            //   ToCountryID: item.ToCountryID.CountryID,
                            //   ToCountry: item.ToCountryID.Country,
                            //   sourceObj: queryString.parse(item.source),
                            //   destinationObj: queryString.parse(item.destination),
                        };
                    });

                    setDataSource(data);
                } else {
                    ANTD.message("Server Error");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteDeal = (id) => {
        ApiClient.delete("admin/dealsImage/" + id)
            .then((res) => {
                if (res.status == 200) {
                    ANTD.message.success("Deal Deleted  successfully", 3);
                    getDeals();
                } else {
                    ANTD.message.error("Error Deleting", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    //   const getCountriesLists = () => {
    //     ApiClient.get("admin/country")
    //       .then((res) => {
    //         if (res.status == 200) {
    //           setCountryList(res.data);
    //         }
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   };

    const addApiCall = (values) => {
        // { console.log(values, "vvvv") }
        let DealsData = {
            // ...formData,
            // ...values,
            Image: formData.Image,

            ServiceType: values?.ServiceType,

        };
        // console.log(formData, values.ServiceType, DealsData, "dddd");

        // if (values.serviceType === 2) {
        //   DealsData.FromAirportCode = values.FromAirportCode.split("-")[2];
        //   DealsData.ToAirportCode = values.ToAirportCode.split("-")[2];
        //   DealsData.source = values.FromAirportCode.split("-")[0];
        //   DealsData.destination = values.ToAirportCode.split("-")[0];
        // }
        delete DealsData.Sno;
        // delete DealsData.FromCountry;
        // delete DealsData.ToCountry;
        // delete DealsData.Image;
        // delete DealsData.sourceObj;
        // delete DealsData.destinationObj;
        // console.log(DealsData, "ddd-2");

        isEdit ? putDealData(DealsData) : postDealData(DealsData);
    };

    const putDealData = (DealsData) => {
        // console.log(DealsData, "put");
        // if (DealsData.serviceType === 2) {
        //   ApiClient.put("admin/dealsImage/" + dealID, {}, DealsData).then((res) => {
        //     if (res.status == 200) {
        //       ANTD.message.success("Data Updated  successfully", 3);
        //       setModalVisible(false);
        //       form.resetFields();
        //       setImageUrl(null);
        //       getDeals();
        //     } else {
        //       ANTD.message.error(" Error Uploading Data", 3);
        //     }
        //   });
        // } else {
        ApiClient.put("admin/dealsImage/" + dealID, {}, DealsData).then((res) => {
            if (res.status == 200) {
                ANTD.message.success("Data Updated  successfully", 3);
                setModalVisible(false);
                form.resetFields();
                setImageUrl(null);
                getDeals();
            } else {
                ANTD.message.error(" Error Uploading Data", 3);
            }
        });

    };

    const postDealData = (DealsData) => {

        ApiClient.post("admin/dealsImage", DealsData).then((res) => {
            if (res.status == 200) {
                ANTD.message.success("Deal added successfully", 3);
                getDeals();
                setModalVisible(false);
                form.resetFields();
                setImageUrl(null);
            } else {
                ANTD.message.error(" Error Uploading Data", 3);
            }
        });

    };

    /*======= Search and Export Options=====*/

    const searchData = (searchData) => {
        setShowSearchBox(true);
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = dataSource.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setDataSource(searchResults);
        } else {
            getDeals();
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
    };

    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );

    //   const handleExcel = (e) => {
    //     const excelCustomData = dataSource.map((item) => {
    //       return {
    //         From_Country: item.FromCountry,
    //         To_Country: item.ToCountry,
    //         From_Airport: item.FromAirportCode,
    //         To_Airport: item.ToAirportCode,
    //         From_Amount: item.FromAmount,
    //       };
    //     });

    //     props.exportExcel(excelCustomData, "dealsExcel");
    //   };

    return (
        <div>
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <ANTD.Row>
                        <ANTD.Col span={24}>
                            <div className="card-bt-gap">
                                <ANTD.Card bordered={false}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5>
                                                    View Deal Images{" "}
                                                    <HelpInfoHelper screenName={"/admin/deals"} />
                                                </h5>
                                                <p>{dataSource.length} rows found !</p>
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <ANTD.Input
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img
                                                src={search}
                                                alt="search"
                                                onClick={(e) => searchData(dataSource)}
                                            />
                                            {/* <img src={excel} alt="excel" onClick={handleExcel} /> */}
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => getDeals()}
                                            />
                                            <p className="add-deposit-icon" onClick={showModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <ANTD.Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                </ANTD.Card>
                            </div>
                        </ANTD.Col>
                    </ANTD.Row>
                </Content>
            </div>

            <ANTD.Modal
                title="Deals"
                className="promo-modal-header admin-bc-level modal-header-bg12"
                visible={modalVisible}
                onCancel={(e) => setModalVisible(false)}
                width={"1100px"}
                footer={[
                    <ANTD.Button key="back" onClick={() => setModalVisible(false)}>
                        Cancel
                    </ANTD.Button>,
                    <ANTD.Button key="submit" type="primary" onClick={form.submit}>
                        {isEdit ? "Update" : "Add"}
                    </ANTD.Button>,
                ]}
            >
                <ANTD.Form
                    form={form}
                    {...formItemLayout}
                    layout={formLayout}
                    onFinish={addApiCall}
                >
                    <ANTD.Row>
                        <ANTD.Col md={12} xs={24}>
                            <ANTD.Form.Item
                                label="Service Type"
                                rules={[{ required: true }]}
                                name="ServiceType"
                            >
                                <ANTD.Select
                                    placeholder="Please Select"
                                    onSelect={(e) => {
                                        setServiceType(e);
                                    }}
                                    value={serviceType}
                                >
                                    <Option value={1}>Deals</Option>
                                    <Option value={2}>Hotels</Option>
                                    {/* <Option value={3}>Bus</Option> */}
                                </ANTD.Select>
                            </ANTD.Form.Item>
                        </ANTD.Col>
                    </ANTD.Row>
                    <ANTD.Row>

                        {/* <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="From Amount"
                name="FromAmount"
                rules={[
                  {
                    required: true,
                    message: "From Amount is required",
                  },
                ]}
              >
                <ANTD.Input type="number" placeholder="Enter Fare" />
              </ANTD.Form.Item>
            </ANTD.Col> */}

                        {/* <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Currency"
                name="Currency"
                rules={[{ required: true, message: "Currency is required" }]}
              >
                <ANTD.Select placeholder="Select Currency">
                  <Option value="2">INR</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col> */}
                        <ANTD.Col md={6} xs={24}>
                            <ANTD.Form.Item label="Upload Image (size : 1 MB)" name="Image">
                                <ANTD.Upload
                                    name="image"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={uploadActionUrl}
                                    data={{ category: "deals" }}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </ANTD.Upload>
                            </ANTD.Form.Item>
                        </ANTD.Col>
                    </ANTD.Row>
                </ANTD.Form>
            </ANTD.Modal>
        </div>
    );
};
export default UpdatedComponent(DealsImages);
