import { Card, Col, Row, Modal, Table } from "antd";
import React, { useState } from "react";
import { MinutesToHours } from "./../../../helpers/MinutesToHours";
import "./BusDetails.scss";
import moment from "moment";
import busl from "../../../assets/images/bus-load.gif";

// const BusDetails = ({ selectedBusData }) => {
//   console.log(selectedBusData,"HHH");
//   return (
//     <div className="busDetails_wrapper">

//       {selectedBusData.map((busDetail, i) => (
//         <div key={"businfo" + i} className="card_wrapper">

//           <Card>
//           <div style={{background:"#f9f9f9",padding:12}}>
//       <h3 className="title">Bus Details</h3>
//       </div>
//       <div style={{padding:12}}>
//             {/* <div className="journeyDate text-right">
//               Journey Date: {busDetail.journeyDate}
//             </div> */}
//             <Row gutter={[16, 16]} align="middle">

//     <Col md={6} sm={12} xs={24}>
//     <img src={bus} alt="bus" style={{ width: "100%", height: "auto" }} />
//       <p className="busname" style={{ fontSize: 16, fontWeight: 600, marginBottom: 8 }}>
//         {busDetail.displayName || "Bus Name"}
//       </p>
//     </Col>

//     {/* Boarding and Dropping Timings */}
//     <Col md={12} sm={24} xs={24}>
//       <div className="bus-timings">
//         <ul style={{ display: "flex", justifyContent: "space-between", padding: 0, listStyle: "none", margin: 0 }}>
//           {/* Boarding Time */}
//           <li style={{ textAlign: "center" }}>
//             <p style={{ fontSize: 14, fontWeight: 500, margin: "0 0 4px 0" }}>
//               {busDetail.boardingTimes
//                 ? moment(busDetail.boardingTimes.Time).format("HH:mm")
//                 : "--:--"}
//             </p>
//             <p style={{ fontSize: 14, margin: "0 0 4px 0", color: "#555" }}>
//               {selectedBusData[i]?.origin || "Origin"}
//             </p>
//             <p className="point" style={{ fontSize: 12, color: "#999", margin: 0 }}>
//               Boarding Point
//             </p>
//             <p className="loc" style={{ fontSize: 12, color: "#555", margin: 0 }}>
//               {busDetail.boardingTimes?.Address || "Address"}
//             </p>
//           </li>

//           <li style={{ alignSelf: "center", fontSize: 20, color: "#888" }}>→</li>

//           {/* Dropping Time */}
//           <li style={{ textAlign: "center" }}>
//             <p style={{ fontSize: 14, fontWeight: 500, margin: "0 0 4px 0" }}>
//               {busDetail.droppingTimes
//                 ? moment(busDetail.droppingTimes.Time).format("HH:mm")
//                 : "--:--"}
//             </p>
//             <p style={{ fontSize: 14, margin: "0 0 4px 0", color: "#555" }}>
//               {selectedBusData[i]?.destination || "Destination"}
//             </p>
//             <p className="point" style={{ fontSize: 12, color: "#999", margin: 0 }}>
//               Dropping Point
//             </p>
//             <p className="loc" style={{ fontSize: 12, color: "#555", margin: 0 }}>
//               {busDetail.droppingTimes?.Address || "Address"}
//             </p>
//           </li>
//         </ul>
//       </div>
//     </Col>

//     {/* Bus Type */}
//     <Col md={6} sm={12} xs={24}>
//       <p className="busType" style={{ fontSize: 14, fontWeight: 500, marginBottom: 0 }}>
//         {busDetail.busType || "Bus Type"}
//       </p>
//     </Col>
//   </Row>

//             {/* <Row gutter={[16, 16]}>
//               <Col md={10} sm={10} xs={24}>
//                 <div className="points_wrapper">
//                   <div className="boarding_wrapper">
//                     <p className="point">Boarding point</p>
//                     <p className="loc">
//                       {busDetail.boardingTimes
//                         ? busDetail.boardingTimes.Address
//                         : ""}
//                     </p>
//                   </div>

//                   <i className="fa fa-arrow-right"></i>

//                   <div className="dropping_wrapper">
//                     <p className="point">Dropping point</p>
//                     <p className="loc">
//                       {busDetail.droppingTimes
//                         ? busDetail.droppingTimes.Address
//                         : ""}
//                     </p>
//                   </div>
//                 </div>
//               </Col>
//               {/* <Col md={9} sm={9} xs={24}>
//                 <p className="busType">
//                   {busDetail.busType ? busDetail.busType : ""}
//                 </p>
//                 /* <div className="opdetail">
//                   <p className="optitle">Operator-Contact </p>
//                   <p className="text">
//                     {busDetail.boardingTimes
//                       ? busDetail.boardingTimes.ContactNumbers
//                       : "-"}
//                   </p>
//                 </div> *
//               </Col> *
//               {/* <Col md={5} sm={5} xs={24}>
//                 <p className="pricetitle">
//                   {i === 0 ? "Onward" : "Return"} Trip Fare
//                 </p>
//                 <p className="price">{"₹ " + busDetail.fareInfo.totalFare}</p>
//                 <p className="infotext">Including GST</p>
//               </Col> *
//             </Row> */}
//             </div>
//           </Card>
//         </div>
//       ))}
//     </div>
//   );
// };


const BusDetails = ({ selectedBusData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancellationData, setCancellationData] = useState([]);
  const [cancel, setCancel] = useState('');
  const handleViewPolicies = (cancellation,cancel) => {
    if (cancellation) {
  
      const cleanedData = cancellation.replace(/["']/g, "");
  
  const policyStringArray = cleanedData.split(" : ");
      setCancellationData(policyStringArray);
    }
    setCancel(cancel);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Define columns for the table
  const columns = [
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
    },
    {
      title: "Refund Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];
 console.log(selectedBusData,"jjj")
  return (
    <div className="busDetails_wrapper">
      {selectedBusData.map((busDetail, i) => {
        // Extract seat numbers
        const seatNumbers = busDetail.seatsInfo
          ? busDetail.seatsInfo.map((seat) => seat.SeatNo).join(", ")
          : "N/A";

        // Boarding and Dropping Dates
        const boardingDate = busDetail.journeyDate
          ? moment(busDetail.journeyDate, "DD-MM-YYYY").format("DD MMM' YY, ddd")
          : "N/A";

        const droppingDate = busDetail.arrivalDate
          ? moment(busDetail.arrivalDate).format("DD MMM' YY, ddd")
          : "N/A";
          const boardingTime = busDetail.boardingTimes?.Time
          ? moment(busDetail.boardingTimes.Time)
          : null;
    
        const droppingTime = busDetail.droppingTimes?.Time
          ? moment(busDetail.droppingTimes.Time)
          : null;
          const calculatedDuration =
      boardingTime && droppingTime
        ? moment
            .utc(droppingTime.diff(boardingTime))
            .format("HH:mm") + " hr"
        : "N/A";

        return (
          <div key={`businfo_${i}`} className="card_wrapper">
            <Card>
              {/* Header Section */}
              <div style={{ background: "#f9f9f9", padding: 12 }}>
                <h3 className="title">Bus Details</h3>
              </div>
              <div style={{ padding: 12 }}>
                <Row align="middle" justify="space-between">
                  <Col md={7}>
                    <h3 style={{ margin: 0, fontSize: 20, fontWeight: "bold", fontFamily:"Nunito" }}>
                      {busDetail.displayName}
                    </h3>
                    <p style={{ fontSize: 14, color: "#777", fontFamily:"Nunito" }}>
                      {busDetail.busType}
                    </p>
                    <p style={{ fontSize: 14, color: "#333", margin: 0 , fontFamily:"Nunito"}}>
                      Seat No: <span style={{ fontWeight:"700" ,color:"#032251", fontFamily:"Nunito"}}>{seatNumbers} </span>
                    </p>
                    <a
                      href="#"
                      style={{ fontSize: 12 , fontFamily:"Nunito" }}
                      onClick={() => handleViewPolicies(busDetail.cancellation,busDetail.cancellation1)}
                      className="view-poli-bus"
                    >
                      <i class="fa fa-clipboard" aria-hidden="true"></i> View Policies
                    </a>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: 700,
                          fontFamily: "Nunito",
                          lineHeight:"20px",
                          color:"#032251",
                          // margin: "4px 0",
                        }}
                      >
                        {busDetail.boardingTimes
                          ? moment(busDetail.boardingTimes.Time).format("HH:mm")
                          : "--:--"}{" "} <br/>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "Nunito",
                          }}
                        >
                          {boardingDate}
                        </span>
                      </p>
                      <p style={{ fontSize: 14, color: "#555", margin: "4px 0", fontFamily: "Nunito", }}>
                        {busDetail.origin}
                      </p>
                      <p className="loc-dest-busp" style={{ fontSize: 12, color: "#999", margin: "4px 0", fontFamily: "Nunito", }}>
                        {busDetail.boardingTimes?.Address[0].toUpperCase() + busDetail.boardingTimes?.Address.slice(1).toLowerCase()}
                      </p>
                    </div>
                  </Col>

                  {/* Duration */}
                  <Col
                    md={5}
                    sm={24}
                    xs={24}
                    style={{
                      textAlign: "center",
                      width: "150px",
                      margin: "0 auto",
                    }}
                  >
                    <p style={{ fontSize: 14, color: "#888", margin: "8px 0", fontFamily: "Nunito", }}>
                      {busDetail.duration}
                    </p>
                    <div
                      style={{
                        // width: "150px",
                        // height: "1px",
                        // background: "#888",
                        // margin: "8px auto",
                        position:"relative",
                        top:"-40px",
                      }}
                    >
                      <img src={busl} alt="" />
                    </div>
                  </Col>

                  {/* Dropping Time */}
                  <Col md={6} sm={12} xs={24}>
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          fontSize: 24,
                          fontWeight: 700,
                          fontFamily: "Nunito",
                          lineHeight:"20px",
                          color:"#032251",
                        }}
                      >
                        {busDetail.droppingTimes
                          ? moment(busDetail.droppingTimes.Time).format("HH:mm")
                          : "--:--"}{" "} <br/>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: "Nunito",
                          }}
                        >
                          {droppingDate}
                        </span>
                      </p>
                      <p style={{ fontSize: 14, color: "#555", margin: "4px 0", fontFamily: "Nunito", }}>
                        {busDetail.destination}
                      </p>
                      <p className="loc-dest-busp" style={{ fontSize: 12, color: "#999", margin: "4px 0", fontFamily: "Nunito", }}>
                        {busDetail.droppingTimes?.Address[0].toUpperCase() + busDetail.droppingTimes?.Address.slice(1).toLowerCase()}
                      </p>
                    </div>
                  </Col>

                </Row>
              </div>


            </Card>
          </div>
        );
      })}

      {/* Modal for Cancellation Policies */}
      <Modal
        title="Cancellation Policies"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        style={{
          width: "700px", // Custom width
          height: "500px", // Custom height
          maxWidth: "90vw", // Responsive width
        }}
      
      >
        <div className="cancllation-policy-tab">
        <div className="cancellation-policy-container">
        <div className="cancellation-block">
         <div className="cancellation-block-body">
      <ul>
        <li>
          <span className="title">Time Before Departure </span>{" "}
          <span className="title last">Cancellation Charges</span>
        </li>
        {/* Loop through the array and parse each policy segment */}
        {cancellationData.map((policy, index) => {
          // Split the time and charge details using the asterisk (`*`) separator
          const [time, charge] = policy.split(" * ");

          return (
            <li key={index}>
              <span>{time}</span>
              <span className="last">{charge}</span>
            </li>
          );
        })}
      </ul>
      <span style={{ color: "red", fontSize: "12px" }}>
        {cancel === "true"
          ? `*Partial cancellation allowed`
          : `*Partial cancellation not allowed`}
      </span>
    </div>
    </div>
    </div>
    <div >
      <h1 style={{fontFamily:"Nunito",fontSize:18,fontWeight:600,marginTop:5,color:"#0056b3"}}>Other Conditions</h1>
    <p> *Please note : the ticket cannot be cancelled after the bus departs from the first boarding point.</p>

<p>* Above defined cancellation charges are illustrated basis maximum fare applicable. Exact cancellation charges will depend on the final price charged along with discount and other adjustments.</p>

<p>* Cancellation amount shown above may also vary basis the non-refundable components of the ticket defined by the bus operator</p>
</div>
    </div>
      </Modal>
    </div>
  );
};



export default BusDetails;
