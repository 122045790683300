import React from "react";
import { Route, Switch } from "react-router-dom";
import AccessPermissions from "../../admin/AccessPermissions/AccessPermissions";

// import BusesCommissions from "../../admin/AdminMarkup/BusesCommissions";
import AllServicesSessiontime from "../../admin/AllServicesSessionTime/AllServiceSessiontime";
import FlightsCommissions from "../../admin/AdminMarkup/FlightsCommissions";
import HotelCommissions from "../../admin/AdminMarkup/HotelsCommissions";
import AdminUser from "../../admin/AdminUser/AdminUser";
import AgentRegistration from "../../admin/AgentRegistration/AgentRegistration";

import B2Bpg from "../../admin/B2BPG/B2Bpg.jsx";
import BankDetails from "../../admin/BankDetails/BankDetails";
import Banners from "../../admin/Banners/Banners";

import SuperBusTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperBusTicket.jsx";
import BookingLimit from "../../admin/BookingLimit/BookingLimit";
import BookingOffline from "../../admin/BookingOffline/BookingOffline";
import CancelRequest from "../../admin/CancelRequest/CancelRequest";
import CarsCancellation from "../../admin/CarCancellation/CarCancellation";
import CarDeals from "../../admin/CarRentalDeals/CarDeals";
import Carsavailability from "../../admin/Carsavailability/Carsavailability";
import Carsmapping from "../../admin/Carsmapping/Carsmapping";
import Carsorder from "../../admin/Carsorder/Carsorder";
// import AirRefundTicket from "../../admin/AirRefundTicket/AirRefundTicket";
// import AirRefundCancellation from "../../admin/AirRefundCancellation/AirRefundCancellation";
import Cartypes from "../../admin/Cartypes/Cartypes";
import CityHotel from "../../admin/CityHotel/CityHotel";
import ManageBands from "../../admin/Cmaster/ManageBands/ManageBands";
import ManageDepartment from "../../admin/Cmaster/ManageDepartment/ManageDepartment";
import ManageDesignations from "../../admin/Cmaster/ManageDesignations/ManageDesignations";
import ManageGrades from "../../admin/Cmaster/ManageGrades/ManageGrades";
import ManageTeams from "../../admin/Cmaster/ManageTeams/ManageTeams";
import CMS from "../../admin/CMS/cms";
import CmsFareRules from "../../admin/CmsFareRules/CmsFareRules";
import CmsTermsOffers from "../../admin/CmsTermsOffers/CmsTermsOffers";
import ContactForm from "../../admin/ContactForms/ContactForm";

// import CruiseMedia from "../../admin/CruiseExtranet/Media";
// import CruiseOrders from "../../admin/CruiseExtranet/Orders";
// import CruisePacakge from "../../admin/CruiseExtranet/Packages";
// import CruiseRoom from "../../admin/CruiseExtranet/Room";
// import CruiseSailingDate from "../../admin/CruiseExtranet/SailingDate";
// import CruiseSchedule from "../../admin/CruiseExtranet/Schedule";
import Dashboard from "../../admin/Dashboard/Dashboard";
// import DBLogs from "../../admin/Logs/DBLogs";
import Deals from "../../admin/Deals/Deals";
import DealsImages from "../../admin/Deals/DealImages.jsx";
import DocumentReqdForVisa from "../../admin/DocumentRequiredmentforvisa/DocumentReqdForVisa";
import Driverinfo from "../../admin/Driverinfo/Driverinfo";
import EmbassyDetail from "../../admin/EmbassyDetail/EmbassyDetails";

import FlightEnquires from "../../admin/FlightEnquires/FlightEnquires";
// import HelicopterEnquiries from "../../admin/HelicopterEnquiries/HelicopterEnquiries";
import HelpInfo from "../../admin/HelpInfo/HelpInfo";

import LedgerStatement from "../../admin/LedgerStatement/LedgerStatement";
import APILogs from "../../admin/Logs/APIAccessLogs";
import PGLogs from "../../admin/Logs/PGLogs";
import ManageAirlines from "../../admin/ManageAirlines/ManageAirlines";

import ManageBalance from "../../admin/ManageBalance/ManageBalance";

import Gstdetails from "../../admin/ManageCompanyDropdown/Gstdetails/Gstdetails";
import ManageBranch from "../../admin/ManageCompanyDropdown/ManageBranch/ManageBranch";
import ManageCompany from "../../admin/ManageCompanyDropdown/ManageCompany/ManageCompany";







import ManageVisaMaster from "../../admin/ManageVisamaster/Managervisamaste";
import SuperVisaTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperVisaTicket.jsx";
import Margins from "../../admin/Margins/Margins";
import Membershipinfo from "../../admin/Membershipinfo/Membershipinfo";
import OfflineEnquiries from "../../admin/OfflineEnquiries/OfflineEnquiries";
// import BusOperatorsService from "../../admin/OperatorsService/BusOperatorsService";
import OperatorsService from "../../admin/OperatorsService/OperatorsService";
import ActivitiesPackages from "../../admin/Packages/ActivitiesPackages";
import PackagesDetailed from "../../admin/PackagesDetailed/PackagesDetailed";
import PartPayment from "../../admin/PartPayment/PartPayment";
import Admin from "../../admin/Permissions/Admin/Admin";
import Promo from "../../admin/Promo/Promo";
import Propertytype from "../../admin/Propertytype/Propertytype";
import Reports from "../../admin/Reports/Reports";
import ResheduleRequest from "../../admin/ResheduleRequest/ResheduleRequest";
import ReviewRating from "../../admin/ReviewRating/ReviewRating";
import Role from "../../admin/Role/Role";
import ServiceType from "../../admin/ServiceType/ServiceType";
import SetCommission from "../../admin/SetCommission/SetCommission";
import Settings from "../../admin/Settings/Settings";
import Siteusers from "../../admin/Siteusers/Siteusers";
import StoreVistors from "../../admin/Storevistors/Storevistors";
import SubscriberReport from "../../admin/SubscriberReport/SubscriberReport";


import SuperCarTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperCarTicket";
import SuperFlightTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperFlightTicket";
import SuperHotelTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperHotelTicket";
import AddProvider from "../../admin/SupplierConfiguration/AddProvider";
import ProviderApi from "../../admin/SupplierConfiguration/ProviderApi";
import TopCitiesAndDestination from "../../admin/TopCitiesAndDestination/TopCitiesAndDestination";
import TopCitiHotel from "../../admin/TopCitiesAndDestination/TopCitiHotel.jsx";









import TravelCalendar from "../../admin/TravelCalendar/TravelCalendar";






import ViewPackages from "../../admin/ViewPackages/ViewPackages";
import VisaDocument from "../../admin/VisaDocument/VisaDocument";
import Visafaq from "../../admin/Visafaq/Visafaq";
import VisaCustomerReports from "../../admin/VisaBookingDetails/VisaCustomerReports"
import Bookingreports from "../../components/Bookingreports/Bookingreports";
import VisaGuide from "../../admin/VisaGuide/VisaGuide";
import VisaType from "../../admin/VisaType/VisaType";
// import YachtEnquiries from "../../admin/YachtEnquiries/YachtEnquiries";
import Error404 from "../../components/ErrorPages/Error404";
import NotFound from "../../components/ErrorPages/NotFound";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import AdminRoute from "../../helpers/AdminProtection";
import FlightBookingReports from "../../admin/SuperAdminBookingReports/FlightBookingReports";
import HotelBookingReports from "../../admin/SuperAdminBookingReports/HotelBookingReports";
import TransferBookingReports from "../../admin/SuperAdminBookingReports/TransferBookingReport";

import Configurations from "../../admin/Configurations/Configurations";
import BusesCommissions from "../../admin/AdminMarkup/BusesCommissions";
import CarCommissions from "../../admin/AdminMarkup/CarCommissions";
import VisaCommissions from "../../admin/AdminMarkup/VisaCommissions";
// import CruiseCommissions from "../../admin/AdminMarkup/CruiseCommissions";
import HolidayCommissions from "../../admin/AdminMarkup/HolidayCommissions";
import CFlightCommissions from "../../admin/AdminMarkup/CFlightCommissions";








import CMSType from "../../admin/CMSType/CMSType";
import Screens from "../../admin/Screens/Screens";
import CurrencyConversion from "../../admin/Currencyconersion/CurrencyConersion";
import FlightSearchCriteria from "../../admin/FlightsExtranet/FlightSearchCriteria";
import FlightExtranetSegments from "../../admin/FlightsExtranet/FlightExtranetSegments";
import FlightExtranetFareDetails from "../../admin/FlightsExtranet/FlightExtranetFareDetails";
import FlightExtranetFareRules from "../../admin/FlightsExtranet/FlightExtranetFareRules";
import FlightExtranetPNR from "../../admin/FlightsExtranet/FlightExtranetPNR";

import SuperCarTransferTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperCarTransferTicket.jsx";
import BusBookingReports from "../../admin/SuperAdminBookingReports/BusBookingReports.jsx";

const AdminNavigations = ({ accessList, accessToken }) => {

  return (
    <div className="mob-M-Top">
      <Switch>
        <Route
          accessList={accessList}
          accessToken={accessToken}
          exact
          path={"/"}
          component={Dashboard}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path={"/admin/dashboard"}
          component={Dashboard}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/sessiontime"
          component={AllServicesSessiontime}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/role"
          component={Role}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/accesspermissions"
          component={AccessPermissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/provider"
          component={AddProvider}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/user"
          component={AdminUser}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/serviceType"
          component={ServiceType}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/configurations"
          component={Configurations}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cmstype"
          component={CMSType}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/ledger-statement"
          component={LedgerStatement}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/operator"
          component={OperatorsService}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/b2b"
          component={B2Bpg}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bankdetails"
          component={BankDetails}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/packagesdetailed"
          component={PackagesDetailed}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/viewpackages"
          component={ViewPackages}
        />

        
       
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/agentregistration"
          component={AgentRegistration}
        />
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/set-commission"
          component={SetCommission}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cityhotel"
          component={CityHotel}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/termsofferscms"
          component={CmsTermsOffers}
        />

      
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bookoffline"
          component={BookingOffline}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/fExtranet"
          component={BookingOffline}
        />

<AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/deals"
          component={Deals}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/dealimages"
          component={DealsImages}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/carDeals"
          component={CarDeals}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightcommissions"
          component={FlightsCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/hotelcommissions"
          component={HotelCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/TranfersCommissions"
          component={CarCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/buscommissions"
          component={BusesCommissions}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/manageairlines"
          component={ManageAirlines}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/managebalance"
          component={ManageBalance}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/membershipinfo"
          component={Membershipinfo}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/partpayment"
          component={PartPayment}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/promo"
          component={Promo}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/storevistors"
          component={StoreVistors}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apiprovider"
          component={ProviderApi}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports"
          component={Reports}
        />

       

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="admin/subscriberReports/"
          component={SubscriberReport}
        />
       

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/banners"
          component={Banners}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/topCities"
          component={TopCitiHotel}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/travelcalendar"
          component={TravelCalendar}
        />
        
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/subscriberReports"
          component={SubscriberReport}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/Siteusers"
          component={Siteusers}
        />

        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/CMS"
          component={CMS}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apilogs"
          component={APILogs}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/pglogs"
          component={PGLogs}
        />

       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/settings"
          component={Settings}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cartypes"
          component={Cartypes}
        />
       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/propertytype"
          component={Propertytype}
        />
        
        
       
       

        

        

       

       
       

       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/managedesignations"
          component={ManageDesignations}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/managegrades"
          component={ManageGrades}
        />
        
       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/manageteams"
          component={ManageTeams}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/managebranch"
          component={ManageBranch}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/managebands"
          component={ManageBands}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/managecompany"
          component={ManageCompany}
        />

        

       

      
       

        

     
      
       


       

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/gstdetails"
          component={Gstdetails}
        />

      
        
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/driverinfo"
          component={Driverinfo}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsmapping"
          component={Carsmapping}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carcancellation"
          component={CarsCancellation}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsavailability"
          component={Carsavailability}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsorder"
          component={Carsorder}
        />

       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/admin"
          component={Admin}
        />

        <Route
          exact
          path="/admin/flight/ticket"
          component={SuperFlightTicket}
        />
        <Route exact path="/admin/hotel/ticket" component={SuperHotelTicket} />
        <Route exact path="/admin/bus/ticket" component={SuperBusTicket} />
       
        <Route
          exact
          path="/admin/visa/ticket"
          component={SuperVisaTicket}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/contactforms"
          component={ContactForm}
        />
        

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/offlineEnquiries"
          component={OfflineEnquiries}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/margins"
          component={Margins}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/CmsFareRules"
          component={CmsFareRules}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/HelpInfo"
          component={HelpInfo}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bookinglimit"
          component={BookingLimit}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reviews-rating"
          component={ReviewRating}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visaDocs"
          component={VisaDocument}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visaType"
          component={VisaType}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visaGuide"
          component={VisaGuide}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/docsReqdVisa"
          component={DocumentReqdForVisa}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/embassyDetails"
          component={EmbassyDetail}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/managevisa"
          component={ManageVisaMaster}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visafaqs"
          component={Visafaq}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visa-customer-reports"
          component={VisaCustomerReports}
        />

        
        
        <Route exact path="/admin/cancelrequest" component={CancelRequest} />
        <Route
          exact
          path="/admin/reshedule-request"
          component={ResheduleRequest}
        />
        
      
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/flightReport"
          component={FlightBookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/hotelReport"
          component={HotelBookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/transferReports"
          component={TransferBookingReports}
        />
         <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/busReport"
          component={BusBookingReports}
        />
      
       
      

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/TranfersCommissions"
          component={CarCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/visacommissions"
          component={VisaCommissions}
        />
        
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/holidaycommissions"
          component={HolidayCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cflightscommissions"
          component={CFlightCommissions}
        />
       
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/screens"
          component={Screens}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/currency-conversion/currency-conversion"
          component={CurrencyConversion}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flight-extranet/search-criteria"
          component={FlightSearchCriteria}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flight-extranet/segments"
          component={FlightExtranetSegments}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flight-extranet/fare-details"
          component={FlightExtranetFareDetails}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flight-extranet/fare-rules"
          component={FlightExtranetFareRules}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flight-extranet/pnr"
          component={FlightExtranetPNR}
        />


        <Route exact path="/admin/cars/ticket" component={SuperCarTicket} />
        <Route exact path="/admin/cartransfer/ticket" component={SuperCarTransferTicket} />
        <Route exact path="" component={Unauthorized} />
        <Route exact path="/404" component={Error404} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AdminNavigations;
