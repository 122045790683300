// Animation.jsx
import React from 'react';
import './AnimationBall.scss'; // Import the CSS file

const Animation = () => {
  return (
    <div className="animated-section">
      <div className="animation-section">
        {/* <div className="cross po-1"></div> */}
        
        <div className="round po-4"></div>
        <div className="round po-5"></div>
        <div className="round po-3"></div>
        <div className="round po-10"></div>
        <div className="round po-1"></div>
        <div className="round po-11"></div>
        <div className="square po-7"></div>
        <div className="square po-9"></div>
        <div className="cross po-2"></div>
        {/* <div className="cross po-3"></div> */}
        <div className="square po-12"></div>
      </div>
    </div>
  );
};

export default Animation;
