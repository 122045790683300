import React from "react";
import "../CombinedBooking/CombinedBooking.scss";
import * as ANTD from "antd";
import { useState } from "react";



const CombinedBooking = (props) => {
  function callback(key) {
    console.log(key);
  }

  const { TabPane } = ANTD.Tabs;
  return (
    <div className="combined-booking-block">
      <div className="combined-booking-container">
        <ANTD.Card className="booking-result-card">
          <div className="booking-tabs-header">
            <ANTD.Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Upcoming" key="1">
                <ANTD.Row>
                  <ANTD.Col md={6}>Booking Details</ANTD.Col>
                  <ANTD.Col md={6}>Booking#</ANTD.Col>
                  <ANTD.Col md={6}>Price</ANTD.Col>
                  <ANTD.Col md={6}>Action</ANTD.Col>
                </ANTD.Row>
              </TabPane>
              <TabPane tab="Completed" key="2">
                <ANTD.Row>
                  <ANTD.Col md={6}>Booking Details</ANTD.Col>
                  <ANTD.Col md={6}>Booking#</ANTD.Col>
                  <ANTD.Col md={6}>Price</ANTD.Col>
                  <ANTD.Col md={6}>Action</ANTD.Col>
                </ANTD.Row>
              </TabPane>
              <TabPane tab="Booking Failed" key="3">
                <ANTD.Row>
                  <ANTD.Col md={6}>Booking Details</ANTD.Col>
                  <ANTD.Col md={6}>Booking#</ANTD.Col>
                  <ANTD.Col md={6}>Price</ANTD.Col>
                  <ANTD.Col md={6}>Action</ANTD.Col>
                </ANTD.Row>
              </TabPane>
            </ANTD.Tabs>
          </div>
        </ANTD.Card>
      </div>
    </div>
  );
};
export default CombinedBooking;
