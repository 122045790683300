// import React, { useState } from "react";
// import {
//   Form,
//   Input,
//   Button,
//   Row,
//   Col,
//   Divider,
//   Select,
//   DatePicker,
//   message,
//   Modal,
// } from "antd";

// import ReCAPTCHA from "react-google-recaptcha";
// import { Helmet } from "react-helmet";
// import APIClient from "../../helpers/ApiClient";
// import CountryList from "../../common/CountryList";
// // import Thankyou from "../Thankyou/Thankyou";
// // import {
// //   CheckCircleOutlined,
// //   FacebookOutlined,
// //   TwitterOutlined,
// //   LinkedinOutlined,
// //   MailOutlined,
// // } from "@ant-design/icons";
// import "./ContactUsFormStyle.scss";
// import { AppConstants } from "../../helpers/constants";
// import { useHistory } from "react-router-dom";
// import Nav1 from "../../common/navbar/Nav1";

// const ContactUsForm = () => {
//   const { Option } = Select;
//   const { TextArea } = Input;
//   const [buttonDisabled, setButtonDisabled] = useState(true);
//   const [activeCategory, setActiveCategory] = useState([]);
//   const [visible, setVisible] = useState(false);
//   const categoryChange = (key) => {
//     setActiveCategory(categories[key].options);
//   };
//   const [form] = Form.useForm();
//   const validateMessages = {
//     required: "",
//   };
//   const history = useHistory();
//   function handleCaptcha(value) {
//     if (value) {
//       setButtonDisabled(false);
//     } else {
//       setButtonDisabled(true);
//     }
//   }
//   const categories = {
//     MyBooking: {
//       title: "My Bookings",
//       options: [
//         "Adding Luggage Priority Boarding",
//         " Seat Selection ON the plane ",
//         "Flight date change Correcting",
//         "changing passenger data",
//         "Checkin and Boarding Passes",
//         " Help Needed",
//       ],
//     },
//     PaymentsAndInvoice: {
//       title: "Payments & Invoice",
//       options: ["Changing Buyers Details", " Items on Invoice"],
//     },
//     Covid: {
//       title: "Covid 19",
//       options: ["Ticket Refund", "Booking Change"],
//     },
//     Complaints: {
//       title: "Complaints",
//       options: ["Flight", "Hotel", "Other Serives", "Customer Service"],
//     },
//     RateUs: {
//       title: "Rate Us",
//       options: [
//         "General Impression of website",
//         "Searching for offers",
//         " Making a Booking",
//         "Customer Service",
//       ],
//     },
//     Other: {
//       title: "Other",
//       options: ["Other"],
//     },
//   };

//   const submitForm = (value) => {
//     submitContactUsDetails(value);
//   };

//   const submitContactUsDetails = (formMapData) => {
//     APIClient.post("admin/contacts", formMapData)
//       .then((response) => {
//         if (response.status == 200) {
//           // setVisible(true);
//           // form.resetFields();
//           history.push("/Thankyou");
//         } else if (response.status == 404) {
//           message.error("Not Found", 3);
//         } else {
//           message.error("Something Went Wrong", 3);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };

//   // const handleOk = () => {
//   //   setVisible(false);
//   // };

//   // const handleCancel = () => {
//   //   setVisible(false);
//   // };

//   return (
//     <>
//     <Nav1/>
//     <div className="contact_wrapper from-contact-24">
//       <Helmet>
//         <title>Contact Us - {AppConstants.DOMAIN_NAME}</title>
//         <meta
//           name="description"
//           content="Go through all the most common frequently asked questions by our customers. If you don't find any contact us @9999292007."
//         />
//       </Helmet>
//       {/* {visible ? (
//         <Thankyou />
//       ) : ( */}
//       <Row justify="center">
//         <Col lg={14} md={20} xs={24}>
//           <div className="inner_wrapper">
//             <div className="contact_header">
//               <h2>Contact Form</h2>
//             </div>
//             <Form
//               layout="vertical"
//               name="ContactUsForm"
//               form={form}
//               validateMessages={validateMessages}
//               initialValues={{
//                 ISDCode: "+91", // Set default phone code here
//               }}
//               style={{
//                 background: "white",
//                 padding: "16px",
//                 borderRadius: "8px",
//               }}
//               onFinish={submitForm}
//               requiredMark={false}
//             >
//               <Divider orientation="left" className="d-table">
//                 How Can we Help You?
//               </Divider>
//               <Row gutter={16}>
//                 <Col md={12} sm={12} xs={24}>
//                   <Form.Item
//                     label="Category "
//                     name="CategoryName"
//                     rules={[{ required: true, message: "Required" }]}

//                   >
//                     <Select onChange={categoryChange}>
//                       {Object.keys(categories).map((key) => {
//                         return (
//                           <option value={key}>{categories[key].title}</option>
//                         );
//                       })}
//                     </Select>
//                   </Form.Item>
//                 </Col>

//                 <Col md={12} sm={12} xs={24}>
//                   <Form.Item
//                     label="Message Topic "
//                     name="CategoryMessage"
//                     rules={[{ required: true, message: "Required" }]}
//                   >
//                     <Select>
//                       {activeCategory.map((key) => {
//                         return <option value={key}>{key}</option>;
//                       })}
//                     </Select>
//                   </Form.Item>
//                 </Col>
//               </Row>
//               <Divider orientation="left" className="d-table">
//                 Write to Us
//               </Divider>

//               <Row gutter={16}>
//                 <Col md={8} sm={8} xs={24}>
//                   <Form.Item
//                     label="First Name "
//                     name="FirstName"
//                     rules={[{ required: true, message: "Required" }]}
//                   >
//                     <Input />
//                   </Form.Item>
//                 </Col>

//                 <Col md={8} sm={8} xs={24}>
//                   <Form.Item
//                     label="Last Name "
//                     name="LastName"
//                     rules={[{ required: true, message: "Required" }]}
//                   >
//                     <Input />
//                   </Form.Item>
//                 </Col>
//                 <Col md={8} sm={8} xs={24}>
//                   <Form.Item
//                     label="Contact Number"
//                     name="MobileNumber"
//                     className="phno"
//                     rules={[
//                       {
//                         required: true,
//                         message: "Phone Number  Required",
//                       },
//                       // {
//                       //   minLength: 10,
//                       //   maxLength: 10,
//                       //   pattern: "^[0-9]{10}$",
//                       //   message: "Must be 10 digits",
//                       // },
//                     ]}
//                   >
//                     <Input
//                       addonBefore={
//                         <Form.Item
//                           style={{ width: "35%" }}
//                           name="ISDCode"
//                           className="phno"
//                           rules={[
//                             {
//                               required: true,
//                               message: "Phone Number Code Required",
//                             },
//                           ]}
//                           noStyle
//                         >
//                           <Select
//                             showSearch
//                             // placeholder="Select Country"
//                             style={{ width: "80px" }}
//                             filterOption={(input, option) =>
//                               option.children
//                                 .toLowerCase()
//                                 .indexOf(input.toLowerCase()) >= 0
//                             }
//                           >
//                             {CountryList.map((item) => (
//                               <Option
//                                 key={item.dial_code}
//                                 value={item.dial_code}
//                               >
//                                 {item.dial_code}
//                               </Option>
//                             ))}
//                           </Select>
//                         </Form.Item>
//                       }
//                       className="inputbg"
//                       placeholder="Enter Mobile No."
//                       onKeyPress={(event) => {
//                         if (!/[0-9]/.test(event.key)) {
//                           event.preventDefault();
//                         }
//                       }}
//                     />

//                   </Form.Item>
//                 </Col>
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item
//                     label="Email"
//                     name="EmailAddress"
//                     rules={[
//                       { required: true, message: "Required" },
//                       {
//                         type: "email",
//                         message: "Email is not a valid email",
//                       },
//                     ]}
//                   >
//                     <Input type="email" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item label="Order Number" name="OrderNumber">
//                     <Input type="number" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={8} sm={12} xs={24}>
//                   <Form.Item label="Departure Date" name="DepartureDate">
//                     <DatePicker className="dateStyle" />
//                   </Form.Item>
//                 </Col>
//                 <Col md={12} sm={12} xs={24}>
//                   <Form.Item
//                     label="Your Message"
//                     name="YourMessage"
//                     rules={[{ required: true, message: "Required" }]}
//                   >
//                     <TextArea rows={4} />
//                   </Form.Item>
//                 </Col>
//               </Row>
//               <Row justify="center" className="my-3">
//                 <ReCAPTCHA
//                   sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
//                   onChange={handleCaptcha}
//                 />
//               </Row>

//               <Form.Item className="center">
//                 <Button
//                   // disabled={buttonDisabled}
//                   htmlType="submit"
//                   type="primary"
//                   onClick={() => form.submit()}
//                 >
//                   Submit
//                 </Button>
//               </Form.Item>
//             </Form>
//           </div>
//         </Col>
//       </Row>
//       {/* )} */}
//     </div>
//     </>
//   );
// };

// export default ContactUsForm;




import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  DatePicker,
  message,
  Collapse,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import "./ContactUsFormStyle.scss";
import CountryList from "../../common/CountryList";
import { PhoneOutlined, MailOutlined, EnvironmentOutlined } from "@ant-design/icons";
import GoogleMapReact from "google-map-react";
import zigZag from "../../assets/images/bg/zig-zag-1.png"
import Nav1 from "../../common/navbar/Nav1";
import APIClient from "../../helpers/ApiClient";
import Animation from "../AnimationBall/AnimationBalls";
import helpdesk from "../../assets/images/pop-up-img-desk.png";

const { Panel } = Collapse;

const ContactUs = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [activeCategory, setActiveCategory] = useState([]);
  const categoryChange = (key) => {
    setActiveCategory(categories[key].options);
  };
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  const history = useHistory();
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const categories = {
    MyBooking: {
      title: "My Bookings",
      options: [
        "Adding Luggage Priority Boarding",
        " Seat Selection ON the plane ",
        "Flight date change Correcting",
        "changing passenger data",
        "Checkin and Boarding Passes",
        " Help Needed",
      ],
    },
    PaymentsAndInvoice: {
      title: "Payments & Invoice",
      options: ["Changing Buyers Details", " Items on Invoice"],
    },
    Covid: {
      title: "Covid 19",
      options: ["Ticket Refund", "Booking Change"],
    },
    Complaints: {
      title: "Complaints",
      options: ["Flight", "Hotel", "Other Serives", "Customer Service"],
    },
    RateUs: {
      title: "Rate Us",
      options: [
        "General Impression of website",
        "Searching for offers",
        " Making a Booking",
        "Customer Service",
      ],
    },
    Other: {
      title: "Other",
      options: ["Other"],
    },
  };

  const submitForm = (value) => {
    submitContactUsDetails(value);
  };

  const submitContactUsDetails = (formMapData) => {
    APIClient.post("admin/contacts", formMapData)
      .then((response) => {
        if (response.status == 200) {
          // setVisible(true);
          form.resetFields();
          history.push("/Thankyou");
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const FAQData = [
    { question: "How can I change my booking?", answer: "You can change your booking through the 'Manage Booking' section. Once you login  in your Account" },
    { question: "What is your refund policy?", answer: "Refunds depend on the ticket type and cancellation time. please check the refund policy on our website" },
    { question: "Do you offer 24/7 support?", answer: "Yes, we are available 24/7 to assist you. please mail us on your queries" },
  ];
  // const handleOk = () => {
  //   setVisible(false);
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };
  const [activeKeys, setActiveKeys] = useState([]);
  const handlePanelChange = (keys) => {
    // Limit the number of active panels to 2
    if (keys.length > 1) {
      keys.shift(); // Remove the oldest active panel to keep the most recent 2
    }
    setActiveKeys(keys);
  };
  return (
    <>
      <Nav1 />
      <div className="contact-us-page">


        {/* Hero Section */}
        <section className="heroS">
          <h1>We’re Here to Help!</h1>
          <p>Reach out to us anytime. Our team is ready to assist you with bookings, support, and more.</p>
          {/* <Button type="primary" size="large">Get in Touch</Button> */}
        </section>

        {/* Contact Cards */}
        <Animation />
        <section className="contact-details">
          <Divider><h4>Contact Us</h4></Divider>
          <Row gutter={16} style={{ justifyContent: "space-around" }}>

            <Col style={{ paddingTop: "20px" }} md={8} sm={12} xs={24} >
              <div className="info-box">
                {/* <p className="cmpnynme">I2SPACE WEB TECHNOLOGIES PRIVATE LIMITED</p> */}
                <img src={helpdesk} alt="" width={"70%"} />
                <p style={{ margin: 0 }}>
                  <p style={{ margin: 0, color: "grey", fontWeight: 400 }}>  {" "}Email us your queries</p>

                  {/* <MailOutlined style={{ fontSize: "2rem", color: "#4a90e2" }} /> */}
                  <p className="mail-us-i"><MailOutlined style={{ fontSize: "1rem", color: "#4a90e2", paddingRight: "2px" }} />outcinfo@gmail.com ,</p>
                  <p className="mail-us-i"><MailOutlined style={{ fontSize: "1rem", color: "#4a90e2", paddingRight: "2px" }} />info@i2space.com</p>
                </p>
              </div>
              <div className="info-box">

                {/* <EnvironmentOutlined style={{ fontSize: "2rem", color: "#4a90e2" }} /> */}

                <span> <EnvironmentOutlined style={{ fontSize: "1rem", color: "#4a90e2" }} />{" "}Visit our office</span>
                <p> {" "}
                I2SPACE WEB TECHNOLOGIES PRIVATE LIMITED <br />
                  <span>1-98/V/G-4, Vishnu Avenue, krithika layout lane, capital park building, Madhapur, Hyderabad, Rangareddy (D), Telangana, 500081.</span>.
                </p>
              </div>


            </Col>
            <Col md={12} sm={12} xs={24}>
              {/* <Divider>Contact Us</Divider> */}
              <div className="contact-form-section">
                <Form
                  layout="vertical"
                  name="ContactUsForm"
                  form={form}
                  validateMessages={validateMessages}
                  initialValues={{
                    ISDCode: "+91", // Set default phone code here
                  }}
                  style={{
                    background: "white",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                  onFinish={submitForm}
                  requiredMark={false}
                >
                  <Divider orientation="left" className="d-table">
                    How Can we Help You?
                  </Divider>
                  <Row gutter={16}>
                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        label="Category "
                        name="CategoryName"
                        rules={[{ required: true, message: "Required" }]}

                      >
                        <Select onChange={categoryChange} placeholder="Select a category">
                          {Object.keys(categories).map((key) => {
                            return (
                              <option value={key}>{categories[key].title}</option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        label="Message Topic "
                        name="CategoryMessage"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select placeholder="Select a topic">
                          {activeCategory.map((key) => {
                            return <option value={key}>{key}</option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider orientation="left" className="d-table">
                    Write to Us
                  </Divider>

                  <Row gutter={16}>
                    <Col md={8} sm={8} xs={24}>
                      <Form.Item
                        label="First Name "
                        name="FirstName"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Enter First Name" />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={8} xs={24}>
                      <Form.Item
                        label="Last Name "
                        name="LastName"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Enter Last Name" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={24}>
                      <Form.Item
                        label="Contact Number"
                        name="MobileNumber"
                        className="phno"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number  Required",
                          },
                          // {
                          //   minLength: 10,
                          //   maxLength: 10,
                          //   pattern: "^[0-9]{10}$",
                          //   message: "Must be 10 digits",
                          // },
                        ]}
                      >
                        <Input
                          placeholder="Enter Mobile No."
                          addonBefore={
                            <Form.Item
                              style={{ width: "25%" }}
                              name="ISDCode"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone Number Code Required",
                                },
                              ]}
                              noStyle
                            >
                              <Select
                                showSearch
                                // placeholder="Select Country"
                                style={{ width: "70px" }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {CountryList.map((item) => (
                                  <Option
                                    key={item.dial_code}
                                    value={item.dial_code}
                                  >
                                    {item.dial_code}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          }
                          className="inputbg"
                          //placeholder="Enter Mobile No."
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />

                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Email"
                        name="EmailAddress"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            type: "email",
                            message: "Email is not a valid email",
                          },
                        ]}
                      >
                        <Input type="email" placeholder="Enter Email Address" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item label="Order Number" name="OrderNumber">
                        <Input placeholder="Enter Order Number" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item label="Departure Date" name="DepartureDate">
                        <DatePicker className="dateStyle" />
                      </Form.Item>
                    </Col>
                    <Col md={24} sm={12} xs={24}>
                      <Form.Item
                        label="Your Message"
                        name="YourMessage"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <TextArea rows={4} placeholder="Enter Your Message" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row justify="center" className="my-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={handleCaptcha}
                    />
                  </Row> */}

                  <Form.Item className="center">
                    <Button
                      // disabled={buttonDisabled}
                      htmlType="submit"
                      type="primary"
                      onClick={() => form.submit()}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </section>
        <section className="faq-section">
          <Divider>FAQ's</Divider>
          <Collapse bordered={false} activeKey={activeKeys} onChange={handlePanelChange} className="faq-drop-ab">
            {FAQData.map((item, index) => (
              <Panel header={item.question} key={index}>
                <p>{item.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </section>



        {/* FAQ Section */}
        {/* <section className="faq-section">
          <Divider>FAQs</Divider>
          <Collapse>
            {FAQData.map((item, index) => (
              <Panel header={item.question} key={index}>
                <p>{item.answer}</p>
              </Panel>
            ))}
          </Collapse>
        </section> */}

        {/* Map Section */}
        {/* <section className="map-section">
          <Divider>Find Us Here</Divider>
          <div style={{ height: "300px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "your-google-maps-key" }}
              defaultCenter={{ lat: 40.7128, lng: -74.006 }}
              defaultZoom={11}
            />
          </div>
        </section> */}
        <img src={zigZag} alt="" className="zigZagD" />
      </div>
    </>
  );
};

export default ContactUs;
