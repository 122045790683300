import { CaretRightOutlined, CloseOutlined, EyeOutlined, DownloadOutlined, } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Image,
  Modal,
} from "antd";
import moment from "moment";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import React, { useEffect, useState } from "react";
// import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import { Link } from "react-router-dom";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import { saveAs } from "file-saver";
import { getVisaTicketStatus } from "../../common/AllTickets/AllTicketStatus";

let uiDateFormat = "DD-MM-YYYY";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const VisaCustomerReports = (props) => {
  const [form] = Form.useForm();

  const [showTable, setShowTable] = useState(false);

  const [isDateDisplay, setIsDateDisplay] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [loading, setLoading] = useState(false);

  const [toDisableDate, setToDisableDate] = useState(moment());
  const [imageRequestModal, setImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);
  const dateFormat = "YYYY-MM-DD"
  const [bookedType, setbookedType] = useState(null);
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    props.exportExcel(excelData, "VisaCustomer_Reports");
  };


  const getVisaCustomerListData = (values) => {
    setLoading(true);

    ApiClient.get("visa/getVisaBookignReportsByFilter", values)
      .then((res) => {
        // { console.log(res, "visa") }
        setLoading(false);

        if (res?.status == 200) {
          setDataSource(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format(dateFormat);
      values.toDate = moment(values.toDate).format(dateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    setDataSource([]);

    getVisaCustomerListData({ ...values });
  };
  const BookedType = (value) => {
    setbookedType(value)

  }
  const reset = () => {
    form.resetFields();
    setbookedType(null);
  };

  const columns = [
    {
      title: "Reference No.",
      dataIndex: "ReferenceNumber",
      // render: (text) => {
      //   return <Link to={`visa/getVisaBookingDetailsByRefNo/${text}`}>{text}</Link>;
      render: (text) => {
        return <Link to={`/admin/visa/ticket?ref=${text}`}>{text}</Link>;
      },
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    // {
    //   title: "Name",
    //   render: (item) => `${item.title} ${item.customerFirstName} ${item.customerLastName}`
    // },

    {
      title: "Name",
      render: (item) => `${item.customerFirstName} ${item.customerLastName}`
    },
    {
      title: "Email",
      dataIndex: "customerEmail"
    },
    {
      title: "Mobile",
      dataIndex: "customerMobile"
    },
    // {
    //   title: "Booking Status",
    //   render: (item) => {
    //     const status = getVisaTicketStatus(item.BookingStatus);
    //     return <span style={{ color:getVisaTicketStatus(status) }}>{status}</span>;
    //   },
    // },
    {
      title: "Passport No.",
      dataIndex: "passportNumber"
    },
    {
      title: "Nationality",
      dataIndex: "customerNationality"
    },
    {
      title: "DOB",
      dataIndex: "customerDOB",
      render: (item) => moment(item).format(dateFormat)
    },
    {
      title: "Booking No",
      dataIndex: "BookingConfirmationNumber"
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      render: (date) => moment(date).format('DD-MM-YYYY'),
    },
    {
      title: "Booked Type",
      dataIndex: "pgType",
      render: (type) => (
        
          type === 4 ? <p>Offline</p> : <p>Online</p>
      ),
    },
    {
      title: "PAN",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.pan) {
          return ImageRequest({ imageUrl: passengers[0]?.pan });
        } else {
          return null;
        }
      },
    },
    {
      title: "Passport",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.passport) {
          return ImageRequest({ imageUrl: passengers[0]?.passport });
        } else {
          return null;
        }
      },
    },
    {
      title: "Photo",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.photo) {
          return ImageRequest({ imageUrl: passengers[0]?.photo });
        } else {
          return null;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "pgType",
      render: (type, record) =>
      type === 4 && record.BookingStatus !== 3 ? (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleUpdateClick(record.ReferenceNumber)}
        >
          Update
        </span>
      ) : null,
    },
  ]
  const handleUpdateClick= (referenceNumber) => {
    ApiClient.put(`visa/visaOfflinebooking/${referenceNumber}`)
      .then((res) => {
        if (res.status === 200) {
          
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error)
      });
    
  };


  // const ImageReques = (imageUrls) => {
  //   return (
  //     <div className="text-center">
  //       {console.log(imageUrls, "imgvisa")}
  //       <span
  //         style={{ cursor: "pointer", color: "green" }}
  //         onClick={(e) => {
  //           setImageRequest(imageUrls);
  //           setImageRequestModal(true);
  //         }}
  //       >
  //         <EyeOutlined />
  //       </span>
  //     </div>
  //   );
  // };
  // const ImageRequests = (imageUrls) => {
  //   return (
  //     <div className="text-center">
  //       {imageUrls.map((imageUrl, index) => (
  //         <span key={index}>
  //           <a
  //             href={imageUrl}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             style={{ cursor: "pointer", color: "green" }}
  //           >
  //             <EyeOutlined />
  //           </a>
  //           {/* {index < imageUrls.length - 1 && <span>&nbsp;&nbsp;</span>} */}
  //         </span>
  //       ))}
  //     </div>
  //   );
  // };
  const ImageRequest = ({ imageUrl }) => {
    if (!imageUrl) {
      return null; 
    }
    return (
      <div className="text-center">
        {/* {console.log(imageUrl, "urlonly")} */}
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setImageRequest(imageUrl);
            setImageRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };
  const downloadImage = () => {
    let url = `${imageRequest}`

    const fileName = imageRequest.split('/')[imageRequest.split('/').length - 1]
    saveAs(url, fileName);
    
  }

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        <CaretRightOutlined rotate={isActive ? 90 : -90} />
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    defaultActiveKey={["1"]}
                  >
                    <Panel key="1">
                      <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{
                          options: 3,
                          ReferenceNo: "",
                          bookingStatus: "",

                        }}
                        onFinish={onFormSubmit}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Time and Date"
                              name="options"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>
                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    // format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    format={uiDateFormat}
                                    disabledDate={disabledFutureDate}
                                    onChange={(date, dateString) =>
                                      onChangeFromDate(date, dateString)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledSelectedDate}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}

                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Reference number"
                              name="ReferenceNo"
                            >
                              <Input placeholder="Enter Reference Number" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Booking Type"
                              name="BookingType"
                            >
                              <Select
                                placeholder="Please select"
                                onChange={BookedType}
                              >
                                <Option value={3}>Online</Option>
                                <Option value={2}>Offline</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                        </Row>
                        <Row className="reports-btns">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => setShowTable(true)}
                          >
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={reset}
                          // onClick={() => {

                          //   form.resetFields();
                          // }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>Customer Reports {dataSource.length}</h5>
                              <HelpInfoHelper screenName={"/admin/carsorder"} />
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              scroll={{ x: true }}
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Content>
      </div>
      <Modal
        visible={imageRequestModal}
        title="Photos"
        onCancel={(e) => {
          setImageRequestModal(false);
          setImageRequest([]);
        }}
        // footer={false}
        footer={[
          <button key="download" onClick={downloadImage}>
            <DownloadOutlined /> Download
          </button>,
        ]}
        className="modal-header-bg12"
      >
        <Row gutter={12}>
          
        </Row>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(VisaCustomerReports);
