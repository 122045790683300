import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
import { useLocalStorage } from "../../helpers/useStorage";

const { Option, OptGroup } = Select;

const BusAutoCompleteSelectByCategory = (props) => {
  const [details, setDetails] = useState({
    data: [],
    fetching: false,
  });

  const [BusRecentSearches, setBusRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  // Fetch data on component mount or when `refName` or `dataFlip` changes
  useEffect(() => {

    let value = props.refName.current.props.value;
    const citySearchParams = queryString.parse(value);

    if (!value || value.trim() === "") {
      return;
    }
    fetchData(citySearchParams.cityName, citySearchParams.cityId);
  }, [props.refName.current, props.dataFlip]);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const debounceOnChange = useCallback(debounce(fetchData, 1000), []);


  const createOptions = (results, cityId) => {
    return results
      .filter((item) => cityId == null || item.cityId === cityId)
      .map((result) => ({
        cityId: `cityName=${result.name}&cityId=${result.cityId}`,
        cityName: result.cityName,
        fullName: result.fullName,
        name: result.name,
        state: result.state,
        country: result.country
      }));
  };

  // Fetch data from API
  function fetchData(value, cityId = null) {
    if (value) {
      setDetails({ data: [], fetching: true });
      APIClient.get(`${props.api}${value}`)
        .then((res) => {
          if (res.status === 200 && res) {

            setDetails({
              data: createOptions(res.data, cityId),
              fetching: false,
            });
            return;
          }
          setDetails({ data: [], fetching: false });
        })
        .catch((error) => {
          console.error(error);
          setDetails({ data: [], fetching: false });
        });
    }
  }

  // Debounce function to limit API calls
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  // Handle recent searches
  const recentSearches = (e) => {
    if (e) {
      const existingSearch = BusRecentSearches.find((item) => item.cityId === e);
      if (existingSearch) {
        setBusRecentSearches([
          existingSearch,
          ...BusRecentSearches.filter((item) => item.cityId !== e),
        ].slice(0, 5));
      } else {
        const optionObj = details.data.find((item) => item.cityId === e);
        if (optionObj) {
          setBusRecentSearches([optionObj, ...BusRecentSearches].slice(0, 5));
        }
      }
    }
  };

  return (
    <Form.Item {...props.formItemProps}>

      <Select

        style={{ width: "100%" }}
        showSearch
        optionLabelProp="label"
        ref={props.refName}

        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={(e) => {
          onSelect();
          recentSearches(e);
        }}
      >
        {/* {BusRecentSearches.length > 0 && (
          <OptGroup label="Recent Searches">
            {BusRecentSearches.map((d) => (
              <Option value={d.cityId} key={"recent" + d.cityId}>
                {d.cityName}
              </Option>
            ))}
          </OptGroup>
        )}

        {props.topCities.length > 0 && (
          <OptGroup label="Top Cities">
            {props.topCities.map((item, index) => (
              <Option value={props.formItemProps.name === "destinationId" ? item.destinationId : item.sourceId} key={"top" + index}>
                {item.cityName.split(",")[0]}
              </Option>
            ))}
          </OptGroup>
        )} */}

        {details.data.length > 0 && (

          <OptGroup>

            {details.data.map((d, index) => {
              const BusesLabel = (
                <div>
                  {/* {console.log(d,"Buses")} */}
                  <p style={{ fontSize: "26px", color: "rgb(1 37 92 / 98%)", fontWeight: "800", margin: "0", lineHeight: "30px", fontFamily: "Nunito", marginTop: "4px" }}>
                    {d.name}
                    {/* {d.cityName.split(",")[0]} */}
                    <span style={{ fontSize: "12px", color: "#9B9B9B", marginTop: "-7px", display: "flex" }}>
                      {"City in " + d.state + " ,"} {d.country}
                    </span>
                  </p>
                  {/* <p style={{ fontSize: "22px", color: "rgb(1 37 92 / 98%)", fontWeight: "800", margin: "0", lineHeight: "30px", fontFamily: "Nunito", marginTop: "9px" }}>
                    {console.log(d, "d")}
                    {d.name},{d.state}
                    <span style={{ fontSize: "12px", color: "#9B9B9B", marginTop: "-7px", display: "flex" }}>
                      {d.country}
                    </span>
                  </p> */}

                </div>
              );
              return (
                <Option label={BusesLabel} value={d.cityId} key={"City" + d.cityId + index}>
                  <div>
                    <p style={{ fontSize: "22px", color: "rgb(1 37 92 / 98%)", fontWeight: "800", margin: "0", lineHeight: "33px", fontFamily: "Nunito", marginTop: "4px" }}>{d.name}</p>

                    <span style={{ fontSize: "12px", color: "#9B9B9B", marginTop: "2px", display: "flex" }}>
                      {d.fullName}</span>
                  </div>
                </Option>
              );
            })}
          </OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default BusAutoCompleteSelectByCategory;
