// import React, { useEffect, useState } from "react";
// import { Row, Col, Modal, Tabs, Skeleton } from "antd";
// import { useHistory } from "react-router-dom";
// import APIClient from "../../helpers/ApiClient";
// import moment from "moment";
// import { useSytContext } from "../../common/providers/SytProvider";
// import Nav1 from "../../common/navbar/Nav1";
// import OffersSlider from "../../common/LandingPageOffers/LandingPageOffers"; // Import the OffersSlider component
// import "./Offers.scss";

// const Offers = () => {
//   const goto = useHistory();
//   const { TabPane } = Tabs;
//   const [details, setDetails] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const { offerBanners } = useSytContext();
//   const [promoCode, setPromoCode] = useState([]);

//   useEffect(() => {
//     getpromo();
//   }, []);

//   const getpromo = () => {
//     APIClient.get("admin/promo")
//       .then((res) => {
//         if (res.status === 200) {
//           setPromoCode(res.data);
//         } else {
//           setPromoCode([]);
//         }
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.error(error);
//         setIsLoading(false);
//       });
//   };

//   const showModal = () => {
//     setDetails(true);
//   };

//   const handleOk = () => {
//     setDetails(false);
//   };

//   const handleCancel = () => {
//     setDetails(false);
//   };

//   const getDaysRemaining = (ValidTill) => {
//     let today = moment();
//     let ValidTillDate = moment(ValidTill);
//     return ValidTillDate.diff(today, "days");
//   };

//   const gotoResults = (serviceType) => {
//     switch (serviceType) {
//       case "Flights":
//         goto.push("/Flights");
//         break;
//       case "Hotels":
//         goto.push("/Hotels");
//         break;
//       case "Bus":
//         goto.push("/bus");
//         break;
//       default:
//         goto.push("/");
//         break;
//     }
//   };

//   const filteredPromo = {
//     flightPromo: promoCode.filter((promo) => promo.ServiceType === 1),
//     hotelPromo: promoCode.filter((promo) => promo.ServiceType === 2),
//     busPromo: promoCode.filter((promo) => promo.ServiceType === 3),
//   };

//   return (
//     <>
//       <Nav1 />

//       <div className="promocodes">
//       <div className="bus-block">
//         {/* <div className="bus-banner">
//           <Banner banner={offerBanners} />
//         </div> */}

//       </div>
//         <div className="promocode-wrapper">
//           <div className="container">
//             <div className="promocodes-section">
//               <div className="service-type-tabs">
//                 <Tabs defaultActiveKey="1">
//                   <TabPane tab="All" key="1">
//                     <div className="tab1-content">
//                       {isLoading ? (
//                         <Skeleton active />
//                       ) : (
//                         <OffersSlider dataSource={promoCode} />
//                       )}
//                     </div>
//                   </TabPane>
//                   <TabPane tab="Flights" key="2">
//                     <div className="tab1-content">
//                       {isLoading ? (
//                         <Skeleton active />
//                       ) : (
//                         <OffersSlider dataSource={filteredPromo.flightPromo} />
//                       )}
//                     </div>
//                   </TabPane>
//                   <TabPane tab="Hotels" key="3">
//                     <div className="tab1-content">
//                       {isLoading ? (
//                         <Skeleton active />
//                       ) : (
//                         <OffersSlider dataSource={filteredPromo.hotelPromo} />
//                       )}
//                     </div>
//                   </TabPane>
//                   <TabPane tab="Bus" key="4">
//                     <div className="tab1-content">
//                       {isLoading ? (
//                         <Skeleton active />
//                       ) : (
//                         <OffersSlider dataSource={filteredPromo.busPromo} />
//                       )}
//                     </div>
//                   </TabPane>
//                 </Tabs>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Modal
//         title="Promo Code"
//         visible={details}
//         onOk={handleOk}
//         onCancel={handleCancel}
//       >
//         <p>Some promo details...</p>
//       </Modal>
//     </>
//   );
// };

// export default Offers;


import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Tabs, Skeleton, message } from "antd";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import Banner from "../banner/Banner";
import { useSytContext } from "../../common/providers/SytProvider";
// import Nav1 from "../../common/navbar/Nav1";
import OffersSlider from "../../common/LandingPageOffers/LandingOffers"; // Import the OffersSlider component
import "./Offers.scss";
import Nav1 from "../../common/navbar/Nav1";
import img1 from "../../assets/images/Offers/img1.jpg";
import Animation from "../AnimationBall/AnimationBalls";
import bat from "../../assets/images/skyline-landmarks.8653326.png";
import wheel from "../../assets/images/skyline-london-eye.59708c1.png";
import fly from "../../assets/images/travel.png";
import hot from "../../assets/images/hotels/ht.png";
import bus from "../../assets/images/busdriver.png";
import all from "../../assets/images/defaultadd.png";
import zigZag from "../../assets/images/bg/zig-zag-1.png";

const BASE = process.env.REACT_APP_BASE_URL;

const AnimatedBanner = () => (
  <div className="animated-banner">
    <h1>Explore Amazing Offers!</h1>
    <p>Find exclusive deals on flights, hotels, buses, and more.</p>
    <img className="skyline" src={bat} alt="" />
    <img className="sky-wheel" src={wheel} alt="" />
    {/* <button className="explore-btn">Explore Now</button> */}
  </div>
);

const SectionTitle = ({ title }) => (
  <div className="section-title">
    <h2>{title}</h2>
    <div className="underline"></div>
  </div>
);
function copyToClipboard(text) {
  // { console.log(text, "copy") }
  // const elem = document.createElement("textarea");
  // elem.value = text;
  // document.body.appendChild(elem);
  // elem.select();
  // document.execCommand("copy");
  // document.body.removeChild(elem);
  navigator.clipboard.writeText(text);
  message.success({
    content: "Code Copied!",
  });
}
const OfferCard = ({ promo, getDaysRemaining, gotoResults }) => (
  <Col className="gutter-row" lg={6} md={8} sm={12} xs={24}>

    <div className="coupon-card">
      <div className="images-slider">
        <img
          src={promo.Image ? BASE + promo.Image.substring(1) : img1}
          alt={promo.Name}
          className="offer-img"
        />
      </div>
      <div className="coupon-content">
        <h4 className="offer-title">{promo.Name}</h4>
        <p className="off-desc-p">{promo.Description}</p>
        <div className="offer-footer">
          <p className="expiry">
            Book by: <span style={{color:"black", fontWeight:"500"}}>{moment(promo.ValidTill).format("DD MMM' YY")}</span>
          </p>
          <button onClick={() => { copyToClipboard(promo.Code); gotoResults() }} className="redeem-btn">
            Redeem Now
          </button>
        </div>
      </div>
    </div>
  </Col>
);

const Offers = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { TabPane } = Tabs;
  const history = useHistory();

  useEffect(() => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          setPromoCodes(res.data);
        }
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const getDaysRemaining = (ValidTill) => {
    const today = moment();
    const ValidTillDate = moment(ValidTill, "YYYY-MM-DD");
    return ValidTillDate.diff(today, "days");
  };

  const gotoResults = (tabKey) => {
    const routes = {
      Flights: "/Flights",
      Hotels: "/Hotels",
      Bus: "/buses",
      All: "/",
    };
    history.push(routes[tabKey]);
    // console.log("comming");
  };

  const categorizedPromos = {
    All: promoCodes,
    Flights: promoCodes.filter((promo) => promo.ServiceType === 1),
    Hotels: promoCodes.filter((promo) => promo.ServiceType === 2),
    Bus: promoCodes.filter((promo) => promo.ServiceType === 3),
  };

  const renderTabHeader = (title) => (
    <div className="tab-header">
      {/* {console.log(title, "tittt")} */}
      <img width={"20px"} src={title === "Flights" ? fly : title === "Hotels" ? hot : title === "Bus" ? bus : all} alt={""} className="tab-image" />
      <span className="tab-title">{title}</span>
    </div>
  );
  return (
    <div className="offers-page">
      <Nav1 />
      {/* <div className="banner-section">
        <Banner />
      </div> */}
      <AnimatedBanner />
      <Animation />
      {/* <div className="container">
        <Tabs defaultActiveKey="All">
          {Object.keys(categorizedPromos).map((category) => (
            <TabPane tab={category} key={category}>
              <SectionTitle title={`Exclusive ${category} Offers`} />
              <Row gutter={[32, 32]}>
                {isLoading
                  ? Array.from({ length: 4 }).map((_, idx) => (
                    <Skeleton.Button
                      key={idx}
                      active
                      shape="square"
                      className="offer-skeleton"
                    />
                  ))
                  : categorizedPromos[category].length > 0
                    ? categorizedPromos[category].map((promo, idx) => (
                      <OfferCard
                        key={`${category}-${idx}`}
                        promo={promo}
                        getDaysRemaining={getDaysRemaining}
                        gotoResults={() => gotoResults(category)}
                      />
                    ))
                    : "No Offers Available"}
              </Row>
            </TabPane>
          ))}
        </Tabs>

      </div> */}
      <div className="containerOf">
        <Tabs defaultActiveKey="All" tabPosition="top">
          {Object.keys(categorizedPromos).map((category) => (
            <TabPane
              tab={renderTabHeader(
                category

              )}
              key={category}
            >
              <div className="tab-content">
                <h2 className="section-title">Exclusive {category} Offers</h2>
                <Row className="tab1-content-off" gutter={[32, 32]}>
                  {isLoading
                    ? Array.from({ length: 4 }).map((_, idx) => (
                      <Skeleton.Button
                        key={idx}
                        active
                        shape="square"
                        className="offer-skeleton"
                      />
                    ))
                    : categorizedPromos[category].length > 0
                      ? categorizedPromos[category].map((promo, idx) => (
                        <OfferCard
                          key={`${category}-${idx}`}
                          promo={promo}
                          getDaysRemaining={getDaysRemaining}
                          gotoResults={() => gotoResults(category)}
                        />

                      ))
                      : "No Offers Available"}
                </Row>
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
      <img src={zigZag} alt="" className="zigZagD" />
    </div>
  );
};

export default Offers;
