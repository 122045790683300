//#region  latest code
import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Radio,
  Row,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Select,
} from "antd";
import FareDetails from "./FareDetails";
import BusDetails from "./BusDetails";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import "./Checkout.scss";
import { Link, useHistory } from "react-router-dom";
import { getPassengerData } from "../../../helpers/PassegerData";
import { SafetyCertificateOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Nav1 from "../../../common/navbar/Nav1";
import bus from "../../../assets/images/bus.jpg";
import simg from "../../../assets/images/seat-b.png";
import busban from "../../../assets/images/BusBanO.png";

const { Option } = Select;
const BusCheckout = ({ location }) => {
  const { user } = useAuthContext();
  const {
    state: {
      busPersist: { selectedBusData },
      otherData: { insuranceData },
    },
    updateBusPassengerData,
    setSelectedInsuranceData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  const [onlyPassengersDetails, setOnlyPassengersDetails] = useState({});
  const [form] = Form.useForm();
  let history = useHistory();

  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  useEffect(() => {
    RemovePromoConvFee();
    loadpassengerData();
  }, []);

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          form.setFieldsValue({
            mobileNo: data.Mobile,
            emailId: data.Email,
          });
        }
      });
    }
  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };

  const updatePassengersData = () => {
    form
      .validateFields()
      .then((data) => {
        const { emailId, mobileNo } = data;
        sessionStorage.setItem("BusPaxFormData", JSON.stringify(data));
        sessionStorage.setItem(
          "BusPassengersDetails",
          JSON.stringify({ ...onlyPassengersDetails, emailId, mobileNo })
        );
        let busPaxArr = [];

        busPaxArr.push({
          tripId: selectedBusData[0].busCardId,

          searchId: selectedBusData[0].searchId,
          boardingId: selectedBusData[0].boardingTimes.PointId,
          droppingId: selectedBusData[0].droppingTimes.PointId,
          noofSeats: selectedBusData[0].seatsInfo.length,
          emailId: emailId,
          mobileNo: mobileNo,
          countryCode: "91",
          passengerDetails: buildPassengerArray(selectedBusData[0].seatsInfo),
          insuranceRequired: data?.insuranceRequired === 1 ? 1 : 0,
        });
        // if (props.gscontext.state.busData.type === "twoWay") {
        //   busPaxArr.push({
        //     tripId: selectedBusData[1].busCardId,
        //     searchId: selectedBusData[1].searchId,
        //     boardingId: selectedBusData[1].boardingTimes.PointId,
        //     droppingId: selectedBusData[1].droppingTimes.PointId,
        //     noofSeats: selectedBusData[1].seatsInfo.length,

        //     emailId: emailId,
        //     mobileNo: mobileNo,
        //     countryCode: "91",
        //     passengerDetails: buildPassengerArray(selectedBusData[1].seatsInfo),
        //   });
        // }

        updateBusPassengerData(busPaxArr);
        history.push("/bus/review");
      })
      .catch((e) => { });
  };

  const buildPassengerArray = (selectedBusData) => {
    return selectedBusData.map((seat) => {
      return {
        age:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].age
            ? onlyPassengersDetails[seat.SeatNo].age
            : "10",
        name:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].name
            ? onlyPassengersDetails[seat.SeatNo].name
            : "",
        countryCode: "91",
        title:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].title
            ? onlyPassengersDetails[seat.SeatNo].title
            : "Mr",
        gender:
          onlyPassengersDetails[seat.SeatNo] &&
            onlyPassengersDetails[seat.SeatNo].gender
            ? onlyPassengersDetails[seat.SeatNo].gender
            : "M",
        seatNo: seat.SeatNo,
        fare: seat.NetFare,
        seatCodes: seat.SeatCode,
        serviceTax: seat.Servicetax,
        markup: seat.markup,
        agentMarkup: seat.agentMarkup,
        // commission: seat.commission ?? 0,
        // adminCommission: seat.adminCommission ?? 0,
        serviceCharge: seat.OperatorServiceCharge,
      };
    });
  };

  useEffect(() => {
    let BusPaxFormData = JSON.parse(sessionStorage.getItem("BusPaxFormData"));
    let BusPassengersDetails = JSON.parse(
      sessionStorage.getItem("BusPassengersDetails")
    );

    if (BusPaxFormData && BusPassengersDetails) {
      form.setFieldsValue({
        ...BusPaxFormData,
      });
      setOnlyPassengersDetails(BusPassengersDetails);
    }
  }, []);
  return (
    <>
      <Nav1 />
      <div className="buscheckout_bg">
        <div className="checkout-heading">
          <div
            style={{ marginTop: "65px" }}
            fluid
            className="checkout-heading-container"
          >
            <div className="goback">
              <Link
                onClick={() => {
                  history.go(-1);
                }}
              >
                <ArrowLeftOutlined />
                <span>Go back and select another Buses</span>
              </Link>
            </div>
            <h3>Fill out the form below and book your stay now!</h3>
          </div>
        </div>
        <div className="buscheckout_wrapper">

          <Row gutter={[16, 16]} style={{ marginTop: "26px" }}>
            <Col md={24} sm={24} xs={24}>
              <Form form={form} layout="vertical" scrollToFirstError={true} 
             // requiredMark={false}
              >
                {/* <h3 className="title">Bus Details</h3> */}
                <Row gutter={[16, 16]}>
                  <Col md={17} sm={16} xs={24}>
                    <div className="left_wrapper">
                      <BusDetails selectedBusData={selectedBusData} />
                    </div>
                    <img width={"100%"} style={{borderRadius:"10px", boxShadow:"1px 2px 12px lightgrey"}} src={busban} alt="" />
                    <div style={{ marginTop: "20px" }}  className="paxinfo_wrapper">
                      {/* <h3 className="title">Passenger Details</h3> */}
                      <Card>
                        <div style={{ background: "#f9f9f9", padding: 12 }}>
                          <h3 className="title">Passenger Details</h3>
                        </div>
                        {selectedBusData[0].seatsInfo &&
                          selectedBusData[0].seatsInfo.map(
                            (selectedSeat, index) => (


                              <div style={{ padding: "4px 15px" }}>
                                <p className="seat-pax-desc" style={{fontFamily:"Nunito"}}><img  width={"20px"} src={simg} alt="" /> {" "}Seat No: {" "}<span style={{ fontWeight:700}}>{selectedSeat.SeatNo} </span></p>
                                <Row gutter={[16, 16]}>
                                  <Col md={4} sm={12} xs={24}>
                                    <Form.Item
                                      name={"Title_" + index}
                                      label="Title"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                    >
                                      <Select
                                      size="large"
                                      placeholder="Select Title"
                                        onSelect={(val) => {
                                          setOnlyPassengersDetails({
                                            ...onlyPassengersDetails,
                                            [selectedSeat.SeatNo]: {
                                              ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                              ],
                                              title: val,
                                            },
                                          });
                                        }}
                                        className="inputbg"
                                      >
                                        <Option value="Mr">Mr</Option>
                                        <Option value="Ms">Ms</Option>
                                        <Option value="Mrs">Mrs</Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      name={"Name_" + index}
                                      label="Name"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required",
                                        },
                                      ]}
                                    >
                                      <Input
                                      size="large"
                                      placeholder="Enter Name"
                                        className="inputbg"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          form.setFieldsValue({
                                            [`Name_${index}`]:
                                              e.target.value.trimStart(),
                                          })
                                        }
                                        onBlur={(e) => {
                                          if (e.target.value.trim()) {
                                            setOnlyPassengersDetails({
                                              ...onlyPassengersDetails,
                                              [selectedSeat.SeatNo]: {
                                                ...onlyPassengersDetails[
                                                selectedSeat.SeatNo
                                                ],
                                                name: e.target.value.trim(),
                                              },
                                            });
                                          }
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      name={"Age_" + index}
                                      label="Age"
                                      rules={[
                                        { required: true, message: "Required" },

                                        {
                                          pattern: "^(0?[1-9]|[1-9][0-9])$",
                                          message:
                                            "Age Should be between 1 to 99",
                                        },
                                      ]}
                                    >
                                      <Input
                                      size="large"
                                      placeholder="Enter Age"
                                        className="inputbg"
                                        onBlur={(e) => {
                                          setOnlyPassengersDetails({
                                            ...onlyPassengersDetails,
                                            [selectedSeat.SeatNo]: {
                                              ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                              ],
                                              age: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col md={4} sm={12} xs={24}>
                                    <Form.Item
                                      name={"Gender_" + index}
                                      label="Gender"
                                      rules={[
                                        { required: true, message: "Required" },
                                      ]}
                                    >
                                      <Select
                                      size="large"
                                      placeholder="Select Gender"
                                        onSelect={(val) => {
                                          setOnlyPassengersDetails({
                                            ...onlyPassengersDetails,
                                            [selectedSeat.SeatNo]: {
                                              ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                              ],
                                              gender: val,
                                            },
                                          });
                                        }}
                                      >
                                        <Option value="M">Male</Option>
                                        <Option value="F">Female</Option>
                                        {/* <Option value="F">Not to Mention</Option> */}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>

                            )
                          )}
                      </Card>
                    </div>
                    {insuranceData.status === 1 &&
                      insuranceData?.serviceType === 3 ? (
                      <div className="paxinfo_wrapper">
                        <Card className="insurance-section">
                          <h5>THE SMART INSURANCE COVER</h5>
                          <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                          <Row gutter={[16, 16]} align="stretch" className="mt-4">
                            <Col md={8} sm={24} xs={24}>
                              <div className="insurance-box">
                                {insuranceData.description_box_1}
                              </div>
                            </Col>
                            <Col md={8} sm={24} xs={24}>
                              <div className="insurance-box">
                                {insuranceData.description_box_2}
                              </div>
                            </Col>
                            <Col md={8} sm={24} xs={24}>
                              <div className="insurance-box">
                                {insuranceData.description_box_3}
                              </div>
                            </Col>
                          </Row>

                          <div className="insurance-coverage">
                            <SafetyCertificateOutlined />
                            <span>
                              Insurance Coverage Amount :{" "}
                              {insuranceData.insuranceCoverage}
                            </span>
                          </div>

                          <Form.Item
                            name="insuranceRequired"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Insurance",
                              },
                            ]}
                          >
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={(e) =>
                                handleInsuranceChange(e.target.value)
                              }
                              className="insurance-radio-wrapper"
                            >
                              <Row gutter={[16, 16]}>
                                <Col md={8} sm={24} xs={24}>
                                  <Radio
                                    className={`radio-btn btn-insure  ${insuranceRequired === 1
                                        ? "btn-insure-active"
                                        : ""
                                      }`}
                                    value={1}
                                  >
                                    Insure For ₹{insuranceData.amount}/pax
                                  </Radio>
                                </Col>

                                <Col md={8} sm={24} xs={24}>
                                  <Radio
                                    value={0}
                                    className={`radio-btn btn-risk ${insuranceRequired === 0
                                        ? "btn-risk-active"
                                        : ""
                                      }`}
                                  >
                                    I'll Risk it
                                  </Radio>
                                </Col>
                              </Row>
                            </Radio.Group>
                          </Form.Item>
                        </Card>
                      </div>
                    ) : null}

                    <div className="paxcontact_wrapper">
                      {/* <h3 className="title">Contact Details</h3> */}
                      <Card>

                        <Row gutter={16}>
                          <Col md={24} sm={24} xs={24}>
                            <div style={{ background: "#f9f9f9", padding: 12 }}>
                              <h3 className="title">Contact Details</h3>
                            </div>
                          </Col>
                          <div style={{ padding: 12, width: 700, display: 'flex' }}>
                            <Col md={10} sm={12} xs={24}>
                              <Form.Item
                                label="Phone number"
                                name="mobileNo"
                                className="phno"
                                rules={[
                                  {
                                    required: true,
                                    message: "Required",
                                  },
                                  {
                                    minLength: 10,
                                    maxLength: 10,
                                    pattern: "^[0-9]{10}$",
                                    message: "Must be 10 digits",
                                  },
                                ]}
                              >
                                <Input size="large" placeholder="Enter Phone number" addonBefore="+91" className="inputbg" />
                              </Form.Item>
                            </Col>
                            <Col md={10} sm={12} xs={24}>
                              <Form.Item
                                label="Email"
                                name="emailId"
                                rules={[
                                  { required: true, message: "Required" },
                                  { type: "email", message: "Invalid Email" },
                                ]}
                              >
                                <Input size="large" placeholder="Enter Email" className="inputbg" />
                              </Form.Item>
                            </Col>
                          </div>
                        </Row>
                      </Card>
                    </div>
                  </Col>

                  <Col md={7} sm={8} xs={24}>
                    <div className="right_wrapper">
                      <FareDetails
                        selectedBusData={selectedBusData}
                        isPromoVisible={true}
                        location={location}
                      />
                    </div>
                  </Col>

                  <Col md={17} sm={16} xs={24}>
                  
                    <Card style={{ boxShadow: "none", background: "none", border: "none", textAlign: "center" }}>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject("Should accept agreement"),
                          },
                        ]}
                      >
                        <Checkbox>
                          I Agree To All The{" "}
                          <a
                            href="/termsofconditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#003b95" }}
                          >
                            {"  Terms & Conditions"}
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacypolicy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#003b95" }}
                          >
                            {" "}
                            Privacy Policy.
                          </a>
                        </Checkbox>
                      </Form.Item>

                      <div className="btn_wrapper">
                        <button
                          type="primary"
                          onClick={() => {
                            updatePassengersData();
                          }}
                          style={{ background: "linear-gradient(90deg, #09b0dd, #026ba5)" }}
                        >
                          Continue to Payment
                        </button>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default BusCheckout;

//#endregion
