import React, { useState } from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import { Link } from "react-feather";
import "./About.scss";
import right from "../../assets/images/pop-up-img.png";
import logo1 from "../../assets/images/inner-bg.jpg";
import bgImg from "../../assets/images/abt-us.jpg"
import Nav1 from "../../common/navbar/Nav1";
import img1 from "../../assets/images/holidays/cart-img.png";
import rep from "../../assets/images/rep-icon.svg";
import Animation from "../../components/AnimationBall/AnimationBalls";
import p1 from "../../assets/images/Flighticons/2.png";
import p2 from "../../assets/images/Flighticons/6.png";
import p3 from "../../assets/images/Flighticons/5.png";
import p4 from "../../assets/images/Flighticons/1.png";
const AboutUs = () => {
  // return <CmsPages cmsId={2} pageTitle="About Us" />;
  const [expanded, setExpanded] = useState("overview");

  const toggleSection = (section) => {
    setExpanded(expanded === section ? null : section);
  };

  return (
    <>
      <Nav1 />
      {/* <img src={bgImg} alt="Background" style={{ width: "100%", height:"420px" }} /> */}
      {/* <div className="login-header">
        <h3 style={{ color: "white", padding: "14px 36px" }}>About Outc</h3>
        <Link to="/">Home <img src={right} alt="right" style={{ width: "18px" }} /> Register</Link>
      </div> */}
      <div className="About-us">

        <div className="About-us-page">
          <div className="hdr">
            <h2>About Us</h2>
          </div>
          {/* <div className="container-box">
            <div className="col-md-8 col-xs-12 wb-padding textContainerStyle">
              <h2 className="hedng-txt">
                outc.in
              </h2>
              <p className="paragraph">
                Welcome to OutC, your one-stop destination for seamless travel planning! Powered by our parent company, <span style={{ color: "#bd0c21", fontWeight: "600" }}>i2Space Web Technologies Pvt. Ltd.</span>, we bring over a decade of expertise in creating world-class travel solutions. </p>
              <p>At OutC, we strive to make your travel experiences hassle-free and enjoyable. Whether you're booking flights, reserving comfortable hotels, or securing a seat on reliable buses, we've got you covered. Our platform is designed to deliver a smooth and intuitive experience for travelers, ensuring quick bookings, competitive pricing, and reliable service every time.

              </p>
            </div>
            <div className="col-md-4 col-xs-12 image">
              <img src={rep} alt="Etravos" className="img-responsive" />
            </div>

          </div> */}
          <div className="container-box">
            <div className="col-md-8 col-xs-12 wb-padding textContainerStyle">
              <h2 className="hedng-txt">
                outc.in
              </h2>
              <p className="paragraph">
                Welcome to OUTC powered by <span style={{ color: "#bd0c21", fontWeight: "600" }}>I2SPACE WEB TECHNOLOGIES PRIVATE LIMITED </span> , your one-stop destination for seamless travel planning. We are dedicated to making your journey effortless by providing the best options for buses, flights, and hotels all in one convenient online platform.
                {/* <br />Whether you’re planning a business trip, a weekend getaway, or a long-awaited vacation, we ensure you can book your complete travel itinerary with ease and confidence. */}
              </p>
              <p>At OutC, we strive to make your travel experiences hassle-free and enjoyable. Whether you're planning a business trip, a weekend getaway, booking flights, reserving comfortable hotels, or securing a seat on reliable buses, we've got you covered. Our platform is designed to deliver a smooth and intuitive experience for travelers, ensuring quick bookings, competitive pricing, and reliable service every time.

              </p>
            </div>
            <div className="col-md-4 col-xs-12 image">
              <img src={rep} alt="Etravos" className="img-responsive" />
            </div>

          </div>

          <div className="abts-cont">
            <Animation />
            {/* <div className="abots-container">


              <h1 className="about-title">Our Vision</h1>
              <p className="vision-text">
                OutC was founded with a vision to transform the way people travel. With innovation at its core, our platform empowers individuals, families, and businesses to explore the world without worry.
              </p>

              <p className="cta-text">
                <span>Start your journey with OutC today and experience travel like never before!</span>
              </p>

            </div> */}
            <div className="abots-container">


              <h1 className="about-title">Our Mission</h1>
              <p className="vision-text">
                Our mission is simple: to empower travelers with the tools and services needed to create memorable and hassle-free experiences. We strive to be your trusted partner in exploring the world, providing you with top-notch travel options that suit your budget and preferences.</p>

              <p className="cta-text">
                <span>Start your journey with OutC today and experience travel like never before!</span>
              </p>

            </div>
          </div>

          <div className="more-abt">
            <div className="pointabt">
              <h1 className="about-title">Why Choose Us?</h1>
              <ul className="why-choose-list">
                <li>
                  <img src={p4} width={"90px"} alt="" />
                  <p>
                    <strong>All-in-One Travel Portal:</strong>
                    Find flights, hotels, and buses in one place for a stress-free travel experience.
                  </p>
                </li>
                <li>
                  <img src={p3} width={"90px"} alt="" />
                  <p>
                    <strong>User-Centric Design:</strong>
                    Easy navigation and fast bookings with just a few clicks.
                  </p>
                </li>
                <li>
                  <img src={p2} width={"90px"} alt="" />
                  <p>
                    <strong>Powered by Expertise:</strong> Backed by i2Space, an ISO-certified company with over 280+ successful travel projects worldwide.
                  </p>
                </li>
                <li>
                  <img src={p1} width={"90px"} alt="" />
                  <p>
                    <strong>Commitment to Quality:</strong> We prioritize your satisfaction, offering top-notch service and a reliable platform.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div style={{ padding: "2rem" }}></div>
          <div className="company-info-container">
            <h1 className="company-title">i2Space Web Technologies Pvt. Ltd.</h1>
            <p className="subtitle">Leading Travel & Hospitality Solutions Provider</p>
            <div className="info-container">
              <div className="info-sec-img">
                <img src={img1} alt="outc" className="img-respe" width={"80%"} />
              </div>
              <div className="info-sec">
                <div className="info-section">
                  <div className="section-header" onClick={() => toggleSection("overview")}>
                    <h2>About Us</h2>
                    <span>{expanded === "overview" ? "-" : "+"}</span>
                  </div>
                  {expanded === "overview" && (
                    <p className="section-content">
                      i2Space Web Technologies Private Limited is an ISO 9001:2008
                      Certified leading software solution provider specializing in Travel
                      & Hospitality Domain Solutions. Established in 2010, headquartered
                      in Hyderabad, i2Space is the vision of self-trained entrepreneur
                      Bhargava who spotted immense opportunity in domestic and
                      international travel markets.
                    </p>
                  )}
                </div>

                <div className="info-section">
                  <div className="section-header" onClick={() => toggleSection("achievements")}>
                    <h2>Our Achievements</h2>
                    <span>{expanded === "achievements" ? "-" : "+"}</span>
                  </div>
                  {expanded === "achievements" && (
                    <p className="section-content">
                      Between 2010 and 2018, i2Space successfully executed over 280+ custom
                      Travel & Hospitality portals. These projects ranged from domestic to
                      international markets, including North America, Australia, Europe, and Africa.
                      Leveraging this experience, we developed eTravos—a cloud-based SaaS
                      product enabling custom travel portals in just 15 minutes. Over 200+
                      cloud sites have been executed!
                    </p>
                  )}
                </div>

                <div className="info-section">
                  <div className="section-header" onClick={() => toggleSection("diversification")}>
                    <h2>Our Diversification</h2>
                    <span>{expanded === "diversification" ? "-" : "+"}</span>
                  </div>
                  {expanded === "diversification" && (
                    <p className="section-content">
                      While Travel & Hospitality is our forte, we’ve expanded into
                      Web Development, API providers, and many more in to Travel services, working with leading
                      service providers from the India, Australia, and the Middle East. Our
                      expertise spans Microsoft Technologies, SAP, Java, Node,
                      Responsive Design, Open Source technologies, and App Development.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "2rem" }}></div>
          <div className="container-contact">
            <h2 className="playfair">Contact us to know more</h2>
          </div>
          <div style={{ padding: "2rem" }}></div>
          <div className="contct-abt-pg  mb-5" >
            <div className="img-pop">
              <img src={right} alt="" width={"80%"} />
            </div>
            <div className='sub-cont'>
              <h4 style={{ lineHeight: '1.5em' }}>
                Your Journey Starts with a Conversation ?<br />
                <span style={{ color: "grey" }}>Have Questions? Let’s Make Things Crystal Clear for You !</span>
              </h4>
              <p>
                Stuck in a maze of choices? Wondering if your travel dreams are even possible? <br />
                Fear not when you can have extraordinary ones! At OutC, we don’t just answer your questions—we unleash possibilities.<br /> Whether it’s a once-in-a-lifetime escape or the tiniest detail of your trip, we’re here to deliver clarity, excitement, and maybe even a touch of travel magic. Don’t let your doubts hold you back—reach out now and let’s turn your 'What ifs?' into ‘HECK YES!’ moments. Go ahead, challenge us—we’re ready to amaze you!
              </p>
              <p style={{ margin: "0", display: "flex", justifyContent: "flex-end" }}>
                <a href='/contact_us_form' className="btn btn-primary" >Contact Us Now</a>
              </p>
            </div>

            <p></p>
          </div>

        </div>
      </div >
    </>
  );
};

export default AboutUs;
