import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import "./DealsDetails.scss";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import ET from "../../../assets/images/Deals-details/ET.png";
import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";
import topFlightNames from "./../../flight-search/FlightsTopCities.json";
import AirlineData from "./../../flight-search/AirlineName.json";
const DealsDetails = () => {
  let search = window.location.search;
  let tomorrowDate = moment().add(5, "days").format("YYYY-MM-DD");
  let urlParams = new URLSearchParams(search);
  let region = urlParams?.get("region").toString();
  let country = urlParams?.get("country").toString();

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    getRoutes(country);
  }, []);

  const getAirlineNamesFromCode = (code) => {
    let result = code;
    AirlineData.forEach((x) => {
      if (x.codeIataAirline == code) {
        result = x.nameAirline;

        return;
      }
    });

    return result;
  };

  const getFlighNameFromCode = (code) => {
    let result = code;

    topFlightNames.forEach((x) => {
      if (x.codeIataAirport == code) {
        result = x.nameAirport;

        return;
      }
    });

    return result;
  };

  const getRoutes = async (country) => {
    const { data } = await ApiClient.get("admin/maps/list", {
      perPage: 10,
      pageNumber: 1,
      Country: country,
      region: region,
    });
    // const maped_data = data.map((i) => {
    //   return {
    //     ...i,
    //     sourceName: getFlighNameFromCode(i.Source),
    //     destinationName: getFlighNameFromCode(i.Destination),
    //   };
    // });
    setRoutes(data);
  };

  return (
    <div className="flight-deals-details">
      {/* <h6>R u in Deals Details pageeeeee</h6> */}
      <div className="deals-details-wrapper">
        <div className="container">
          <div className="bread-crumb-block">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Outc</Link>
              </li>
              <li>
                <Link to="/">Deals</Link>
              </li>
            </ul>
          </div>
          <div className="main-text">
            <h2>Plan Your Travel now, fly later!</h2>
          </div>
        </div>
      </div>
      <div className="deals-details-content-wrapper">
        <div className="container">
          <div className="looking-flights">
            <h3>Looking for Cheap Flights to {country} ?</h3>
            <div>
              <ANTD.Card bordered={false}>
                <p>
                  Book your {country} flights to cross off another trip on your
                  bucket list. You’ve always dreamt of jet setting off to this
                  hot spot in Africa, so now’s the time to put your dreams into
                  motion. Pack your itinerary with all the top attractions, and
                  don’t forget to inquire about the country’s must-see hidden
                  treasures. Whether you’re taking the trip solo or inviting the
                  whole family along, you’re certain to create ever-lasting
                  memories in this country.
                </p>
              </ANTD.Card>
            </div>
          </div>
          <div className="popular-flights">
            <div className="popular-text">
              <h3>Popular Flights to {country}</h3>
              <div>
                <p>
                  Take a look at the top flights to {country} from {region}.
                  Booking with Outc.in makes it easy to find the top airlines
                  serving airports in {country}. Stop your Google search, the
                  following selection of well traveled and popular routes will
                  give you some good ideas of where to go on your next trip.
                </p>
              </div>
            </div>
            <div className="popular-cards">
              <ANTD.Row gutter={16}>
                {routes.map((route, index) => {
                  return (
                    <ANTD.Col key={`flg${index}`} md={12} xs={24}>
                      <div>
                        <ANTD.Card bordered={false}>
                          <ul className="flights-travel-list">
                            <Link
                              to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${route.Source}&destination=${route.Destination}`}
                            >
                              <li>
                                <a>{`${route.SourceName} to ${route.DestinationName} (${route.Source} to ${route.Destination})`}</a>

                                <p>
                                  <RightOutlined />
                                </p>
                              </li>
                            </Link>
                          </ul>
                        </ANTD.Card>
                      </div>
                    </ANTD.Col>
                  );
                })}
              </ANTD.Row>
            </div>
          </div>
          <div className="popular-flights">
            <div className="popular-text">
              <h3>Airlines in {country}</h3>
              <div>
                <p>
                  outc.in has easy to use booking services that let you browse
                  major airlines flying to and from Africa airports. Serving
                  Africa airports with direct and indirect to international and
                  domestic destinations, you are sure to find just the itinerary
                  you are looking for.
                </p>
              </div>
            </div>
            <div className="popular-cards">
              <ANTD.Row>
                {routes.map((route, index) => {
                  return (
                    <ANTD.Col key={`air${index}`} lg={12} md={12} xs={24}>
                      <div className="left-card">
                        <ANTD.Card bordered={false}>
                          <ul className="flights-travel-list">
                            <li className="list-with-img">
                              <div className="imageItem">
                                <img
                                  src={
                                    "https://d2mccptxtk231d.cloudfront.net/1.0.1005/resources/assets/scss/skin/img/airline-master/" +
                                    route.AirLine +
                                    ".png"
                                  }
                                  alt="KL"
                                />
                              </div>

                              <ANTD.Button className="airline-btn">
                                {"Fly " + route.AirLineName}
                              </ANTD.Button>
                            </li>
                          </ul>
                        </ANTD.Card>
                      </div>
                    </ANTD.Col>
                  );
                })}
              </ANTD.Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsDetails;
