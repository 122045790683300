// import React, { useContext, useState, useEffect, useReducer } from "react";
// import { Card, Col, Form, Row, Input, message, Tooltip, } from "antd";
// import { useHistory, Link } from "react-router-dom";
// import admeal from "../../assets/images/Flighticons/icons8-food-50.png";
// import adbag from "../../assets/images/Flighticons/bagg1.png";
// import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
// import { useAuthContext } from "../../common/providers/AuthProvider";
// import { useFlightContext } from "../../common/providers/Flights/FlightContext";
// import ApiClient from "../../helpers/ApiClient";
// import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
// import FlightDetailsCard from "../FlightsCheckout/Flight-Details-Card";
// import FareSummaryCard from "../FlightsCheckout/FareSummaryCard";
// import BookPayCard from "../FlightsCheckout/Book-Pay-Card";
// import FlightGrandTotalCard from "../FlightsCheckout/FlightGrandTotalCard";
// import { getFlightGrandTotalPrice } from "../FlightsCheckout/flightPriceHelper";
// import "./Flights-review.scss";
// import CashFreeNewVersionCheckout from "../../helpers/CashFreeNewVersionCheckout"
// import {
//   ArrowLeftOutlined, ShoppingOutlined,
// } from "@ant-design/icons";
// import moment from "moment";
// import PaymentComponent from "../../helpers/CCavanuePayCheckout";
// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
// const PGTYPE = "PGTYPE";
// const PGDISPLAY = "PGDISPLAY";
// const PGDATA = "PGDATA";

// const dateFormat = "DD-MM-YYYY";
// const oriDateFormat = "YYYY-MM-DD";

// const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

// function reducer(state, action) {
//   switch (action.type) {
//     case PGTYPE:
//       return { ...state, pgType: action.payload };
//     case PGDISPLAY:
//       return { ...state, pgDisplay: action.payload };
//     case PGDATA:
//       return { ...state, pgData: action.payload };
//     default:
//       return state;
//   }
// }

// const Review = () => {
//   const {
//     state: {
//       otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount, mealData, baggageData },
//     },

//   } = useContext(GlobalStatesContext);
//   const {
//     user,
//     isLogin: { agent },
//   } = useAuthContext();

//   // const { activeCurrency,  } = useCurrencyContext()
//   const { activeCurrency, currencyValue } = useCurrencyContext();

//   const {
//     updateAirBookRespState,
//     state: {
//       airBookStateObj,
//       flightAirPriceResp,
//       flightSearchObj,
//       selectedFlight,
//     },
//   } = useFlightContext();
//   const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
//   const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)
//   const [verifyModalVisible, setVerifyModalVisible] = useState(false);
//   const history = useHistory();
//   // const [pgDisplay, setPgDisplay] = useState(false);
//   // const [pgData, setPgData] = useState({});
//   // const [pgType, setPgType] = useState(-1);
//   const [isLoading, setIsLoading] = useState(false);
//   const [loadingSpin, setLoadingSpin] = useState(false);
//   useEffect(() => {
//     if (Object.keys(airBookStateObj).length <= 0) {
//       // history.push("/not-found");
//       console.log(Object.keys(airBookStateObj).length, airBookStateObj, updateAirBookRespState, "ddddd");
//       // alert.message("Please Retry Your Search");
//       // if (airBookStateObj?.message) message.error(resp.message, 3);
//       alert("Please Retry Your Search");
//     }
//   }, [airBookStateObj]);


//   const [pgData, setPgData] = useState({});
//   const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
//   const userReg = (name, email, mobile, code) => {
//     let regObj = {
//       Name: name,
//       Email: email,
//       DialingCode: code,
//       Mobile: mobile,
//       Password: "",
//       Role: 4,
//       DeviceToken: "string",
//       DeviceType: "Web",
//       FirBaseToken: "string",
//     };

//     ApiClient.post("admin/user/register", regObj)
//       .then(() => { })
//       .catch();
//   };

//   const ccavanueCheckoutData = (resp) => {
//     setPgData(resp)
//     setOpenCCavanueCheckout(true)
//   }

//   const fetchAirBook = (pgType = null) => {
//     if (!user) {
//       userReg(
//         airBookStateObj.passengers[0].firstName,
//         airBookStateObj.passengers[0].email,
//         airBookStateObj.passengers[0].mobile,
//         airBookStateObj.passengers[0].areaCode,
//       );
//     }

//     const allAmount = getFlightGrandTotalPrice(
//       flightAirPriceResp,
//       ConvFee,
//       promoData,
//       flightSearchObj,
//       selectedInsuranceData,
//       redeemAmount,
//       mealData,
//       baggageData
//     );

//     airBookStateObj.promoData = promoData.Code ?? "";
//     airBookStateObj.convienenceId = ConvFee.id ?? 0;
//     airBookStateObj.pgType = pgType ? pgType : pgDetails.pgType;
//     airBookStateObj.totalPrice = Number(allAmount.grandTotal);
//     airBookStateObj.isCouponReedem = redeemAmount.status ?? false;
//     airBookStateObj.currency = activeCurrency || "INR"
//     console.log("act", airBookStateObj)
//     // return;
//     setLoadingSpin(true);
//     setIsLoading(true);
//     ApiClient.post("flights/airBlock", airBookStateObj)
//       .then((resp) => {
//         return resp;
//       })
//       .then((resp) => {
//         setIsLoading(false);
//         console.log(resp, "resp");
//         setLoadingSpin(false);

//         if (resp.statusCode === 200) {
//           updateAirBookRespState(resp);
//           console.log("block", resp?.data)
//           // if (resp?.data?.BookingStatus === "Blocked") {
//           if (resp?.data?.pgType == 1) {
//             ccavanueCheckoutData(resp.data)
//           } else if (resp?.data?.pgType == 2) {
//             window.location.href = resp?.data?.payment_link;
//           } else {
//             window.location.href = resp?.data?.url;
//           }

//           // }
//           // }
//           // processPayment(resp.data.BookingRefNo, allAmount);

//           // } else {
//           //   console.log("airbook feching failed");
//           // }
//         }
//         // else {
//         //   alert(resp?.message);
//         // }
//         else {
//           if (resp?.message) message.error(resp.message, 3);
//           else if (resp?.data[0]?.errorDetail)
//             message.error(resp?.data[0]?.errorDetail, 3);
//           else message.error("Failed", 3);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//         // alert(err);
//         setLoadingSpin(false);
//         setIsLoading(false);
//       });
//   };
//   const CashFreeNewVersionCheckoutData = (resp) => {
//     setPgData(resp)
//     setOpenCashfreeNewVersionCheckout(true)
//   }
//   const processPayGateway = () => {
//     dispatchPgDetails({ type: PGDISPLAY, payload: true });
//   };

//   const blockApiReq = (pgType) => {
//     dispatchPgDetails({ type: PGTYPE, payload: pgType });
//     if (pgType) {
//       // if (agent) {
//       fetchAirBook(pgType);
//       // } else {
//       //   ValidateBookingLimit();
//       // }
//     }
//   };

//   // const processPayment = (oid, allAmount) => {
//   //   const userDetails = {
//   //     ...airBookStateObj.passengers[0],
//   //   };

//   //   let {
//   //     grandTotal,
//   //     commission,
//   //     convamount,
//   //     markup,
//   //     agentMarkup,
//   //     adminCommission,
//   //   } = allAmount;
//   //   // this is payment data

//   //   let agentMarkupAmount = 0;
//   //   let commissionAmount = 0;
//   //   let adminCommissionAmount = 0;
//   //   if (user?.Role?.RoleId === 5) {
//   //     commissionAmount = Number(commission);
//   //     agentMarkupAmount = Number(agentMarkup);
//   //     adminCommissionAmount = Number(adminCommission);
//   //   }

//   //   let data = {
//   //     OrderId: oid,
//   //     OrderAmount: Number(grandTotal),
//   //     OrderCurrency: "INR",
//   //     OrderNote: "Static Note",
//   //     CustomerName: userDetails.firstName + " " + userDetails.lastName,
//   //     CustomerEmail: userDetails.email,
//   //     CustomerPhone: userDetails.mobile,
//   //     TDS: 0,
//   //     GST: 0,
//   //     Commission: commissionAmount, // for agent only
//   //     AdminMarkup: markup,
//   //     AgentMarkup: agentMarkupAmount, // for agent only
//   //     Conveniencefee: Number(convamount),
//   //     AdminCommission: adminCommissionAmount, // for agent only
//   //   };
//   //   // set PgData and Display
//   //   setPgData(data);
//   //   setPgDisplay(true);
//   // };

//   const handleVerifyOtp = (otpValue) => {
//     ApiClient.post("admin/verifyserviceOtp", {
//       Mobile: airBookStateObj.passengers[0].mobile,
//       DialingCode: airBookStateObj.passengers[0].areaCode,
//       Otp: Number(otpValue),
//     })
//       .then((res) => {
//         if (res?.status === 200) {
//           setVerifyModalVisible(false);
//           fetchAirBook();
//         } else {
//           if (res?.message) message.error(res.message, 3);
//           else message.error("Booking Failed", 3);
//         }
//       })
//       .catch();
//   };

//   const handleSendOTP = () => {
//     ApiClient.post("admin/sendserviceOtp", {
//       Mobile: airBookStateObj.passengers[0].mobile,
//       DialingCode: airBookStateObj.passengers[0].areaCode,
//     })
//       .then((res) => {
//         if (res?.status === 200) {
//           setVerifyModalVisible(true);
//         } else {
//           if (res?.message) message.error(res.message, 3);
//           else message.error("Booking Failed", 3);
//         }
//       })
//       .catch();
//   };
//   const ValidateBookingLimit = () => {
//     const { grandTotal } = getFlightGrandTotalPrice(
//       flightAirPriceResp,
//       ConvFee,
//       promoData,
//       flightSearchObj,
//       selectedInsuranceData,
//       redeemAmount
//     );
//     ApiClient.post("admin/validatebookinglimit", {
//       serviceType: 1,
//       roleType: user?.Role?.RoleId ?? 4,
//       bookingAmount: grandTotal ? grandTotal : 0,
//     })
//       .then((res) => {
//         if (res?.status === 200 && res?.isValid) {
//           handleSendOTP();
//         } else {
//           if (res?.message) message.error(res.message, 3);
//           else message.error("Booking Failed", 3);
//         }
//       })
//       .catch();
//   };
//   const luggageDetails = (data) => {
//     return (
//       <div className="pop-ssr">
//         <Row gutter={16}>
//           {data?.baggagePref?.map((baggage, i) => {
//             return (
//               <>
//                 {
//                   baggage.map((bags, j) => {
//                     return (
//                       <>
//                         {bags.map((bag, k) => {
//                           return (
//                             <div className="pop-sel">
//                               <Col className="ssr-col">
//                                 <div className="ssr-city">{bag.cityPair}</div>
//                                 <div>{bag.baggWeight} {'Kg'}</div>

//                               </Col>
//                             </div>);

//                         })}
//                       </>)
//                   })}
//               </>
//             );
//           })}
//           {/* {console.log(data, "ddd")} */}
//         </Row>
//       </div>
//     );
//   };
//   const mealsDetails = (data) => {
//     return (
//       <div className="pop-ssr">
//         <Row gutter={16}>
//           {data?.mealPref?.map((meals, i) => {
//             return (
//               <>
//                 {
//                   meals.map((meal, j) => {
//                     return (
//                       <>
//                         {meal.map((m, k) => {
//                           return (
//                             <div className="pop-sel">
//                               <Col className="ssr-col">
//                                 <div className="ssr-city">{m.cityPair}</div>
//                                 <div>{m.mealCode}</div>
//                                 {/* {console.log(bag, "hello")} */}

//                               </Col>
//                             </div>);

//                         })}
//                       </>)
//                   })}
//               </>
//             );
//           })}
//           {/* {console.log(data, "ddd")} */}
//         </Row>
//       </div>
//     );
//   };

//   return (
//     <div>
//       <div style={{ paddingTop: "100px" }} className="checkout-heading">
//         <div fluid className="checkout-heading-container">
//           <div className="goback">
//             <Link
//               onClick={() => {
//                 history.go(-1);
//               }}
//             >
//               <ArrowLeftOutlined />
//               <span>Go back and Modify the Data</span>
//             </Link>
//           </div>
//           <h3>Verify out the form below and book your flight now!</h3>
//         </div>
//       </div>
//       <section className="checkout-body-sec">
//         <div className="checkout-container flights-poins-sec review-container">
//           <Row gutter={[16, 16]} className="flight-review-row">
//             <Col className="checkout-bottom-part">
//               <FlightDetailsCard
//                 selectedFlight={selectedFlight}
//                 flightSearchObj={flightSearchObj}
//               />
//               <Card style={{ marginTop: "30px" }} className="flight-cards-details">
//                 <div className="inner-part-pass1">
//                   <p className="heding1">Passengers</p>

//                   {!airBookStateObj.passengers
//                     ? null
//                     : airBookStateObj.passengers.length > 0 && (
//                       <Form
//                         layout="vertical"
//                         className="passenger-form user-details "
//                       >
//                         {airBookStateObj.passengers.map((paxData) => (
//                           <Row gutter={[16, 8]}>
//                             <div className="pax-heading">
//                               {paxData.paxType === "ADT"
//                                 ? "Adult"
//                                 : paxData.paxType === "CHD"
//                                   ? "Child"
//                                   : "Infant"}
//                             </div>
//                             {console.log(paxData, "paxdata")}
//                             <Col md={4} sm={8} xs={24}>
//                               <Form.Item label="Title">
//                                 <Input value={paxData.title} readOnly />
//                               </Form.Item>
//                             </Col>
//                             <Col md={8} sm={8} xs={24}>
//                               <Form.Item label="First Name">
//                                 <Input value={paxData.firstName} readOnly />
//                               </Form.Item>
//                             </Col>
//                             <Col md={6} sm={8} xs={24}>
//                               <Form.Item label="Last Name">
//                                 <Input value={paxData.lastName} readOnly />
//                               </Form.Item>
//                             </Col>

//                             {paxData.hasOwnProperty("dob") && (
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="Date of birth">
//                                   <Input
//                                     value={moment(
//                                       paxData.dob,
//                                       oriDateFormat
//                                     ).format(dateFormat)}
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                             )}
//                             {paxData.hasOwnProperty("passengerNationality") && (
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="Nationality">
//                                   <Input
//                                     value={paxData.passengerNationality}
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                             )}
//                             {paxData.hasOwnProperty("passportNumber") && (
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item label="Passport Number">
//                                   <Input
//                                     value={paxData.passportNumber}
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                             )}
//                             {paxData.hasOwnProperty("passportDOI") && (
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item
//                                   label="Passport DOI"
//                                   className="passport-dates"
//                                 >
//                                   <Input
//                                     value={moment(
//                                       paxData.passportDOI,
//                                       oriDateFormat
//                                     ).format(dateFormat)}
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                             )}
//                             {paxData.hasOwnProperty("passportDOE") && (
//                               <Col md={6} sm={8} xs={24}>
//                                 <Form.Item
//                                   label="Passport DOE"
//                                   className="passport-dates"
//                                 >
//                                   <Input
//                                     value={moment(
//                                       paxData.passportDOE,
//                                       oriDateFormat
//                                     ).format(dateFormat)}
//                                     readOnly
//                                   />
//                                 </Form.Item>
//                               </Col>
//                             )}
//                             {paxData.hasOwnProperty(
//                               "passportIssuedCountry"
//                             ) && (
//                                 <Col md={6} sm={8} xs={24}>
//                                   <Form.Item label="Passport Issued By">
//                                     <Input
//                                       value={paxData.passportIssuedCountry}
//                                       readOnly
//                                     />
//                                   </Form.Item>
//                                 </Col>
//                               )}
//                             <Col md={6} sm={8} xs={24}>
//                               <div className="ssr-rev-1">
//                                 <div className="ssr-label">Extra Services</div>
//                                 <div className="ssr-rev">
//                                   <div className="ssr-m" >
//                                     <Tooltip placement="bottom" title={mealsDetails(paxData)}>
//                                       {/* <img src={admeal} alt="" /> */}
//                                       Meals
//                                     </Tooltip>
//                                   </div>
//                                   <div className="ssr-b">
//                                     <Tooltip placement="bottom" title={luggageDetails(paxData)}>
//                                       Baggage
//                                       {/* <ShoppingOutlined /> */}
//                                     </Tooltip>
//                                   </div>

//                                 </div>
//                               </div>
//                             </Col>

//                           </Row>
//                         ))}
//                       </Form>
//                     )}

//                 </div>
//               </Card>

//               {!airBookStateObj.gstDetails
//                 ? null
//                 : airBookStateObj.gstDetails.gstNumber && (
//                   <div className="user-details">
//                     <p className="heading">GST Details</p>
//                     <Card className="checkout-custom-card">
//                       <Form
//                         layout="vertical"
//                         initialValues={{ ...airBookStateObj.gstDetails }}
//                       >
//                         <Row gutter={[16, 8]}>
//                           <Col md={8} sm={8} xs={24}>
//                             <Form.Item
//                               label="Company Name"
//                               name="gstCompanyName"
//                             >
//                               <Input readOnly />
//                             </Form.Item>
//                           </Col>
//                           <Col md={8} sm={8} xs={24}>
//                             <Form.Item label="GST Number" name="gstNumber">
//                               <Input readOnly />
//                             </Form.Item>
//                           </Col>
//                           <Col md={8} sm={8} xs={24}>
//                             <Form.Item label="Contact Number" name="gstPhoneNo">
//                               <Input readOnly />
//                             </Form.Item>
//                           </Col>
//                           <Col md={8} sm={8} xs={24}>
//                             <Form.Item label="Company Email" name="gstEmailId">
//                               <Input readOnly />
//                             </Form.Item>
//                           </Col>
//                           <Col md={8} sm={8} xs={24}>
//                             <Form.Item
//                               label="Company Address"
//                               name="gstAddressLine1"
//                             >
//                               <Input readOnly />
//                             </Form.Item>
//                           </Col>
//                         </Row>
//                       </Form>
//                     </Card>
//                   </div>
//                 )}
//             </Col>
//             <Col className="checkout-top-part1">
//                {/* {console.log( flightAirPriceResp, "revie")}  */}
//               {Object.keys(flightAirPriceResp).length > 0 && (
//                 <div className="checkout-sticky-part">
//                   <p className="heading-3">Fare Details</p>
//                   {(flightAirPriceResp.flightDetails.length === 1 && flightAirPriceResp.mappingType?.toUpperCase() === "COMBINED") ?
//                     <>
//                       {flightAirPriceResp.flightDetails.map((flightDetObj, i) => (
//                         <FareSummaryCard
//                           flightDetObj={flightDetObj}
//                           flightSearchObj={flightSearchObj}
//                           currency={flightDetObj.fareFamilies[0].currency}
//                           title={i === 0 ? "Trip Fare" : "Fare"}
//                           index={i}
//                         // mealdetails={mealData}
//                         // baggagedetails={baggageData}
//                         />
//                       ))} </> :
//                     <>
//                       {flightAirPriceResp.flightDetails.map((flightDetObj, i) => (
//                         <FareSummaryCard
//                           flightDetObj={flightDetObj}
//                           flightSearchObj={flightSearchObj}
//                           currency={flightDetObj.fareFamilies[0].currency}
//                           title={i === 0 ? "Onward Trip Fare" : "Return Trip Fare"}
//                           index={i}
//                         // mealdetails={mealData}
//                         // baggagedetails={baggageData}
//                         />
//                       ))} </>
//                   }



//                   {flightAirPriceResp?.flightDetails?.length > 0 ? (
//                     <div className="grand-total-card">
//                       <FlightGrandTotalCard
//                         airPriceResp={flightAirPriceResp}
//                         PromoNotVisible={true}
//                         flightSearchObj={flightSearchObj}
//                         mealdetails={mealData}
//                         baggagedetails={baggageData}

//                       />
//                     </div>
//                   ) : null}

//                   <BookPayCard
//                     isLoading={false}
//                     bookpaycardinfo={"flight-review"}
//                     // fetchAirBook={agent ? fetchAirBook : ValidateBookingLimit}
//                     pgDisplay={pgDetails.pgDisplay}
//                     pgData={pgDetails.pgData}
//                     processPayGateway={processPayGateway}
//                     blockApiReq={blockApiReq}
//                     loadingSpin={loadingSpin}
//                   />
//                   {
//                     openCCavanueCheckout && (
//                       <PaymentComponent
//                         pgData={pgData}

//                       />
//                     )
//                   }


//                 </div>
//               )}
//             </Col>
//           </Row>
//           {
//             openCashfreeNewVersionCheckout && (
//               <CashFreeNewVersionCheckout
//                 pgData={pgData}
//               // loadingSpin={loadingSpin}
//               />
//             )
//           }
//         </div>
//         {verifyModalVisible && (
//           <VerifyOtpModal
//             visible={verifyModalVisible}
//             setVerifyModalVisible={setVerifyModalVisible}
//             handleVerifyOtp={handleVerifyOtp}
//             handleResendOtp={handleSendOTP}
//             mobile={airBookStateObj.passengers[0].mobile}
//           />
//         )}

//       </section>
//     </div>
//   );
// };

// export default Review;

import React, { useContext, useState, useEffect, useReducer } from "react";
import { Card, Col, Form, Row, Input, message, Tooltip, Statistic, Modal, } from "antd";
import { useHistory, Link } from "react-router-dom";
import admeal from "../../assets/images/Flighticons/icons8-food-50.png";
import adbag from "../../assets/images/Flighticons/bagg1.png";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import ApiClient from "../../helpers/ApiClient";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
import FlightDetailsCard from "../FlightsCheckout/Flight-Details-Card";
import FareSummaryCard from "../FlightsCheckout/FareSummaryCard";
import BookPayCard from "../FlightsCheckout/Book-Pay-Card";
import FlightGrandTotalCard from "../FlightsCheckout/FlightGrandTotalCard";
import { getFlightGrandTotalPrice } from "../FlightsCheckout/flightPriceHelper";
import "./Flights-review.scss";
import CashFreeNewVersionCheckout from "../../helpers/CashFreeNewVersionCheckout"
import {
  ArrowLeftOutlined, ShoppingOutlined, ClockCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import PaymentComponent from "../../helpers/CCavanuePayCheckout";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import Nav1 from "../../common/navbar/Nav1";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const { Countdown } = Statistic;

const Review = () => {
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount, mealData, baggageData, seatData },
    },

  } = useContext(GlobalStatesContext);
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();

  // const { activeCurrency,  } = useCurrencyContext()
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();

  const {
    updateAirBookRespState,
    state: {
      airBookStateObj,
      flightAirPriceResp,
      flightSearchObj,
      selectedFlight,
      sessiontimeout, status,
    },

  } = useFlightContext();

  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const history = useHistory();
  // const [pgDisplay, setPgDisplay] = useState(false);
  // const [pgData, setPgData] = useState({});
  // const [pgType, setPgType] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  useEffect(() => {
    if (Object.keys(airBookStateObj).length <= 0) {
      // history.push("/not-found");
      // console.log(Object.keys(airBookStateObj).length, airBookStateObj, updateAirBookRespState, "ddddd");
      // alert.message("Please Retry Your Search");
      // if (airBookStateObj?.message) message.error(resp.message, 3);
      alert("Please Retry Your Search");
    }
  }, [airBookStateObj]);
  // useEffect(() => { getsessiontimeout() }, []);

  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
        // { console.log(resp, "currency12") }
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          // if (window.location.search !== "?error=Currency%20Error")
          //   window.location.href = "/error?error=Currency Error";
        }
      })
      .catch((e) => {
        // if (window.location.search !== "?error=Currency%20Error")
        //   window.location.href = "/error?error=Currency Error";
      });
  }, []);



  const [pgData, setPgData] = useState({});
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)
  // const userReg = (name, email, mobile, code) => {
  //   let regObj = {
  //     Name: name,
  //     Email: email,
  //     DialingCode: code,
  //     Mobile: mobile,
  //     Password: "",
  //     Role: 4,
  //     DeviceToken: "string",
  //     DeviceType: "Web",
  //     FirBaseToken: "string",
  //   };

  //   ApiClient.post("admin/user/register", regObj)
  //     .then(() => { })
  //     .catch();
  // };

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }

  const fetchAirBook = (pgType = null) => {
    // if (!user) {
    //   userReg(
    //     airBookStateObj.passengers[0].firstName,
    //     airBookStateObj.passengers[0].email,
    //     airBookStateObj.passengers[0].mobile,
    //     airBookStateObj.passengers[0].areaCode,
    //   );
    // }

    const allAmount = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount,
      mealData,
      baggageData,
      seatData,
    );

    airBookStateObj.promoData = promoData.Code ?? "";
    airBookStateObj.convienenceId = ConvFee.id ?? 0;
    airBookStateObj.pgType = pgType ? pgType : pgDetails.pgType;
    airBookStateObj.totalPrice = Number(allAmount.grandTotal);
    airBookStateObj.isCouponReedem = redeemAmount.status ?? false;
    airBookStateObj.currency = user?.Currency ? user?.Currency : activeCurrency || "INR";
    airBookStateObj.currencyRatio = user?.Role?.RoleId === 5 ? Number(currencies[user?.Currency]) : Number(currencyRatio);

    setLoadingSpin(true);
    setIsLoading(true);
    console.log(airBookStateObj, "airBookStateObj");
    ApiClient.post("flights/airBlock", airBookStateObj)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        setIsLoading(false);

        setLoadingSpin(false);

        if (resp.statusCode === 200) {
          updateAirBookRespState(resp);

          if (resp?.data?.BookingStatus === "Blocked") {
            CashFreeNewVersionCheckoutData(resp.data);
          }

           else {
            window.location.href = resp?.data?.url;
          }


        }

        else {
          if (resp?.message) message.error(resp.message, 3);
          else if (resp?.data[0]?.errorDetail)
            message.error(resp?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err);
        setLoadingSpin(false);
        setIsLoading(false);
      });
  };
  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }
  const processPayGateway = () => {
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      // if (agent) {
      fetchAirBook(pgType);
      // } else {
      //   ValidateBookingLimit();
      // }
    }
  };


  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          fetchAirBook();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const ValidateBookingLimit = () => {
    const { grandTotal } = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 1,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const luggageDetails = (data) => {
    return (
      <div className="pop-ssr">
        <Row gutter={16}>
          {data?.baggagePref?.map((baggage, i) => {
            return (
              <>
                {
                  baggage.map((bags, j) => {
                    return (
                      <>
                        {bags.map((bag, k) => {
                          return (
                            <div className="pop-sel">
                              <Col className="ssr-col">
                                <div className="ssr-city">{bag.cityPair}</div>
                                <div>{bag.baggWeight} {'Kg'}</div>

                              </Col>
                            </div>);

                        })}
                      </>)
                  })}
              </>
            );
          })}
          {/* {console.log(data, "ddd")} */}
        </Row>
      </div>
    );
  };
  const seatDetails = (data) => {
    return (
      <div className="pop-ssr">
        <Row gutter={16}>
          {data?.seatPref?.map((baggage, i) => {
            return (
              <>
                {
                  baggage.map((bags, j) => {
                    return (
                      <>
                        {bags.map((bag, k) => {
                          return (
                            <div className="pop-sel">
                              <Col className="ssr-col">
                                <div className="ssr-city">{bag.cityPair}</div>
                                <div className="ssr-city">{bag.seatCode}</div>

                              </Col>
                            </div>);

                        })}
                      </>)
                  })}
              </>
            );
          })}
          {/* {console.log(data, "ddd")} */}
        </Row>
      </div>
    );
  };
  const mealsDetails = (data) => {
    return (
      <div className="pop-ssr">
        <Row gutter={16}>
          {data?.mealPref?.map((meals, i) => {
            return (
              <>
                {
                  meals.map((meal, j) => {
                    return (
                      <>
                        {meal.map((m, k) => {
                          return (
                            <div className="pop-sel">
                              <Col className="ssr-col">
                                <div className="ssr-city">{m.cityPair}</div>
                                <div>{m.mealCode}</div>
                                {/* {console.log(bag, "hello")} */}

                              </Col>
                            </div>);

                        })}
                      </>)
                  })}
              </>
            );
          })}
          {/* {console.log(data, "ddd")} */}
        </Row>
      </div>
    );
  };
  const handelCountdown = () => {

    Modal.warning({
      icon: <ClockCircleOutlined />,
      //title: "",
      content: (<div><h5>Your Session is Expired</h5><p>Click on "OK" to continue with New Search</p></div>),
      onOk() {

        let queryParams = (JSON.parse(localStorage.getItem('FlightSearchBar')));
        history.push("/flight/results?" + queryParams);
      },
    });
  };
  return (
    <>
    <Nav1/>
    <div>
      <div style={{ paddingTop: "100px" }} className="checkout-heading">
        <div fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Modify the Data</span>
            </Link>
          </div>
          <h3>Verify out the form below and book your flight now!</h3>
        </div>
      </div>
      {/* {status != 0 &&
        <div className="countdown3">
          <div className="countdown-main-box">
            <div className="countdown1">
              <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
            </div>
            <div className="countdown2">
              <Countdown
                className="busCountdown"
                onFinish={handelCountdown}
                value={sessiontimeout}
                format={"mm[ min]  ss[ sec]"}
              />
            </div>
          </div>
        </div>} */}
      <section className="checkout-body-sec" style={{marginBottom:"48px",marginTop:"48px"}}>
        <div className="checkout-container flights-poins-sec review-container">
          <Row gutter={[16, 16]} className="flight-review-row">
            <Col className="checkout-bottom-part">
              <FlightDetailsCard
                selectedFlight={selectedFlight}
                flightSearchObj={flightSearchObj}
              />
              <Card style={{ marginTop: "30px" }} className="flight-cards-details">
                <div className="inner-part-pass1">
                 <div style={{background:"#f9f9f9",padding:"13px"}}>
                  <p className="heding1">Passengers</p>
                  </div> 
                  {!airBookStateObj.passengers
                    ? null
                    : airBookStateObj.passengers.length > 0 && (
                      <Form
                        layout="vertical"
                        className="passenger-form user-details  custom-form"
                        style={{padding:13}}
                      >
                        {airBookStateObj.passengers.map((paxData) => (
                          <Row gutter={[16, 8]}>
                            <div className="pax-heading">
                              {paxData.paxType === "ADT"
                                ? "Adult"
                                : paxData.paxType === "CHD"
                                  ? "Child"
                                  : "Infant"}
                            </div>
                            {/* {console.log(paxData, "paxdata")} */}
                            <Col md={4} sm={8} xs={24}>
                              <Form.Item label="Title">
                                <Input value={paxData.title} readOnly />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item label="First Name">
                                <Input value={paxData.firstName} readOnly />
                              </Form.Item>
                            </Col>
                            <Col md={6} sm={8} xs={24}>
                              <Form.Item label="Last Name">
                                <Input value={paxData.lastName} readOnly />
                              </Form.Item>
                            </Col>

                            {paxData.hasOwnProperty("dob") && (
                              <Col md={6} sm={8} xs={24}>
                                <Form.Item label="Date of birth">
                                  <Input
                                    value={moment(
                                      paxData.dob,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {paxData.hasOwnProperty("passengerNationality") && (
                              <Col md={6} sm={8} xs={24}>
                                <Form.Item label="Nationality">
                                  <Input
                                    value={paxData.passengerNationality}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {paxData.hasOwnProperty("passportNumber") && (
                              <Col md={6} sm={8} xs={24}>
                                <Form.Item label="Passport Number">
                                  <Input
                                    value={paxData.passportNumber}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {paxData.hasOwnProperty("passportDOI") && (
                              <Col md={6} sm={8} xs={24}>
                                <Form.Item
                                  label="Passport DOI"
                                  className="passport-dates"
                                >
                                  <Input
                                    value={moment(
                                      paxData.passportDOI,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {paxData.hasOwnProperty("passportDOE") && (
                              <Col md={6} sm={8} xs={24}>
                                <Form.Item
                                  label="Passport DOE"
                                  className="passport-dates"
                                >
                                  <Input
                                    value={moment(
                                      paxData.passportDOE,
                                      oriDateFormat
                                    ).format(dateFormat)}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {paxData.hasOwnProperty(
                              "passportIssuedCountry"
                            ) && (
                                <Col md={6} sm={8} xs={24}>
                                  <Form.Item label="Passport Issued By">
                                    <Input
                                      value={paxData.passportIssuedCountry}
                                      readOnly
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            <Col md={8} sm={8} xs={24}>
                              <div className="ssr-rev-1">
                                <div className="ssr-label">Extra Services</div>
                                <div className="ssr-rev">
                                  <div className="ssr-m" >
                                    <Tooltip placement="bottom" title={mealsDetails(paxData)}>
                                      {/* <img src={admeal} alt="" /> */}
                                      Meals
                                    </Tooltip>
                                  </div>
                                  <div className="ssr-b">
                                    <Tooltip placement="bottom" title={luggageDetails(paxData)}>
                                      Baggage
                                      {/* <ShoppingOutlined /> */}
                                    </Tooltip>
                                  </div>
                                  <div className="ssr-b">
                                    <Tooltip placement="bottom" title={seatDetails(paxData)}>
                                      seat
                                      {/* <ShoppingOutlined /> */}
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        ))}
                      </Form>
                    )}

                </div>
              </Card>

              {!airBookStateObj.gstDetails
                ? null
                : airBookStateObj.gstDetails.gstNumber && (
                  <div className="user-details">
                    <p className="heading">GST Details</p>
                    <Card className="checkout-custom-card">
                      <Form
                        layout="vertical"
                        initialValues={{ ...airBookStateObj.gstDetails }}
                      >
                        <Row gutter={[16, 8]}>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Name"
                              name="gstCompanyName"
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="GST Number" name="gstNumber">
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="Contact Number" name="gstPhoneNo">
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="Company Email" name="gstEmailId">
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Company Address"
                              name="gstAddressLine1"
                            >
                              <Input readOnly />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                  </div>
                )}
            </Col>
            <Col className="checkout-top-part1">
              {/* {console.log( flightAirPriceResp, "revie")}  */}
              {Object.keys(flightAirPriceResp).length > 0 && (
                <div className="checkout-sticky-part">
                  <p className="heading-3">Fare Details</p>
                  {(flightAirPriceResp.flightDetails.length === 1 || flightAirPriceResp.mappingType?.toUpperCase() === "COMBINED") ?
                    <>
                      {flightAirPriceResp?.searchRequest?.airTravelType === "multidestination" ? <>
                        {/* {airPriceResp.flightDetails.map((flightDetObj, i) => ( */}
                        <FareSummaryCard
                          flightDetObj={flightAirPriceResp.flightDetails[0]}
                          flightSearchObj={flightSearchObj}
                          // mealdetails={mealPlanPaxArr}
                          // baggagedetails={baggagePlanArr}
                          title={

                            "Trip Fare"

                          }
                          index={"0"}
                        />
                        {/* ))
                     } */}
                      </> : <>
                        {flightAirPriceResp.flightDetails.map((flightDetObj, i) => (
                          <FareSummaryCard
                            flightDetObj={flightDetObj}
                            flightSearchObj={flightSearchObj}
                            // mealdetails={mealPlanPaxArr}
                            // baggagedetails={baggagePlanArr}
                            title={
                              i === 0
                                ? "Trip Fare"
                                : "Fare"
                            }
                            index={i}
                          />
                        ))
                        }
                      </>
                      }

                    </> :
                    <>
                      {flightAirPriceResp.flightDetails.map((flightDetObj, i) => (
                        <FareSummaryCard
                          flightDetObj={flightDetObj}
                          flightSearchObj={flightSearchObj}
                          currency={flightDetObj.fareFamilies[0].currency}
                          title={i === 0 ? "Onward Trip Fare" : "Return Trip Fare"}
                          index={i}
                        // mealdetails={mealData}
                        // baggagedetails={baggageData}
                        />
                      ))} </>
                  }



                  {/* {flightAirPriceResp?.flightDetails?.length > 0 ? (
                    <div className="grand-total-card">
                      <FlightGrandTotalCard
                        airPriceResp={flightAirPriceResp}
                        PromoNotVisible={true}
                        flightSearchObj={flightSearchObj}
                        mealdetails={mealData}
                        baggagedetails={baggageData}
                        seatdetails={seatData}
                      />
                    </div>
                  ) : null}

                  <BookPayCard
                    isLoading={false}
                    bookpaycardinfo={"flight-review"}
                    // fetchAirBook={agent ? fetchAirBook : ValidateBookingLimit}
                    pgDisplay={pgDetails.pgDisplay}
                    pgData={pgDetails.pgData}
                    processPayGateway={processPayGateway}
                    blockApiReq={blockApiReq}
                    loadingSpin={loadingSpin}
                  />
                  {
                    openCCavanueCheckout && (
                      <PaymentComponent
                        pgData={pgData}

                      />
                    )
                  } */}


                </div>
              )}
               {flightAirPriceResp?.flightDetails?.length > 0 ? (
                    <div className="grand-total-card">
                      <FlightGrandTotalCard
                        airPriceResp={flightAirPriceResp}
                        PromoNotVisible={true}
                        flightSearchObj={flightSearchObj}
                        mealdetails={mealData}
                        baggagedetails={baggageData}
                        seatdetails={seatData}
                      />
                    </div>
                  ) : null}

                  <BookPayCard
                    isLoading={false}
                    bookpaycardinfo={"flight-review"}
                    // fetchAirBook={agent ? fetchAirBook : ValidateBookingLimit}
                    pgDisplay={pgDetails.pgDisplay}
                    pgData={pgDetails.pgData}
                    processPayGateway={processPayGateway}
                    blockApiReq={blockApiReq}
                    loadingSpin={loadingSpin}
                  />
                  {
                    openCCavanueCheckout && (
                      <PaymentComponent
                        pgData={pgData}

                      />
                    )
                  }
            </Col>
          </Row>
          {
            openCashfreeNewVersionCheckout && (
              <CashFreeNewVersionCheckout
                pgData={pgData}
              loadingSpin={loadingSpin}
              />
            )
          }
        </div>
        {verifyModalVisible && (
          <VerifyOtpModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyOtp={handleVerifyOtp}
            handleResendOtp={handleSendOTP}
            mobile={airBookStateObj.passengers[0].mobile}
          />
        )}

      </section>
    </div>
    </>
  );
};

export default Review;

