import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import Nav1 from "../../common/navbar/Nav1";

const Privacypolicy = () => {
  return (
    <>
    <Nav1/>
  <CmsPages cmsId={6} pageTitle="Privacy Policy" />
  </>
);
};

export default Privacypolicy;
