import React from "react";

import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSytContext } from "../providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import "../footer/Footer.scss";
import { useAuthContext } from "../../common/providers/AuthProvider";
import log1 from "../../assets/images/logo-bg.png";

const Footer = () => {
  const { siteDetails } = useSytContext();
  const {
    isLogin: { flag, agent, isGuest },
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();
  let services = user?.Services ? user?.Services : [];

  return (
    <div id="footer">
      <div className="footer_links_wrapper e-hide">
        <div className="links_wrapper">
          <Row>
            <Col md={24}>
              <div className="social_links">
                <a href="#!">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-google"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col className="d-none" md={8}>
              <div className="social_fb_text">
                <Button variant="primary" size="sm">
                  <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                  Like
                </Button>
                <Button variant="primary" size="sm">
                  Share
                </Button>
                <small>
                  721k peope like this.<a href="#!">Sign Up</a>to see what your
                  friends like.
                </small>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer_bottom_section bottom-postion-ft">
        <div className="bg-ft-col-22">
          <Row className="add-logos-ft">
            <Col md={12} xs={24} className="ft-col-1">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1-tr">
                    Our<span> Happy Travellers</span>
                  </h3>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>25000+</h3>
                  <p>Happy Travellers</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>5000+</h3>
                  <p>Memorable Tours</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100+</h3>
                  <p>Awards</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100%</h3>
                  <p>Reliable</p>
                </Col>
              </Row>
            </Col>
            <Col md={12} xs={24} className="ft-col-2">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1">
                    Our <span>Affiliations</span>
                  </h3>
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-7.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-8.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-9.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-10.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-11.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-12.png").default}
                    alt=""
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer_social_info e-hide">
          <div className="antd-container">
            <ul>
              <li>
                <i className="fa fa-birthday-cake cake-icon"></i>
                <p>
                  <strong> 15 years</strong>
                  <br />
                  of experience
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>50</strong>
                  <br />
                  countries
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong> 150 M</strong>
                  <br />
                  customers
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>950</strong>
                  <br />
                  airlines
                </p>
              </li>
              <li>
                <i className="hotels-icon">H</i>
                <p>
                  <strong>1.3 M</strong>
                  <br />
                  hotels
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom_countries countries-top">
          <div className="antd-container">
            <div className="countries-top-section">
              <div className="countries_links">
                <div className="footr-inter-sites">
                  <Row>
                    <Col md={6} xs={24}>
                      {/* <p className="hdr-foot">Contact Us</p> */}
                      <img src={log1} alt="" width={"80px"} style={{borderRadius:"12px"}}/>
                      <ul className="cms-pages-links">
                        <li>

                          {/* <span className="supp-1">Customer Care (24x7)
                          </span>

                          {/* <p className="supp">  <i className="fa fa-phone">  </i> +971 (0) 56 819 9992  </p>
                          <p className="supp">  <i className="fa fa-phone">  </i> +971 (0) 54 569 9994 </p> */}
                          {/* <p className="supp">  <i className="fa fa-phone">  </i> IND +91 (0) 99999 99999</p> */}
                          {/* <p className="supp">  <i className="fa fa-phone">  </i> DXB +971 (0) 568199992</p> */}



                        </li>
                        <li>
                          <span className="supp-1">Need Help </span>
                          <p className="supp" style={{paddingTop:"4px"}}>  <i className="fa fa-envelope-o">  </i>outcinfo@gmail.com</p>
                          <p className="supp">  <i className="fa fa-envelope-o">  </i> info@i2space.in</p>
                        </li>

                      </ul>
                    </Col>

                    <Col md={4} xs={24}>
                      <p className="hdr-foot">Company</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/about-us">About Us </Link>
                        </li>

                        {/* <li>
                          <Link to="/ourteam">Our Team </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/testimonials">Testimonials </Link>
                        </li> */}



                        <li>
                          <Link to="/termsofconditions">
                            Terms of Conditions{" "}
                          </Link>
                        </li>

                        {/* <li>
                          <Link to="/privacypolicy">Privacy Policy </Link>
                        </li> */}

                        <li>
                          <Link to="/disclaimer">Disclaimer </Link>
                        </li>

                        {/* <li>
                          <Link to="/careers">Careers </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/Socialresponsibility">
                            Social Responsibility{" "}
                          </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/web-check-in">Check Flight Info </Link>
                        </li>

                        <li>
                          <Link to="/Refundpolicy">Refund Policy </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/Investorrelations">
                            Investor Relations{" "}
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="/web-check-in">Web Check In </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/flightenquiry">Flight Enquiry</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/contact_us_form">Contact Us</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/check-status">Check Status</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/bus-hire">Bus Hire</Link>
                        </li>
                        <li>
                          <Link to="/forex">Forex</Link>
                        </li>
                        <li>
                          <Link to="/car-hire">Car Hire</Link>
                        </li> */}
                      </ul>
                    </Col>

                    <Col md={4} xs={24}>
                      <p className="hdr-foot">Support</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/contact_us_form">Contact Us</Link>
                        </li>
                        {/* <li>
                          <Link to="/visa">Visa </Link>
                        </li> */}
                        {/* <li>
                          <Link to="/web-check-in">Check Flight Info </Link>
                        </li> */}

                        <li>
                          <Link to="/Refundpolicy">Refund Policy </Link>
                        </li>
                        {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                          <li>
                            <Link to="/web-check-in">Web Check In </Link>
                          </li> : null} */}

                        {/* <li>
                          <Link to="/Yacht">Yacht</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/activities">Forex</Link>
                        </li>
                        <li>
                          <Link to="/Helicopter">Helicopter</Link>
                        </li>
                        <li>
                          <Link to="/bus-hire">Bus Hire</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/car-hire">Cars Hire</Link>
                        </li> */}
                        {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                          <li>
                            <Link to="/flightGroupEnquiry">Flight Enquiry</Link>
                          </li> : null} */}
                        {/* 
                        <li>
                          <Link to="/blog">Blog </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/">Sitemap </Link>
                        </li> */}
                        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                          <li>
                            <Link to="/offers">Offers </Link>
                          </li> : null}
                        {/* <li>
                          <Link to="/faqs">Faqs </Link>
                        </li> */}
                      </ul>
                    </Col>

                    {/* <Col md={4} xs={24}>
                      <p>POPULAR AIRLINES</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/#">IndiGo Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">Air India Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">GoAir Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">Vistara Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">SpiceJet Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">Air Asia Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">British airways </Link>
                        </li>

                        <li>
                          <Link to="/#">Qatar airways </Link>
                        </li>

                        <li>
                          <Link to="/#">Singapore Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">Etihad airways </Link>
                        </li>

                        <li>
                          <Link to="/#">Thai airways </Link>
                        </li>

                        <li>
                          <Link to="/#">United Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">American Airlines </Link>
                        </li>

                        <li>
                          <Link to="/#">Malaysia Airlines </Link>
                        </li>
                      </ul>
                    </Col> */}
                    <Col md={5} xs={24}>
                      <p className="hdr-foot">Other Services</p>
                      <ul className="cms-pages-links">
                        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
                          <li>
                            <Link to="/">Flights</Link>
                          </li> : null}
                        <li>
                          <Link to="/buses">Bus</Link>
                        </li>
                        {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("7")) || agent === false) ?
                          <li>
                            <Link to="/cars">Cars</Link>
                          </li> : null} */}
                        {((user?.Role?.Name === "Agent" && user?.Services.includes("2")) || agent === false) ?
                          <li>
                            <Link to="/hotels">Hotels</Link>
                          </li> : null}

                        {/* <li>
                          <Link to="/holidays">Holidays</Link>
                        </li> */}

                        {/* <li>
                          <Link to="/check-status">Check Status</Link>
                        </li> */}

                        {/* <li>
                          <Link to="/insurance">Insurance</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/activities">Acitivities</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/buildyourpackage">Build a Package</Link>
                        </li> */}
                        {/* {((user?.Role?.Name === "Agent" && user?.Services.includes("8")) || agent === false) ?
                          <li>
                            <Link to="/visasearch">Visa</Link>
                          </li> : null} */}
                        {/* <li>
                          <Link to="/charterFlights">Charter Flights</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/forex">Forex</Link>
                        </li> */}

                        {/* <li>
                          <Link to="/user-registration">User Register </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/agent-registration">
                            Partner Register{" "}
                          </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/corporate-registration">
                            Corporate Register
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="/">Vendor Register</Link>
                        </li> */}
                      </ul>
                    </Col>

                    <Col md={5} xs={24} className="footer-flex">
                      <Row>
                        {/* <Col span={12}>
                          <p>PAYMENT & SECURITY</p>
                          <div className="pay-ment-img">
                            <ul className="footer-visa-images">
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-1.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-2.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-3.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-4.png")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                            </ul>

                            <p>FOLLOW US ON</p>
                            <div className="social_wrapper">
                              <div className="social_icons">
                                <a href="#" target="_blank">
                                  <FacebookOutlined />
                                </a>
                                <a href="#" target="_blank">
                                  <YoutubeOutlined />
                                </a>
                                <a href="#" target="_blank">
                                  <TwitterOutlined />
                                </a>
                                <a href="#" target="_blank">
                                  <InstagramOutlined />
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col> */}
                        <Col span={12}>
                          <div className="last-sec-down">
                            <p className="hdr-foot">Mobile App</p>
                            {/* <h5 className="your-trip-app">OutC.com App</h5>  */}

                            <span style={{ color: "grey", fontSize: "12px" }}>Coming Soon...</span>
                            <a
                              href="#"
                              className="pl_store-horizontal"
                              title="Google Play Store"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <img
                                className="g-store"
                                src={
                                  require("../../assets/images/playstore.png").default
                                }
                                alt=""
                              />
                            </a>
                            {/* <a
                              href="#"
                              className="app_store-horizontal"
                              title="App Store"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <img
                                className="a-store"
                                src={
                                  require("../../assets/images/applestore.png").default
                                }
                                alt=""
                              />
                            </a> */}

                            <div className="social_links-n">
                              <a href="#!">
                                <i className="fa fa-facebook"></i>
                              </a>
                              <a href="#!">
                                <i className="fa fa-google"></i>
                              </a>
                              <a href="#!">
                                <i className="fa fa-twitter"></i>
                              </a>
                              <a href="#!">
                                <i className="fa fa-instagram"></i>
                              </a>
                            </div>

                          </div>
                        </Col>
                      </Row>
                      {siteDetails?.BusinessAddress?.length > 0 && (
                        <div className="footer-address-cc">
                          <ul>
                            <li>
                              <i className="fa fa-map-marker"></i>
                              <p>
                                Address:{" "}
                                {siteDetails?.BusinessAddress[0]?.Address},
                                <br />
                                {siteDetails?.BusinessAddress[0]?.CityName},
                                {siteDetails?.BusinessAddress[0]?.CountryName},{" "}
                                {siteDetails?.BusinessAddress[0]?.PostalCode}
                              </p>
                            </li>
                            <li>
                              <i className="fa fa-phone"></i>
                              <p>Phone: 011-49511313/(+91-9999442804)</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <p>Email: contact@outc.com</p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="copyright">
              copyright &copy;
              outc.in
              {/* 2021 {AppConstants.DOMAIN_NAME} */}
              , All rights reserved.
            </div>
            {/* <div className="social_wrapper">
              <div className="social_icons">
                <a href={siteDetails.facebook} >
                  <FacebookOutlined />
                </a>
                <a href={siteDetails.youtube} >
                  <YoutubeOutlined />
                </a>
                <a href={siteDetails.twitter} >
                  <TwitterOutlined />
                </a>
                <a href={siteDetails.instagram} >
                  <InstagramOutlined />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
