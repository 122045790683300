import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";
import icon from "../../../assets/images/Icons/plane.png"
import icon1 from "../../../assets/images/mumbai.webp"
import icon2 from "../../../assets/images/delhi.png"
import icon3 from "../../../assets/images/nellore.png"
import icon4 from "../../../assets/images/chennai.png"
import icon5 from "../../../assets/images/hyderabad.png"
import icon6 from "../../../assets/images/bangalore.png"
import "./topDomesticRoutes.scss";


const { TabPane } = Tabs;

const TopBuses1 = ({ deals, type }) => {
    let tomorrowDate = moment().add(2, "days").format("DD-MM-YYYY");
    const flightData = [
      {
        city: "Hyderabad",
        cityCode: "2862",
        destinations: [
          { name: "Vijayawada", code: "8464" },
          { name: "Rayachoti", code:  "6871" },
          { name: "Bangalore", code: "702" },
          { name: "Nirmal", code: "5874"  }
        ],
        image: icon5,
      },
      {
        city: "Delhi",
        cityCode: "1780",
        destinations: [
          { name: "Ludhiana", code: "4588" },
          { name: "Ahmedabad", code: "81" },
          { name: "Jaipur", code: "2983" },
          { name: "Indore", code: "2898" }
        ],
        image: icon2, // Replace with correct image path
      },
      {
        city: "Bangalore",
        cityCode: "702", 
        destinations: [
          { name: "Chennai", code: "1445" },
          { name: "Hyderabad", code: "2862" },
          { name: "Yadgir", code: "8576" },
          { name: "Sangli", code: "7126" }
        ],
        image: icon6,
      },
      {
        city: "Chennai",
        cityCode: "1445",
        destinations: [
          { name: "Bangalore", code: "702" },
          { name: "Vijayawada", code: "8464" },
          { name: "Madurai", code: "4659" },
          { name: "Thuraiyur", code: "7996" }
        ],
        image: icon4, // Replace with correct image path
      },
      {
        city: "Mumbai",
        cityCode: "5335",
        destinations: [
          { name: "Nagpur", code: "5506" },
          { name: "Indore", code: "2898" },
          { name: "Aurangabad", code: "460" },
          { name: "Bangalore", code: "702" }
        ],
        image: icon1,
      },
  
      {
        city: "Nellore",
        cityCode: "5783",
        destinations: [
          { name: "Hyderabad", code: "2862" },
          { name: "Chennai", code: "1445" },
          { name: "Bangalore", code: "702" },
          { name: "Kadapa", code: "3244" },
          
        ],
        image: icon3, // Replace with correct image path
      },
  
  
      // {
      //   city: "Ahmedabad",
      //   cityCode: "AMD",
      //   destinations: [
      //     { name: "Mumbai", code: "BOM" },
      //     { name: "Delhi", code: "DEL" },
      //     { name: "Bangalore", code: "BLR" },
      //     { name: "Goa", code: "GOI" }
      //   ],
      //   image: icon6, // Replace with correct image path
      // },
    ];
    return (
      <div className="flight-routes-container">
        {flightData.map((route, index) => (
          <div key={index} className="flight-route-card">
            <div style={{ display: "flex", marginRight: 110, width: "100%" }}>
              <div className="image-container">
                <img src={route.image} alt={`${route.city} Flights`} />
              </div>
              <div style={{ alignContent: "center" }} className="flit-infoRts">
                <h3>{route.city} </h3>
                <p className="fly-txt-top">
                  <span style={{ color: "black" }}>To:</span>{" "}
                  {route.destinations.map((destination, i) => (
                    <Link style={{ paddingLeft: "4px" }} key={i}  to={`/bus/results?destinationId=${destination.code}&destinationName=${destination.name}&journeyDate=${tomorrowDate}&sourceId=${route.cityCode}&sourceName=${route.city}`}
                    >
                      {destination.name}
                      {i < route.destinations.length - 1 ? " • " : ""}
                    </Link>
                  ))}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default TopBuses1;