import React from 'react';
import * as ANTD from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import text from "../../../assets/images/Icons/bus-loc-5.png"

import './BusEnquiry.scss';

const features = [
    {
        title: "4000+ Bus Partners",
        description: "Ranging from State RTCs to private partners",
        icon: "trus-bus-tic.png",
        
    },
    {
        title: "Fastest Bus Booking",
        description: "Swift and seamless bus ticket booking experience",
        icon: "tatkal-icon.png",
    },
    {
        title: "24/7 Customer Support",
        description: "Available for all your bus booking needs",
        icon: "bus-loc-5.png", 
    },
    {
        title: "Instant Refunds",
        description: "With free cancellation when changing or cancelling",
        icon: "refund.png", 
    },
    {
        title: "Best Deals & Discounts",
        description: "Unlock best value with premium deals & discounts",
        icon: "pnr-no-results.png",
    },
];

const BusEnquiry = () => {
    return (
        <div className="features-container">
            <h2 className="features-heading">Why Choose Us for Bus Ticket Booking?</h2>
            <p className="features-subheading">
                We are India's fastest growing online ticket booking platform. We offer partnerships with over 4000+ private bus partners, covering more than 350,000 bus routes.
            </p>

            <ANTD.Carousel
                dots={false}
                autoplay
                slidesToShow={3}
                arrows
                prevArrow={<LeftCircleOutlined className="carousel-arrow" />}
                nextArrow={<RightCircleOutlined className="carousel-arrow" />}

            >
                {features.map((feature, index) => (
                    <div key={index} className="feature-card">
                        <div className="feature-cont-card">
                            <div className="icon-container">
                                <img src={require(`../../../assets/images/Icons/${feature.icon}`).default} alt={feature.title} className="feature-icon" />
                                {/* <img src={text} alt={feature.title} className="feature-icon" /> */}
                            </div>
                            <h3 className="feature-title">{feature.title}
                                <p className="feature-description">{feature.description}</p>
                            </h3>
                        </div>
                    </div>
                ))}
            </ANTD.Carousel>
        </div>
    );
};

export default BusEnquiry;
