import React, { useState, useEffect } from "react";
import "./PaymentSuccess.scss";
import moment from "moment";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import { getDateRange } from "../../helpers/activitiesHelper";
import ActivitiesSuccessSkeleton from "../../common/ActivitiesSuccessSkeleton/ActivitiesSuccessSkeleton";
import ActivitiesTicketSuccess from "../../components/TicketSuccessPages/ActivitiesTicketSuccess/ActivitiesTicketSuccess";
import Busticketsuccess from "../../components/TicketSuccessPages/Busticketsuccess/Busticketsuccess";
import BuildPackageTicketSuccess from "../../components/TicketSuccessPages/BuildPackageTicketSuccess/BuildPackageTicketSuccess";
import CarTransferTicketSuccess from "../../components/TicketSuccessPages/CarTicketSucess/CarTransferTick";
import Flightticketsuccess from "../../components/TicketSuccessPages/Flightticketsuccess/Flightticketsuccess";
import SuccessSkeleton from "../../common/SuccessSkeleton/SuccessSkeleton";
import HotelTicketSuccess from "../../components/TicketSuccessPages/HotelTicketSuccess/HotelTicketSuccess";
import CarTicketSuccess from "../../components/TicketSuccessPages/CarTicketSucess/CarTicketSucess";
import VisaTicketSuccess from "../../components/TicketSuccessPages/VisaTicketSucess/VisaTicketSucess";
import HolidayTicketSuccess from "../../components/TicketSuccessPages/HolidayTicketSuccess/HolidayTicketSuccess";
import CruiseTicketSuccess from "../../components/TicketSuccessPages/CruiseTicketSuccess/CruiseTicketSuccess";
import RBTicketSuccess from "../../components/TicketSuccessPages/RBTicketSuccess/RBTicketSuccess";
import CFTicketSuccess from "../../components/TicketSuccessPages/CFTicketSuccess/CFTicketSuccess";

const PaymentSucces = () => {
  console.log("iam comming");
  const [type, setType] = useState("");

  // const [buildTicketDetails, setbuildTicketDetails] = useState({
  //   bookingRefNo: "",
  //   message: "",
  //   hotelsPackageDetails: [],
  //   activitiesPackageDetails: [],
  //   tourDetails: [],
  //   request: {},
  //   loading: true,
  // });

  // const [activitiesTicketDetails, setActivitiesTicketDetails] = useState({
  //   message: "",
  //   TourDetails: [],
  //   Passengers: {},
  //   loading: true,
  // });

  const [flightTicketsDetails, setFlightTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    BookingRefNo: null,
    flightDetails: [],
    ticketAllData: {},
    loading: true,
  });

  const [hotelTicketsDetails, setHotelTicketsDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });
  const [carTicketsDetails, setCarTicketDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });
  const [carTrTicketsDetails, setCarTrTicketDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });
  const [visaTicketDetails, setVisaTicketDetails] = useState({
    errorMessage: "",
    otherMessage: "",
    ticketAllData: {},
    loading: true,
  });

  const [busTicketsDetails, setBusTicketsDetails] = useState({
    twoWay: false,
    refNo1: null,
    refNo2: null,
    loading: true,
  });
  // const [holidayTicketDetails, setHolidayTicketDetails] = useState({
  //   message: "",
  //   TourDetails: {},
  //   loading: true,
  // });
  // const [cruiseTicketDetails, setCruiseTicketDetails] = useState({
  //   message: "",
  //   cruiseDetails: {},
  //   loading: true,
  // });
  // const [cFTicketDetails, setCFTicketDetails] = useState({
  //   message: "",
  //   charterFlightDetails: {},
  //   loading: true,
  //   otherMessage: "",
  // });
  // const [rbTicketDetails, setRBTicketDetails] = useState({
  //   message: "",
  //   rbDetails: {},
  //   loading: true,
  //   otherMessage: "",
  // });

  const params = queryString.parse(document.location.search);

  const airBook = (value) => {
    console.log(value, "value")
    ApiClient.post("flights/airBook/" + value)
      .then((response) => {
        if (response.statusCode === 200) {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            BookingRefNo: response.data.BookingRefNo,
            flightDetails: response.data.flightDetails,
            ticketAllData: response.charges.ticketAllData,
            loading: false,
          });
          // return;
        } else if (response.statusCode == 400 && response.data.length > 0) {
          setFlightTicketsDetails({
            errorMessage: response.data[0].errorDetail,
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        } else {
          setFlightTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            BookingRefNo: null,
            flightDetails: [],
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((error) => {
        setFlightTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          BookingRefNo: null,
          flightDetails: [],
          ticketAllData: {},
          loading: false,
        });
      });
  };

  const busBookApi = async (preBookingRefId, ticketNo) => {
    setBusTicketsDetails((prev) => ({
      ...prev,
      loading: true,
    }));
    await ApiClient.get("buses/bookTicket?refNo=" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (ticketNo == 2) {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo2: resp.data.referenceNo,
            }));
          } else {
            setBusTicketsDetails((prev) => ({
              ...prev,

              refNo1: resp.data.referenceNo,
            }));
          }
        }
        setBusTicketsDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const busBooking = async (ref) => {
    setType("BUS");
    let busResObj = JSON.parse(localStorage.getItem("twoWayBusData"));
    let ticketNo = 1;

    if (busResObj) {
      if (busResObj.length > 1) {
        setBusTicketsDetails({
          twoWay: true,
          refNo1: null,
          refNo2: null,
          loading: true,
        });
        await busBookApi(busResObj[0].BookingReferenceNo, ticketNo);
        ticketNo = 2;
      }
    }

    await busBookApi(ref, ticketNo);
  };

  // const ActivitiesBookingApi = (ref) => {
  //   setType("ACTIVITIES");
  //   ApiClient.get("activities/tours/booking/" + ref)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.statusCode === 200) {
  //         setActivitiesTicketDetails({
  //           ...resp.data,
  //           message: "SUCCESS",
  //           loading: false,
  //         });
  //       } else {
  //         setActivitiesTicketDetails({
  //           ...activitiesTicketDetails,
  //           message: "FAILED",
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setActivitiesTicketDetails({
  //         ...activitiesTicketDetails,
  //         message: "FAILED",
  //         loading: false,
  //       });
  //       return err;
  //     });
  // };

  // const packageBookingApi = (ref) => {
  //   ApiClient.get("buildPackage/packageBook/" + ref)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) {
  //         let data = {
  //           ...resp.data,
  //           tourDetails: [],
  //         };

  //         if (Object.keys(resp.data?.request).length > 0) {
  //           const req = resp.data?.request;

  //           const tourAvlDate = getDateRange(
  //             req?.checkInDate,
  //             req?.checkOutDate
  //           );

  //           if (resp.data.activitiesPackageDetails.length > 0) {
  //             resp.data?.activitiesPackageDetails?.map((tour) => {
  //               tour?.optionData?.map((optionData) => {
  //                 let i = tourAvlDate.indexOf(
  //                   moment(optionData.tourDate).format("DD-MM-YYYY")
  //                 );

  //                 if (
  //                   data.tourDetails.hasOwnProperty(i) &&
  //                   data.tourDetails[i]?.length > 0
  //                 ) {
  //                   data.tourDetails[i].push(optionData);
  //                 } else {
  //                   data.tourDetails[i] = [optionData];
  //                 }
  //               });
  //             });
  //           }
  //         }

  //         setbuildTicketDetails({
  //           ...data,
  //           message: "SUCCESS",
  //           loading: false,
  //         });
  //       } else {
  //         setbuildTicketDetails({
  //           bookingRefNo: "",
  //           hotelsPackageDetails: [],
  //           activitiesPackageDetails: [],
  //           message: "FAILED",
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setbuildTicketDetails({
  //         bookingRefNo: "",
  //         hotelsPackageDetails: [],
  //         activitiesPackageDetails: [],
  //         message: "FAILED",
  //         loading: false,
  //       });
  //       return err;
  //     });
  // };

  const hotelBookApi = (preBookingRefId) => {
    setHotelTicketsDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });

    ApiClient.post("hotels-v2/hotelBook/" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp.data,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setHotelTicketsDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setHotelTicketsDetails({
            errorMessage: "",
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setHotelTicketsDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };
  const carTrBookApi = (preBookRefId) => {
    setCarTrTicketDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });
    ApiClient.get("transfers/book?refNo=" + preBookRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          // console.log(resp);
          setCarTrTicketDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp?.data,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setCarTrTicketDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setCarTrTicketDetails({
            errorMessage: resp.message,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setCarTrTicketDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };
  const carBookApi = (preBookingRefId) => {
    setCarTicketDetails({
      errorMessage: "",
      otherMessage: "",
      ticketAllData: {},
      loading: true,
    });

    // ApiClient.get("visa/visaBooking/" + preBookingRefId)
    ApiClient.get("carextranet/carBooking/" + preBookingRefId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setCarTicketDetails({
            errorMessage: "",
            otherMessage: "",
            ticketAllData: resp?.data?.ticketDetails,
            loading: false,
          });
        } else if (resp.status == 400 && resp.data.length > 0) {
          setCarTicketDetails({
            errorMessage: resp.data[0].errorDetail,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        } else {
          setCarTicketDetails({
            errorMessage: resp.message,
            otherMessage: "Booking Failed",
            ticketAllData: {},
            loading: false,
          });
        }
      })
      .catch((err) => {
        setCarTicketDetails({
          errorMessage: "",
          otherMessage: "Booking Failed",
          ticketAllData: {},
          loading: false,
        });
      });
  };
  // const visaBookApi = (preBookingRefId) => {
  //   setVisaTicketDetails({
  //     errorMessage: "",
  //     otherMessage: "",
  //     ticketAllData: {},
  //     loading: true,
  //   });

  //   ApiClient.get("visa/visaBooking/" + preBookingRefId)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) {
  //         setVisaTicketDetails({
  //           errorMessage: "",
  //           otherMessage: "",
  //           ticketAllData: resp?.data?.ticketDetails,
  //           loading: false,
  //         });
  //       } else if (resp.status == 409) {
  //         setVisaTicketDetails({
  //           errorMessage: resp.message,
  //           otherMessage: "Booking Failed",
  //           ticketAllData: {},
  //           loading: false,
  //         });
  //       } else {
  //         setVisaTicketDetails({
  //           errorMessage: resp.message,
  //           otherMessage: "Booking Failed",
  //           ticketAllData: {},
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setVisaTicketDetails({
  //         errorMessage: err,
  //         otherMessage: "Booking Failed",
  //         ticketAllData: {},
  //         loading: false,
  //       });
  //     });
  // };
  // const holidayBookApi = (bookingRefId) => {
  //   setHolidayTicketDetails({
  //     message: "",
  //     TourDetails: {},
  //     loading: true,
  //   });
  //   setType("TOUREXNET");
  //   ApiClient.get("ToursExtranet/tours_book/" + bookingRefId)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) {
  //         setHolidayTicketDetails({
  //           ...resp.data,
  //           message: "SUCCESS",
  //           loading: false,
  //         });
  //       } else {
  //         setHolidayTicketDetails({
  //           ...holidayTicketDetails,
  //           message: "FAILED",
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setHolidayTicketDetails({
  //         ...holidayTicketDetails,
  //         message: "FAILED",
  //         loading: false,
  //       });
  //       return err;
  //     });
  // };

  // const cruiseBookApi = (bookingRefId) => {
  //   setCruiseTicketDetails({
  //     message: "",
  //     cruiseDetails: {},
  //     loading: true,
  //   });
  //   setType("CEX");
  //   ApiClient.get("cruise_book/" + bookingRefId)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) {
  //         setCruiseTicketDetails({
  //           ...resp.data,
  //           message: "SUCCESS",
  //           loading: false,
  //         });
  //       } else {
  //         setCruiseTicketDetails({
  //           ...cruiseTicketDetails,
  //           message: "FAILED",
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setCruiseTicketDetails({
  //         ...cruiseTicketDetails,
  //         message: "FAILED",
  //         loading: false,
  //       });
  //       return err;
  //     });
  // };
  // const charteFlightBookApi = (bookingRefId) => {
  //   setCFTicketDetails({
  //     message: "",
  //     charterFlightDetails: {},
  //     loading: true,
  //     otherMessage: "",
  //   });
  //   setType("CFX");
  //   ApiClient.get("CharterFlights/charterflight/book/" + bookingRefId)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.status === 200) {
  //         setCFTicketDetails({
  //           ...resp.data,
  //           message: "SUCCESS",
  //           loading: false,
  //           otherMessage: resp.message,
  //         });
  //       } else {
  //         setCFTicketDetails({
  //           ...cFTicketDetails,
  //           message: "FAILED",
  //           loading: false,
  //           otherMessage: resp.message,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setCFTicketDetails({
  //         ...cFTicketDetails,
  //         message: "FAILED",
  //         loading: false,
  //         otherMessage: "",
  //       });
  //       return err;
  //     });
  // };

  // const rechargeBillBookApi = (bookingRefId) => {
  //   setRBTicketDetails({
  //     message: "",
  //     rbDetails: {},
  //     loading: true,
  //     otherMessage: "",
  //     OperatorName: "",
  //     OperatorLogo: "",
  //     CircleName: "",
  //     CategoryName: "",
  //     contactNo: "",
  //     opRefNo: "",
  //     txId: "",
  //     response: "",
  //   });
  //   setType("RB");
  //   ApiClient.get("rbservice/rechargeandbillpayment/" + bookingRefId)
  //     .then((result) => {
  //       return result;
  //     })
  //     .then((resp) => {
  //       if (resp.statusCode === 200) {
  //         setRBTicketDetails({
  //           ...resp.data,
  //           message: "SUCCESS",
  //           loading: false,
  //           otherMessage: resp.message
  //             ? resp.message
  //             : resp.errorMsg
  //             ? resp.errorMsg
  //             : "",
  //           response: resp.status,
  //           OperatorName: resp.OperatorName,
  //           OperatorLogo: resp.OperatorLogo,
  //           CircleName: resp.CircleName,
  //           CategoryName: resp.CategoryName,
  //           contactNo: resp.contactNo,
  //           opRefNo: resp.opRefNo,
  //           txId: resp.txId,
  //         });
  //       } else {
  //         setRBTicketDetails({
  //           ...rbTicketDetails,
  //           message: "FAILED",
  //           loading: false,
  //           otherMessage: resp.message
  //             ? resp.message
  //             : resp.errorMsg
  //             ? resp.errorMsg
  //             : "",
  //           reponse: resp.status,
  //           OperatorName: resp.OperatorName,
  //           OperatorLogo: resp.OperatorLogo,
  //           CircleName: resp.CircleName,
  //           CategoryName: resp.CategoryName,
  //           contactNo: resp.contactNo,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setRBTicketDetails({
  //         ...rbTicketDetails,
  //         message: "FAILED",
  //         loading: false,
  //         otherMessage: err,
  //         OperatorName: "",
  //         OperatorLogo: "",
  //         CircleName: "",
  //         CategoryName: "",
  //         contactNo: "",
  //         response: "",
  //       });
  //       return err;
  //     });
  // };

  useEffect(() => {
    if (params.ref && params.ref.indexOf("OUTC-F") != -1) {
      setType("FLIGHT");
      airBook(params.ref);
    } else if (params.ref && params.ref.indexOf("OUTC-H") != -1) {
      setType("HOTEL");
      hotelBookApi(params.ref);
    }
    else if (params.ref && params.ref.indexOf("OUTC-B") != -1) {
      setType("BUS");
      busBooking(params.ref);
    }
    //  else if (params.ref && params.ref.indexOf("User") != -1) {
    // UserWallet(params.ref);
    // }
    //  else if (params.ref && params.ref.indexOf("AJML-BP") != -1) {
    //   setType("BUILDPACKAGE");
    //   packageBookingApi(params.ref);
    //   
    // } else if (params.ref && params.ref.indexOf("AJML-A") != -1) {
    //   ActivitiesBookingApi(params.ref);
    // }
    // else if (params.ref && params.ref.indexOf("AJML-TR") != -1) {
    //   setType("CARTransfer");
    //   carTrBookApi(params.ref);
    // }else if (params.ref && params.ref.indexOf("AJML-CAR") != -1) {
    //   setType("CAR");
    //   carBookApi(params.ref);
    // } else if (params.ref && params.ref.indexOf("AJML-VISA") != -1) {
    //   setType("VISA");
    //   visaBookApi(params.ref);
    // } else if (params.ref && params.ref.indexOf("AJML-TEX") != -1) {
    //   holidayBookApi(params.ref);
    // }
    // else if (params.ref && params.ref.indexOf("AJML-CEX") != -1) {
    //   cruiseBookApi(params.ref);
    // } else if (params.ref && params.ref.indexOf("AJML-RB") != -1) {
    //   rechargeBillBookApi(params.ref);
    // } else if (params.ref && params.ref.indexOf("AJML-CFX") != -1) {
    //   charteFlightBookApi(params.ref);
    // }
  }, []);

  return (
    <div className="success_area">
      {/* <div className="hdr">
        <h3 style={{color:"#bd0c21"}}>Booking Confirmation</h3>
      </div> */}
      {type === "FLIGHT" ? (
        flightTicketsDetails.loading ? (
          <SuccessSkeleton title="Flight" />
        ) : (
          <Flightticketsuccess flightTicketsDetails={flightTicketsDetails} />
        )
      ) : null}
      {type === "BUS" ? (
        busTicketsDetails.loading ? (
          <SuccessSkeleton title="Bus" />
        ) : (
          <Busticketsuccess busTicketsDetails={busTicketsDetails} />
        )
      ) : null}
      {/* {type === "ACTIVITIES" ? (
        activitiesTicketDetails.loading ? (
          <ActivitiesSuccessSkeleton title="Activity" />
        ) : (
          <ActivitiesTicketSuccess
            activitiesTicketDetails={activitiesTicketDetails}
          />
        )
      ) : null} */}

      {/* {type === "BUILDPACKAGE" ? (
        buildTicketDetails.loading ? (
          <ActivitiesSuccessSkeleton title="Build Package" />
        ) : (
          <BuildPackageTicketSuccess buildTicketDetails={buildTicketDetails} />
        )
      ) : null} */}

      {type === "HOTEL" ? (
        hotelTicketsDetails.loading ? (
          <SuccessSkeleton title="Hotel" />
        ) : (
          <HotelTicketSuccess hotelTicketsDetails={hotelTicketsDetails} />
        )
      ) : null}
      {/* {type === "CARTransfer" ? (
        carTrTicketsDetails.loading ? (
          <SuccessSkeleton title="CAR-Transfer" />
        ) : (
          <CarTransferTicketSuccess carTicketsDetails={carTrTicketsDetails} />
        )
      ) : null}
      {type === "CAR" ? (
        carTicketsDetails.loading ? (
          <SuccessSkeleton title="CAR" />
        ) : (
          <CarTicketSuccess carTicketsDetails={carTicketsDetails} />
        )
      ) : null}
      {type === "VISA" ? (
        visaTicketDetails.loading ? (
          <SuccessSkeleton title="VISA" />
        ) : (
          <VisaTicketSuccess visaTicketDetails={visaTicketDetails} />
        )
      ) : null}
      {type === "TOUREXNET" ? (
        holidayTicketDetails.loading ? (
          <SuccessSkeleton title="Holiday" />
        ) : (
          <HolidayTicketSuccess holidayTicketDetails={holidayTicketDetails} />
        )
      ) : null} */}
      {/* {type === "CEX" ? (
        cruiseTicketDetails.loading ? (
          <SuccessSkeleton title="Holiday" />
        ) : (
          <CruiseTicketSuccess cruiseTicketDetails={cruiseTicketDetails} />
        )
      ) : null} */}
      {/* {type === "RB" ? (
        rbTicketDetails.loading ? (
          <SuccessSkeleton title="Recharge/Bills" />
        ) : (
          <RBTicketSuccess rbTicketDetails={rbTicketDetails} />
        )
      ) : null} */}
      {/* {type === "CFX" ? (
        cFTicketDetails.loading ? (
          <SuccessSkeleton title="Charter Flight ltinerary" />
        ) : (
          <CFTicketSuccess cFTicketDetails={cFTicketDetails} />
        )
      ) : null} */}
    </div>
  );
};
export default PaymentSucces;
