import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Tabs,
    Tooltip,
    Collapse
} from "antd";
import { useFlightContext } from "../../../common/providers/Flights/FlightContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import { calculateDuration } from "../../../common/AllTickets/Flight/flightHelper";
import ApiClient from "../../../helpers/ApiClient";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import CustomFliImg from "../../../common/CustomImg/CusFliLogo";
import {
    MailOutlined,
    RightOutlined,
    ShoppingOutlined,
    LineOutlined,
    CloseOutlined
} from "@ant-design/icons";
import "./RoundtpSeFlight.scss";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";


export const FlightTimings = ({ flightInfo, airSearchData, fareFamilies }) => {
    const { user } = useAuthContext();
    const [fareRulesResp, setFareRulesResp] = useState("");
    const { TabPane } = Tabs;
    const [cmsFareRules, setCmsFareRules] = useState({});
    const [flightDetails, setFlightDetails] = useState(false);
    const showModal = () => {
        let obj = {
            airlineCode: flightInfo.airLine,
            fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
            roleID: user?.Role?.RoleId ?? 4,
            membershipID: user?.Membership ?? 1,
        };
        getCmsFareRules(obj);
    };

    const getCmsFareRules = (obj) => {
        ApiClient.get("admin/getFlightFareRules", obj)
            .then((res) => {
                if (res.status === 200) {
                    setCmsFareRules(res.data);
                    setFlightDetails({
                        visible: true,
                    });
                } else {
                    setCmsFareRules({});
                    setFlightDetails({
                        visible: true,
                    });
                }
            })
            .catch((e) => {
                setCmsFareRules({});
                setFlightDetails({
                    visible: true,
                });
            });
    };

    const fetchFlightFares = (str, event) => {
        if (str === "2") {
            let fareReqObj = {
                traceId: airSearchData.traceId,
                flightId: flightInfo.flightId,
                airTravelType: airSearchData.airTravelType,
                mappingType: airSearchData.resultsType,
                itineraryViewType: "1",
            };
            ApiClient.post("flights/airFareRules", fareReqObj)
                .then((result) => {
                    return result;
                })
                .then((resp) => {
                    if (resp.statusCode === 200) {
                        if (resp.data) {
                            if (resp.data.fareRules) {
                                setFareRulesResp(resp);
                            }
                        }


                    } else {
                        let error_resp = {
                            data: {
                                fareRules: [
                                    {
                                        ruleDetails:
                                            "Something went wrong , Fare Rules not Available",
                                    },
                                ],
                            },
                        };
                        setFareRulesResp(error_resp);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const formatFlightTime = (flightTime) => {
        let [date, time] = flightTime.split("T");
        let [hour, minute, seconds] = time.split(":");
        return `${hour}:${minute}`;
    };

    const luggageDetails = () => {
        return (
            <div className="luggage-tooltip">
                <Row gutter={16}>
                    {fareFamilies.fareFamilies[0].baggage.map((bag) => {
                        return (
                            <Col>
                                <p>{bag.cityPair}</p>
                                <p>
                                    <i className="fa fa-plane"></i>{" "}
                                    <span>Airline {bag.airline}</span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.baggageInfo} Check-In Baggage </span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                                </p>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };
    return (
        <>
            <div className="flight-timings">
                {/* {fareFamilies.fareFamilies.length > 0 && (
                    <div className="refundText">
                        {flightInfo.isRefundable ? "" : "Non"} Refundable
                    </div>
                )} */}
                <div className="timing_wrapper flight-syt-time">
                    <div className="plane-timings">
                        <ul>
                            <li>
                                <p>
                                    {formatFlightTime(
                                        flightInfo.flightSegments[0].departureDateTime
                                    )}
                                </p>
                            </li>
                            <li>
                                <LineOutlined />
                            </li>
                            <li>
                                {" "}
                                <div className="travel-time">

                                    <div>
                                        <p>{flightInfo?.duration}</p>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <LineOutlined />
                            </li>
                            <li>
                                {flightInfo.flightSegments.length > 0 ? (
                                    <div>
                                        <p>
                                            {formatFlightTime(
                                                flightInfo.flightSegments[
                                                    flightInfo.flightSegments.length - 1
                                                ].arrivalDateTime
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <p>
                                            {formatFlightTime(
                                                flightInfo.flightSegments[0].arrivalDateTime
                                            )}
                                        </p>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="facility-icons">
                    <Tooltip placement="bottom" title={luggageDetails}>
                        <p className="details-bag-icon" style={{ marginRight: 18 }}>
                            Baggage <ShoppingOutlined />
                        </p>
                    </Tooltip>
                </div>
                <div className="details-btn">
                    <p className="details-text" onClick={showModal}>
                        Details
                    </p>
                </div>
            </div>

            <div>
                <Modal
                    title="Flight Details"
                    visible={flightDetails}
                    footer={[null]}
                    onOk={(e) => setFlightDetails(false)}
                    onCancel={(e) => setFlightDetails(false)}
                    className="flight-details-modal"
                >
                    <div className="all-times-local">
                        <Tabs
                            defaultActiveKey="1"
                            onTabClick={(str, e) => fetchFlightFares(str, e)}
                        >
                            <TabPane tab="Segments" key="1">
                                <div className="from-to-modal-details">
                                    <Row gutter={16} className="more-details-row">
                                        <Col md={14} xs={24}>
                                            <Row align="middle" gutter={16}>
                                                <Col md={8} xs={8} className="more-details-list">


                                                    <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                                                        {flightInfo.originCity}
                                                    </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                                                        ({flightInfo.flightSegments[0].origin})
                                                    </span>


                                                    <p>
                                                        <strong>
                                                            {dateFormat(
                                                                flightInfo.flightSegments[0].departureDateTime,
                                                                "HH:MM"
                                                            )}
                                                            ,
                                                        </strong>
                                                        <span>
                                                            {dateFormat(
                                                                flightInfo.flightSegments[0].departureDateTime,
                                                                "dd mmm (ddd)"
                                                            )}
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col md={8} xs={8}>
                                                    <div className="break-journey-city">
                                                        <div className="inner_wrapper">
                                                            {flightInfo.flightSegments.map(
                                                                (flightSegInfo, index) =>
                                                                    index !== 0 ? (
                                                                        <div
                                                                            className="stop_points"
                                                                            key={flightSegInfo.segId}
                                                                        >
                                                                            <Tooltip
                                                                                placement="top"
                                                                                title={() => (
                                                                                    <div className="tooltip-dataRe">
                                                                                        {flightInfo.flightSegments.length -
                                                                                            1 >
                                                                                            1 ? (
                                                                                            <h4>Change</h4>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        <p>
                                                                                            {flightSegInfo.origin +
                                                                                                " " +
                                                                                                flightSegInfo.origin}
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                                className="citynames-tooltip"
                                                                            >
                                                                                <span className="break-city-name pointer_cursor">
                                                                                    {flightSegInfo.origin}
                                                                                </span>
                                                                            </Tooltip>
                                                                        </div>
                                                                    ) : null
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={8} xs={8} className="more-details-list">
                                                    <p>
                                                        <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                                                            {flightInfo.destinationCity}
                                                        </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                                                            ({flightInfo.flightSegments[
                                                                flightInfo.flightSegments.length - 1
                                                            ].destination})
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            {dateFormat(
                                                                flightInfo.flightSegments[
                                                                    flightInfo.flightSegments.length - 1
                                                                ].arrivalDateTime,
                                                                "HH:MM"
                                                            )}
                                                            ,
                                                        </strong>
                                                        <span>
                                                            {dateFormat(
                                                                flightInfo.flightSegments[
                                                                    flightInfo.flightSegments.length - 1
                                                                ].arrivalDateTime,
                                                                "dd mmm (ddd)"
                                                            )}
                                                        </span>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6} xs={24} className="trip-duration-time">
                                            <p>Total trip duration:</p>
                                            <p>
                                                {flightInfo.flightSegments.length > 0
                                                    ? calculateDuration(
                                                        flightInfo.flightSegments[0].departureDateTime,
                                                        flightInfo.flightSegments[
                                                            flightInfo.flightSegments.length - 1
                                                        ].arrivalDateTime
                                                    )
                                                    : calculateDuration(
                                                        flightInfo.flightSegments[0].departureDateTime,
                                                        flightInfo.flightSegments[0].arrivalDateTime
                                                    )}{" "}
                                                |
                                                {flightInfo.flightSegments.length - 1 === 0 ? (
                                                    <>Direct</>
                                                ) : (
                                                    <>{flightInfo.flightSegments.length - 1} stops </>
                                                )}
                                            </p>
                                            <p></p>
                                        </Col>
                                        <Col md={4} xs={24} className="trip-duration-time">
                                            <p>class:</p>
                                            <p>{airSearchData.cabinClass}</p>
                                        </Col>
                                    </Row>
                                </div>

                                {flightInfo.flightSegments.map((flightSegment, i) => (
                                    <div
                                        className="total-flight-details"
                                        key={"flighttiming" + i}
                                    >
                                        <div className="to-fro-flight-details">
                                            <div className="from-city-flight-details">
                                                <span>{flightSegment.origin}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    departure:{" "}
                                                    <strong>
                                                        {dateFormat(
                                                            flightSegment.departureDateTime,
                                                            "HH:MM"
                                                        )}
                                                    </strong>
                                                    {" , "}
                                                </span>
                                                <span>
                                                    {dateFormat(
                                                        flightSegment.departureDateTime,
                                                        "dd mmm (ddd)"
                                                    )}
                                                </span>
                                                <span>
                                                    <strong>
                                                        {"  "}
                                                        {flightInfo.airLineName}(
                                                        {flightSegment.marketingAirline}-
                                                        {flightSegment.flightNumber})
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="collapsed-card">
                                        </div>

                                        <div className="to-fro-flight-details">
                                            <div className="from-city-flight-details">
                                                <span>{flightSegment.destination}</span>
                                            </div>
                                            <div>
                                                <span>
                                                    arrival:{" "}
                                                    <strong>
                                                        {formatFlightTime(flightSegment.arrivalDateTime)}
                                                    </strong>
                                                </span>
                                                {" , "}
                                                <span>
                                                    {dateFormat(
                                                        flightSegment.arrivalDateTime,
                                                        "dd mmm (ddd)"
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        {i < flightInfo.flightSegments.length - 1 && (
                                            <div className="halt-wrapper">
                                                <p>
                                                    Layover Time:{" "}
                                                    {calculateDuration(
                                                        flightInfo.flightSegments[i + 1].departureDateTime,
                                                        flightSegment.arrivalDateTime
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </TabPane>

                            {cmsFareRules?.cancelPolicyDescription ? (
                                <TabPane tab="Cancel Policy" key="3">
                                    <div className="fareRule-container">
                                        <div>
                                            {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                                        </div>
                                    </div>
                                </TabPane>
                            ) : (
                                <TabPane tab="Cancel Policy" key="2">
                                    <div className="fareRule-container">
                                        {!fareRulesResp ? (
                                            <p>Loading...</p>
                                        ) : (
                                            fareRulesResp.data.fareRules.map((rule, i) => {
                                                return (
                                                    <div key={i}>
                                                        <pre>{ReactHtmlParser(rule.ruleDetails)}</pre>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </TabPane>
                            )}
                            {cmsFareRules?.reschedulePolicyDescription && (
                                <TabPane tab="Reschedule Policy" key="4">
                                    <div className="fareRule-container">
                                        <pre>
                                            {ReactHtmlParser(
                                                cmsFareRules.reschedulePolicyDescription
                                            )}
                                        </pre>
                                    </div>
                                </TabPane>
                            )}

                            {cmsFareRules?.covidRulesDescription && (
                                <TabPane tab="Covid Rules Policy" key="5">
                                    <div className="fareRule-container">
                                        <pre>
                                            {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                                        </pre>
                                    </div>
                                </TabPane>
                            )}
                            {cmsFareRules?.otherRulesDescription && (
                                <TabPane tab="Other Rules Policy" key="6">
                                    <div className="fareRule-container">
                                        <pre>
                                            {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                                        </pre>
                                    </div>
                                </TabPane>
                            )}
                        </Tabs>
                    </div>
                </Modal>
            </div>
        </>
    );
};

const Flight = (props) => {
    const {
        isLogin: { agent },
    } = useAuthContext();
    const { TabPane } = Tabs;
    const flightInfo = props.flightInfo;
    const flightSearchObj = props.flightSearchObj;
    const fareFamilies = props.fareFamilies;
    const promoData = props.promoData;
    const selectedFlight = props.selectedFlight;
    const MultiCityIndex = props.MultiCityIndex;
    const showNetFare = props.showNetFare;
    const index = props.index;
    const setActiveCollapse = props.setActiveCollapse;
    const activeCollapse = props.activeCollapse;
    const changeFlightResultsView = props.changeFlightResultsView;
    const journeyType = props.journeyType;
    const [cmsFareRules, setCmsFareRules] = useState({});
    const { Panel } = Collapse;
    const [flightArr, setFlightArr] = useState([]);
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const [act, setact] = useState(0)
    // const onClickChooseFlight = (fareFamiliesIndex) => {
    //     try {
    //         if (
    //             (flightSearchObj.resultsType === "Combined" &&
    //                 flightSearchObj.airTravelType === "roundTrip") || (flightSearchObj.resultsType === "Combined" &&
    //                     flightSearchObj.airTravelType === "multidestination")
    //         ) {
    //             flightInfo.flightDetails.map((flightDetObj) => {
    //                 flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
    //                 flightArr.push(flightDetObj);
    //                 setFlightArr([flightArr]);
    //             });
    //             changeFlightResultsView(flightArr);
    //         } else {
    //             if (
    //                 selectedFlight.length > 0 &&
    //                 flightSearchObj.airTravelType === "roundTrip"
    //             ) {
    //                 if (journeyType === "return") {
    //                     if (selectedFlight.length === 2) {
    //                         let copySelectedFlights = [...selectedFlight];
    //                         copySelectedFlights[1] = flightInfo;

    //                         changeFlightResultsView(copySelectedFlights);
    //                     } else {
    //                         changeFlightResultsView([...selectedFlight, flightInfo]);
    //                     }
    //                 } else {
    //                     let copySelectedFlights = [...selectedFlight];
    //                     copySelectedFlights[0] = flightInfo;

    //                     changeFlightResultsView(copySelectedFlights);
    //                 }

    //             } else if (selectedFlight.length > 0 &&
    //                 flightSearchObj.airTravelType === "multidestination" && flightSearchObj.resultsType == "Separate") {
    //                 let copyarray = flightInfo;
    //                 let copy = flightInfo.fareFamilies.fareFamilies.filter((_, index) => index == fareFamiliesIndex);
    //                 copyarray.fareFamilies.fareFamilies = copy;
    //                 if (selectedFlight.length <= flightSearchObj.originDestinations.length) {
    //                     let copySelectedFlights = [...selectedFlight];
    //                     copySelectedFlights[MultiCityIndex] = copyarray;

    //                     changeFlightResultsView(copySelectedFlights);
    //                 }
    //                 else {

    //                     changeFlightResultsView([...selectedFlight, flightInfo]);
    //                 }
    //             } else {
    //                 changeFlightResultsView([flightInfo]);
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };


    const onClickChooseFlight = (fareFamiliesIndex, jrny) => {
        // console.log("iamco");
        if (
            flightSearchObj.resultsType === "COMBINED" &&
            (flightSearchObj.airTravelType === "roundTrip" ||
                flightSearchObj.airTravelType === "multidestination")
        ) {
            // console.log("iamco1");
            setFlightArr(
                flightInfo.flightDetails.map((flightDetObj) => ({
                    ...flightDetObj,
                    fareFamilies: flightInfo.fareFamilies,
                }))
            );
        } else if (selectedFlight.length > 0 && flightSearchObj.airTravelType === "roundTrip") {
            const newSelectedFlights = [...selectedFlight] || [];
            // console.log("by1", selectedFlight.length, flightInfo, newSelectedFlights);
            // console.log("iamco2");
            if (jrny === "Return") {
                newSelectedFlights[1] = flightInfo;
                // console.log("iamco3",jrny);
                // console.log("by2", jrny)
            } else {
                newSelectedFlights[0] = flightInfo;
                // console.log("by3")
            }
            changeFlightResultsView(newSelectedFlights);
        } else {
            changeFlightResultsView([flightInfo]);
            // console.log("by4")
        }
    };


    const [fareRulesResp, setFareRulesResp] = useState("");
    const { user } = useAuthContext();
    const [flightDetails, setFlightDetails] = useState(false);
    const [flightdetailsactivecss, setflightdetailsactivecss] = useState({
        flightdetails: true, cancel: false, reschedule: false
        , covidRules: false, otherRules: false
    })
    const showModal = () => {
        let obj = {
            airlineCode: flightInfo.airLine,
            fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
            roleID: user?.Role?.RoleId ?? 4,
            membershipID: user?.Membership ?? 1,
        };
        getCmsFareRules(obj);
    };
    const fetchFlightFares = (str, event) => {
        if (str === "2") {
            let fareReqObj = {
                traceId: flightSearchObj.traceId,
                flightId: flightInfo.flightId,
                airTravelType: flightSearchObj.airTravelType,
                mappingType: flightSearchObj.resultsType,
                itineraryViewType: "1",
            };
            ApiClient.post("flights/airFareRules", fareReqObj)
                .then((result) => {
                    return result;
                })
                .then((resp) => {
                    if (resp.statusCode === 200) {
                        if (resp.data) {
                            if (resp.data.fareRules) {
                                setFareRulesResp(resp);
                            }
                        }


                    } else {
                        let error_resp = {
                            data: {
                                fareRules: [
                                    {
                                        ruleDetails:
                                            "Something went wrong , Fare Rules not Available",
                                    },
                                ],
                            },
                        };
                        setFareRulesResp(error_resp);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const getCmsFareRules = (obj) => {
        ApiClient.get("admin/getFlightFareRules", obj)
            .then((res) => {
                if (res.status === 200) {
                    setCmsFareRules(res.data);
                    setFlightDetails({
                        visible: true,
                    });
                } else {
                    setCmsFareRules({});
                    setFlightDetails({
                        visible: true,
                    });
                }
            })
            .catch((e) => {
                setCmsFareRules({});
                setFlightDetails({
                    visible: true,
                });
            });
    };
    const formatFlightTime = (flightTime) => {
        let [date, time] = flightTime.split("T");
        let [hour, minute, seconds] = time.split(":");
        return `${hour}:${minute}`;
    };
    const [openMailModal, setOpenMailModal] = useState(false);
    const showMailModal = () => {
        setOpenMailModal(true);
    };
    const openOk = (e) => {
        setOpenMailModal(false);
    };

    const OpenCancel = (e) => {
        setOpenMailModal(false);
    };
    const [openNumberModal, setOpenNumberModal] = useState(false);

    const clickOk = (e) => {
        setOpenNumberModal(false);
    };

    const clickCancel = (e) => {
        setOpenNumberModal(false);
    };
    const [Emailform] = Form.useForm();

    const sendEmail = (formData) => {
        let emailData = {
            to: formData.email,
            data: {
                url: document.location.href,
                airLine: flightInfo.airLine,
                airLineLogo: flightInfo.airLineLogo,
                airLineName: flightInfo.airLineName,
                cabinClass: flightSearchObj.cabinClass,
                currency: flightInfo.fareFamilies.fareFamilies[0].currency,
                destiantionName: flightInfo.destiantionName,
                destination: flightInfo.destination,
                duration: flightInfo.duration,
                flightId: flightInfo.flightId,
                flightSegments: flightInfo.flightSegments,
                isBookOffline: flightInfo.isBookOffline,
                origin: flightInfo.origin,
                originName: flightInfo.originName,
                totalFare: Number(
                    flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                ).toFixed(2),
            },
            cc: "",
            bcc: "",
            subject: "Flight Information",
        };

        ApiClient.post("email/flightsegment", emailData)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Mail Sent Successfully", 3);
                    Emailform.resetFields();
                    OpenCancel();
                }
            })
            .catch((e) => console.log(e));
    };
    const priceToolTip = () => {
        let base = 0;
        let service = 0;
        let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);
        let Agentmarkup = Number(flightInfo.fareFamilies.fareFamilies[0].agentMarkup);

        flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
            if (fare.fareTag === "Base" && fare.paxType === "ADT") {
                base = fare.amount;


            } else if (fare.fareTag === "Tax" && fare.paxType === "ADT") {
                service = fare.amount;

            }
        });

        return (
            <div className="pointer_cur">
                <p>Per Pax's</p>
                <p>
                    Base Fare: {activeCurrency} {currencyValue(base + markup)}
                </p>
                <p>
                    Tax Charges: {activeCurrency} {currencyValue(service)}
                </p>
            </div>
        );
    };

    const luggageDetails = () => {
        return (
            <div className="luggage-tooltip">
                <Row gutter={16}>
                    {flightInfo.fareFamilies.fareFamilies[0].baggage.map((bag) => {
                        return (
                            <Col>

                                <p>{bag.cityPair}</p>
                                <p>
                                    <i className="fa fa-plane"></i>{" "}
                                    <span>Airline {bag.airline}</span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.baggageInfo} Check-In Baggage </span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                                </p>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };

    const AirportToolTip = (heading, code, name) => {
        return (
            <div className="tooltip-dataRe">
                <h4>{heading}</h4>
                <p>{code}</p>
                <p>{name}</p>
            </div>
        );
    };

    return (<>
        <div className={" flight-results-container"} >
            <div className="combined-view">

                <Card className="combined-flight-card">
                    <div>
                        <div className="flight-Retrip-details">
                            <div className="itinary-Redetails">
                                <div className="itinary-REflight-name">
                                    <CustomFliImg
                                        className="img-hot"
                                        src={flightInfo?.airLineLogo}
                                        // src={flightimg}
                                        alt={flightInfo.airLine}
                                    />


                                    <div className="token-number-Reflught">
                                        <p className="airline-name-Re" style={{ fontWeight: 700 }}>
                                            {flightInfo?.airLineName}
                                        </p>
                                        <span style={{ fontWeight: 700, color: "grey", fontSize: "12px" }}>
                                            ({flightInfo?.flightSegments?.[0]?.marketingAirline}-
                                            {flightInfo?.flightSegments?.[0]?.flightNumber})
                                        </span>
                                        {/* &nbsp; */}


                                    </div>
                                </div>
                                {/* {console.log(flightInfo, "flightInfo")} */}
                                {/* <FlightTimings
                    props={props}
                    flightInfo={flightInfo}
                    airSearchData={flightSearchObj}
                    fareFamilies={fareFamilies}
                    indexf={props?.ind}
                  /> */}
                                <div className="fromTo">
                                    <div className="cities-from-to">
                                        <div className="loc_wrapper">
                                            <Tooltip
                                                placement="top"
                                                title={AirportToolTip(
                                                    "Departure",
                                                    flightInfo.originName,
                                                    flightInfo.originCity,
                                                    flightInfo?.flightSegments?.[0]?.departureDateTime
                                                )}
                                                className="citynames-tooltip"
                                            >
                                                <div className="code pointer_cursor">

                                                    <div className="time-txt">
                                                        {moment(flightInfo?.flightSegments[0]?.departureDateTime).format("HH:MM")}
                                                    </div>
                                                    <p className="dest-name-txt">{flightInfo.origin}</p>
                                                    {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                                                </div>
                                            </Tooltip>


                                            <div className="break-Rejourney-container">
                                                <p className="break-jrny-up">
                                                    {(() => {
                                                        if (flightInfo?.flightSegments.length === 0) return "00:00 hrs";

                                                        const departure = new Date(flightInfo?.flightSegments[0].departureDateTime);
                                                        const arrival = new Date(flightInfo?.flightSegments[flightInfo?.flightSegments.length - 1].arrivalDateTime);

                                                        const diffMs = arrival - departure;
                                                        const hours = Math.floor(diffMs / (1000 * 60 * 60));
                                                        const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                                                        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hrs`;
                                                    })()}
                                                </p>
                                                <div className="break-Rejourney-city">
                                                    <div className="inner_wrapper">
                                                        {flightInfo?.flightSegments?.map((flightSegInfo, index) =>
                                                            index !== 0 ? (
                                                                <div className="stop_points" key={flightSegInfo.segId}>
                                                                    <Tooltip
                                                                        title={AirportToolTip(
                                                                            "Change",
                                                                            flightSegInfo?.originName,
                                                                            flightSegInfo?.originCity,
                                                                            flightSegInfo?.departureDateTime
                                                                        )}
                                                                        className="citynames-tooltip"
                                                                    >
                                                                        <span className="break-city-name pointer_cursor">
                                                                            {flightSegInfo?.origin}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            ) : flightInfo?.flightSegments.length === 1 ? "Direct" : null
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            {flightInfo.flightSegments.length === 4 && (
                                                <Tooltip
                                                    placement="top"
                                                    title={AirportToolTip(
                                                        "Destination",
                                                        flightInfo.flightSegments[3].destinationName,
                                                        flightInfo.flightSegments[3].destinationCity,
                                                        flightInfo.flightSegments[3].arrivalDateTime
                                                    )}
                                                    className="citynames-tooltip"
                                                >
                                                    <div className="code pointer_cursor">
                                                        {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                                                        <div className="time-txt">
                                                            {moment(flightInfo?.flightSegments[3]?.arrivalDateTime).format("HH:MM")}
                                                        </div>
                                                        <p className="dest-name-txt"> {flightInfo.flightSegments[3].destination}                           </p>

                                                    </div>
                                                </Tooltip>
                                            )}
                                            {flightInfo.flightSegments.length === 3 && (
                                                <Tooltip
                                                    placement="top"
                                                    title={AirportToolTip(
                                                        "Destination",
                                                        flightInfo.flightSegments[2].destinationName,
                                                        flightInfo.flightSegments[2].destinationCity,
                                                        flightInfo.flightSegments[2].arrivalDateTime
                                                    )}
                                                    className="citynames-tooltip"
                                                >
                                                    <div className="code pointer_cursor">
                                                        {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                                                        <div className="time-txt">
                                                            {moment(flightInfo?.flightSegments[2]?.arrivalDateTime).format("HH:MM")}
                                                        </div>
                                                        <p className="dest-name-txt"> {flightInfo.flightSegments[2].destination}                           </p>

                                                    </div>
                                                </Tooltip>
                                            )}
                                            {flightInfo.flightSegments.length === 2 && (
                                                <Tooltip
                                                    placement="top"
                                                    title={AirportToolTip(
                                                        "Destination",
                                                        flightInfo.flightSegments[1].destinationName,
                                                        flightInfo.flightSegments[1].destinationCity,
                                                        flightInfo.flightSegments[1].arrivalDateTime
                                                    )}
                                                    className="citynames-tooltip"
                                                >
                                                    <div className="code pointer_cursor">
                                                        {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                                                        <div className="time-txt">
                                                            {moment(flightInfo?.flightSegments[1]?.arrivalDateTime).format("HH:MM")}
                                                        </div>
                                                        <p className="dest-name-txt"> {flightInfo.flightSegments[1].destination}                           </p>

                                                    </div>
                                                </Tooltip>
                                            )}
                                            {flightInfo.flightSegments.length === 1 && (
                                                <Tooltip
                                                    placement="top"
                                                    title={AirportToolTip(
                                                        "Destination",
                                                        flightInfo.flightSegments[0].destinationName,
                                                        flightInfo.flightSegments[0].destinationCity,
                                                        flightInfo.flightSegments[0].arrivalDateTime
                                                    )}
                                                    className="citynames-tooltip"
                                                >
                                                    <div className="code pointer_cursor">
                                                        {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                                                        <div className="time-txt">
                                                            {moment(flightInfo?.flightSegments[0]?.arrivalDateTime).format("HH:MM")}
                                                        </div>
                                                        <p className="dest-name-txt"> {flightInfo.flightSegments[0].destination}                           </p>
                                                        {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                                                    </div>

                                                </Tooltip>
                                            )}
                                        </div>

                                    </div>


                                </div>
                                {/* <div className="flight-class-details">
                    <span className="stopsType">
                      {flightInfo?.flightSegments?.length === 1 ? (
                        <>Direct</>
                      ) : (
                        <>{flightInfo?.flightSegments?.length - 1} stops </>
                      )}
                    </span>
                    <span className="flight-class">
                      class: {flightSearchObj?.cabinClass}
                    </span>
                    {fareFamilies?.fareFamilies?.length === 1 && (

                      <span className="flight-class">
                        Fare Type: {fareFamilies?.fareFamilies[0]?.coupanType}
                      </span>
                    )}
       
                  </div> */}




                                {/* <div className="choose-btn-blockN"> */}

                                <div className="choose-Rebtn-section">
                                    <div className="share-price-mail">

                                        <div className="choose-btn-priceN">
                                            {showNetFare ? (
                                                <>
                                                    <span className="currencyType text-line">{activeCurrency}</span>
                                                    {/* <Tooltip title={priceToolTip}> */}
                                                    <span className="currency text-line">
                                                        {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                                                        {currencyValue(Math.round(
                                                            flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                                                        ))}
                                                    </span>
                                                    {/* </Tooltip> */}

                                                    <div className="text-center">
                                                        <p className="netfare">
                                                            Inc: {activeCurrency}{" "}
                                                            {currencyValue(Math.round(
                                                                flightInfo?.fareFamilies?.fareFamilies[0]?.commission
                                                            ))}{" "}
                                                        </p>
                                                        <p className="netfare">
                                                            {activeCurrency}{" "}
                                                            {currencyValue(Math.round(
                                                                flightInfo?.fareFamilies?.fareFamilies[0]?.adultNetFare
                                                            ))}{" "}
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>
                                                    <span className="currencyType">
                                                        {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                                                        {activeCurrency === "INR" ? "₹" : activeCurrency}
                                                    </span>
                                                    {/* <Tooltip title={priceToolTip}> */}
                                                    <span className="currency">
                                                        {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} adultPublishFare */}
                                                        {currencyValue(Math.round(
                                                            flightInfo.fareFamilies.fareFamilies[0].totalPublishFare
                                                        ))}
                                                    </span>
                                                    {/* </Tooltip>{" "} */}

                                                </div>
                                            )}
                                        </div>




                                    </div>
                                    {/* <div className="txt-off">Extra 10% off</div> */}
                                    <div className="choose-web-btn">
                                        <button
                                            // className="choose-button"
                                            className={`choose-button ${(selectedFlight[0]?.flightId === flightInfo.flightId || selectedFlight[1]?.flightId === flightInfo.flightId) && MultiCityIndex !== null
                                                ? "Selected-btn" : ""}`}
                                                style={{
                                                    background: (selectedFlight[0]?.flightId === flightInfo.flightId || 
                                                                selectedFlight[1]?.flightId === flightInfo.flightId) && MultiCityIndex !== null
                                                      ? " linear-gradient(115deg, rgb(0 89 194), rgb(0 0 0)) "// Selected button background color
                                                      : "linear-gradient(to right, #0370a9, #08acda)", // Default background color
                                                  }}
                                            onClick={(e) => {
                                                let reqData = {
                                                    serviceType: 1,
                                                    airlineCode: flightInfo.airLine,
                                                };
                                                if (flightInfo.fareFamilies.fareFamilies.length == 1) {
                                                    onClickChooseFlight(0, journeyType);
                                                } else {

                                                    if (activeCollapse === index) {
                                                        setActiveCollapse("");
                                                    } else {
                                                        setActiveCollapse(index);
                                                    }
                                                }
                                            }}
                                        >

                                            {

                                                (selectedFlight[0]?.flightId === flightInfo.flightId || selectedFlight[1]?.flightId === flightInfo.flightId) && MultiCityIndex !== null
                                                    ? "Selected"
                                                    : flightInfo.fareFamilies.fareFamilies.length === 1
                                                        ? "Book Now"
                                                        : activeCollapse === index && activeCollapse != null
                                                            ? <i class="fa fa-angle-double-up" style={{ fontSize: "14px" }} aria-hidden="true"><span className="text-le" >Prices</span></i>
                                                            : <i class="fa fa-angle-double-down" style={{ marginTop: "2px", fontSize: "14px" }} aria-hidden="true"><span className="text-le" >Prices</span></i>
                                            }
                                            {/* {(activeCollapse === index && activeCollapse != null) ? "Hide Prices" : flightInfo.fareFamilies.fareFamilies.length == 1 ? "Book Now" : "View Prices"} */}
                                        </button>
                                        {fareFamilies?.fareFamilies?.length > 0 && (
                                            <div className="refundRText">
                                                {flightInfo?.isRefundable === true ? (
                                                    <p className="refund">Refundable</p>
                                                ) : (
                                                    <p className="Nrefund">Non-Refundable</p>
                                                )}
                                            </div>
                                        )}

                                    </div>

                                    {/* </div> */}


                                    {/* <Modal
                    title="Send Flight Information"
                    visible={openMailModal}
                    onOk={openOk}
                    onCancel={OpenCancel}
                    footer={false}
                    className="flight-infoModal"
                    centered
                    width={400}
                  >
                    <Form onFinish={sendEmail} form={Emailform}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Required!" },
                          { type: "email", message: "Email is not a valid email" },
                        ]}
                      >
                        <Input placeholder="Enter Email Address" />
                      </Form.Item>

                      <Button type="primary" htmlType={"submit"}>
                        Send
                      </Button>
                    </Form>
                  </Modal>
                  <Modal
                    title="Send Flight Information"
                    visible={openNumberModal}
                    onOk={clickOk}
                    onCancel={clickCancel}
                    className="flight-infoModal"
                    footer={false}
                    centered
                    width={400}
                  >
                    <Input placeholder="Enter Mobile Number" />
                    <Button type="primary">Send</Button>
                  </Modal> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {promoData && !agent
                        ? promoData?.length > 0 && (
                            <div className="promo-strip">
                                <div className="promoData">
                                    Use code {promoData[1]?.Code} to get FLAT{" "}
                                    {promoData[1]?.Discount}{" "}
                                    {promoData[1]?.DiscountType === 0 ? "" : "%"} instant
                                    discount in this flight.
                                </div>

                            </div>
                        )
                        : ""} */}
                </Card>
            </div>

        </div >
        <div>
            <Modal
                title="Flight Details"
                visible={flightDetails}
                footer={[null]}
                onOk={(e) => setFlightDetails(false)}
                onCancel={(e) => setFlightDetails(false)}
                className="flight-details-modal"
            >
                <div className="all-times-local">
                    <Tabs
                        defaultActiveKey="1"
                        onTabClick={(str, e) => fetchFlightFares(str, e)}
                    >
                        <TabPane tab="Segments" key="1">
                            <div className="from-to-modal-details">
                                <Row gutter={16} className="more-details-row">
                                    <Col md={14} xs={24}>
                                        <Row align="middle" gutter={16}>
                                            <Col md={8} xs={8} className="more-details-list">


                                                <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                                                    {flightInfo.originCity}
                                                </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                                                    ({flightInfo.flightSegments[0].origin})
                                                </span>


                                                <p>
                                                    <strong>
                                                        {dateFormat(
                                                            flightInfo.flightSegments[0].departureDateTime,
                                                            "HH:MM"
                                                        )}
                                                        ,
                                                    </strong>
                                                    <span>
                                                        {dateFormat(
                                                            flightInfo.flightSegments[0].departureDateTime,
                                                            "dd mmm (ddd)"
                                                        )}
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col md={8} xs={8}>
                                                <div className="break-Rejourney-city">
                                                    <div className="inner_wrapper">
                                                        {flightInfo.flightSegments.map(
                                                            (flightSegInfo, index) =>
                                                                index !== 0 ? (
                                                                    <div
                                                                        className="stop_points"
                                                                        key={flightSegInfo.segId}
                                                                    >
                                                                        <Tooltip
                                                                            placement="top"
                                                                            title={() => (
                                                                                <div className="tooltip-dataRe">
                                                                                    {flightInfo.flightSegments.length -
                                                                                        1 >
                                                                                        1 ? (
                                                                                        <h4>Change</h4>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    <p>
                                                                                        {flightSegInfo.origin +
                                                                                            " " +
                                                                                            flightSegInfo.origin}
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                            className="citynames-tooltip"
                                                                        >
                                                                            <span className="break-city-name pointer_cursor">
                                                                                {flightSegInfo.origin}
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                ) : null
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8} xs={8} className="more-details-list">
                                                <p>
                                                    <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                                                        {flightInfo.destinationCity}
                                                    </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                                                        ({flightInfo.flightSegments[
                                                            flightInfo.flightSegments.length - 1
                                                        ].destination})
                                                    </span>
                                                </p>
                                                <p>
                                                    <strong>
                                                        {dateFormat(
                                                            flightInfo.flightSegments[
                                                                flightInfo.flightSegments.length - 1
                                                            ].arrivalDateTime,
                                                            "HH:MM"
                                                        )}
                                                        ,
                                                    </strong>
                                                    <span>
                                                        {dateFormat(
                                                            flightInfo.flightSegments[
                                                                flightInfo.flightSegments.length - 1
                                                            ].arrivalDateTime,
                                                            "dd mmm (ddd)"
                                                        )}
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} xs={24} className="trip-duration-time">
                                        <p>Total trip duration:</p>
                                        <p>
                                            {flightInfo.flightSegments.length > 0
                                                ? calculateDuration(
                                                    flightInfo.flightSegments[0].departureDateTime,
                                                    flightInfo.flightSegments[
                                                        flightInfo.flightSegments.length - 1
                                                    ].arrivalDateTime
                                                )
                                                : calculateDuration(
                                                    flightInfo.flightSegments[0].departureDateTime,
                                                    flightInfo.flightSegments[0].arrivalDateTime
                                                )}{" "}
                                            |
                                            {flightInfo.flightSegments.length - 1 === 0 ? (
                                                <>Direct</>
                                            ) : (
                                                <>{flightInfo.flightSegments.length - 1} stops </>
                                            )}
                                        </p>
                                        <p></p>
                                    </Col>
                                    <Col md={4} xs={24} className="trip-duration-time">
                                        <p>class:</p>
                                        <p>{flightSearchObj.cabinClass}</p>
                                    </Col>
                                </Row>
                            </div>

                            {flightInfo.flightSegments.map((flightSegment, i) => (
                                <div
                                    className="total-flight-details"
                                    key={"flighttiming" + i}
                                >
                                    <div className="to-fro-flight-details">
                                        <div className="from-city-flight-details">
                                            <span>{flightSegment.origin}</span>
                                        </div>
                                        <div>
                                            <span>
                                                departure:{" "}
                                                <strong>
                                                    {dateFormat(
                                                        flightSegment.departureDateTime,
                                                        "HH:MM"
                                                    )}
                                                </strong>
                                                {" , "}
                                            </span>
                                            <span>
                                                {dateFormat(
                                                    flightSegment.departureDateTime,
                                                    "dd mmm (ddd)"
                                                )}
                                            </span>
                                            <span>
                                                <strong>
                                                    {"  "}
                                                    {flightInfo.airLineName}(
                                                    {flightSegment.marketingAirline}-
                                                    {flightSegment.flightNumber})
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="collapsed-card">
                                    </div>

                                    <div className="to-fro-flight-details">
                                        <div className="from-city-flight-details">
                                            <span>{flightSegment.destination}</span>
                                        </div>
                                        <div>
                                            <span>
                                                arrival:{" "}
                                                <strong>
                                                    {formatFlightTime(flightSegment.arrivalDateTime)}
                                                </strong>
                                            </span>
                                            {" , "}
                                            <span>
                                                {dateFormat(
                                                    flightSegment.arrivalDateTime,
                                                    "dd mmm (ddd)"
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {i < flightInfo.flightSegments.length - 1 && (
                                        <div className="halt-wrapper">
                                            <p>
                                                Layover Time:{" "}
                                                {calculateDuration(
                                                    flightInfo.flightSegments[i + 1].departureDateTime,
                                                    flightSegment.arrivalDateTime
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </TabPane>

                        {cmsFareRules?.cancelPolicyDescription ? (
                            <TabPane tab="Cancel Policy" key="3">
                                <div className="fareRule-container">
                                    <div>
                                        {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                                    </div>
                                </div>
                            </TabPane>
                        ) : (
                            <TabPane tab="Cancel Policy" key="2">
                                <div className="fareRule-container">
                                    {!fareRulesResp ? (
                                        <p>Loading...</p>
                                    ) : (
                                        fareRulesResp.data.fareRules.map((rule, i) => {
                                            return (
                                                <div key={i}>
                                                    <pre>{ReactHtmlParser(rule.ruleDetails)}</pre>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </TabPane>
                        )}
                        {cmsFareRules?.reschedulePolicyDescription && (
                            <TabPane tab="Reschedule Policy" key="4">
                                <div className="fareRule-container">
                                    <pre>
                                        {ReactHtmlParser(
                                            cmsFareRules.reschedulePolicyDescription
                                        )}
                                    </pre>
                                </div>
                            </TabPane>
                        )}

                        {cmsFareRules?.covidRulesDescription && (
                            <TabPane tab="Covid Rules Policy" key="5">
                                <div className="fareRule-container">
                                    <pre>
                                        {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                                    </pre>
                                </div>
                            </TabPane>
                        )}
                        {cmsFareRules?.otherRulesDescription && (
                            <TabPane tab="Other Rules Policy" key="6">
                                <div className="fareRule-container">
                                    <pre>
                                        {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                                    </pre>
                                </div>
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </Modal>
        </div>
    </>
    );
};

export const FlightSelection = ({
    flightInfo,
    changeFlightResultsView,
    flightSearchObj,
    selectedFlight,
    showNetFare,
    journeyType,
    MultiCityIndex,
    setActiveCollapse, activeCollapse, index
}) => {
    const { Panel } = Collapse;
    const [flightArr, setFlightArr] = useState([]);
    const { activeCurrency, currencyValue } = useCurrencyContext();
    //  console.log(journeyType, "jt", flightArr, flightInfo, MultiCityIndex, index);
    // useEffect(() => {
    //     const newSelectedFlights = [null, null];
    //     if (journeyType == "Onward" && index == "FLC-1") {
    //      //   console.log("iam not comming");

    //         newSelectedFlights[0] = flightInfo;

    //     }
    //     else if (journeyType == "Return" && index == "FLC-2"){
    //        // console.log(newSelectedFlights, "selected");
    //         newSelectedFlights[1] = flightInfo;
    //     }


    //     if (!selectedFlight || selectedFlight.length === 0) {
    //      //   console.log(newSelectedFlights, "selected");
    //         changeFlightResultsView(newSelectedFlights);
    //     }
    // }, [journeyType, index, flightInfo]);
    const onClickChooseFlight = (fareFamiliesIndex, jrny) => {
        if (
            flightSearchObj.resultsType === "COMBINED" &&
            (flightSearchObj.airTravelType === "roundTrip" ||
                flightSearchObj.airTravelType === "multidestination")
        ) {
            setFlightArr(
                flightInfo.flightDetails.map((flightDetObj) => ({
                    ...flightDetObj,
                    fareFamilies: flightInfo.fareFamilies,
                }))
            );
        } else if (selectedFlight.length > 0 && flightSearchObj.airTravelType === "roundTrip") {
            const newSelectedFlights = [...selectedFlight];
            // console.log("by1", selectedFlight.length, flightInfo, newSelectedFlights);
            if (jrny === "Return") {
                newSelectedFlights[1] = flightInfo;
                // console.log("by2", jrny)
            } else {
                newSelectedFlights[0] = flightInfo;
                // console.log("by3")
            }
            changeFlightResultsView(newSelectedFlights);
        } else {
            changeFlightResultsView([flightInfo]);
            // console.log("by4")
        }
    };


    const [openMailModal, setOpenMailModal] = useState(false);
    const showMailModal = () => {
        setOpenMailModal(true);
    };
    const openOk = (e) => {
        setOpenMailModal(false);
    };

    const OpenCancel = (e) => {
        setOpenMailModal(false);
    };
    const [openNumberModal, setOpenNumberModal] = useState(false);

    const clickOk = (e) => {
        setOpenNumberModal(false);
    };

    const clickCancel = (e) => {
        setOpenNumberModal(false);
    };
    const [Emailform] = Form.useForm();

    const sendEmail = (formData) => {
        let emailData = {
            to: formData.email,
            data: {
                url: document.location.href,
                airLine: flightInfo.airLine,
                airLineLogo: flightInfo.airLineLogo,
                airLineName: flightInfo.airLineName,
                cabinClass: flightSearchObj.cabinClass,
                currency: flightInfo.fareFamilies.fareFamilies[0].currency,
                destiantionName: flightInfo.destiantionName,
                destination: flightInfo.destination,
                duration: flightInfo.duration,
                flightId: flightInfo.flightId,
                flightSegments: flightInfo.flightSegments,
                isBookOffline: flightInfo.isBookOffline,
                origin: flightInfo.origin,
                originName: flightInfo.originName,
                totalFare: Number(
                    flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                ).toFixed(2),
            },
            cc: "",
            bcc: "",
            subject: "Flight Information",
        };

        ApiClient.post("email/flightsegment", emailData)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Mail Sent Successfully", 3);
                    Emailform.resetFields();
                    OpenCancel();
                }
            })
            .catch((e) => console.log(e));
    };
    const priceToolTip = () => {
        let base = 0;
        let service = 0;
        let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);
        let Agentmarkup = Number(flightInfo.fareFamilies.fareFamilies[0].agentMarkup);

        flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
            if (fare.fareTag === "Base" && fare.paxType === "ADT") {
                base = fare.amount;


            } else if (fare.fareTag === "Tax" && fare.paxType === "ADT") {
                service = fare.amount;

            }
        });

        return (
            <div className="pointer_cur">
                <p>Per Pax's</p>
                <p>
                    Base Fare: {activeCurrency} {currencyValue(base + markup)}
                </p>
                <p>
                    Tax Charges: {activeCurrency} {currencyValue(service)}
                </p>
            </div>
        );
    };

    const luggageDetails = () => {
        return (
            <div className="luggage-tooltip">
                <Row gutter={16}>
                    {flightInfo.fareFamilies.fareFamilies[0].baggage.map((bag) => {
                        return (
                            <Col>

                                <p>{bag.cityPair}</p>
                                <p>
                                    <i className="fa fa-plane"></i>{" "}
                                    <span>Airline {bag.airline}</span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.baggageInfo} Check-In Baggage </span>
                                </p>
                                <p>
                                    <ShoppingOutlined className="ant-icon" />{" "}
                                    <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                                </p>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };

    const pricetool = () => { }
    return (
        <>
            <div className="choose-btn-block"   >
                <div className="share-price-mail">
                    {selectedFlight[MultiCityIndex]?.flightId == flightInfo.flightId && MultiCityIndex != null ? <p style={{ marginTop: '3%', marginRight: '10%' }} >Selected</p> : ""}
                    <div className="choose-btn-price">
                        {showNetFare ? (
                            <>
                                <span className="currencyType text-line">{activeCurrency}</span>
                                <Tooltip title={priceToolTip}>
                                    <span className="currency text-line">
                                        {
                                            currencyValue(
                                                flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                                            )
                                        }
                                    </span >
                                </Tooltip >

                                <div>
                                    <Tooltip placement="bottom" title={luggageDetails}>
                                        <p className="details-bag-icon" style={{ marginRight: 18 }} >
                                            Baggage <ShoppingOutlined />
                                        </p>
                                    </Tooltip>
                                </div>

                                <div className="text-left">
                                    <p>
                                        {activeCurrency}{" "}
                                        {currencyValue(
                                            flightInfo.fareFamilies.fareFamilies[0].adultFare
                                        )}{" "}
                                    </p>
                                    <p className="netfare">
                                        Inc: {activeCurrency}{" "}
                                        {currencyValue(
                                            flightInfo.fareFamilies.fareFamilies[0].commission
                                        )}{" "}
                                    </p>
                                </div>
                                {/* <p className="flight-class" style={{ margin: '0px 0px 0px' }}>
                                    Coupan Type: {flightInfo.fareFamilies?.fareFamilies[0]?.coupanType}
                                </p> */}
                                <div>
                                    <Tooltip placement="bottom" title={luggageDetails}>
                                        <p className="details-bag-icon" style={{ marginRight: 18 }}>
                                            Baggage <ShoppingOutlined />
                                        </p>
                                    </Tooltip>
                                </div>

                            </>
                        ) : (
                            <>
                                <span className="currencyType">
                                    {activeCurrency}
                                </span>
                                <Tooltip title={priceToolTip}>
                                    <span className="currency">
                                        {currencyValue(
                                            flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                                        )}
                                    </span>

                                </Tooltip>{" "}
                                {/* <p className="flight-class">
                                    Coupan Type: {flightInfo.fareFamilies?.fareFamilies[0]?.coupanType}
                                </p> */}
                                {/* <div>
                                    <Tooltip placement="bottom" title={luggageDetails}>
                                        <p className="details-bag-icon" style={{ marginRight: 18 }}>
                                            Baggage <ShoppingOutlined />
                                        </p>
                                    </Tooltip>
                                </div> */}
                            </>

                        )}

                    </div >
                    <div className="right-price-section">
                        <span className="price-text">
                            Price for{" "}
                            {flightSearchObj.adultCount +
                                flightSearchObj.infantCount +
                                flightSearchObj.childCount}{" "}
                            passenger,
                            <br />{" "}
                            {flightSearchObj.airTravelType === "oneWay"
                                ? "Oneway"
                                : "Round Trip"}
                        </span>
                    </div>
                </div >
                <div className="choose-btn-section">
                    <div className="choose-web-btn">
                        <button
                            className="choose-button"
                            onClick={(e) => {
                                let reqData = {
                                    serviceType: 1,
                                    airlineCode: flightInfo.airLine,
                                };
                                if (flightInfo.fareFamilies.fareFamilies.length == 1) {
                                    onClickChooseFlight(0, journeyType);
                                } else {

                                    if (activeCollapse === index) {
                                        setActiveCollapse("");
                                    } else {
                                        setActiveCollapse(index);
                                    }
                                }
                            }}
                        >
                            {activeCollapse === index && activeCollapse != null
                                ? <i class="fa fa-chevron-up" aria-hidden="true">Prices</i>
                                : flightInfo.fareFamilies.fareFamilies.length == 1
                                    ? "Book Now"
                                    : <i class="fa fa-angle-double-down" aria-hidden="true">Prices</i>}
                            {/* {(activeCollapse === index && activeCollapse != null) ? "Hide Prices" : flightInfo.fareFamilies.fareFamilies.length == 1 ? "Book Now" : "View Prices"} */}
                        </button>
                    </div>

                </div>

                <Modal
                    title="Send Flight Information"
                    visible={openMailModal}
                    onOk={openOk}
                    onCancel={OpenCancel}
                    footer={false}
                    className="flight-infoModal"
                    centered
                    width={400}
                >
                    <Form onFinish={sendEmail} form={Emailform}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: "Required!" },
                                { type: "email", message: "Email is not a valid email" },
                            ]}
                        >
                            <Input placeholder="Enter Email Address" />
                        </Form.Item>

                        <Button type="primary" htmlType={"submit"}>
                            Send
                        </Button>
                    </Form>
                </Modal>
                <Modal
                    title="Send Flight Information"
                    visible={openNumberModal}
                    onOk={clickOk}
                    onCancel={clickCancel}
                    className="flight-infoModal"
                    footer={false}
                    centered
                    width={400}
                >
                    <Input placeholder="Enter Mobile Number" />
                    <Button type="primary">Send</Button>
                </Modal>
            </div >
        </>
    );
};
export const FlightSe = ({
    flightInfo,
    changeFlightResultsView,
    flightSearchObj,
    selectedFlight,
    showNetFare,
    journeyType = false,
    MultiCityIndex,
    setActiveCollapse, activeCollapse, index,
    traceId,
    ListType
}) => {
    // {console.log("iam comming",selectedFlight)}
    const {
        updateSelectedFlight,
    } = useFlightContext();
    const { Panel } = Collapse;
    const [flightArr, setFlightArr] = useState([]);
    const [flightInfoCopy, setFlightInfoCopy] = useState(flightInfo)
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const [FareDetails, setFareDetails] = useState(false);
    const [FareRules, setFareRules] = useState();
    const [flightdetailsactivecss, setflightdetailsactivecss] = useState({
        flightdetails: true, cancel: false, reschedule: false
        , covidRules: false, otherRules: false
    })
    const [act, setact] = useState(0)
    const [fareIndex, setfareIndex] = useState(0);
    const onClickChooseFlight = (fareFamiliesIndex) => {
        // {console.log(journeyType,selectedFlight,flightSearchObj.airTravelType,fareFamiliesIndex,"journry")}
        const newFlightInfo = { ...flightInfo };
        // console.log(newFlightInfo, "flightinfochange");
        if (newFlightInfo.fareFamilies?.fareFamilies?.length > 1) {
            newFlightInfo.fareFamilies.fareFamilies = getFaredetails([...newFlightInfo.fareFamilies.fareFamilies], fareFamiliesIndex);
        }
        if (flightSearchObj.airTravelType === "roundTrip") {
            if (journeyType === "return") {
                if (selectedFlight.length === 2) {
                    updateSelectedFlight([...selectedFlight.slice(0, 1), newFlightInfo]);
                } else {
                    updateSelectedFlight([...selectedFlight, newFlightInfo]);
                }
            } else {
                updateSelectedFlight([newFlightInfo, ...selectedFlight.slice(1)]);
            }
        } else if (flightSearchObj.airTravelType === "multidestination") {
            if (selectedFlight.length < flightSearchObj.originDestinations.length - 1) {
                updateSelectedFlight([...selectedFlight, newFlightInfo]);
            } else {
                updateSelectedFlight([newFlightInfo]);
            }
        } else {
            updateSelectedFlight([newFlightInfo]);
        }
    };
    const getFaredetails = (fareArr, fareFamiliesIndex) => {
        let FareIndexData = [];
        let ZeroIndexData = [];
        let FareFamiliesList = fareArr
        FareIndexData = FareFamiliesList[fareFamiliesIndex];
        ZeroIndexData = FareFamiliesList[0];
        FareFamiliesList[0] = FareIndexData;
        FareFamiliesList[fareFamiliesIndex] = ZeroIndexData;
        return FareFamiliesList;

    }
    const [openMailModal, setOpenMailModal] = useState(false);
    const showMailModal = () => {
        setOpenMailModal(true);
    };
    const openOk = (e) => {
        setOpenMailModal(false);
    };

    const OpenCancel = (e) => {
        setOpenMailModal(false);
    };
    const [openNumberModal, setOpenNumberModal] = useState(false);
    const showNumberModal = () => {
        setOpenNumberModal(true);
    };

    const clickOk = (e) => {
        setOpenNumberModal(false);
    };

    const clickCancel = (e) => {
        setOpenNumberModal(false);
    };
    const [Emailform] = Form.useForm();
    const getCoupanTypeData = (key, coupanType) => {

    };
    const getAdminFareRules = (fareReqObj) => {

    };
    const formatFlightTime = (flightTime) => {
        let [date, time] = flightTime.split("T");
        let [hour, minute, seconds] = time.split(":");
        return `${hour}:${minute}`;
    };
    const sendEmail = (formData) => {
        let emailData = {
            to: formData.email,
            data: {
                url: document.location.href,
                airLine: flightInfo.airLine,
                airLineLogo: flightInfo.airLineLogo,
                airLineName: flightInfo.airLineName,
                cabinClass: flightSearchObj.cabinClass,
                currency: flightInfo.fareFamilies.fareFamilies[0].currency,
                destiantionName: flightInfo.destiantionName,
                destination: flightInfo.destination,
                duration: flightInfo.duration,
                flightId: flightInfo.flightId,
                flightSegments: flightInfo.flightSegments,
                isBookOffline: flightInfo.isBookOffline,
                origin: flightInfo.origin,
                originName: flightInfo.originName,
                totalFare: Number(
                    flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                ).toFixed(2),
            },
            cc: "",
            bcc: "",
            subject: "Flight Information",
        };

        ApiClient.post("email/flightsegment", emailData)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Mail Sent Successfully", 3);
                    Emailform.resetFields();
                    OpenCancel();
                }
            })
            .catch((e) => console.log(e));
    };
    const priceToolTip = () => {
        let base = 0;
        let service = 0;
        let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);

        flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
            if (fare.fareTag == "Base") {
                base += fare.amount;

            } else if (fare.fareTag == "Tax") {
                service += fare.amount;

            }
        });

        return (
            <div className="pointer_cursor">
                <p>
                    Base Fare: {activeCurrency} {currencyValue(base + markup)}
                </p>
                <p>
                    Tax Charges: {activeCurrency} {currencyValue(service)}
                </p>
            </div>
        );
    };
    const getCancellationDetails = (id) => {

        let FareRuleObj = {
            flowType: "SEARCH",
            traceId: traceId,
            flightId: flightInfo?.flightId,
            airTravelType: flightSearchObj.airTravelType,
            priceId: id,
            mappingType: flightSearchObj.resultsType,
            itineraryViewType: "1",

        }
        ApiClient.post("flights/airFareRules", FareRuleObj)
            .then((response) => {
                if (response.statusCode == 200) {
                    setFareRules(response?.data?.fareRules)
                    setFareDetails(true);
                } else if (response.status == 404) {
                    message.error(response.message, 3);
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <>
            {/* {ListType && (<div className="flightdeatils_drop">
        <span style={{ fontSize: '13px' }} className="flightdeatils_drop_text" onClick={() => { setact(1); }}
        ><a>Flight Details</a></span>
        {flightInfo.fareFamilies.fareFamilies.map((item, indexP) => {
          return (
            <span>{flightInfo.fareFamilies.fareFamilies.length == 1 ? <span className="View_Cancel-detailss" onClick={() => getCancellationDetails(item.fareId)}><a>Fare Rules</a></span> : ""} </span>
          )
        })}

      </div>
      )} */}




            {ListType && (act === 1 && <Collapse activeKey={act} bordered={false}>

                <Panel showArrow={false} key={1}>
                    <div className="flight_deatails">
                        <div className="flight_details_headings">
                            <div className="active_tab" onClick={() => setflightdetailsactivecss((prev) => ({ ...prev, flightdetails: true }))}
                                style={{ backgroundColor: flightdetailsactivecss?.flightdetails ? "#0091FF" : "" }}><a>Flight Details</a></div>
                        </div>
                        <div className="active_tab tab-X-one">
                            <CloseOutlined onClick={() => setact(0)} />
                        </div>
                        <div className="flightdetails_content" >
                            <div className="Journey_heading">
                                <p className="journey_route">{flightInfo?.originCity} To {flightInfo?.destinationCity} , {moment(flightInfo?.flightSegments[0].departureDateTime).format("MMM D")}</p>
                            </div>
                            <div>
                                {flightInfo.flightSegments.map((flightSegment, i) => (
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div className="flight_logo_up">
                                                <img className="flight_logo" src={`https://www.gstatic.com/flights/airline_logos/70px/${flightSegment?.operatingAirline}.png`}></img>
                                                <span className="airLine"><b>{flightSegment?.airLineName}</b></span>
                                                <span className="flight_number">{flightSegment?.operatingAirline}|{flightSegment?.operatingFlightNumber}</span>
                                            </div>
                                            <Row gutter={16} className="flight_timing">
                                                <Col className="origin_details">
                                                    <p className="time">
                                                        {flightSegment?.originCity}<br />
                                                        {formatFlightTime(
                                                            flightSegment.departureDateTime
                                                        )}
                                                    </p>
                                                    <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                                                        {moment(
                                                            flightSegment.departureDateTime
                                                        ).format("dddd, Do MMMM YY")}
                                                    </p>
                                                    <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.departureTerminal}</p>
                                                </Col >
                                                <Col className="duration">
                                                    <i class="fa fa-clock-o" style={{ color: 'black', fontSize: '18px' }} ></i>
                                                    <p>{flightSegment?.journeyDuration}</p>
                                                </Col>
                                                <Col className="destination_details">
                                                    <p className="time">
                                                        {flightSegment?.destinationCity}<br />
                                                        {formatFlightTime(
                                                            flightSegment.arrivalDateTime
                                                        )}
                                                    </p>
                                                    <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                                                        {moment(
                                                            flightSegment.arrivalDateTime
                                                        ).format("dddd, Do MMMM YY")}
                                                    </p>
                                                    <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.arrivalTerminal}</p>
                                                </Col >
                                            </Row >
                                        </div >
                                        {i === flightInfo.flightSegments.length - 1 ? null : <div className="change_planes">
                                            <div className="change_planes_border">Layover : &nbsp;
                                                {moment.utc(moment(flightInfo.flightSegments[i + 1]?.departureDateTime).diff(moment(flightSegment.arrivalDateTime))).format('HH:mm')}
                                            </div>
                                        </div>}
                                    </div >
                                ))}
                            </div >
                        </div >
                    </div >


                </Panel >
            </Collapse >)}
            {
                !ListType && <div className="flightdeatils_drop">
                    <span style={{ fontSize: '13px' }} className="flightdeatils_drop_text" onClick={() => { setact(1); }}
                    ><a>Flight Details</a></span>

                </div>
            }

            {
                (act === 1 && !ListType) && <Collapse activeKey={act} bordered={false}>
                    <Panel showArrow={false} key={1}>
                        <div className="flight_deatails">
                            <div className="flight_details_headings">
                                {flightInfo?.flightDetails.map((flight, id) => {
                                    return <div className="active_tab" onClick={() => setfareIndex(id)}><a>Flight Details({flight?.origin}-{flight?.destination})</a>
                                    </div>
                                })}</div>
                            <div className="active_tab tab-X-one">
                                <CloseOutlined onClick={() => setact(0)} />
                            </div>
                            <div className="flightdetails_content" >

                                <div>
                                    {flightInfo?.flightDetails?.[fareIndex]?.flightSegments.map((flightSegment, i) => (

                                        <div>
                                            <div style={{ display: 'flex' }} >
                                                <div className="flight_logo_up">
                                                    <img className="flight_logo" src={`https://www.gstatic.com/flights/airline_logos/70px/${flightSegment?.operatingAirline}.png`}></img>
                                                    <span className="airLine"><b>{flightSegment?.airLineName}</b></span>
                                                    <span className="flight_number">{flightSegment?.operatingAirline}|{flightSegment?.operatingFlightNumber}</span>
                                                </div>
                                                <Row gutter={16} className="flight_timing">
                                                    <Col className="origin_details">
                                                        <p className="time">
                                                            {flightSegment?.originCity}<br />
                                                            {formatFlightTime(
                                                                flightSegment.departureDateTime
                                                            )}
                                                        </p>
                                                        <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                                                            {moment(
                                                                flightSegment.departureDateTime
                                                            ).format("dddd, Do MMMM YY")}
                                                        </p>
                                                        <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.departureTerminal}</p>
                                                    </Col >
                                                    <Col className="duration">
                                                        <i class="fa fa-clock-o" style={{ color: 'black', fontSize: '18px' }} ></i>
                                                        <p>{flightSegment?.journeyDuration}</p>
                                                    </Col>
                                                    <Col className="destination_details">
                                                        <p className="time">
                                                            {flightSegment?.destinationCity}
                                                            <br />
                                                            {formatFlightTime(
                                                                flightSegment.arrivalDateTime
                                                            )}
                                                        </p>
                                                        <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                                                            {moment(
                                                                flightSegment.arrivalDateTime
                                                            ).format("dddd, Do MMMM YY")}
                                                        </p>
                                                        <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.arrivalTerminal}</p>
                                                    </Col >
                                                </Row >
                                            </div >
                                            {
                                                flightInfo.flightSegments?.length - 1 > i && <div className="change_planes">
                                                    <div className="change_planes_border">Change of planes</div>
                                                </div>
                                            }
                                        </div >
                                    ))
                                    }
                                </div >
                            </div >
                        </div >


                    </Panel >
                </Collapse >}
            {
                index === activeCollapse ? <div className={flightInfo.fareFamilies.fareFamilies.length > 1 ? "FarDetailsCollapse" : "FarDetailsCollapse_none"}>
                    <Collapse activeKey={activeCollapse} bordered={false}>
                        <Panel key={index} showArrow={false}>
                            {flightInfo.fareFamilies.fareFamilies.length > 0 && (
                                <div className="fareTypeContainer mt-1">
                                    <Row className="FareDetails_Heading"  >
                                        <Col className="view-pricecolss">FARE TYPE</Col>
                                        {/* <Col className="view-pricecolss1">CABIN BAG</Col> */}
                                        <Col className="view-pricecolss1" style={{ marginRight: "8px" }}>CHECK-IN</Col>
                                        <Col className="view-pricecolss2" style={{ marginRight: "16px" }}>CANCELLATION</Col>
                                        <Col className="view-pricecolss1" style={{ marginRight: "48px" }}>SEAT</Col>
                                        <Col className="view-pricecolss1" style={{ marginRight: "4px" }}>Price</Col>
                                    </Row >

                                    {
                                        flightInfo.fareFamilies.fareFamilies.map((item, indexP) => {
                                            return (


                                                <Row className="Faredetails_content"  >

                                                    <Col className="view-pricecolss">
                                                        <p className="FareTypeName">{item.coupanType}</p>
                                                        <p className="sub-FareTypeName">
                                                            {item.coupanType.toUpperCase() == "SMEFARE"
                                                                ? "Eligible for small or medium corporate customer."
                                                                : item.coupanType.toUpperCase() == "CORPORATEFARE"
                                                                    ? "Exclusively offered to organizations."
                                                                    : "Fare offered by airline."}
                                                        </p>
                                                        <p className="sub-FareTypeName">
                                                            <b>Sub-Cabin Class: {item.segmentInfos.length ? item.segmentInfos[0].cabinSubClass : ""}</b>
                                                        </p>
                                                    </Col>
                                                    {/* <Col className="view-pricecolss1">
                                                        <p className="m-0 text">{item.baggage[0]?.cabinBaggageInfo}</p>
                                                    </Col> */}
                                                    <Col className="view-pricecolss1">
                                                        <p className="m-0 text">{item.baggage[0]?.baggageInfo}</p>
                                                    </Col>
                                                    <Col className="view-pricecolss2">

                                                        <Button style={{ width: '80%' }} className="View_Canceldetails" onClick={() => getCancellationDetails(item.fareId)}>View</Button>
                                                    </Col >

                                                    <Col className="view-pricecolss1">
                                                        <p className="m-0 text">{item.segmentInfos[0].seatRemaining}  Available</p>
                                                    </Col>
                                                    {/* <Col className="view-pricecolss1">
                                                        {" "}
                                                    </Col> */}
                                                    <Col className="Button_fare view-pricecolss3"  >
                                                        <div className="view-pricecolss1">
                                                            <p className="Fare_text" style={{ display: "contents" }}><span style={{ fontSize: "12px", fontWeight: "600" }}>₹</span> {Math.round(item.adultNetFare)}</p>
                                                        </div>
                                                        <div className="choose-web-btn text-right">
                                                            <Button
                                                                style={{ width: '100%' }}
                                                                className="choose-button"
                                                                onClick={() => {
                                                                    onClickChooseFlight(indexP);
                                                                }}
                                                            >
                                                                Book
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row >
                                            );
                                        })
                                    }
                                </div >
                            )}

                            <div className="bottom-airline-text">
                                <p className="static_text">
                                    Airline reserves the right to change the Cancellation/Change Fees from time to time without any prior intimation. *Fee as per existing Regular Fare Policy applicable & may vary for
                                    domestic and international bookings. **Days left for departure.
                                </p>
                            </div>
                        </Panel >
                    </Collapse >
                </div > : null}
            <div>
                <Modal
                    title="Fare Rules"
                    visible={FareDetails}
                    footer={[null]}
                    onOk={(e) => setFareDetails(false)}
                    onCancel={(e) => setFareDetails(false)}
                    className="flight-details-modal"
                >
                    <div style={{ padding: "8px" }}>
                        <b>CancellationPolicy : </b>
                        <div>{FareRules ? FareRules.map((data, id) => {
                            return (<div>
                                <div>{id + 1}Flight</div>

                                <div>{ReactHtmlParser(data.ruleDetails)}</div>
                            </div >
                            )
                        }) : "No Rule"}</div >
                    </div >
                </Modal >
            </div >
        </>
    );
};

export default Flight;
