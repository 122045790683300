import React, { useState, useEffect } from "react";
import "../Dashboard/Dashboard.scss";
import { Row, Col, Card, Skeleton, Collapse } from "antd";
import { Link } from "react-router-dom";
import Flightsalescount from "../../admin/Flightsalescount/Flightsalescount";
import Bussalescount from "../../admin/Bussalescount/Bussalescount";
import HotelSalescount from "../../admin/Hotelsalescount/Hotelsalescount";

import FlightSalesCountSkeleton from "../Flightsalescount/FlightSalesCountSkeleton";
import BusSalesCountSkeleton from "../Bussalescount/BusSalesCountSkeleton";
import HotelSalesCountSkeleton from "../Hotelsalescount/HotelSalesCountSkeleton";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Panel } = Collapse;
const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({   
    Buses: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    Flights: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    Hotels: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    Recharge: {
      recent_bookings: [],
    },
    userCounts: {},
  });
  useEffect(() => {
    getDashboardData();
  }, []);
  const getDashboardData = () => {
    ApiClient.get("admin/dashboard")
      .then((res) => {
        if (res && res.status == 200) {
          setDashboardData((prev) => ({ ...prev, ...res.data }));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>
                Dashboard <HelpInfoHelper screenName={"/admin/dashboard"} />
              </h4>
            </div>
          </div>

          <div>
            <Row gutter={[16, 16]}>
              {/* card--1--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--4--close */}

              {/* card--5--open */}
              <Col xs={24} md={6}>
                <Card>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </Col>

              {/* card--5--close */}
            </Row>
          </div>
          {/* first--order--close--- */}

          {/* flight--sales--open */}

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <FlightSalesCountSkeleton />
              </Col>
            </Row>
          </div>

          {/* flight--sales--close */}

          {/* Bus--sales--open */}

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <BusSalesCountSkeleton />
              </Col>
            </Row>
          </div>

          <div className="order-count-cards">
            <Row>
              <Col xs={24} md={24}>
                <HotelSalesCountSkeleton />
              </Col>
            </Row>
          </div>
          {/* Bus--sales--close */}
        </div>
      ) : (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>Dashboard</h4>
            </div>

            <div className="dash-board-header-btn"></div>
          </div>

          <div className="order-count-cards">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={6}>
                <Card className="order-card card-bg-1">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">
                        Site Partners Count
                      </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.agents_count}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-desktop dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/agentregistration">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <Col xs={24} md={6}>
                <Card className="order-card card-bg-4">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Subscribers </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.subscribers}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-clock-o dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>
                  <div className="bottom-dashboad-bdr-3">
                    <Link to="/admin/subscriberReports">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>

              {/* card--4--close */}

              {/* card--5--open */}
              {/* <Col xs={24} md={6}>
                <Card className="order-card card-bg-5">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Customer Requests</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.customer_requests}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>

                  <div className="bottom-dashboad-bdr-4">
                    <p className="dashboard-datails-p">
                      View Details&nbsp;
                      <i
                        className="fa fa-arrow-circle-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </Card>
              </Col> */}

              {/* card--5--close */}

              {/* card--5--open */}
              <Col xs={24} md={6}>
                <Card className="order-card card-bg-b2">
                  <Row>
                    <Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Site Users Count</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.users_count}
                      </p>
                    </Col>
                    <Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </Col>
                  </Row>

                  <div className="bottom-dashboad-bdr-4">
                    <Link to="/admin/siteusers">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          {/* first--order--close--- */}

          {/* flight--sales--open */}
          <Collapse accordion className="my-2" defaultActiveKey={["flight"]}>
            <Panel header="Flight Sales" key="flight" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <Flightsalescount flightsData={dashboardData.Flights} />
                  </Col>
                </Row>
              </div>
            </Panel>

            <Panel header="Bus Sales" key="bus" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <Bussalescount busData={dashboardData.Buses} />
                  </Col>
                </Row>
              </div>
            </Panel>

            <Panel header="Hotel Sales" key="hotels" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <HotelSalescount hotelData={dashboardData.Hotels} />
                  </Col>
                </Row>
              </div>
            </Panel>

            {/* <Panel header="Activity Sales" key="activity" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <Activitysalescount
                      activitiesData={dashboardData.Activities}
                    />
                  </Col>
                </Row>
              </div>
            </Panel> */}

            {/* <Panel header="Recharge/Bills Sales" key="r/b" className="">
              <div className="order-count-cards">
                <Row>
                  <Col xs={24} md={24}>
                    <RBSalesCount salesData={dashboardData.Recharge} />
                  </Col>
                </Row>
              </div>
            </Panel> */}
          </Collapse>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
