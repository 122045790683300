import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Tabs,
  Tooltip,
  Collapse,
  Spin,
} from "antd";
import moment from "moment";
import CustomFliImg from "../../../common/CustomImg/CusFliLogo";
import departureplane from "../../../assets/images/departure-plane.jpeg";
import arivalplane from "../../../assets/images/arival-plane.png";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import dateFormat from "dateformat";
import { useFlightContext } from "../../../common/providers/Flights/FlightContext";
// import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { calculateDuration } from "../../../common/AllTickets/Flight/flightHelper";
import ApiClient from "../../../helpers/ApiClient";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import {
  MailOutlined,
  RightOutlined,
  ShoppingOutlined,
  LineOutlined,
  InfoCircleOutlined,
  CiCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import "./Flight.scss";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import { getFacet } from "bizcharts";
import { LoadingOutlined } from "@ant-design/icons";
// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const FlightTimings = ({ flightInfo, airSearchData, fareFamilies, indexf, props }) => {
  //console.log(flightInfo, indexf, "info");
  const { user } = useAuthContext();
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { TabPane } = Tabs;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [cmsFareRules, setCmsFareRules] = useState({});
  const [flightDetails, setFlightDetails] = useState(false);
  const [flightInfoResp, setFlightInfoResp] = useState("");
  const {
    updateSelectedFlight,
    state: { flightAirSearchResp, flightSearchObj, selectedFlight },
  } = useFlightContext();

  // console.log(flightAirSearchResp, updateSelectedFlight, selectedFlight, "searchresp");
  const [isLoading, setIsLoading] = useState(true);

  const showModal = () => {
    // console.log("iam Comming");
    setFlightDetails(true);

    fetchFlightFares();
    flightInfoRes();
  };

  const getFlightIds = () => {



    if (airSearchData?.airTravelType === "oneWay") {
      return [flightInfo?.flightId];
    } else {

      if (airSearchData?.airTravelType === "roundTrip" && flightAirSearchResp?.resultsType === "SEPARATE") {
        return [flightInfo?.flightId];
      } else {
        // console.log(flightInfo,props.flightresult,indexf,"hemanth")


        const flightIds = props.flightresult[indexf]?.flightDetails?.map(detail => detail.flightId);



        return flightIds;
      }
    }
  };

  const flightInfoRes = () => {
    if (airSearchData) {
      let selectedFlightArray = [];

      // if (airSearchData.airTravelType.toUpperCase() === "MULTIDESTINATION") {
      //   if (airSearchData.resultsType.toUpperCase() === "COMBINED" &&
      //     airSearchData.airTravelType.toUpperCase() === "MULTIDESTINATION") {
      //     selectedFlightArray.push({
      //       fareId: fareFamilies.fareFamilies[0]?.fareId,
      //       flightId: flightInfo.flightId,
      //       coupanType: fareFamilies.fareFamilies[0]?.coupanType,
      //       fareType: fareFamilies.fareFamilies[0]?.fareType,
      //       subCabinClass:
      //         fareFamilies.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
      //     });
      //   }
      //   else {

      //     selectedFlightArray.push({
      //       fareId: fareFamilies.fareFamilies[0]?.fareId,
      //       flightId: flightInfo.flightId,
      //       coupanType: fareFamilies.fareFamilies[0]?.coupanType,
      //       fareType: fareFamilies.fareFamilies[0]?.fareType,
      //       subCabinClass:
      //         fareFamilies.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
      //     });

      //   }
      // } else {

      //   if (airSearchData.resultsType.toUpperCase() === "COMBINED" &&
      //     airSearchData.airTravelType.toUpperCase() === "ROUNDTRIP") {
      //     selectedFlightArray.push({
      //       fareId: fareFamilies.fareFamilies[0]?.fareId,
      //       flightId: flightInfo.flightId,
      //       coupanType: fareFamilies.fareFamilies[0]?.coupanType,
      //       fareType: fareFamilies.fareFamilies[0]?.fareType,
      //       subCabinClass:
      //         fareFamilies.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,
      //     });
      //   } else {
      //     selectedFlightArray.push({
      //       fareId: fareFamilies.fareFamilies[0]?.fareId,
      //       flightId: flightInfo.flightId,
      //       coupanType: fareFamilies.fareFamilies[0]?.coupanType,
      //       fareType: fareFamilies.fareFamilies[0]?.fareType,
      //       subCabinClass:
      //         fareFamilies.fareFamilies[0]?.segmentInfos[0]?.cabinSubClass,


      //     });
      //   }

      // }
      setIsLoading(true);
      let airInfoReqObj;
      if (flightInfo?.supplierKey === 'AKBAR') {
        airInfoReqObj = {
          flowType: "SEARCH",
          traceId: airSearchData.traceId,
          // flightId: flightInfo.flightId,
          flightId: getFlightIds(),
          airTravelType: airSearchData.airTravelType,
          priceId:
            airSearchData?.resultsType == "COMBINED"
              ? fareFamilies?.fareFamilies?.[0].fareId
              : flightInfo.fareFamilies?.fareFamilies[0].fareId,
          mappingType: airSearchData.resultsType,
          itineraryViewType: "1",
          TripType: flightInfo?.isReturn ? "Return" : "Onward",
          Supplier: flightInfo.supplierKey,
          tokenId: airSearchData?.resultsType == "COMBINED"
            ? fareFamilies?.fareFamilies?.[0].supplierParameter
            : flightInfo.fareFamilies?.fareFamilies[0].supplierParameter,
        };
      } else {
        airInfoReqObj = {
          flowType: "SEARCH",
          traceId: airSearchData.traceId,
          flightId: flightInfo.flightId,
          // flightId: getFlightIds(),
          airTravelType: airSearchData.airTravelType,
          priceId:
            airSearchData?.resultsType == "COMBINED"
              ? fareFamilies?.fareFamilies?.[0].fareId
              : flightInfo.fareFamilies?.fareFamilies[0].fareId,
          mappingType: airSearchData.resultsType,
          itineraryViewType: "1",
          Supplier: flightInfo.supplierKey,
        };
      }

      // let airInfoReqObj = {
      //   userId: user?.UserID ?? 1,
      //   roleType: user?.Role?.RoleId ?? 4,
      //   membership: user?.Membership ?? 1,
      //   traceId: airSearchData.traceId,
      //   flightIds: getFlightIds(),
      //   selectedFlights: selectedFlightArray,
      //   airTravelType: airSearchData.airTravelType,
      //   mappingType: airSearchData.resultsType,
      //   itineraryViewType: "1",
      //   TripType: flightInfo?.isReturn ? "Return" : "Onward",
      //   gstDetails: {
      //     gstAddressLine1: "",
      //     gstAddressLine2: "",
      //     gstCity: "",
      //     gstState: "",
      //     gstpinCode: "",
      //     gstEmailId: "",
      //     gstNumber: "",
      //     gstPhoneNo: "",
      //     gstCompanyName: "",
      //   },
      // };


      ApiClient.post("flights/getFlightInfo", airInfoReqObj)
        // .then((resp) => {
        //   // { console.log(resp, "airInfo") }
        //   return resp;
        // })
        .then((resp) => {
          if (resp.statusCode != 500) {
            setFlightInfoResp(resp.data);
          } else {
            setIsLoading(false);
          }
          // getFlightInfoTotalPrice(resp.data.paxPriceDetails?.[0]?.paxFareDetal);
        })
        .catch((err) => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  const getCmsFareRules = (obj) => {
    ApiClient.get("admin/getFlightFareRules", obj)
      .then((res) => {
        if (res.status == 200) {
          setCmsFareRules(res.data);
          setFlightDetails({
            visible: true,
          });
        } else {
          setCmsFareRules({});
          setFlightDetails({
            visible: true,
          });
        }
      })
      .catch((e) => {
        setCmsFareRules({});
        setFlightDetails({
          visible: true,
        });
      });
  };


  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const [isBaseDropdownOpen, setBaseDropdownOpen] = useState(false);
  const [isTaxDropdownOpen, setTaxDropdownOpen] = useState(false);

  // const [selectedCityPair, setSelectedCityPair] = useState('');
  const priceTipInfo = () => {
    const paxFareDetails = flightInfoResp?.paxPriceDetails || [];
    // const baggageDetails = flightInfoResp?.ssrPaidFalse?.[0]?.BAGGAGE || [];

    const toggleDropdown = (type) => {
      if (type === 'base') {
        setBaseDropdownOpen(!isBaseDropdownOpen);
      } else if (type === 'tax') {
        setTaxDropdownOpen(!isTaxDropdownOpen);
      }
    };

    let adtBase = 0;
    let chldBase = 0;
    let infBase = 0;
    let totalBase = 0;
    let serviceTax = 0;
    let adultCount = flightInfoResp?.adultCount;
    let childCount = flightInfoResp?.childCount;
    let InFtCount = flightInfoResp?.infantCount;

    paxFareDetails.map((fare) => {
      if (fare.fareTag === "Base") {
        if (fare.paxType === 'ADT') {
          adtBase += Number(fare.amount) * adultCount;
        } else if (fare.paxType === 'CHD') {
          chldBase += Number(fare.amount) * childCount;
        } else if (fare.paxType === 'INF') {
          infBase += Number(fare.amount) * InFtCount;
        }
      } else if (fare.fareTag === "Tax") {
        if (fare.paxType === 'ADT') {
          serviceTax += Number(fare.amount) * adultCount;
        } else if (fare.paxType === 'CHD') {
          serviceTax += Number(fare.amount) * childCount;
        } else if (fare.paxType === 'INF') {
          serviceTax += Number(fare.amount) * InFtCount;
        }
      }
    });
    // console.log(totalBase, paxFareDetails, adtBase, "testt");
    totalBase = adtBase + chldBase + infBase;

    let totalPublishF = 0;
    let totalPassengers = adultCount + childCount + InFtCount;

    return (
      <div className="fare-summary-container">
        <div className="fare-summary-table">
          <p>Fare Summary</p>
          {totalBase > 0 ? (
            <table className="tab-flight-Info">
              <tbody>
                <tr>
                  <td className="toggle-row">
                    Base Fare
                    <span style={{ fontSize: "10px", color: "blue" }} onClick={() => toggleDropdown('base')}>
                      {isBaseDropdownOpen ? " (view less)" : " (view More)"}
                    </span>
                    <span className="total-amount">{currencyValue(totalBase)}</span>
                  </td>
                </tr>
                {isBaseDropdownOpen && (
                  <>
                    {adultCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Adult x {adultCount}: <span className="total-amount">{currencyValue(adtBase)}</span>
                        </td>
                      </tr>
                    )}
                    {childCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Child x {childCount}: <span className="total-amount">{currencyValue(chldBase)}</span>
                        </td>
                      </tr>
                    )}
                    {InFtCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Infant x {InFtCount}: <span className="total-amount">{currencyValue(infBase)}</span>
                        </td>
                      </tr>
                    )}
                  </>
                )}
                <tr>
                  <td className="toggle-row">
                    Tax Charges
                    <span className="total-amount">{currencyValue(serviceTax)}</span>
                  </td>
                </tr>
                {isTaxDropdownOpen &&
                  paxFareDetails
                    .filter((fare) => fare.fareTag === 'Tax')
                    .map((fare, index) => (
                      <tr key={index}>
                        <td>{fare.paxType}: {currencyValue(fare.amount)}</td>
                      </tr>
                    ))}
                <tr>
                  <td className="total-row">
                    Total Amount
                    <span style={{ color: "#35459c" }} className="total-amount">
                      {activeCurrency} {currencyValue(totalBase + serviceTax)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>

        <div className="fare-inf">
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> The above data is indicative, fare rules are subject to changes by the Airline from time to time depending upon Fare class and change/cancellation fee amount may also vary based on fluctuations in currency conversion rates.
          </p>
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> Although we will try to keep this section updated regularly.
          </p>
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> Feel free to call our Contact Centre for exact cancellation/change fee.
          </p>
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> Cancellation/Date change request will be accepted 30 hrs prior to departure.
          </p>
        </div>
      </div>
    );
  };


  const flightBaggageInfo = () => {
    const baggageDetails = flightInfoResp?.ssrPaidFalse || [];

    const groupBaggageDetailsByCityPair = () => {
      return baggageDetails.reduce((acc, baggage) => {
        baggage?.BAGGAGE?.forEach(bag => {
          const { cityPair, weight, paxType } = bag;
          if (!acc[cityPair]) {
            acc[cityPair] = [];
          }
          acc[cityPair].push({ weight, paxType });
        });
        return acc;
      }, {});
    };

    const groupedBaggageDetails = groupBaggageDetailsByCityPair();

    const formatWeightWithPaxType = (weight, paxType) => {
      switch (paxType) {
        case 'ADT':
          return `${weight} (Adult)`;
        case 'CHD':
          return `${weight} (Child)`;
        case 'INF':
          return `${weight} (Infant)`;
        default:
          return weight;
      }
    };

    return (
      <div className="fare-summary-container">
        <div><p>Baggage Allowed </p></div>

        {Object.keys(groupedBaggageDetails).map((cityPair, index) => {
          // Using Set to keep track of unique baggage details
          const uniqueCheckinBaggage = new Set();
          const uniqueCabinBaggage = new Set();

          // Helper function to format weight and handle "0 kg"
          const formatWeight = (weight, paxType) => {
            const formattedWeight = formatWeightWithPaxType(weight, paxType);
            return formattedWeight === "0 kg" ? "N/A" : formattedWeight;
          };

          return (
            <table key={index} className="tab-flight-Info">
              <thead>
                <tr>
                  <th>Sector Route</th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Check-in Baggage</div>
                  </th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Cabin Baggage</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{cityPair}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag) => {
                        const checkinWeight = formatWeight(bag?.weight?.split(',')[0], bag?.paxType);
                        if (!uniqueCheckinBaggage.has(checkinWeight)) {
                          uniqueCheckinBaggage.add(checkinWeight);
                          return <div key={checkinWeight}>{checkinWeight}</div>;
                        }
                        return null;
                      }).filter(Boolean).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag) => {
                        const cabinWeight = formatWeight(bag?.weight?.split(',')[1], bag?.paxType);
                        if (!uniqueCabinBaggage.has(cabinWeight)) {
                          uniqueCabinBaggage.add(cabinWeight);
                          return <div key={cabinWeight}>{cabinWeight}</div>;
                        }
                        return null;
                      }).filter(Boolean).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}



        <div className="fare-inf">
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> The information presented above is as obtained from the airline reservation system.<br />
            <span style={{ color: "#bd0c21" }}>OutC.in</span> does not guarantee the accuracy of this information.
          </p>
          <p style={{ color: "grey", fontSize: "13px" }}>
            <i className="fa fa-circle-o" aria-hidden="true"></i> The baggage allowance may vary according to stop-overs, connecting flights and changes in airline rules.
          </p>
        </div>
      </div>
    );
  };






  const fetchFlightFares = () => {

    let FareRuleObj;
    if (flightInfo?.supplierKey === 'AKBAR') {
      FareRuleObj = {
        flowType: "SEARCH",
        traceId: airSearchData.traceId,
        // flightId: flightInfo.flightId,
        flightId: getFlightIds(),
        airTravelType: airSearchData.airTravelType,
        priceId:
          airSearchData?.resultsType == "COMBINED"
            ? fareFamilies?.fareFamilies?.[0].fareId
            : flightInfo.fareFamilies?.fareFamilies[0].fareId,
        mappingType: airSearchData.resultsType,
        itineraryViewType: "1",
        TripType: flightInfo?.isReturn ? "Return" : "Onward",
        Supplier: flightInfo.supplierKey,
        tokenId: airSearchData?.resultsType == "COMBINED"
          ? fareFamilies?.fareFamilies?.[0]?.supplierParameter
          : flightInfo.fareFamilies?.fareFamilies?.[0]?.supplierParameter,
      };
    } else {
      FareRuleObj = {
        flowType: "SEARCH",
        traceId: airSearchData.traceId,
        flightId: flightInfo.flightId,
        // flightId: getFlightIds(),
        airTravelType: airSearchData.airTravelType,
        priceId:
          airSearchData?.resultsType == "COMBINED"
            ? fareFamilies?.fareFamilies?.[0].fareId
            : flightInfo.fareFamilies?.fareFamilies[0].fareId,
        mappingType: airSearchData.resultsType,
        itineraryViewType: "1",
        Supplier: flightInfo.supplierKey,
      };
    }
    // console.log(FareRuleObj,"FareRuleObj")
    ApiClient.post("flights/airFareRules", FareRuleObj)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.data) {
            // { console.log(resp, "cancel123") }
            if (resp.data.fareRules) {
              setFareRulesResp(resp);
            }
          }


        } else {
          let error_resp = {
            data: {
              fareRules: [
                {
                  // supplierName :"AKBAR",
                  ruleDetails: {
                    Description:
                      "Something went wrong , Fare Rules not Available",
                  },
                },
              ],
            },
          };
          setFareRulesResp(error_resp);
        }
      })
      .catch((err) => console.log(err));

  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime?.split("T");
    let [hour, minute, seconds] = time?.split(":");
    return `${hour}:${minute}`;
  };
  // console.log(flightInfo, airSearchData.infantCount, "fareRules")
  const luggageDetails = () => {
    return (
      <div className="luggage-tooltip">
        <Row gutter={16}>
          {fareFamilies?.fareFamilies?.[0]?.baggage ? (
            fareFamilies?.fareFamilies?.[0]?.baggage?.map((bag) => (
              <Col key={bag.cityPair}>
                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} Check-In Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
                <span><p>Please refer Baggage Information inside Flight Details</p></span>
              </Col>
            ))
          ) : (
            <p>No baggage information available</p>
          )}
          {/* {fareFamilies?.fareFamilies[0]?.baggage?.map((bag) => {
            return (
              <Col>
                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} CheckedIn Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
              </Col>
            );
          })} */}
        </Row>
      </div>
    );
  };


  const groupedFareRules = fareRulesResp?.data?.fareRules?.reduce((acc, current) => {
    const cityPair = current?.cityPair;
    if (!acc[cityPair]) {
      acc[cityPair] = [];
    }
    acc[cityPair]?.push(current);
    return acc;
  }, {});

  const formatText = (text) => {
    return text
      .replace(/\n/g, '<br>')  // Convert new lines to <br>
      .replace(/(NOTE.*?:)/g, '<strong>$1</strong>')  // Bold any lines that start with 'NOTE'
      .replace(/(CHANGES|CANCELLATIONS|ANY TIME|NO-SHOW)/g, '<strong>$1</strong>')  // Bold specific keywords
      .replace(/(\d+ AED)/g, '<strong>$1</strong>')  // Bold amounts (e.g., 150 AED)
      .replace(/(\-+\n)/g, '<hr>')  // Replace multiple dashes with a horizontal rule
      .replace(/^\s+|\s+$/gm, '');  // Trim leading/trailing whitespace from each line
  }
  return (
    <>
      <div className="flight-timings">
        {/* {console.log(fareFamilies.fareFamilies, flightInfo, "fare")} */}
        {fareFamilies?.fareFamilies?.length > 0 && (
          <div className="refundText">
            {flightInfo?.isRefundable === true ? (
              <p className="refund">Refundable</p>
            ) : (
              <p className="Nrefund">Non-Refundable</p>
            )}
          </div>
        )}
        <div className="timing_wrapper flight-syt-time">
          <div className="plane-timings">
            <ul>
              <li>
                <p>
                  {/* {console.log(flightInfo?.flightSegments?.[0]?.departureDateTime, flightInfo?.flightSegments?.[0]?.arrivalDateTime, flightInfo, "date")} */}
                  {formatFlightTime(
                    flightInfo?.flightSegments?.[0]?.departureDateTime
                  )}
                </p>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {" "}
                <div className="travel-time">
                  {/* <p>
                    <ClockCircleOutlined />
                  </p> */}
                  <div>
                    <p style={{ color: "#bc0c21" }}>{flightInfo?.duration}</p>
                  </div>

                </div>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {flightInfo?.flightSegments?.length > 0 ? (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo?.flightSegments[
                          flightInfo?.flightSegments?.length - 1
                        ]?.arrivalDateTime
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo?.flightSegments?.[0]?.arrivalDateTime
                      )}
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        {airSearchData?.airTravelType?.toUpperCase() != "MULTIDESTINATIONs" ? (
          <div className="details-btn">

            <p className="details-text" onClick={showModal}>
              Flight Details
            </p>
          </div>
        ) : null}
      </div>

      {/* Details Modal */}
      <div>
        <Modal
          title="Flight Details"
          visible={flightDetails}
          footer={[null]}
          onOk={(e) => setFlightDetails(false)}
          onCancel={(e) => setFlightDetails(false)}
          className="flight-details-modal"
        >
          <div className="all-times-local">
            <Tabs
              defaultActiveKey="1"
            // onTabClick={(str, e) => fetchFlightFares(str, e)}
            >
              <TabPane tab="Sector" key="1">
                <div className="from-to-modal-details">
                  <Row gutter={16} className="more-details-row">
                    <Col md={12} xs={24}>
                      <Row align="middle" gutter={16}>
                        <Col md={8} xs={8} className="more-details-list">

                          <p>{flightInfo.flightSegments[0].origin}</p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                        <Col md={8} xs={8}>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo.flightSegments.map(
                                (flightSegInfo, index) =>
                                  index !== 0 ? (
                                    <div
                                      className="stop_points"
                                    // key={flightSegInfo.segId}
                                    >
                                      <Tooltip
                                        placement="top"
                                        title={() => (
                                          <div className="tooltip-data">
                                            {flightInfo.flightSegments.length -
                                              1 >
                                              1 ? (
                                              <h4>Change</h4>
                                            ) : (
                                              ""
                                            )}
                                            <p>
                                              {flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName}
                                            </p>
                                          </div>
                                        )}
                                        className="citynames-tooltip"
                                      >
                                        <span className="break-city-name pointer_cursor">
                                          {/* {console.log("iam only one")} */}
                                          {flightSegInfo.origin}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>
                            {
                              flightInfo.flightSegments[
                                flightInfo.flightSegments.length - 1
                              ].destination
                            }
                          </p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} xs={24} className="trip-duration-time">
                      <p>Total trip duration:</p>
                      <p>
                        {flightInfo.flightSegments.length > 0
                          ? calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].arrivalDateTime
                          )
                          : calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[0].arrivalDateTime
                          )}{" "}
                        |
                        {flightInfo.flightSegments.length - 1 === 0 ? (
                          <>Direct</>
                        ) : (
                          <>{flightInfo.flightSegments.length - 1} stops </>
                        )}
                      </p>
                    </Col>
                    <Col md={3} xs={24} className="trip-duration-time">
                      <p>Class:</p>
                      <p>{airSearchData.cabinClass}</p>
                    </Col>
                    <Col md={4} xs={24} className="trip-duration-time">
                      <p>CupanType:</p>
                      <p>
                        {
                          flightInfo?.fareFamilies?.fareFamilies?.[0]
                            ?.coupanType
                        }
                      </p>
                    </Col>
                  </Row>
                </div>
                {flightInfo.flightSegments.map((flightSegment, i) => (
                  <div
                    className="total-flight-details"
                    key={"flighttiming" + i}
                  >
                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.origin}</span>
                      </div>
                      <div>
                        <span>
                          departure:{" "}
                          <strong>
                            {dateFormat(
                              flightSegment.departureDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                        </span>
                        <span>
                          {dateFormat(
                            flightSegment.departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                        <span>
                          <strong>
                            {"  "}
                            {flightInfo.airLineName}(
                            {flightSegment.marketingAirline}-
                            {flightSegment.flightNumber})
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div className="collapsed-card">

                    </div>

                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.destination}</span>
                      </div>
                      <div>
                        <span>
                          arrival:{" "}
                          <strong>
                            {formatFlightTime(flightSegment?.arrivalDateTime)}
                          </strong>
                        </span>
                        {" , "}
                        <span>
                          {dateFormat(
                            flightSegment?.arrivalDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>
                    </div>
                    {i < flightInfo.flightSegments.length - 1 && (
                      <div className="halt-wrapper">
                        <p>
                          Layover Time:{" "}
                          {calculateDuration(
                            flightInfo?.flightSegments[i + 1]?.departureDateTime,
                            flightSegment?.arrivalDateTime
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </TabPane>
              {/* {console.log(fareRulesResp, "cancel")} */}

              {cmsFareRules?.cancelPolicyDescription ? (
                <TabPane tab="Fare Rules" key="3">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                    </pre>
                  </div>
                </TabPane>
              ) : (
                <TabPane tab="Fare Rules" key="2">
                  <div className="fareRule-container">
                    {!fareRulesResp ? (
                      <p>Loading...</p>
                    ) : (
                      fareRulesResp?.data.supplierName === "AKBAR" && fareRulesResp?.data?.fareRules != undefined ? (
                        <div>
                          {fareRulesResp.data.fareRules.length >= 1 ? (
                            <>

                              {Object.keys(groupedFareRules).map((cityPair, index) => (
                                <div key={index} style={{ marginBottom: '20px' }}>
                                  <h1>{cityPair}</h1>
                                  {groupedFareRules[cityPair].map((fareRestriction, restrictionIndex) => (
                                    <div key={restrictionIndex}>
                                      {/* <div style={{ marginRight: '20px', fontWeight: 'bold', minWidth: '150px' }}> */}
                                      {/* <h3>{fareRestriction.fareRestriction}</h3> */}
                                      {fareRestriction.fareRestriction.length > 100 ?
                                        ReactHtmlParser(formatText(fareRestriction.fareRestriction)) : <h3>{fareRestriction.fareRestriction}</h3>}
                                      {/* </div> */}
                                      <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                        <thead>
                                          <tr>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Description</th>
                                            <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Adult</th>
                                            {airSearchData.childCount > 0 && (
                                              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Child</th>
                                            )}
                                            {airSearchData.infantCount > 0 && (
                                              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Infant</th>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {fareRestriction?.ruleDetails?.length > 0 ? (
                                            fareRestriction?.ruleDetails?.map((flight, flightIndex) => (
                                              <tr key={`${restrictionIndex}-${flightIndex}`}>
                                                <td style={{ border: '1px solid black', padding: '8px' }}>{flight?.Description}</td>
                                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                                  {flight?.adultPrice !== null ? `${activeCurrency} ${currencyValue(flight?.adultPrice.toFixed(2))}` : "N/A"}
                                                </td>
                                                {airSearchData?.childCount > 0 && (
                                                  <td style={{ border: '1px solid black', padding: '8px' }}>
                                                    {flight?.childPrice !== null ? `${activeCurrency} ${currencyValue(flight?.childPrice.toFixed(2))}` : "N/A"}
                                                  </td>
                                                )}
                                                {airSearchData?.infantCount > 0 && (
                                                  <td style={{ border: '1px solid black', padding: '8px' }}>
                                                    {flight?.InfantPrice !== null ? `${activeCurrency} ${currencyValue(flight?.InfantPrice.toFixed(2))}` : "N/A"}
                                                  </td>
                                                )}
                                              </tr>
                                            ))
                                          ) : (
                                            <tr>
                                              <td colSpan={4} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>No data available</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </>
                          ) : (
                            fareRulesResp?.data?.fareRules.map((rule, i) => (
                              <>
                                {rule?.fareRestriction.length > 100 ?
                                  ReactHtmlParser(formatText(rule?.fareRestriction)) : <h3>{rule.fareRestriction}</h3>}
                              </>
                            ))
                          )}
                        </div>


                      ) : (
                        fareRulesResp?.data?.fareRules.map((rule, i) => (<>
                          {rule?.ruleDetails ?
                            <p key={i}>{ReactHtmlParser(rule?.ruleDetails)}</p> :
                            <p>Something went wrong, please try again</p>
                          }
                        </>
                        ))
                      )
                    )}

                  </div>
                </TabPane>
              )}
              {cmsFareRules?.reschedulePolicyDescription && (
                <TabPane tab="Reschedule Policy" key="4">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(
                        cmsFareRules.reschedulePolicyDescription
                      )}
                    </pre>
                  </div>
                </TabPane>
              )}

              {cmsFareRules?.covidRulesDescription && (
                <TabPane tab="Covid Rules Policy" key="5">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.otherRulesDescription && (
                <TabPane tab="Other Rules Policy" key="6">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
              {isLoading &&
                <TabPane tab="Fare Summary" key="3">
                  <div>
                    {!flightInfoResp ? (
                      <p>Loading ...</p>
                    ) : (
                      <div>
                        {!flightInfoResp ? (
                          <p>Loading ...</p>
                        ) : (
                          <div >
                            {priceTipInfo()}
                          </div>
                        )}
                      </div>

                    )}
                  </div>
                </TabPane>}
              {isLoading &&
                <TabPane tab="Baggage" key="4">
                  <div>
                    {!flightInfoResp ? (
                      <p>Loading ...</p>
                    ) : (
                      <div>
                        {!flightInfoResp ? (
                          <p>Loading ...</p>
                        ) : (
                          <div >
                            {flightBaggageInfo()}
                          </div>
                        )}
                      </div>

                    )}
                  </div>
                </TabPane>}
            </Tabs>
          </div>
        </Modal>
      </div>
      {/* Details Modal */}
    </>
  );
};

const Flight = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { TabPane } = Tabs;
  const flightInfo = props.flightInfo;
  const flightSearchObj = props.flightSearchObj;
  const fareFamilies = props.fareFamilies;
  const promoData = props.promoData;
  const selectedFlight = props.selectedFlight;
  const MultiCityIndex = props.MultiCityIndex;
  const showNetFare = props.showNetFare;
  const index = props.index;
  const changeFlightResultsView = props.changeFlightResultsView;
  const journeyType = props.journeyType;
  const [cmsFareRules, setCmsFareRules] = useState({});
  const { Panel } = Collapse;
  const [act, setact] = useState(0);
  const activeCollapse = props.activeCollapse;
  const setActiveCollapse = props.setActiveCollapse;
  // console.log(props, "fligttttt");
  // const flightInfo = props.flightInfo;
  // const flightSearchObj = props.flightSearchObj;
  // const fareFamilies = props.fareFamilies;
  // const promoData = props.promoData;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [flightArr, setFlightArr] = useState([]);
  // const indx = props.ind;
  const AirportToolTip = (heading, code, name, date) => {
    return (
      <div className="tooltip-data">
        <h4>
          {heading}-{moment(date).format("DD/MM-(ddd)")}
        </h4>
        <p>{code}</p>
        <p>{name}</p>
      </div>
    );
  };
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { user } = useAuthContext();
  const [flightDetails, setFlightDetails] = useState(false);
  const [flightdetailsactivecss, setflightdetailsactivecss] = useState({
    flightdetails: true, cancel: false, reschedule: false
    , covidRules: false, otherRules: false
  })
  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };
  const onClickChooseFlight = (fareFamiliesIndex) => {
    // console.log(fareFamiliesIndex, "hi, booknow");
    try {
      if (
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") ||
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION")
      ) {
        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });

        changeFlightResultsView(flightArr);
        setFlightArr([]);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;
              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;
            changeFlightResultsView(copySelectedFlights);
          }
        } else if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" &&
          flightSearchObj.resultsType.toUpperCase() == "SEPARATE"
        ) {
          let copyarray = flightInfo;
          let copy = flightInfo.fareFamilies.fareFamilies.filter(
            (_, index) => index == fareFamiliesIndex
          );

          copyarray.fareFamilies.fareFamilies = copy;
          if (
            selectedFlight.length <= flightSearchObj.originDestinations.length
          ) {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[MultiCityIndex] = copyarray;

            changeFlightResultsView(copySelectedFlights);
          } else {
            changeFlightResultsView([...selectedFlight, flightInfo]);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={" flight-results-container"}>
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <div>
              <div className="flight-trip-details">
                <div className="itinary-details">
                  <div className="itinary-flight-name">
                    <CustomFliImg
                      className="img-hot"
                      src={flightInfo?.airLineLogo}
                      // src={flightimg}
                      alt={flightInfo.airLine}
                    />

                    <div className="token-number-flught">
                      <p style={{
                        fontWeight: 700, width: "85px",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}>
                        {flightInfo?.airLineName} <span style={{ color: "#ffff" }}>{flightInfo?.supplierKey}</span>
                      </p>
                      {/* &nbsp; */}
                      <span style={{ fontWeight: 700, color: "grey" }}>
                        ({flightInfo?.flightSegments?.[0]?.marketingAirline}-
                        {flightInfo?.flightSegments?.[0]?.flightNumber})
                      </span>

                    </div>

                  </div>
                  {/* {console.log(flightInfo, "flightInfo")} */}

                  <div className="fromTo">
                    <div className="cities-from-to">
                      <div className="loc_wrapper">
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Departure",
                            flightInfo.originName,
                            flightInfo.originCity,
                            flightInfo?.flightSegments?.[0]?.departureDateTime
                          )}
                          className="citynames-tooltip"
                        >
                          <div className="code pointer_cursor">
                            {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                            <div className="time-txt">
                              {moment(flightInfo?.flightSegments[0]?.departureDateTime).format("HH:MM")}
                            </div>
                            <p className="dest-name-txt">{flightInfo.origin}</p>
                            {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                          </div>
                        </Tooltip>


                        <div className="break-journey-container">
                          <p className="break-jrny-up">
                            {(() => {
                              if (flightInfo?.flightSegments.length === 0) return "00:00 hrs";

                              const departure = new Date(flightInfo?.flightSegments[0].departureDateTime);
                              const arrival = new Date(flightInfo?.flightSegments[flightInfo?.flightSegments.length - 1].arrivalDateTime);

                              const diffMs = arrival - departure;
                              const hours = Math.floor(diffMs / (1000 * 60 * 60));
                              const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                              return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hrs`;
                            })()}
                          </p>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo?.flightSegments?.map((flightSegInfo, index) =>
                                index !== 0 ? (
                                  <div className="stop_points" key={flightSegInfo.segId}>
                                    <Tooltip
                                      title={AirportToolTip(
                                        "Change",
                                        flightSegInfo?.originName,
                                        flightSegInfo?.originCity,
                                        flightSegInfo?.departureDateTime
                                      )}
                                      className="citynames-tooltip"
                                    >
                                      <span className="break-city-name pointer_cursor">
                                        {flightSegInfo?.origin}
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : flightInfo?.flightSegments.length === 1 ? "Direct" : null
                              )}
                            </div>
                          </div>
                        </div>


                        {flightInfo.flightSegments.length === 4 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[3].destinationName,
                              flightInfo.flightSegments[3].destinationCity,
                              flightInfo.flightSegments[3].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[3]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[3].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 3 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[2].destinationName,
                              flightInfo.flightSegments[2].destinationCity,
                              flightInfo.flightSegments[2].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[2]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[2].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 2 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[1].destinationName,
                              flightInfo.flightSegments[1].destinationCity,
                              flightInfo.flightSegments[1].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[1]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[1].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 1 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[0].destinationName,
                              flightInfo.flightSegments[0].destinationCity,
                              flightInfo.flightSegments[0].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[0]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[0].destination}                           </p>
                              {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                            </div>

                          </Tooltip>
                        )}
                      </div>

                    </div>


                  </div>





                  {/* <div className="choose-btn-blockN"> */}
                  <div className="share-price-mail">

                    <div className="choose-btn-priceN">
                      {showNetFare ? (
                        <>
                          <span className="currencyType text-line">{activeCurrency}</span>
                          {/* <Tooltip title={priceToolTip}> */}
                          <span className="currency text-line">
                            {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                            {currencyValue(Math.round(
                              flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                            ))}
                          </span>
                          {/* </Tooltip> */}

                          <div className="text-center">
                            <p className="netfare">
                              Inc: {activeCurrency}{" "}
                              {currencyValue(Math.round(
                                flightInfo?.fareFamilies?.fareFamilies[0]?.commission
                              ))}{" "}
                            </p>
                            <p className="netfare">
                              {activeCurrency}{" "}
                              {currencyValue(Math.round(
                                flightInfo?.fareFamilies?.fareFamilies[0]?.adultNetFare
                              ))}{" "}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div>
                          <span className="currencyType">
                            {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                            {activeCurrency === "INR" ? "₹" : activeCurrency}
                          </span>
                          {/* <Tooltip title={priceToolTip}> */}
                          {/* {console.log(flightInfo, "familf")} */}
                          <span className="currency">
                            {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} adultPublishFare */}
                            {currencyValue(Math.round(
                              flightInfo.fareFamilies.fareFamilies[0].totalPublishFare
                            ))}
                          </span>
                          {/* </Tooltip>{" "} */}
                          {fareFamilies?.fareFamilies?.length > 0 && (
                            <div className="refundText">
                              {flightInfo?.isRefundable === true ? (
                                <p className="refund">Refundable</p>
                              ) : (
                                <p className="Nrefund">Non-Refundable</p>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>



                    {/* 
                      <div className="right-price-section1">
                        <span className="price-text">
                          Price for{" "}
                          {flightSearchObj.adultCount +
                            flightSearchObj.infantCount +
                            flightSearchObj.childCount}{" "}
                          passenger,
                          <br />{" "}
                          {flightSearchObj.airTravelType === "oneWay"
                            ? "Oneway"
                            : "Round Trip"}
                        </span>
                      </div> */}
                  </div>
                  <div className="choose-btn-section">
                    <div className="txt-off">Extra 10% off</div>
                    <div className="choose-web-btn" >
                      <div>
                        <button
                          className="choose-button"
                          onClick={(e) => {
                            let reqData = {
                              serviceType: 1,
                              airlineCode: flightInfo.airLine,
                            };
                            if (flightInfo.fareFamilies.fareFamilies.length == 1) {
                              onClickChooseFlight(0);
                            } else {
                              if (activeCollapse === index) {
                                setActiveCollapse("");
                              } else {
                                setActiveCollapse(index);
                                // getAdminFareRules(reqData);
                              }
                            }
                          }}
                        >
                          {activeCollapse === index && activeCollapse != null
                            ? "Hide Prices"
                            : flightInfo.fareFamilies.fareFamilies.length == 1
                              ? "Book Now"
                              : "View Prices"}
                        </button>
                      </div>

                      {/* <div className="Flit-Det-btn">Flight Details</div> */}
                    </div>

                    {/* </div> */}


                    {/* <Modal
                    title="Send Flight Information"
                    visible={openMailModal}
                    onOk={openOk}
                    onCancel={OpenCancel}
                    footer={false}
                    className="flight-infoModal"
                    centered
                    width={400}
                  >
                    <Form onFinish={sendEmail} form={Emailform}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Required!" },
                          { type: "email", message: "Email is not a valid email" },
                        ]}
                      >
                        <Input placeholder="Enter Email Address" />
                      </Form.Item>

                      <Button type="primary" htmlType={"submit"}>
                        Send
                      </Button>
                    </Form>
                  </Modal>
                  <Modal
                    title="Send Flight Information"
                    visible={openNumberModal}
                    onOk={clickOk}
                    onCancel={clickCancel}
                    className="flight-infoModal"
                    footer={false}
                    centered
                    width={400}
                  >
                    <Input placeholder="Enter Mobile Number" />
                    <Button type="primary">Send</Button>
                  </Modal> */}
                  </div>
                </div>
              </div>
            </div>
            {promoData && !agent
              ? promoData?.length > 0 && (
                <div className="promo-strip">
                  <div className="promoData">
                    Use code {promoData[1]?.Code} to get FLAT{" "}
                    {promoData[1]?.Discount}{" "}
                    {promoData[1]?.DiscountType === 0 ? "" : "%"} instant
                    discount in this flight.
                  </div>

                </div>
              )
              : ""}
          </Card>
        </div>
      </div>
    </div>
  );
};

export const FlightCom = ({ props, flightInfo, flightInfo1,
  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
  MultiCityIndex,
  setActiveCollapse,
  activeCollapse,
  index,
  traceId,
  promoData,
  fareFamilies,
  isLastItem,
}) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  // console.log(props, "fligttttt");
  // const flightInfo = props.flightInfo;
  // const flightSearchObj = props.flightSearchObj;
  // const fareFamilies = props.fareFamilies;
  // const promoData = props.promoData;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [flightArr, setFlightArr] = useState([]);
  // const indx = props.ind;
  const AirportToolTip = (heading, code, name, date) => {
    return (
      <div className="tooltip-data">
        <h4>
          {heading}-{moment(date).format("DD/MM-(ddd)")}
        </h4>
        <p>{code}</p>
        <p>{name}</p>
      </div>
    );
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };
  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      // console.log(flightInfo1.flightDetails,"hh")
      if (
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") ||
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION")
      ) {
        flightInfo1.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo1.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });

        changeFlightResultsView(flightArr);
        setFlightArr([]);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;
              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;
            changeFlightResultsView(copySelectedFlights);
          }
        } else if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION"
          // &&
          // flightSearchObj.resultsType.toUpperCase() == "SEPARATE"
        ) {
          let copyarray = flightInfo;
          let copy = flightInfo.fareFamilies.fareFamilies.filter(
            (_, index) => index == fareFamiliesIndex
          );

          copyarray.fareFamilies.fareFamilies = copy;
          if (
            selectedFlight.length <= flightSearchObj.originDestinations.length
          ) {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[MultiCityIndex] = copyarray;

            changeFlightResultsView(copySelectedFlights);
          } else {
            changeFlightResultsView([...selectedFlight, flightInfo]);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={" flight-results-container"}>
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <div>
              <div className="flight-trip-detailsCom">
                <div className="itinary-details1">
                  <div className="itinary-flight-name">
                    <CustomFliImg
                      className="img-hot"
                      src={flightInfo?.airLineLogo}
                      // src={flightimg}
                      alt={flightInfo.airLine}
                    />

                    <div className="token-number-flught">
                      <p style={{
                        fontWeight: 700, width: "85px",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}>
                        {flightInfo?.airLineName} <span style={{ color: "#ffff" }}>{flightInfo?.supplierKey}</span>
                      </p>
                      {/* &nbsp; */}
                      <span style={{ fontWeight: 700, color: "grey" }}>
                        ({flightInfo?.flightSegments?.[0]?.marketingAirline}-
                        {flightInfo?.flightSegments?.[0]?.flightNumber})
                      </span>

                    </div>

                  </div>
                  {/* {console.log(flightInfo, "flightInfo")} */}

                  <div className="fromToC">
                    <div className="cities-from-to">
                      <div className="loc_wrapper">
                        <Tooltip
                          placement="top"
                          title={AirportToolTip(
                            "Departure",
                            flightInfo.originName,
                            flightInfo.originCity,
                            flightInfo?.flightSegments?.[0]?.departureDateTime
                          )}
                          className="citynames-tooltip"
                        >
                          <div className="code pointer_cursor">
                            {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                            <div className="time-txt">
                              {moment(flightInfo?.flightSegments[0]?.departureDateTime).format("HH:MM")}
                            </div>
                            <p className="dest-name-txt">{flightInfo.origin}</p>
                            {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                          </div>
                        </Tooltip>


                        <div className="break-journey-container">
                          <p className="break-jrny-up">
                            {(() => {
                              if (flightInfo?.flightSegments.length === 0) return "00:00 hrs";

                              const departure = new Date(flightInfo?.flightSegments[0].departureDateTime);
                              const arrival = new Date(flightInfo?.flightSegments[flightInfo?.flightSegments.length - 1].arrivalDateTime);

                              const diffMs = arrival - departure;
                              const hours = Math.floor(diffMs / (1000 * 60 * 60));
                              const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                              return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hrs`;
                            })()}
                          </p>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo?.flightSegments?.map((flightSegInfo, index) =>
                                index !== 0 ? (
                                  <div className="stop_points" key={flightSegInfo.segId}>
                                    <Tooltip
                                      title={AirportToolTip(
                                        "Change",
                                        flightSegInfo?.originName,
                                        flightSegInfo?.originCity,
                                        flightSegInfo?.departureDateTime
                                      )}
                                      className="citynames-tooltip"
                                    >
                                      <span className="break-city-name pointer_cursor">
                                        {flightSegInfo?.origin}
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : flightInfo?.flightSegments.length === 1 ? "Direct" : null
                              )}
                            </div>
                          </div>
                        </div>


                        {flightInfo.flightSegments.length === 4 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[3].destinationName,
                              flightInfo.flightSegments[3].destinationCity,
                              flightInfo.flightSegments[3].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[3]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[3].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 3 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[2].destinationName,
                              flightInfo.flightSegments[2].destinationCity,
                              flightInfo.flightSegments[2].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[2]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[2].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 2 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[1].destinationName,
                              flightInfo.flightSegments[1].destinationCity,
                              flightInfo.flightSegments[1].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[1]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[1].destination}                           </p>

                            </div>
                          </Tooltip>
                        )}
                        {flightInfo.flightSegments.length === 1 && (
                          <Tooltip
                            placement="top"
                            title={AirportToolTip(
                              "Destination",
                              flightInfo.flightSegments[0].destinationName,
                              flightInfo.flightSegments[0].destinationCity,
                              flightInfo.flightSegments[0].arrivalDateTime
                            )}
                            className="citynames-tooltip"
                          >
                            <div className="code pointer_cursor">
                              {/* <img
                              src={departureplane}
                              className="flightari-dep-iconsres"
                            /> */}
                              <div className="time-txt">
                                {moment(flightInfo?.flightSegments[0]?.arrivalDateTime).format("HH:MM")}
                              </div>
                              <p className="dest-name-txt"> {flightInfo.flightSegments[0].destination}                           </p>
                              {/* {flightInfo.originCity
                              ? flightInfo.originCity
                              : flightInfo.origin} */}
                            </div>

                          </Tooltip>
                        )}
                      </div>

                    </div>


                  </div>





                  {/* <div className="choose-btn-blockN"> */}
                  {/* {console.log(MultiCityIndex, isLastItem, "mukl")} */}
                  {isLastItem ?
                    <div className="comb-int-box">
                      <div className="share-price-mail">

                        <div className="choose-btn-priceN">
                          {showNetFare ? (
                            <>
                              <span className="currencyType text-line">{activeCurrency}</span>
                              {/* <Tooltip title={priceToolTip}> */}
                              <span className="currency text-line">
                                {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                                {currencyValue(Math.round(
                                  flightInfo1.fareFamilies.fareFamilies[0].adultPublishFare
                                ))}
                              </span>
                              {/* </Tooltip> */}

                              <div className="text-center">
                                <p className="netfare">
                                  Inc: {activeCurrency}{" "}
                                  {currencyValue(Math.round(
                                    flightInfo1?.fareFamilies?.fareFamilies[0]?.commission
                                  ))}{" "}
                                </p>
                                <p className="netfare">
                                  {activeCurrency}{" "}
                                  {currencyValue(Math.round(
                                    flightInfo1?.fareFamilies?.fareFamilies[0]?.adultNetFare
                                  ))}{" "}
                                </p>
                              </div>
                            </>
                          ) : (
                            <div>
                              <span className="currencyType">
                                {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                                {activeCurrency === "INR" ? "₹" : activeCurrency}
                              </span>
                              {/* <Tooltip title={priceToolTip}> */}
                              {/* {console.log(flightInfo1, "familf")} */}
                              <span className="currency">
                                {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} adultPublishFare */}
                                {currencyValue(Math.round(
                                  flightInfo1.fareFamilies.fareFamilies[0].totalPublishFare
                                ))}
                              </span>
                              {/* </Tooltip>{" "} */}
                              {fareFamilies?.fareFamilies?.length > 0 && (
                                <div className="refundText">
                                  {flightInfo1?.isRefundable === true ? (
                                    <p className="refund">Refundable</p>
                                  ) : (
                                    <p className="Nrefund">Non-Refundable</p>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>



                        {/* 
                      <div className="right-price-section1">
                        <span className="price-text">
                          Price for{" "}
                          {flightSearchObj.adultCount +
                            flightSearchObj.infantCount +
                            flightSearchObj.childCount}{" "}
                          passenger,
                          <br />{" "}
                          {flightSearchObj.airTravelType === "oneWay"
                            ? "Oneway"
                            : "Round Trip"}
                        </span>
                      </div> */}
                      </div>
                      <div className="choose-btn-section">
                        <div className="txt-off">Extra 10% off</div>
                        <div className="choose-web-btn" >
                          <div>
                            <button
                              className="choose-button"
                              onClick={(e) => {
                                let reqData = {
                                  serviceType: 1,
                                  airlineCode: flightInfo1.airLine,
                                };
                                if (flightInfo1.fareFamilies.fareFamilies.length == 1) {
                                  onClickChooseFlight(0);
                                } else {
                                  if (activeCollapse === index) {
                                    setActiveCollapse("");
                                  } else {
                                    setActiveCollapse(index);
                                    // getAdminFareRules(reqData);
                                  }
                                }
                              }}
                            >
                              {activeCollapse === index && activeCollapse != null
                                ? "Hide Prices"
                                : flightInfo1.fareFamilies.fareFamilies.length == 1
                                  ? "Book Now"
                                  : "View Prices"}
                            </button>
                          </div>

                          <div className="Flit-Det-btn">Flight Details</div>
                        </div>

                        {/* </div> */}


                        {/* <Modal
                    title="Send Flight Information"
                    visible={openMailModal}
                    onOk={openOk}
                    onCancel={OpenCancel}
                    footer={false}
                    className="flight-infoModal"
                    centered
                    width={400}
                  >
                    <Form onFinish={sendEmail} form={Emailform}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Required!" },
                          { type: "email", message: "Email is not a valid email" },
                        ]}
                      >
                        <Input placeholder="Enter Email Address" />
                      </Form.Item>

                      <Button type="primary" htmlType={"submit"}>
                        Send
                      </Button>
                    </Form>
                  </Modal>
                  <Modal
                    title="Send Flight Information"
                    visible={openNumberModal}
                    onOk={clickOk}
                    onCancel={clickCancel}
                    className="flight-infoModal"
                    footer={false}
                    centered
                    width={400}
                  >
                    <Input placeholder="Enter Mobile Number" />
                    <Button type="primary">Send</Button>
                  </Modal> */}
                      </div>
                    </div> : <>

                      <div className="empty-div" style={{ width: "340px" }}>

                      </div>

                    </>
                  }
                </div>
              </div>
            </div>
            {promoData && !agent
              ? promoData?.length > 0 && (
                <div className="promo-strip">
                  <div className="promoData">
                    Use code {promoData[1]?.Code} to get FLAT{" "}
                    {promoData[1]?.Discount}{" "}
                    {promoData[1]?.DiscountType === 0 ? "" : "%"} instant
                    discount in this flight.
                  </div>

                </div>
              )
              : ""}
          </Card>
        </div>
      </div>
    </div>
  );
};
export const FlightSelection = ({
  flightInfo,

  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
  MultiCityIndex,
  setActiveCollapse,
  activeCollapse,
  index,
}) => {
  const { Panel } = Collapse;
  const [flightArr, setFlightArr] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      if (
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") ||
        (flightSearchObj.resultsType.toUpperCase() === "COMBINED" &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION")
      ) {
        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });

        changeFlightResultsView(flightArr);
        setFlightArr([]);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;
              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;
            changeFlightResultsView(copySelectedFlights);
          }
        } else if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" &&
          flightSearchObj.resultsType.toUpperCase() == "SEPARATE"
        ) {
          let copyarray = flightInfo;
          let copy = flightInfo.fareFamilies.fareFamilies.filter(
            (_, index) => index == fareFamiliesIndex
          );

          copyarray.fareFamilies.fareFamilies = copy;
          if (
            selectedFlight.length <= flightSearchObj.originDestinations.length
          ) {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[MultiCityIndex] = copyarray;

            changeFlightResultsView(copySelectedFlights);
          } else {
            changeFlightResultsView([...selectedFlight, flightInfo]);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);
  const showNumberModal = () => {
    setOpenNumberModal(true);
  };

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();
  const getCoupanTypeData = (key, coupanType) => { };
  const getAdminFareRules = (fareReqObj) => { };
  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        )?.toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };
  const priceToolTip = () => {
    let base = 0;
    let service = 0;
    let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);
    let Agentmarkup = Number(
      flightInfo.fareFamilies.fareFamilies[0].agentMarkup
    );
    let TotalBase = Number(
      flightInfo.fareFamilies.fareFamilies[0].totalBaseFare
    );
    let TotalPublish = Number(
      flightInfo.fareFamilies.fareFamilies[0].totalPublishFare
    );
    let Nettax = TotalPublish - TotalBase;
    flightInfo?.fareFamilies?.fareFamilies?.[0]?.flightFares?.forEach((fare) => {
      if (fare.fareTag == "Base") {
        base += fare.amount;

      } else if (fare.fareTag == "Tax") {
        service += fare.amount;

      }
    });

    return (
      <div className="pointer_cursor">
        <p>Per Pax's</p>
        <p>
          {/* Base Fare: {activeCurrency} {currencyValue(base + markup)} */}
          Base Fare : {activeCurrency} {currencyValue(TotalBase)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(Nettax)}
        </p>
      </div>
    );
  };
  return (
    <>
      <div className="choose-btn-block">
        <div className="share-price-mail">
          {/* {selectedFlight[MultiCityIndex]?.flightId == flightInfo.flightId &&
            MultiCityIndex != null ? (
            <p>Selected</p>
          ) : (
            ""
          )} */}
          <div className="choose-btn-price">
            {showNetFare ? (
              <>
                <span className="currencyType text-line">{activeCurrency}</span>
                {/* <Tooltip title={priceToolTip}> */}
                <span className="currency text-line">
                  {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} */}
                  {currencyValue(Math.round(
                    flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                  ))}
                </span>
                {/* </Tooltip> */}

                <div className="text-center">
                  <p className="netfare">
                    Inc: {activeCurrency}{" "}
                    {currencyValue(Math.round(
                      flightInfo?.fareFamilies?.fareFamilies[0]?.commission
                    ))}{" "}
                  </p>
                  <p className="netfare">
                    {activeCurrency}{" "}
                    {currencyValue(Math.round(
                      flightInfo?.fareFamilies?.fareFamilies[0]?.adultNetFare
                    ))}{" "}
                  </p>
                </div>
              </>
            ) : (
              <>
                <span className="currencyType">
                  {/* {flightInfo.fareFamilies.fareFamilies[0].currency} */}
                  {activeCurrency}
                </span>
                {/* <Tooltip title={priceToolTip}> */}
                <span className="currency">
                  {/* {flightInfo.fareFamilies.fareFamilies[0].totalFare.toFixed(2)} adultPublishFare */}
                  {currencyValue(Math.round(
                    flightInfo.fareFamilies.fareFamilies[0].totalPublishFare
                  ))}
                </span>
                {/* </Tooltip>{" "} */}
              </>
            )}
          </div>
          {/* <div className="share-section">
            <MailOutlined onClick={() => showMailModal()} />
           </div> */}
          {/* <div className="right-price-section">
            <span className="price-text">
              Price for{" "}
              {flightSearchObj.adultCount +
                flightSearchObj.infantCount +
                flightSearchObj.childCount}{" "}
              passenger,
              <br />{" "}
              {flightSearchObj.airTravelType === "oneWay"
                ? "Oneway"
                : "Round Trip"}
            </span>
          </div> */}
        </div>
        <div className="choose-btn-section">
          <div className="choose-web-btn">
            <button
              className="choose-button"
              onClick={(e) => {
                let reqData = {
                  serviceType: 1,
                  airlineCode: flightInfo.airLine,
                };
                if (flightInfo.fareFamilies.fareFamilies.length == 1) {
                  onClickChooseFlight(0);
                } else {
                  if (activeCollapse === index) {
                    setActiveCollapse("");
                  } else {
                    setActiveCollapse(index);
                    getAdminFareRules(reqData);
                  }
                }
              }}
            >
              {activeCollapse === index && activeCollapse != null
                ? <i class="fa fa-chevron-up" aria-hidden="true">Prices</i>
                : flightInfo.fareFamilies.fareFamilies.length == 1
                  ? "Book Now"
                  : <i class="fa fa-angle-double-down" aria-hidden="true">Prices</i>}
            </button>


          </div>

        </div>


        <Modal
          title="Send Flight Information"
          visible={openMailModal}
          onOk={openOk}
          onCancel={OpenCancel}
          footer={false}
          className="flight-infoModal"
          centered
          width={400}
        >
          <Form onFinish={sendEmail} form={Emailform}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Required!" },
                { type: "email", message: "Email is not a valid email" },
              ]}
            >
              <Input placeholder="Enter Email Address" />
            </Form.Item>

            <Button type="primary" htmlType={"submit"}>
              Send
            </Button>
          </Form>
        </Modal>
        <Modal
          title="Send Flight Information"
          visible={openNumberModal}
          onOk={clickOk}
          onCancel={clickCancel}
          className="flight-infoModal"
          footer={false}
          centered
          width={400}
        >
          <Input placeholder="Enter Mobile Number" />
          <Button type="primary">Send</Button>
        </Modal>
      </div>
    </>
  );
};
export const FlightSe = ({
  flightInfo,
  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
  MultiCityIndex,
  setActiveCollapse,
  activeCollapse,
  index,
  traceId,
}) => {
  const { Panel } = Collapse;
  const [flightArr, setFlightArr] = useState([]);
  const [flightInfoCopy, setFlightInfoCopy] = useState(flightInfo);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [FareDetails, setFareDetails] = useState(false);
  const { TabPane } = Tabs;


  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      let updatedFlightArr = [];
      if (
        flightSearchObj.resultsType === "COMBINED" &&
        flightSearchObj.airTravelType === "roundTrip"
      ) {
        console.log("im here", flightInfo, fareFamiliesIndex);
        // flightInfo.flightDetails.map((flightDetObj) => {
        //   flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
        //   flightArr.push(flightDetObj);
        //    setFlightArr([flightArr]);
        // });
        // changeFlightResultsView(flightArr);
        if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
          let FareFamiliesList = getFaredetails(
            [...flightInfo?.fareFamilies?.fareFamilies],
            fareFamiliesIndex
          );

          flightInfo.fareFamilies.fareFamilies = FareFamiliesList;
        }

        flightInfo?.flightDetails?.forEach((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo?.fareFamilies;
          updatedFlightArr?.push(flightDetObj);
        });

        setFlightArr(updatedFlightArr);
        changeFlightResultsView(updatedFlightArr);
      } else {
        console.log("iam here2");
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          console.log("iam here3");
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }
          if (journeyType === "return") {

            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;

              changeFlightResultsView(copySelectedFlights);
            } else {

              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;
            changeFlightResultsView(copySelectedFlights);
          }
          console.log("iam here4");
        } else if (flightSearchObj.airTravelType === "multidestination") {
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }
          console.log("iam here5");

          flightInfo.flightDetails.map((flightDetObj) => {
            flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
            flightArr.push(flightDetObj);
            setFlightArr([flightArr]);
          });
          changeFlightResultsView(flightArr);


        } else {
          console.log("iam here6");
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }

          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
    }
  };
  const getFaredetails = (fareArr, fareFamiliesIndex) => {
    let FareIndexData = [];
    let ZeroIndexData = [];
    let FareFamiliesList = fareArr;
    FareIndexData = FareFamiliesList[fareFamiliesIndex];
    ZeroIndexData = FareFamiliesList[0];
    FareFamiliesList[0] = FareIndexData;
    FareFamiliesList[fareFamiliesIndex] = ZeroIndexData;
    return FareFamiliesList;
  };
  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);
  const showNumberModal = () => {
    setOpenNumberModal(true);
  };

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();
  const getCoupanTypeData = (key, coupanType) => { };
  const getAdminFareRules = (fareReqObj) => { };
  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        ).toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };

  const priceTip = () => {
    let base = 0;
    let service = 0;
    let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);

    flightInfo.fareFamilies.fareFamilies.map((fare) => {
      if (fare.fareTag == "Base") {
        base += fare.amount;

      } else if (fare.fareTag == "Tax") {
        service += fare.amount;

      }
    });

    return (
      <div className="pointer_cursor">
        <p>
          Base Fare: {activeCurrency} {currencyValue(base + markup)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(service)}
        </p>
      </div>
    );
  };
  const [loading, setLoading] = useState(false);
  const [openShow, setShow] = useState(true);
  const getCancellationDetails = async (id, tokenId, x) => {
    setActiveTabKey(x);
    try {
      setFareDetails(true);

      let FareRuleObj = {
        flowType: "SEARCH",
        traceId: traceId,
        flightId: [flightInfo?.flightDetails?.[0]?.flightId ?? flightInfo?.flightId], //flightInfo?.flightId, view fares
        airTravelType: flightSearchObj.airTravelType,
        priceId: id,
        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
        Supplier: flightInfo?.flightDetails?.[0]?.supplierKey ?? flightInfo?.supplierKey,
        TripType: flightInfo?.flightDetails ? "RoundTrip" : flightInfo?.isReturn ? "Return" : "Onward",
        tokenId: tokenId,
      };

      setLoading(true);

      // First API Call for Fare Rules
      const fareRulesResponse = await ApiClient.post("flights/airFareRules", FareRuleObj);



      if (fareRulesResponse.statusCode === 200) {
        setFareRules(fareRulesResponse);
      } else if (fareRulesResponse.status === 404) {
        message.error(fareRulesResponse.message, 3);
        setFareDetails(false);
      } else {
        let error_resp = {
          data: {
            fareRules: [
              {
                ruleDetails: {
                  Description: "Something went wrong, Fare Rules not available",
                },
              },
            ],
          },
        };
        setFareRules(error_resp);
        setFareDetails(false);
        message.error(fareRulesResponse.message ?? "fetching fare rules failed");
      }



      const flightInfoResponse = await ApiClient.post("flights/getFlightInfo", FareRuleObj);

      // console.log(flightInfoResponse, "res-flight-info");

      if (flightInfoResponse?.statusCode === 200) {
        // Handle flight info response here
        setFlightIn(flightInfoResponse?.data);
      } else {
        setShow(false);
        message.error(flightInfoResponse?.message ?? "fetching flight info failed");
      }

    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching data.");
    } finally {
      setLoading(false);

    }
  };


  const [FareRules, setFareRules] = useState("");
  const [flightIn, setFlightIn] = useState("");
  const [isBaseDropdownOpen, setBaseDropdownOpen] = useState(false);
  const [isTaxDropdownOpen, setTaxDropdownOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const onTabChange = async (key) => {
    setActiveTabKey(key);

    //  console.log(key,"kkk");
  };

  const groFareRules = FareRules?.data?.fareRules?.reduce((acc, current) => {
    const cityPair = current?.cityPair;
    if (!acc[cityPair]) {
      acc[cityPair] = [];
    }
    acc[cityPair]?.push(current);
    return acc;
  }, {});
  const formatText = (text) => {
    return text
      .replace(/\n/g, '<br>')  // Convert new lines to <br>
      .replace(/(NOTE.*?:)/g, '<strong>$1</strong>')  // Bold any lines that start with 'NOTE'
      .replace(/(CHANGES|CANCELLATIONS|ANY TIME|NO-SHOW)/g, '<strong>$1</strong>')  // Bold specific keywords
      .replace(/(\d+ AED)/g, '<strong>$1</strong>')  // Bold amounts (e.g., 150 AED)
      .replace(/(\-+\n)/g, '<hr>')  // Replace multiple dashes with a horizontal rule
      .replace(/^\s+|\s+$/gm, '');  // Trim leading/trailing whitespace from each line
  }
  const priceTipInfo = () => {
    const paxFareDetails = flightIn?.paxPriceDetails || [];
    // const baggageDetails = flightInfoResp?.ssrPaidFalse?.[0]?.BAGGAGE || [];

    const toggleDropdown = (type) => {
      if (type === 'base') {
        setBaseDropdownOpen(!isBaseDropdownOpen);
      } else if (type === 'tax') {
        setTaxDropdownOpen(!isTaxDropdownOpen);
      }
    };

    let adtBase = 0;
    let chldBase = 0;
    let infBase = 0;
    let totalBase = 0;
    let serviceTax = 0;
    let adultCount = flightIn?.adultCount;
    let childCount = flightIn?.childCount;
    let InFtCount = flightIn?.infantCount;

    paxFareDetails.map((fare) => {
      if (fare.fareTag === "Base") {
        if (fare.paxType === 'ADT') {
          adtBase += Number(fare.amount) * adultCount;
        } else if (fare.paxType === 'CHD') {
          chldBase += Number(fare.amount) * childCount;
        } else if (fare.paxType === 'INF') {
          infBase += Number(fare.amount) * InFtCount;
        }
      } else if (fare.fareTag === "Tax") {
        if (fare.paxType === 'ADT') {
          serviceTax += Number(fare.amount) * adultCount;
        } else if (fare.paxType === 'CHD') {
          serviceTax += Number(fare.amount) * childCount;
        } else if (fare.paxType === 'INF') {
          serviceTax += Number(fare.amount) * InFtCount;
        }
      }
    });
    // console.log(totalBase, paxFareDetails, adtBase, "testt");
    totalBase = adtBase + chldBase + infBase;

    let totalPublishF = 0;
    let totalPassengers = adultCount + childCount + InFtCount;

    return (
      <div className="fare-summary-container">
        <div className="fare-summary-table">
          <p>Fare Summary</p>
          {totalBase > 0 ? (
            <table className="tab-flight-Info">
              <tbody>
                <tr>
                  <td className="toggle-row">
                    Base Fare
                    <span style={{ fontSize: "10px", color: "blue" }} onClick={() => toggleDropdown('base')}>
                      {isBaseDropdownOpen ? " (close)" : " (view)"}
                    </span>
                    <span className="total-amount">{currencyValue(Math.round(totalBase))}</span>
                  </td>
                </tr>
                {isBaseDropdownOpen && (
                  <>
                    {adultCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Adult x {adultCount}: <span className="total-amount">{currencyValue(Math.round(adtBase))}</span>
                        </td>
                      </tr>
                    )}
                    {childCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Child x {childCount}: <span className="total-amount">{currencyValue(Math.round(chldBase))}</span>
                        </td>
                      </tr>
                    )}
                    {InFtCount > 0 && (
                      <tr>
                        <td className="toggle-row">
                          Infant x {InFtCount}: <span className="total-amount">{currencyValue(Math.round(infBase))}</span>
                        </td>
                      </tr>
                    )}
                  </>
                )}
                <tr>
                  <td className="toggle-row">
                    Tax Charges
                    <span className="total-amount">{currencyValue(Math.round(serviceTax))}</span>
                  </td>
                </tr>
                {isTaxDropdownOpen &&
                  paxFareDetails
                    .filter((fare) => fare.fareTag === 'Tax')
                    .map((fare, index) => (
                      <tr key={index}>
                        <td>{fare.paxType}: {currencyValue(Math.round(fare.amount))}</td>
                      </tr>
                    ))}
                <tr>
                  <td className="total-row">
                    Total Amount
                    <span style={{ color: "#35459c" }} className="total-amount">
                      {activeCurrency} {currencyValue(Math.round(totalBase + serviceTax))}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>


      </div>
    );
  };


  const flightBaggageInfo = () => {
    const baggageDetails = flightIn?.ssrPaidFalse || [];

    const groupBaggageDetailsByCityPair = () => {
      return baggageDetails.reduce((acc, baggage) => {
        baggage?.BAGGAGE?.forEach(bag => {
          const { cityPair, weight, paxType } = bag;
          if (!acc[cityPair]) {
            acc[cityPair] = [];
          }
          acc[cityPair].push({ weight, paxType });
        });
        return acc;
      }, {});
    };

    const groupedBaggageDetails = groupBaggageDetailsByCityPair();

    const formatWeightWithPaxType = (weight, paxType) => {
      switch (paxType) {
        case 'ADT':
          return `${weight} (Adult)`;
        case 'CHD':
          return `${weight} (Child)`;
        case 'INF':
          return `${weight} (Infant)`;
        default:
          return weight;
      }
    };

    return (
      <div className="fare-summary-container" style={{ marginTop: "2px 4px" }}>
        <div><p>Baggage Allowed </p></div>

        {Object.keys(groupedBaggageDetails).map((cityPair, index) => {
          // Using Set to keep track of unique baggage details
          const uniqueCheckinBaggage = new Set();
          const uniqueCabinBaggage = new Set();

          // Helper function to format weight and handle "0 kg"
          const formatWeight = (weight, paxType) => {
            const formattedWeight = formatWeightWithPaxType(weight, paxType);
            return formattedWeight === "0 kg" ? "N/A" : formattedWeight;
          };

          return (
            <table key={index} className="tab-flight-Info">
              <thead>
                <tr>
                  <th>Sector</th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Check-in Baggage</div>
                  </th>
                  <th>
                    <div style={{ display: "flex", justifyContent: "center" }}>Cabin Baggage</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{cityPair}</td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag) => {
                        const checkinWeight = formatWeight(bag?.weight?.split(',')[0], bag?.paxType);
                        if (!uniqueCheckinBaggage.has(checkinWeight)) {
                          uniqueCheckinBaggage.add(checkinWeight);
                          return <div key={checkinWeight}>{checkinWeight}</div>;
                        }
                        return null;
                      }).filter(Boolean).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                      {groupedBaggageDetails[cityPair]?.map((bag) => {
                        const cabinWeight = formatWeight(bag?.weight?.split(',')[1], bag?.paxType);
                        if (!uniqueCabinBaggage.has(cabinWeight)) {
                          uniqueCabinBaggage.add(cabinWeight);
                          return <div key={cabinWeight}>{cabinWeight}</div>;
                        }
                        return null;
                      }).filter(Boolean).reduce((prev, curr) => [prev, ' | ', curr])}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}




      </div>
    );
  };


  return (
    <>
      <div className="FarDetailsCollapse">
        <Collapse activeKey={activeCollapse} bordered={false}>
          <Panel
            style={{ background: "#eaeaea" }}
            key={index}
            showArrow={false}
          >
            {/* {console.log(flightInfo.fareFamilies,"fli8fare")} */}
            {flightInfo?.fareFamilies?.fareFamilies?.length > 0 && (
              <div className="fareTypeContainer mt-1">
                <Row className="row-Headings" gutter={10}>
                  <Col md={7} style={{ display: "flex", justifyContent: "start", paddingLeft: "24px" }}>FARE TYPE</Col>
                  {/* <Col md={4}>CABIN BAG</Col> */}
                  <Col md={6} style={{ display: "flex", justifyContent: "center" }}>Flight Info</Col>
                  <Col md={4} style={{ display: "flex", justifyContent: "center" }}>Policy</Col>

                  <Col md={4} style={{ display: "flex", justifyContent: "center" }}>Price</Col>
                  <Col md={3}></Col>
                </Row>

                {flightInfo?.fareFamilies?.fareFamilies.map((item, indexP) => {

                  return (
                    <Row className={item?.coupanType.includes("NDC FARE") ? 'row-DataNdc' : 'row-Data'}
                      key={item?.fareId}>
                      {/* {console.log(item, indexP, "multi")} */}
                      <Col md={7} style={{ display: "block", alignItems: "center" }}>
                        {/* <p className="TypeName">
                          {item?.coupanType} -{" "}
                          {item?.segmentInfos?.[0]?.cabinClass || null}
                        </p> */}

                        {item?.segmentInfos.map((segment, i) => {
                          return (
                            <p className="TypeName">
                              {((flightSearchObj.resultsType.toUpperCase() === "COMBINED" || flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") &&
                                item?.segmentInfos.length === 2)
                                ? <span className="flight-dir">{i === 1 ? " RT" : "OW"}</span> : flightSearchObj.resultsType.toUpperCase() === "COMBINED" && flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" ? <span className="flight-dir">{"OW"}</span> : null}
                              <span>{segment?.bookingClass}</span>
                              {/* {item?.segmentInfos?.[0]?.cabinClass || null} */}
                            </p>
                          )
                        })
                        }


                        {/* <p className="sub-FareTypeName">
                          <b>
                            Sub-Cabin Class:{" "}
                            {item?.segmentInfos?.length
                              ? item.segmentInfos?.[0]?.cabinSubClass
                              : ""}
                          </b>
                        </p> */}
                        {/* <p className="TypeName">
                          {item?.coupanType?.toUpperCase() == "SMEFARE"
                            ? "Eligible for small or medium corporate customer."
                            : item?.coupanType?.toUpperCase() == "CORPORATEFARE"
                              ? "Exclusively offered to organizations."
                              : "Fare offered by airline."}
                        </p> */}
                        {/* <p className="FareTypeName"> */}

                      </Col>
                      {/* <Col className="bag-inf" md={4}>
                        <p className="m-0 text">
                          {item?.baggage?.[0]?.cabinBaggageInfo || "No baggage info"}
                        </p>
                      </Col> */}
                      <Col className="bag-inf" md={6}>
                        {/* <p className="m-0 text">
                          {item?.baggage?.[0]?.baggageInfo || "No baggage info"}
                        </p> */}
                        {/* {item.segmentInfos?.[0]?.cabinSubClass &&
                          <div className="cabinTy">
                            <Tooltip title={"Cabin Class"}>
                            {item?.segmentInfos?.length
                              ? item.segmentInfos?.[0]?.cabinSubClass
                              : ""}
                              {/* {item?.segmentInfos?.[0]?.cabinClass?.[0]} *
                            </Tooltip>
                          </div>
                        } */}

                        <div className="bagCkIn"
                          onClick={() => getCancellationDetails(item.fareId, item.supplierParameter, "2")}>
                          <Tooltip title={"Allowed Baggage " + item?.baggage?.[0]?.baggageInfo}>
                            <span><i class="fa fa-suitcase" aria-hidden="true"></i></span>{" "}
                            <span>{item?.baggage?.[0]?.baggageInfo}</span>
                          </Tooltip>
                        </div>
                        <div className="seatIf">
                          <Tooltip title={item?.segmentInfos?.[0]?.seatRemaining + " Seats left"}>
                            <span><i class="fa fa-users" aria-hidden="true"></i></span>{" "}
                            <span>{item?.segmentInfos?.[0]?.seatRemaining || "N/A"}</span>
                          </Tooltip>
                        </div>
                        <div className="refstatus">
                          {/* <span><i class="fa fa-users" aria-hidden="true"></i></span>{" "} */}
                          <Tooltip title={item?.isRefundable ? "Refundable" : "Non-Refundable"}>
                            {item?.isRefundable ?
                              <span style={{ color: "green" }}> {"R"}</span> : <span style={{ color: "red" }}>{"N"}</span>}
                          </Tooltip>
                        </div>

                      </Col>
                      <Col className="bag-inf" md={4}>


                        <Button
                          className="details-btn"
                          onClick={() => getCancellationDetails(item.fareId, item.supplierParameter, "1")}
                        >
                          Fare Details
                        </Button>
                        {/* {loading && <Spin size="large" />} */}
                      </Col>

                      <Col md={4} className="priceCh">
                        <div
                          style={{ whiteSpace: "nowrap" }}
                          key={item?.fareId}
                        // className="priceCh"
                        >
                          {/* {" "} */}
                          <span className="pri-ad1 m-0 pr-1 text">
                            {activeCurrency}
                          </span>{" "}

                          <span className="pri-ad m-0 text">
                            {currencyValue(Math.round(item.totalPublishFare?.toFixed(2)))}
                          </span>
                          {/* <p className="pri-ad m-0 text">{activeCurrency} {currencyValue(item.adultNetFare.toFixed(2))}</p> */}
                        </div>
                      </Col>
                      <Col className="price-dat" md={3}>

                        <div className="choose-web-btn text-right" key={indexP}>
                          <Button
                            className="choose-button"
                            style={{ margin: "0" }}
                            onClick={() => {
                              onClickChooseFlight(indexP);
                            }}
                          >
                            Book Now
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}

            <div className="bottom-airline-text">
              <p style={{ color: "#757575", fontSize: "12px" }} className="mb-0 p-2">
                Airline reserves the right to change the Cancellation/Change
                Fees from time to time without any prior intimation. *Fee as per
                existing Regular Fare Policy applicable & may vary for domestic
                and international bookings. **Days left for departure.
              </p>
            </div>
          </Panel>
        </Collapse>
      </div>
      {FareDetails &&

        <Modal
          title="Fare Details"
          visible={FareDetails}
          footer={[null]}
          onOk={(e) => setFareDetails(false)}
          onCancel={(e) => setFareDetails(false)}
          className="flight-details-modal1"
        >
          <div className="fareRule-container" style={{ padding: "4px 8px" }}>

            {loading ? (
              <div style={{ padding: "10px 0 10px 10px" }} >
                <Spin
                  size="large"
                  indicator={antIcon}
                  style={{ marginRight: 8 }}
                />
                Loading...
              </div>
            ) :
              <Tabs
                activeKey={activeTabKey} onChange={onTabChange}
                defaultActiveKey="1"
              // onTabClick={(str, e) => fetchFlightFares(str, e)}
              >

                <TabPane tab="Fare Summary" key="1">
                  <div className="Fare-modal">
                    {openShow &&
                      <div className="Fare-cont">
                        <div className="Fare-box">
                          {/* {console.log(flightIn, "info")} */}
                          {priceTipInfo()}
                        </div>
                      </div>
                    }
                    <div className={openShow ? "fare-summ-info" : "fare-summ-inf1"} >
                      <p style={{ margin: "4px 2px" }}>Fare Rules</p>
                      <div className="Fare-rule-info">
                        {FareRules?.data?.supplierName === "AKBAR" && FareRules?.data != undefined ? (
                          <div>
                            {FareRules?.data?.fareRules?.length >= 1 ? (
                              <>
                                {/* {console.log(groFareRules, FareRules?.data, "fareee")} */}
                                {Object.keys(groFareRules).map((cityPair, index) => (
                                  <div key={index} style={{ marginBottom: '20px' }}>
                                    <h3>{cityPair}</h3>
                                    {groFareRules[cityPair].map((fareRestriction, restrictionIndex) => (
                                      <div key={restrictionIndex}>
                                        {/* <div style={{ marginRight: '20px', fontWeight: 'bold', minWidth: '150px' }}> */}
                                        {/* <h3>{fareRestriction.fareRestriction}</h3> */}
                                        {fareRestriction.fareRestriction.length > 100 ?
                                          ReactHtmlParser(formatText(fareRestriction.fareRestriction)) : <h6>{fareRestriction.fareRestriction}</h6>}
                                        {/* </div> */}
                                        <table style={{ border: '1px solid black', borderCollapse: 'collapse', width: '100%' }}>
                                          <thead>
                                            <tr>
                                              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Description</th>
                                              <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Adult</th>
                                              {flightSearchObj.childCount > 0 && (
                                                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Child</th>
                                              )}
                                              {flightSearchObj.infantCount > 0 && (
                                                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Infant</th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {fareRestriction?.ruleDetails.length > 0 ? (
                                              fareRestriction?.ruleDetails.map((flight, flightIndex) => (
                                                <tr key={`${restrictionIndex}-${flightIndex}`}>
                                                  <td style={{ border: '1px solid black', padding: '8px' }}>{flight?.Description}</td>
                                                  <td style={{ border: '1px solid black', padding: '8px' }}>
                                                    {flight?.adultPrice !== null ? `${activeCurrency} ${currencyValue(flight?.adultPrice?.toFixed(2))}` : "N/A"}
                                                  </td>
                                                  {flightSearchObj?.childCount > 0 && (
                                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                                      {flight?.childPrice !== null ? `${activeCurrency} ${currencyValue(flight?.childPrice?.toFixed(2))}` : "N/A"}
                                                    </td>
                                                  )}
                                                  {flightSearchObj?.infantCount > 0 && (
                                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                                      {flight?.InfantPrice !== null ? `${activeCurrency} ${currencyValue(flight?.InfantPrice?.toFixed(2))}` : "N/A"}
                                                    </td>
                                                  )}
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={4} style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>No data available</td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </>
                            ) : (
                              FareRules?.data.fareRules.map((rule, i) => (
                                <>
                                  {rule.fareRestriction.length > 100 ?
                                    ReactHtmlParser(formatText(rule.fareRestriction)) : <h6>{rule.fareRestriction}</h6>}
                                </>
                              ))
                            )}
                          </div>


                        ) : (
                          FareRules.data.fareRules.map((rule, i) => (<>
                            {rule?.ruleDetails ?
                              <p key={i}>{ReactHtmlParser(formatText(rule?.ruleDetails))}</p> :
                              <p>Something went wrong, please try again</p>
                            }
                          </>
                          ))
                        )}

                      </div>
                    </div>
                  </div>
                  <div className="fare-flig-inf" style={{ marginTop: "4px" }}>
                    <p style={{ color: "grey", fontSize: "13px" }}>
                      <i className="fa fa-circle-o" aria-hidden="true"></i> The above data is indicative, fare rules are subject to changes by the Airline from time to time depending upon Fare class and change/cancellation fee amount may also vary based on fluctuations in currency conversion rates.
                    </p>
                    <p style={{ color: "grey", fontSize: "13px" }}>
                      <i className="fa fa-circle-o" aria-hidden="true"></i> Although we will try to keep this section updated regularly.
                    </p>
                    <p style={{ color: "grey", fontSize: "13px" }}>
                      <i className="fa fa-circle-o" aria-hidden="true"></i> Feel free to call our Contact Centre for exact cancellation/change fee.
                    </p>



                    <p style={{ color: "grey", fontSize: "13px" }}>
                      <i className="fa fa-circle-o" aria-hidden="true"></i> The information presented above is as obtained from the airline reservation system.
                      <span style={{ color: "#bd0c21" }}>outc.in </span> does not guarantee the accuracy of this information.
                    </p>
                  </div>
                </TabPane>
                {openShow &&
                  <TabPane tab="Baggage" key="2">
                    <div className="Fare-bagin">
                      {flightBaggageInfo()}
                    </div>
                    <div className="fare-flig-inf" style={{ marginTop: "4px" }}>
                      <p style={{ color: "grey", fontSize: "13px" }}>
                        <i className="fa fa-circle-o" aria-hidden="true"></i> The information presented above is as obtained from the airline reservation system.
                        <span style={{ color: "#bd0c21" }}> outc.in </span> does not guarantee the accuracy of this information.
                      </p>
                      <p style={{ color: "grey", fontSize: "13px" }}>
                        <i className="fa fa-circle-o" aria-hidden="true"></i> The baggage allowance may vary according to stop-overs, connecting flights and changes in airline rules.
                      </p>
                    </div>
                  </TabPane>
                }
              </Tabs>


            }
            {/* <div className="fare-flig-inf">
              <p style={{ color: "grey", fontSize: "13px" }}>
                <i className="fa fa-circle-o" aria-hidden="true"></i> The above data is indicative, fare rules are subject to changes by the Airline from time to time depending upon Fare class and change/cancellation fee amount may also vary based on fluctuations in currency conversion rates.
              </p>
              <p style={{ color: "grey", fontSize: "13px" }}>
                <i className="fa fa-circle-o" aria-hidden="true"></i> Although we will try to keep this section updated regularly.
              </p>
              <p style={{ color: "grey", fontSize: "13px" }}>
                <i className="fa fa-circle-o" aria-hidden="true"></i> Feel free to call our Contact Centre for exact cancellation/change fee.
              </p>



              <p style={{ color: "grey", fontSize: "13px" }}>
                <i className="fa fa-circle-o" aria-hidden="true"></i> The information presented above is as obtained from the airline reservation system.
                <span style={{ color: "#bd0c21" }}> OutC.com.com </span> does not guarantee the accuracy of this information.
              </p>
              <p style={{ color: "grey", fontSize: "13px" }}>
                <i className="fa fa-circle-o" aria-hidden="true"></i> The baggage allowance may vary according to stop-overs, connecting flights and changes in airline rules.
              </p>
            </div> */}
            {/* <div className="Fare-bagin">
              {flightBaggageInfo()}
            </div> */}
          </div>
        </Modal >
      }
    </>
  );
};

export default Flight;

