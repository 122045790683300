// import React, { useContext, useEffect, useState } from "react";
// import { Button, Card, Col, Input, Form, Row, message, Radio, Carousel, Collapse, } from "antd";
// import moment from "moment";
// import { useAuthContext } from "../../common/providers/AuthProvider";
// import { GlobalStatesContext } from "../../common/providers";
// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
// import { CloseOutlined } from "@ant-design/icons";
// import APIClient from "../../helpers/ApiClient";
// import "./FlightsCheckout.scss"

// import { getFlightGrandTotalPrice } from "./flightPriceHelper";

// const FlightGrandTotalCard = ({
//   airPriceResp,
//   PromoNotVisible = false,
//   flightSearchObj,
//   mealdetails,
//   baggagedetails
// }) => {
//   const {
//     isLogin: { agent },
//     user,
//   } = useAuthContext();
//   const [promoform] = Form.useForm();

//   const {
//     state: {
//       otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
//     },
//     AddConvFee,
//     validatePromoCode,
//     RemovePromo,
//     getInsuranceByServiceType,
//     validateRedeemCoupon,
//     RemoveRedeemCoupon,
//   } = useContext(GlobalStatesContext);
//   const { activeCurrency, currencyValue } = useCurrencyContext();
//   // console.log(activeCurrency, currencyValue,"valuesd");
//   const [promoCode, setPromoCode] = useState([]);
//   let dateFormat = "YYYY-MM-DD";
//   const [redeemData, setRedeemData] = useState({});


//   useEffect(() => {
//     if (user) {
//       getRedeemCoupon(user.UserID);
//     }
//   }, [user]);
//   const getRedeemCoupon = (userID) => {
//     setRedeemData({});
//     APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
//       .then((res) => {
//         if (res.status === 200) {
//           setRedeemData(res.data);
//         }
//       })
//       .catch((error) => {
//         setRedeemData({});
//       });
//   };
//   useEffect(() => {
//     getInsuranceByServiceType(1);
//   }, []);

//   useEffect(() => {
//     if (!agent) {
//       AddConvFee(1);
//       getpromo();
//     }
//   }, [agent]);

//   const getpromo = () => {
//     APIClient.get("admin/promo")
//       .then((res) => {
//         if (res.status === 200) {
//           let data = res.data.filter((item) =>
//             moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
//           );
//           if (data.length > 0) {
//             let flightPromo = data.filter(
//               (promo) =>
//                 promo.ServiceType === 1 &&
//                 (promo.ApplicationType === 1 || promo.ApplicationType === 3)
//             );

//             setPromoCode(flightPromo);
//           }
//         }
//       })
//       .catch((error) => {
//         setPromoCode([]);

//         console.error(error);
//       });
//   };

//   const handleApply = (code) => {
//     promoform.setFieldsValue({
//       promo: code,
//     });

//     promoform.submit();
//   };

//   const {
//     grandTotal,
//     convamount,
//     discount,
//     insuranceTotal,
//     commission,
//     redeemTotal,
//     mealAmount,
//     baggageAmount,
//   } = getFlightGrandTotalPrice(
//     airPriceResp,
//     ConvFee,
//     promoData,
//     flightSearchObj,
//     selectedInsuranceData,
//     redeemAmount,
//     mealdetails,
//     baggagedetails
//   );
//   useEffect(() => {
//     // Close the panel when the key changes
//     setActiveKey([]);
//   }, []);
//   // { console.log(mealAmount, mealdetails, baggageAmount, commission, "ffffamil") }
//   const [activeKey, setActiveKey] = useState(['1']);
//   const [active, setActive] = useState(['1']);
//   const onChange = (key) => {


//     setActiveKey(key);
//   };
//   const onSsr = (key) => {
//     setActive(key);
//   };
//   let Netfare = grandTotal - commission;
//   const item = [
//     {
//       key: '1',
//       label: 'Add On',
//       children: <div className="tot-fare-tab">
//         {mealAmount > 0 ?
//           <div className="tab">
//             <p className="amount-ta">
//               Meal :
//             </p>
//             <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
//           </div> : null}
//         {baggageAmount > 0 ?
//           <div className="tab">
//             <p className="amount-ta">
//               Baggage :
//             </p>
//             <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
//           </div> : null}

//       </div>,
//     },

//   ];
//   const items = [
//     {
//       key: '2',
//       label: 'Add On',
//       children: <div className="tot-fare-tab">
//         {mealAmount > 0 || baggageAmount > 0 ?
//           <>
//             {mealAmount > 0 ?
//               <div className="tab">
//                 <p className="amount-ta">
//                   Meal :
//                 </p>
//                 <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
//               </div> : null}
//             {baggageAmount > 0 ?
//               <div className="tab">
//                 <p className="amount-ta">
//                   Baggage :
//                 </p>
//                 <p className="amou" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
//               </div> : null}
//           </> : "No Add On Services Added"}

//       </div>,
//     },

//     {
//       key: '1',
//       label: 'Fare Summary',
//       children: <div className="tot-fare-tab">
//         <div className="tab">
//           <p className="amount-tag">
//             Grand Total :
//           </p>
//           <p className="amount" style={{ color: "#bd0c21" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(grandTotal)}</p>
//         </div>

//         <div className="tab">
//           <p className="amount-tag">
//             Commission :
//           </p>
//           <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(commission)}</p>
//         </div>
//         <div className="tab">
//           <p className="amount-tag">
//             Net Fare :
//           </p>
//           <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(Netfare)}</p>
//         </div>
//       </div>,
//     },
//     // {
//     //   key: '2',
//     //   label: 'Total Net Fare',
//     //   // children: <p>{Pan}</p>,
//     // },
//     // {
//     //   key: '3',
//     //   label: 'Commission Earned',
//     //   children: <p className="amount ml-1 pax-comm">
//     //     {" "}
//     //     {currencyValue(commission)}
//     //   </p>,
//     // },
//   ];
//   return (
//     <>
//       <div className="grand-t">
//         <p className="Tf-tag">Total Fare</p>
//         {!agent && user?.Role?.RoleLevel !== 3 && (
//           <>

//             {(baggageAmount > 0 || mealAmount > 0) ?
//               <div className="info">


//                 <Collapse accordion ghost onChange={onSsr} activeKey={active}>
//                   {item.map((item) => (
//                     <Collapse.Panel key={item.key} header={item.label}>
//                       <p>{item.children}</p>
//                     </Collapse.Panel>
//                   ))}
//                 </Collapse>

//               </div> : null}


//             {convamount > 0 ?
//               <Row justify={"space-between"} className="grand_total_card_row">
//                 <Col>
//                   <p>Convenience Fee</p>
//                 </Col>
//                 <Col>
//                   <p>
//                     {activeCurrency} {currencyValue(convamount)}
//                   </p>
//                 </Col>
//               </Row> : null}
//             {promoData.status && (
//               <Row justify={"space-between"} className="grand_total_card_row">
//                 <Col>
//                   <p>Discount</p>
//                 </Col>
//                 <Col>
//                   <p>
//                     - {activeCurrency} {currencyValue(discount)}
//                   </p>
//                 </Col>
//               </Row>
//             )}
//           </>
//         )}

//         {selectedInsuranceData.status === 1 &&
//           selectedInsuranceData?.serviceType === 1 ? (
//           <Row justify={"space-between"} className="grand_total_card_row">
//             <Col>
//               <p>Insurance Amount</p>
//             </Col>
//             <Col>
//               <p>
//                 {activeCurrency} {currencyValue(insuranceTotal)}
//               </p>
//             </Col>
//           </Row>
//         ) : null}
//         {!agent ?
//           <div className="pax-total-price">
//             <div className="pax-type">
//               <p>Grand Total:</p>
//             </div>
//             <div className="total">
//               <p>{activeCurrency} </p>
//               <p className="amount ml-1" style={{ color: "#bd0c21" }}> {currencyValue(grandTotal)}</p>
//             </div>
//           </div> : null
//         }
//         {/* {console.log(agent,"chkagent")} */}
//         {agent || commission > 0 ? (
//           <div>

//             <div className="info">


//               <Collapse accordion ghost onChange={onChange} activeKey={activeKey}>
//                 {items.map((item) => (
//                   <Collapse.Panel key={item.key} header={item.label}>
//                     <p>{item.children}</p>
//                   </Collapse.Panel>
//                 ))}
//               </Collapse>

//             </div>
//             {/* <div className="pax-total-price">
//               <div className="pax-type">
//                 <p className="pax-comm">Commission Earned:</p>
//               </div>
//               <div className="total">
//                 <p className="pax-comm">{activeCurrency} </p>
//                 <p className="amount ml-1 pax-comm">
//                   {" "}
//                   {currencyValue(commission)}
//                 </p>
//               </div>
//             </div> */}
//           </div>
//         ) : null}
//       </div>
//       {!agent && user?.Role?.RoleLevel !== 3 && !PromoNotVisible ? (
//         <div className="promo-card apply-promo-fbt">
//           {promoData.status == false ? (
//             <div className="promo-c">

//               <p className="name">Apply Promo</p>

//               <div className="promo-input">
//                 <Form
//                   name="customized_form_controls"
//                   layout="inline"
//                   form={promoform}
//                   onFinish={(d) => {
//                     validatePromoCode({
//                       ServiceType: 1,
//                       Amount: Number(grandTotal),
//                       PromoCode: d.promo,
//                       UserId: user?.UserID ?? 1,
//                     });
//                   }}
//                 >
//                   <Form.Item name="promo" className="promo-input-item">
//                     <Input
//                       className="contacts-value-de"
//                       placeholder="Enter Your Promo code"
//                       autoComplete="off"
//                       size={"large"}
//                     />
//                   </Form.Item>
//                   <Form.Item className="promo-input-btn">
//                     <Button
//                       className="apply-promo-value-code"
//                       type="primary"
//                       htmlType="submit"
//                       size="large"
//                     >
//                       Apply
//                     </Button>
//                   </Form.Item>
//                 </Form>
//               </div>
//               <div className="promo-data-1">
//                 {promoCode.length ? (
//                   promoCode.map((item, key) => {
//                     return (
//                       <div className="promo-cp-coupons mt-3" key={key}>
//                         <div className="inline-cp-promo">
//                           <Form>
//                             <Form.Item>
//                               <Radio
//                                 onClick={() => handleApply(item.Code)}
//                               ></Radio>
//                             </Form.Item>
//                           </Form>
//                           <p className="promo-key-cp">{item.Code}</p>
//                           {item.DiscountType === 1 ? (
//                             <p className="save-cp-offer">
//                               Save {(item.Discount) + " %"}
//                             </p>
//                           ) : (
//                             <p className="save-cp-offer">
//                               Save {activeCurrency}&nbsp;
//                               {currencyValue(item.Discount)}
//                             </p>
//                           )}
//                         </div>

//                         <div className="promo-percentage-cp">
//                           <p>{item.Description}</p>
//                         </div>
//                       </div>
//                     );
//                   })
//                 ) : (
//                   <div className="promo-cp-coupons mt-3">
//                     <div className="inline-cp-promo">
//                       <p className="promo-key-cp">No Promo Code Available</p>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           ) : (
//             <div className="promo-c">
//               <div className="promo-card-header">
//                 <p
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                   }}
//                   className="name"
//                 >
//                   Promo Coupon{" "}
//                   <CloseOutlined
//                     onClick={() => {
//                       RemovePromo();
//                       promoform.resetFields();
//                     }}
//                   />{" "}
//                 </p>
//               </div>
//               <div className="promo-input">
//                 <p>
//                   <span className="applied"> {promoData.Code} </span> Promo Code
//                   Applied
//                 </p>
//               </div>
//             </div>
//           )}
//         </div>
//       ) : null}
//       {/* {user && redeemData?.couponAmt > 0 ? (
//         redeemAmount.status === true ? (
//           <Card className="flight-cards-details mt-3">
//             <div className="d-flex justify-content-between align-items-center">
//               <p className="name mb-0">
//                 Redeemed From Coupon Wallet: {redeemTotal}
//               </p>
//               {PromoNotVisible ? null : (
//                 <CloseOutlined
//                   onClick={() => {
//                     RemoveRedeemCoupon();
//                   }}
//                 />
//               )}
//             </div>
//           </Card>
//         ) : PromoNotVisible ? null : (
//           <Card className="flight-cards-details mt-3">
//             <p className="font-weight-bold mb-1">
//               Coupon Wallet: {/* {activeCurrency} *
//               {"("}
//               {redeemData?.couponAmt} {")"}
//             </p>

//             <div className="book-pay-btn">
//               {" "}
//               <Button
//                 type="primary"
//                 className=" redeem_btn"
//                 onClick={() => {
//                   validateRedeemCoupon({
//                     userId: user?.UserID ?? 0,
//                     roleType: user?.Role?.RoleId ?? 0,
//                     membershipId: user?.Membership ?? 0,
//                     couponAmt: redeemData?.couponAmt ?? 0,
//                   });
//                 }}
//               >
//                 Redeem Coupon
//               </Button>
//             </div>
//           </Card>
//         )
//       ) : null} */}
//     </>
//   );
// };

// export default FlightGrandTotalCard;

import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Form, Row, message, Radio, Modal, Carousel, Collapse, Alert, Typography, } from "antd";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { CloseOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import "./FlightsCheckout.scss";
import { getFlightGrandTotalPrice } from "./flightPriceHelper";
import Login from "../Login/Login";
const { Title, Paragraph } = Typography;
const FlightGrandTotalCard = ({
  airPriceResp,
  PromoNotVisible = false,
  flightSearchObj,
  mealdetails,
  baggagedetails,
  location,
  seatdetails,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [promoform] = Form.useForm();

  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  // console.log(activeCurrency, currencyValue,"valuesd");
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  const [redeemData, setRedeemData] = useState({});
  const [loginModal, setLoginModal] = useState(false);


  useEffect(() => {
    if (user) {
      getRedeemCoupon(user.UserID);
    }
  }, [user]);
  const getRedeemCoupon = (userID) => {
    setRedeemData({});
    APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };
  useEffect(() => {
    getInsuranceByServiceType(1);
  }, []);

  useEffect(() => {
    if (!agent) {
      AddConvFee(1);
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment(), 'day')
          );
          if (data.length > 0) {
            let flightPromo = data.filter(
              (promo) =>
                promo.ServiceType === 1 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(flightPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const handleApply = (code) => {
    if (user != null) {
      promoform.setFieldsValue({
        promo: code,
      });

      promoform.submit();
    } else {
      setLoginModal(true);
      // message.error("please Sign-In to Avail Offers")
    }
  };

  const {
    grandTotal,
    convamount,
    discount,
    insuranceTotal,
    commission,
    redeemTotal,
    mealAmount,
    baggageAmount,
    seatAmount,
  } = getFlightGrandTotalPrice(
    airPriceResp,
    ConvFee,
    promoData,
    flightSearchObj,
    selectedInsuranceData,
    redeemAmount,
    mealdetails,
    baggagedetails,
    seatdetails,
  );
  useEffect(() => {
    // Close the panel when the key changes
    setActiveKey([]);
  }, []);
  // { console.log(mealAmount, mealdetails, baggageAmount, commission, "ffffamil") }
  const [activeKey, setActiveKey] = useState(['1']);
  const [active, setActive] = useState(['1']);
  const onChange = (key) => {


    setActiveKey(key);
  };
  const onSsr = (key) => {
    setActive(key);
  };
  let Netfare = grandTotal - commission;
  const item = [
    {
      key: '1',
      label: 'Travel Addons',
      children: <div className="tot-fare-tab">
        {mealAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Meal :
            </p>
            <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
          </div> : null}
        {baggageAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Baggage :
            </p>
            <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
          </div> : null}
        {seatAmount > 0 ?
          <div className="tab">
            <p className="amount-ta">
              Seat :
            </p>
            <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(seatAmount)}</p>
          </div> : null}
      </div>,
    },

  ];
  const items = [
    {
      key: '2',
      label: 'Travel Addons',
      children: <div className="tot-fare-tab">
        {mealAmount > 0 || baggageAmount > 0 || seatAmount > 0 ?
          <>
            {mealAmount > 0 ?
              <div className="tab">
                <p className="amount-ta">
                  Meal :
                </p>
                <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(mealAmount)}</p>
              </div> : null}
            {baggageAmount > 0 ?
              <div className="tab">
                <p className="amount-ta">
                  Baggage :
                </p>
                <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(baggageAmount)}</p>
              </div> : null}
            {seatAmount > 0 ?
              <div className="tab">
                <p className="amount-ta">
                  Seat :
                </p>
                <p className="amou" style={{ color: "#000000" }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(seatAmount)}</p>
              </div> : null}
          </> : "No Add On Services Added"}

      </div>,
    },

    {
      key: '1',
      label: 'Fare Summary',
      children: <div className="tot-fare-tab">
        <div className="tab">
          <p className="amount-tag" style={{ fontSize: '20px', fontWeight: 600, fontFamily: 'Nunito' }}>
            Grand Total :
          </p>
          <p className="amount" style={{ color: "#000000", fontSize: '22px', fontWeight: 600, fontFamily: 'Nunito' }}><span style={{ color: "black" }} className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(grandTotal)}</p>
        </div>

        <div className="tab">
          <p className="amount-tag">
            Commission :
          </p>
          <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(commission)}</p>
        </div>
        <div className="tab">
          <p className="amount-tag">
            Net Fare :
          </p>
          <p className="amount"><span className="txt-inr">{activeCurrency}</span>{" "}{currencyValue(Netfare)}</p>
        </div>
      </div>,
    },
    // {
    //   key: '2',
    //   label: 'Total Net Fare',
    //   // children: <p>{Pan}</p>,
    // },
    // {
    //   key: '3',
    //   label: 'Commission Earned',
    //   children: <p className="amount ml-1 pax-comm">
    //     {" "}
    //     {currencyValue(commission)}
    //   </p>,
    // },
  ];
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  return (
    <>
      <>
        <Card>
          <div className="grand-t">
            {/* <p className="Tf-tag">Total Fare</p> */}
            {!agent && user?.Role?.RoleLevel !== 3 && (
              <>
                {/* {console.log("hh",agent)} */}
                {(baggageAmount > 0 || mealAmount > 0 || seatAmount > 0) ?
                  <div className="info">


                    <Collapse accordion ghost onChange={onSsr} activeKey={active}>
                      {item.map((item) => (
                        <Collapse.Panel key={item.key} header={item.label}>
                          <p>{item.children}</p>
                        </Collapse.Panel>
                      ))}
                    </Collapse>

                  </div> : null}


                {convamount > 0 ?
                  <Row justify={"space-between"} className="grand_total_card_row">
                    <Col>
                      <p style={{ fontSize: '16px', fontWeight: 600, fontFamily: 'Nunito' }}>Convenience Fee</p>
                    </Col>
                    <Col>
                      <p style={{ fontSize: '16px', fontWeight: 600, fontFamily: 'Nunito' }}>
                        {activeCurrency} {currencyValue(convamount)}
                      </p>
                    </Col>
                  </Row> : null}
                {promoData.status && (
                  <Row justify={"space-between"} className="grand_total_card_row">
                    <Col>
                      <p style={{ fontSize: '16px', fontWeight: 600, fontFamily: 'Nunito' }}>Discount</p>
                    </Col>
                    <Col>
                      <p style={{ fontSize: '14px', fontWeight: 600, fontFamily: 'Nunito' }}>
                        - {activeCurrency} {currencyValue(discount)}
                      </p>
                    </Col>
                  </Row>
                )}
              </>
            )}

            {selectedInsuranceData.status === 1 &&
              selectedInsuranceData?.serviceType === 1 ? (
              <Row justify={"space-between"} className="grand_total_card_row">
                <Col>
                  <p>Insurance Amount</p>
                </Col>
                <Col>
                  <p>
                    {activeCurrency} {currencyValue(insuranceTotal)}
                  </p>
                </Col>
              </Row>
            ) : null}
            {!agent ?
              <div className="pax-total-price" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="pax-type">
                  <p style={{ fontSize: '22px', fontWeight: 600, fontFamily: 'Nunito' }}>Grand Total:</p>
                </div>
                <div className="total" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p style={{ color: "rgb(0 59 149)", fontSize: '22px', fontWeight: 600, fontFamily: 'Nunito' }}>{activeCurrency} </p>
                  <p className="amount ml-1" style={{ color: "rgb(0 59 149)", fontSize: '22px', fontWeight: 600, fontFamily: 'Nunito' }}> {currencyValue(grandTotal)}</p>
                </div>
              </div> : null
            }
            {/* {console.log(agent,"chkagent")} */}
            {agent && user?.Role?.RoleLevel === 3 ? (
              <div>

                <div className="info">

                  {/* {console.log("hh",agent)} */}
                  <Collapse accordion ghost onChange={onChange} activeKey={activeKey}>
                    {items.map((item) => (
                      <Collapse.Panel key={item.key} header={item.label}>
                        <p>{item.children}</p>
                      </Collapse.Panel>
                    ))}
                  </Collapse>

                </div>
                {/* <div className="pax-total-price">
              <div className="pax-type">
                <p className="pax-comm">Commission Earned:</p>
              </div>
              <div className="total">
                <p className="pax-comm">{activeCurrency} </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(commission)}
                </p>
              </div>
            </div> */}
              </div>
            ) : null}
          </div>
        </Card>


        {/* {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="flight-cards-details mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {PromoNotVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : PromoNotVisible ? null : (
          <Card className="flight-cards-details mt-3">
            <p className="font-weight-bold mb-1">
              Coupon Wallet: {/* {activeCurrency} *
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>

            <div className="book-pay-btn">
              {" "}
              <Button
                type="primary"
                className=" redeem_btn"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null} */}

      </>
      <>
        {!agent && user?.Role?.RoleLevel !== 3 && !PromoNotVisible ? (
          <div className="promo-card apply-promo-fbt">
            {promoData.status == false ? (
              <div className="promo-c">
                <div style={{ background: "#f9f9f9" }}>
                  <p className="name" style={{ color: "black", fontWeight: 700, fontFamily: "Nunito", fontSize: "18px" }}>Apply Promo</p>
                </div>
                <div style={{ marginLeft: 16 }}>
                  {user != null ?
                    <div className="promo-input">
                      <Form
                        name="customized_form_controls"
                        layout="inline"
                        form={promoform}
                        onFinish={(d) => {
                          validatePromoCode({
                            ServiceType: 1,
                            Amount: Number(grandTotal),
                            PromoCode: d.promo,
                            UserId: user?.UserID ?? 1,
                            userMail: user?.Email,
                          });
                        }}
                      >
                        <Form.Item name="promo" className="promo-input-item">
                          <Input
                            className="contacts-value-de"
                            placeholder="Enter Your Promo code"
                            autoComplete="off"
                            size={"large"}
                          />
                        </Form.Item>
                        <Form.Item className="promo-input-btn">
                          <Button
                            className="apply-promo-value-code"
                            type="primary"
                            htmlType="submit"
                            size="large"
                          >
                            Apply
                          </Button>
                        </Form.Item>
                      </Form>
                    </div> : <div className="promo-input">
                      <p>Please <span style={{ color: "#003b95", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                    </div>
                  }
                </div>
                <div style={{ padding: 5, marginRight: 16 }}>
                  <div className="promo-data-1">
                    {promoCode.length ? (
                      promoCode.map((item, key) => {
                        return (
                          <div className="promo-cp-coupons mt-3" key={key}>
                            <div className="inline-cp-promo">
                              <Form>
                                <Form.Item>
                                  <Radio
                                    onClick={() => handleApply(item.Code)}
                                  ></Radio>
                                </Form.Item>
                              </Form>
                              <p className="promo-key-cp">{item.Code}</p>
                              {/* {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {(item.Discount) + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )} */}
                            </div>

                            <div className="promo-percentage-cp">
                              <p>{item.Description}  {item.DiscountType === 1 ? (
                                <span className="save-cp-offer">
                                  Save {(item.Discount) + " %"}
                                </span>
                              ) : (
                                <span className="save-cp-offer">
                                  Save {activeCurrency}&nbsp;
                                  {currencyValue(item.Discount)}
                                </span>
                              )}</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="promo-cp-coupons mt-3">
                        <div className="inline-cp-promo">
                          <p className="promo-key-cp">No Promo Code Available</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="promo-c">
                <div className="promo-card-header">
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="name"
                  >
                    Promo Coupon{" "}
                    <CloseOutlined
                      onClick={() => {
                        RemovePromo();
                        promoform.resetFields();
                      }}
                    />{" "}
                  </p>
                </div>
                <div className="promo-input">
                  <p>
                    <span className="applied"> {promoData.Code} </span> Promo Code
                    Applied
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Why u need to Sign In ?</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-hotel-show12"
          style={{width:"600px"}}
          visible={loginModal}
          onOk={() => {
            setModalVisible((prev) => ({ ...prev, visible: true }));
            setLoginModal(false);
          }}
          onCancel={(e) => setLoginModal(false)}
          footer={[
            <div>
              <Button type="secondary" onClick={() => setLoginModal(false)}>
                Close
              </Button>
              <Button type="primary" onClick={() => {
                setModalVisible((prev) => ({ ...prev, visible: true }));
                setLoginModal(false);
              }}>
                SignIn Now
              </Button>
            </div>,

          ]}
        >
          <div className="login-infi-det">
            <Alert
              message="Sign In is Required"
              description="To avail promotions, please signIn in to your account."
              type="warning"
              showIcon
              style={{ marginBottom: "20px" }}
              className="promo-alert"
            />
            <Paragraph>
              Signing in ensures you enjoy a safe and personalized experience, along with exclusive benefits:
            </Paragraph>
            <ul className="benefits-list">
              <li>
                <strong>Secure Transactions:</strong> Your information is protected with advanced encryption and secure systems.
              </li>
              <li>
                <strong>Exclusive Promotions:</strong> Unlock special discounts and offers available only to signed-in users.
              </li>
              <li>
                <strong>Save Preferences:</strong> Keep track of your bookings, preferences, and previous searches for a seamless experience.
              </li>
              <li>
                <strong>Faster Checkouts:</strong> Autofill your saved details for quicker booking.
              </li>
              <li>
                <strong>Personalized Recommendations:</strong> Get suggestions tailored to your interests and preferences.
              </li>
            </ul>
          </div>
          {/* <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        /> */}
        </Modal>
        <Modal
          centered
          visible={modalVisible.visible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          className="login-modal"
          footer={false}
        >
          <Login
            location={Loc}
            setModalVisible={(value) => setModalVisible(value)}
            type={modalVisible.type}
          />
        </Modal>
      </>
    </>
  );
};

export default FlightGrandTotalCard;

