import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";

import invoice from "../../assets/images/invoice.png";

import CarsDoc from "../PdfDocuments/Cars/CarsDoc";
import BusDoc from "../PdfDocuments/Bus/BusDoc";
import TransferDoc from "../PdfDocuments/Cars/TransferDoc";
import Invoice from "./Model/UserInvoice";
import FlightDoc from "../PdfDocuments/Flight/FlightDoc";

import HotelDoc from "../PdfDocuments/Hotel/HotelDoc";
import VisaDoc from "../PdfDocuments/Visa/VisaDoc";


import BusNewInvoiceDoc from "../PdfDocuments/Bus/BusInvoice";
import FlightInvoiceDoc from "../PdfDocuments/Flight/FlightInvoice";

import HotelNewInvoiceDoc from "../PdfDocuments/Hotel/HotelInvoice"




import PaymentGateway from "../../helpers/PaymentGateway";
import moment from "moment";
import { useSytContext } from "../../common/providers/SytProvider";

import HolidayInvoice from "../PdfDocuments/Holiday/HolidayInvoice";

import ReactDOMServer from "react-dom/server";
import TransferInvoiceDoc from "../PdfDocuments/Cars/TransferInvoiceDoc";

const TicketSidebar = ({
  ticketData,
  type,
  onCancelTicket,
  onGetStatus,
  getTicketDetails = () => { },
  cmsFareRules = {},
  fareRulesResp = null,
  onResheduleTicket,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  // console.log(agent, user, "userg");
  const { logo, agentLogo } = useSytContext();
  const [smsForm] = Form.useForm();
  const [pstMrkForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [pgData, setPgData] = useState({
    data: {},
    visible: false,
  });
  const [invoiceData, setInvoiceData] = useState({});
  const [creditNoteData, setCreditNoteData] = useState({});
  const [withFare, setWithFare] = useState(-1);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);
  const [pstMrkModalVisible, setPstMrkModalVisible] = useState(false);

  const toggleEmailModal = () => {
    emailForm.resetFields();
    if (!emailModalVisible) loadpassengerData("Email");
    setEmailModalVisible((prev) => !prev);
  };
  const toggleSmsModal = () => {
    smsForm.resetFields();
    if (!smsModalVisible) loadpassengerData("SMS");
    setSmsModalVisible((prev) => !prev);
  };

  const togglePstMrkModal = () => {
    pstMrkForm.resetFields();
    setPstMrkModalVisible((prev) => !prev);
  };
  // console.log("side", ticketData, type);

  const loadpassengerData = (type) => {
    if (user && user?.UserID) {
      getPassengerData(user?.UserID).then((data) => {
        if (data.status) {
          if (type === "SMS") smsForm.setFieldsValue({ Mobile: data.Mobile });
          // else emailForm.setFieldsValue({ Email: data.Email });
        }
      });
    }
  };

  const addPostMarkup = (req) => {
    ApiClient.post("admin/postmarkup", req).then((res) => {
      if (res.status === 200) {
        if (res?.message) message.success(res?.message, 3);
        getTicketDetails();
        setPstMrkModalVisible(false);
      } else if (res.status === 400) {
        if (res?.message) message.success(res?.message, 3);
      } else {
        if (res?.message) message.success(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  useEffect(() => {
    getInvoiceData();
    getCreditNoteData();
  }, []);
  const getCreditNoteData = () => {
    let formData = {};
    if (type === "Cruise") {
      formData.serviceType = 9;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (type === "Holiday") {
      formData.serviceType = 6;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (type === "CharterFlight") {
      formData.serviceType = 10;
      formData.refNo = ticketData.ReferenceNo;
    }
    ApiClient.post("admin/creditnotes", formData)
      .then((res) => {
        if (res.status == 200) {
          setCreditNoteData(res.data);
        } else {
          setCreditNoteData({});
        }
      })
      .catch((error) => {
        setCreditNoteData({});
      });
  };

  const getInvoiceData = () => {
    let formData = {};
    if (type === "Flight") {
      formData.serviceType = 1;
      formData.refNo = ticketData.referenceNumber;
    }

    if (type === "Hotel") {
      formData.serviceType = 2;
      formData.refNo = ticketData.RefNumber;
    }
    if (type === "Bus") {
      formData.serviceType = 3;
      formData.refNo = ticketData.bookingRefNo;
    }

    if (type === "Activities") {
      formData.serviceType = 4;
      formData.refNo = ticketData?.TourDetails?.[0].referenceNumber;
    }
    if (type === "Transfer") {
      formData.serviceType = 5;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (type === "Cruise") {
      formData.serviceType = 9;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (type === "Holiday") {
      formData.serviceType = 6;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (type === "CharterFlight") {
      formData.serviceType = 10;
      formData.refNo = ticketData.ReferenceNo;
    }
    if (formData) {
      ApiClient.post("admin/invoice", formData)
        .then((res) => {
          if (res.status == 200) {
            setInvoiceData(res.data);
          } else {
            setInvoiceData({});
          }
        })
        .catch((error) => {
          setInvoiceData({});
        });
    }
  };

  const submitPostMarkup = (val) => {
    if (user) {
      let formData = {
        amount: val.amount,
        userId: user?.UserID,
      };

      if (type === "Flight") {
        formData.serviceType = 1;
        formData.refNumber = ticketData.referenceNumber;
      }

      if (type === "Hotel") {
        formData.serviceType = 2;
        formData.refNumber = ticketData.RefNumber;
      }
      if (type === "Bus") {
        formData.serviceType = 3;
        formData.refNumber = ticketData.bookingRefNo;
      }

      if (type === "Activities") {
        formData.serviceType = 4;
        formData.refNumber = ticketData?.TourDetails?.[0].referenceNumber;
      }
      if (type === "BuildPackage") {
        formData.serviceType = 5;
        formData.refNumber = ticketData.RefNumber;
      }
      if (type === "Cruise") {
        formData.serviceType = 9;
        formData.refNumber = ticketData.ReferenceNo;
      }
      addPostMarkup(formData);
    }
  };
  const HolidaysendEmailSmsETicket = (val) => {
    //console.log(val)
    ApiClient.post("email/emailpdf", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        if (res?.message) message.error(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  const sendEmailSmsETicket = (val) => {
    ApiClient.post("CommonUtility/notification", val).then((res) => {
      if (res.status === 200) {
        message.success(
          `${val.trigger === 2 ? "Sms" : "Email"} Sent Successfully`
        );
        setEmailModalVisible(false);
        setSmsModalVisible(false);
      } else {
        if (res?.message) message.error(res?.message, 3);
        else message.error("Failed");
      }
    });
  };
  const submitEmailForm = (val) => {
    if (limitCount.emailCount > 5) {
      message.error("Limit Exceeded");
      setEmailModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({
        ...prev,
        emailCount: limitCount.emailCount + 1,
      }));
    }
    

    if (type === "Flight") {
      let formData = {
        phoneNumber: "string",
        travelType: 1,
        trigger: 1,
        bookingReference: ticketData.referenceNumber,
        email: val.Email,
        withFare: withFare,
      };

      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
      

        travelType: 2,
        trigger: 1,
        email: val?.Email,
        PDFContent: k(ticketData?.Price),
        bookingReference: ticketData.RefNumber,
        withFare: withFare,
        
      };
      sendEmailSmsETicket(formData);
      // HolidaysendEmailSmsETicket(formData);
    }

   

    if (type === "Car") {
      let formData = {
        phoneNumber: "string",
        travelType: 5,
        trigger: 1,
        bookingReference: ticketData.BookingRefNo,
        email: val.Email,
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
   
    if (type === "Holiday") {
      let formData = {
      

        travelType: 6,
        trigger: 1,
        email: val?.Email,
        PDFContent: k(ticketData?.Price),
        bookingReference: ticketData.RefNumber,
        withFare: withFare,
        // packageName: ticketData?.TourName,
        //journeyDate:"2022-07-19"
      };
      HolidaysendEmailSmsETicket(formData);
    }
   
  };

  const k = (price) => {
    const htl = ReactDOMServer.renderToStaticMarkup(
     
    );

   
    let k = htl.toString();
    k = k.replaceAll("VIEW", "div");
    k = k.replaceAll("DOCUMENT", "div");
    k = k.replaceAll("PAGE", "div");
    k = k.replaceAll("TEXT", "p");
    k = k.replaceAll("IMAGE", "img");
    return k;
  };
  const sendSms = (val) => {
    if (limitCount.smsCount > 2) {
      message.error("Limit Exceeded");
      setSmsModalVisible(false);
      return;
    } else {
      setLimitCount((prev) => ({ ...prev, smsCount: limitCount.smsCount + 1 }));
    }
    if (type === "Bus") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 3,
        trigger: 2,
        bookingReference: ticketData.bookingRefNo,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Flight") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 1,
        trigger: 2,
        bookingReference: ticketData.id,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
    if (type === "Hotel") {
      let formData = {
        phoneNumber: val.Mobile,
        travelType: 2,
        trigger: 2,
        bookingReference: ticketData.RefNumber,
        email: "string",
        withFare: withFare,
      };
      sendEmailSmsETicket(formData);
    }
  };

  const handleCancel = (cancelTicketType) => {
    onCancelTicket(cancelTicketType);
  };
  const handleStatus = (status) => {
    onGetStatus(status);
  };
  const handleReshedule = (ticketType) => {
    onResheduleTicket(ticketType);
  };
  const printTicket = () => {
    window.print();
  };

  const confirmHotelBooking = () => {
    if (type === "Hotel") {
      let name = "";
      if (ticketData.guests.length > 0) {
        name = `${ticketData?.guests[0]?.FirstName} ${ticketData?.guests[0]?.LastName}`;
      }

      let data = {
        OrderId: ticketData.RefNumber,
        OrderAmount: Number(ticketData.totalPrice),
        OrderCurrency: "INR",
        OrderNote: "Hotel booking",
        CustomerName: name,
        CustomerEmail: ticketData.email,
        CustomerPhone: ticketData.phoneNo,
        TDS: 0,
        GST: 0,
        Commission: ticketData.Commission, // for agent only
        AdminMarkup: ticketData.AdminMarkup,
        AgentMarkup: ticketData.AgentMarkup, // for agent only
        Conveniencefee: ticketData.Conveniencefee,
        AdminCommission: ticketData.AdminCommission, // for agent only
      };

      setPgData({
        data,
        visible: true,
      });
    }
  };
  const HolidayfilterData = () => {
    let arr2 = [];

    ticketData?.PackageOverview?.forEach((day, idx) => {
      Object.entries(day).map(([key, datas]) => {
        if (key.includes("Day")) {
          //let filtering= datas.filter((t) => t.Servicetype == Servicetype)

          arr2.push(datas);
        }
      });
    });

    return arr2;
  };
  return (
    <div className="actionable-buttons">
      <div className="mb-3">
        <Collapse
          accordion
          expandIcon={() => <DownloadOutlined />}
          expandIconPosition="left"
        >
          <Panel
            header={
              type === "Flight"
                ? "Download E-Ticket"
                : type === "Hotel"
                  ? "Download E-Voucher"
                  : type === "Visa"
                    ? "Download Receipt"
                    : "Download E-Ticket"
            }
            key="1"
          >
            <div className="trip-type-selector">
              <div className="trip-type-selector">
              
                {type === "Car" ? (
                  <PDFDownloadLink
                    document={
                      <CarsDoc
                        ticketData={ticketData}
                        withFare={true}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="carsTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1">
                          {agent
                            ? "E-Ticket (With Fare)"
                            : "E-Ticket (With Fare)"}
                          {/* {agent ? "E-Ticket" : "E-Ticket"} */}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Car" ? (
                  <PDFDownloadLink
                    document={
                      <CarsDoc
                        ticketData={ticketData}
                        withFare={false}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="carsTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <>
                          {agent || user?.UserID === 1 ? (
                            <Button className="mb-1">
                              E-Ticket (Without Fare)
                            </Button>
                          ) : null}
                        </>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Transfer" ? (
                  <PDFDownloadLink
                    document={
                      <TransferDoc
                        ticketData={ticketData}
                        withFare={true}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="TransferTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1">
                          {agent ? "E-Ticket (With Fare)" : "With Fare"}
                          {/* {agent ? "E-Ticket" : "E-Ticket"} */}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Transfer" ? (
                  <PDFDownloadLink
                    document={
                      <TransferDoc
                        ticketData={ticketData}
                        withFare={false}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="TransferTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <>
                          {agent || user?.UserID === 1 ? (
                            <Button className="mb-1">
                              E-Ticket (Without Fare)
                            </Button>
                          ) : null}
                        </>

                        // <Button className="mb-1">
                        //   {agent
                        //     ? "E-Ticket (Without Fare)"
                        //     : "E-Ticket(Without Fare)"}
                        // </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Visa" ? (
                  <PDFDownloadLink
                    document={
                      <VisaDoc
                        ticketData={ticketData}
                        withFare={true}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="VisaTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1">
                          {/* {agent ? "E-Ticket (With Fare)" : "With Fare"} */}
                          {agent ? "E-Ticket" : "E-Ticket"}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {/* {type === "Car" ? (
                  <PDFDownloadLink
                    document={
                      <CarsDoc
                        ticketData={ticketData}
                        withFare={false}
                        agent={agent}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="carsTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button>
                          {" "}
                          {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null} */}
                {/* {console.log(ticketData, "tddd")} */}
                {type === "Flight" ? (
                  <PDFDownloadLink
                    document={
                      <FlightDoc
                        ticketData={ticketData}
                        fareRulesResp={fareRulesResp}
                        cmsFareRules={cmsFareRules}
                        withFare={true}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="flightTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1">
                          {agent
                            ? "E-Ticket (With Fare)"
                            : "E-Ticket (With Fare)"}
                          {/* {agent ? "E-Ticket" : "E-Ticket"} */}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Flight" ? (
                  <PDFDownloadLink
                    document={
                      <FlightDoc
                        ticketData={ticketData}
                        fareRulesResp={fareRulesResp}
                        cmsFareRules={cmsFareRules}
                        withFare={false}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="flightTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <>
                          {agent || user?.UserID === 1 ? (
                            <Button>
                              E-Ticket (Without Fare)
                            </Button>
                          ) : null}
                        </>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Bus" ? (
                  <PDFDownloadLink
                    document={
                      <BusDoc
                        ticketData={ticketData}
                        withFare={true}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="busTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1">
                          {agent ? "E-Ticket (With Fare)" : "With Fare"}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Bus" ? (
                  <PDFDownloadLink
                    document={
                      <BusDoc
                        ticketData={ticketData}
                        withFare={false}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="busTicket.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button>
                          {" "}
                          {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Hotel" ? (
                  <PDFDownloadLink
                    document={
                      <HotelDoc
                        ticketData={ticketData}
                        cmsFareRules={cmsFareRules}
                        withFare={true}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="hotelVoucher.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="mb-1" style={{ marginLeft: -13 }}>
                          {agent
                            ? "E-Voucher (With Fare)"
                            : "E-Voucher (With Fare)"}
                          {/* {agent ? "E-Ticket" : "E-Ticket"} */}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Hotel" ? (
                  <PDFDownloadLink
                    document={
                      <HotelDoc
                        ticketData={ticketData}
                        cmsFareRules={cmsFareRules}
                        withFare={false}
                        Logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="hotelVoucher.pdf"

                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <>
                          {agent || user?.UserID === 1 ? (
                            <Button style={{ marginLeft: -13 }}>
                              E-Ticket (Without Fare)
                            </Button>
                          ) : null}
                        </>
                        // <Button style={{ marginLeft: -13 }}>
                        //   {" "}
                        //   {agent ? "E-Voucher (Without Fare)" : "E-Voucher (Without Fare)"}
                        // </Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
               

                

                
                {/* {((invoiceData.bookingStatus === 3 && agent) || (invoiceData.bookingStatus === 3 && user.UserID === 1)) ? (
                  type === "Flight" ? (
                    <PDFDownloadLink
                      document={
                        <FlightInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"invoice"}
                        />
                      }
                      fileName="Flight_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Invoice</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null}
                {(([4, 5, 6].includes(invoiceData.bookingStatus)  && agent) || ([4, 5, 6].includes(invoiceData.bookingStatus)  && user.UserID === 1)) ? (
                  type === "Flight" ? (
                    <PDFDownloadLink
                      document={
                        <FlightInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"credit_note"}
                        />
                      }
                      fileName="Flight_Credit_Note.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Credit Note</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null}
                {((invoiceData.BookingStatus === 2 && agent) || (invoiceData.BookingStatus === 2 && user.UserID === 1))  ? (
                  type === "Hotel" ? (
                    <PDFDownloadLink
                      document={
                        <HotelNewInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"invoice"}
                        />
                      }
                      fileName="Hotel_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Invoice</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null}
                {(([3, 9].includes(invoiceData.BookingStatus) && agent) || ([3, 9].includes(invoiceData.BookingStatus) && user.UserID === 1))  ? (
                  type === "Hotel" ? (
                    <PDFDownloadLink
                      document={
                        <HotelNewInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"credit_note"}
                        />
                      }
                      fileName="Hotel_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Credit Note</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null}

              {((invoiceData.BookingStatus === 3 && agent) || (invoiceData.BookingStatus === 3 && user.UserID === 1)) ?  (
                  type === "Transfer" ? (
                    <PDFDownloadLink
                      document={
                        <TransferInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"invoice"}
                        />
                      }
                      fileName="Transfer_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Invoice</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null}
                 {(([4].includes(invoiceData.BookingStatus) && agent) || ([4].includes(invoiceData.BookingStatus) && user.UserID === 1)) ? (
                  type === "Transfer" ? (
                    <PDFDownloadLink
                      document={
                        <TransferInvoiceDoc
                          invoiceData={invoiceData}
                          logo={agent ? agentLogo : logo}
                          type={"credit_note"}
                        />
                      }
                      fileName="Transfer_Credit_Note.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Credit Note</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null
                ) : null} */}

                {/* {invoiceData?.TourDetails?.length > 0 ? (
                  invoiceData.TourDetails[0].bookingStatus === 0 && agent ? (
                    type === "Activities" ? (
                      <PDFDownloadLink
                        document={
                          <ActivitiesNewInvoiceDoc
                            invoiceData={invoiceData}
                            logo={agent ? agentLogo : logo}
                            type={"credit_note"}
                          />
                        }
                        fileName="Activities_Invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Button>Loading document... </Button>
                          ) : (
                            <Button className="invoice-btn">Credit Note</Button>
                          )
                        }
                      </PDFDownloadLink>
                    ) : null
                  ) : null
                ) : null} */}
               
                
                {type === "Holiday" ? (
                  <PDFDownloadLink
                    document={
                      <HolidayInvoice
                        invoiceData={invoiceData}
                        logo={agent ? agentLogo : logo}
                      />
                    }
                    fileName="Holiday_Invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="invoice-btn">Invoice</Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
                {type === "Holiday" &&
                  creditNoteData &&
                  ticketData?.BookingStatus === 4 ? (
                  <PDFDownloadLink
                    document={
                      <HolidayInvoice
                        invoiceData={creditNoteData}
                        logo={agent ? agentLogo : logo}
                        type={"credit_note"}
                      />
                    }
                    fileName="Holiday_CreditNote.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="invoice-btn">Credit Note</Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null}
               
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
      <div>
        <div>
          {invoice.bookingStatus === 2 ? (
            ""
          ) : (type === "Flight" ? (<div>
            {(invoiceData.bookingStatus === 3 && agent) ||
              ((user !== null) &&
                (invoiceData.bookingStatus === 3 && user?.UserID === 1)) ? (
              type === "Flight" ? (
                <PDFDownloadLink
                  document={
                    <FlightInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"invoice"}
                    />
                  }
                  fileName="Flight_Invoice.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black", marginBottom: 15 }}>
                        {" "}
                        <img src={invoice} style={{ width: 13 }} /> Invoice
                      </p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null}
            {/* {([4, 5, 6].includes(invoiceData.bookingStatus) && agent) ||
              ((user !== null) &&
                ([4, 5, 6].includes(invoiceData.bookingStatus) &&
                  user?.UserID === 1)) ? (
              type === "Flight" ? (
                <PDFDownloadLink
                  document={
                    <FlightInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"credit_note"}
                    />
                  }
                  fileName="Flight_Credit_Note.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black" }}>Credit Note</p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null} */}
          </div>) : null

          )}
          {invoice.BookingStatus === 6 ? (
            ""
          ) : (type === "Hotel" ? (<div>
            {(invoiceData.BookingStatus === 2 && agent) ||
              ((user !== null) &&
                (invoiceData.BookingStatus === 2 && user?.UserID === 1)) ? (
              type === "Hotel" ? (
                <PDFDownloadLink
                  document={
                    <HotelNewInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"invoice"}
                    />
                  }
                  fileName="Hotel_Invoice.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black", marginBottom: 15 }}>
                        {" "}
                        <img src={invoice} style={{ width: 13 }} /> Invoice
                      </p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null}
            {/* {([3, 9].includes(invoiceData.BookingStatus) && agent) ||
              ((user !== null) &&
                ([3, 9].includes(invoiceData.BookingStatus) &&
                  user?.UserID === 1)) ? (
              type === "Hotel" ? (
                <PDFDownloadLink
                  document={
                    <HotelNewInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"credit_note"}
                    />
                  }
                  fileName="Hotel_Invoice.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black" }}>Credit Note</p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null} */}
          </div>) : null

          )}
           {invoice.BookingStatus === 5 ? (
            ""
          ) : (type === "Bus" ? (<div>
            {(invoiceData.BookingStatus === 3 && agent) ||
              ((user !== null) &&
                (invoiceData.BookingStatus === 3 && user?.UserID === 1)) ? (
              type === "Bus" ? (
                <PDFDownloadLink
                  document={
                    <BusNewInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"invoice"}
                    />
                  }
                  fileName="Hotel_Invoice.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black", marginBottom: 15 }}>
                        {" "}
                        <img src={invoice} style={{ width: 13 }} /> Invoice
                      </p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null}
             </div>) : null

)}
           {[5, 7].includes(invoiceData.BookingStatus) && agent ? (
                type === "Bus" ? (
                  <PDFDownloadLink
                    document={
                      <BusNewInvoiceDoc
                        invoiceData={invoiceData}
                        logo={logo}
                        type={"credit_note"}
                      />
                    }
                    fileName="Bus_Credit_Note.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button>Loading document... </Button>
                      ) : (
                        <Button className="invoice-btn">Credit Note</Button>
                      )
                    }
                  </PDFDownloadLink>
                ) : null
              ) : null}

             
          {invoice.BookingStatus === 2 ? (
            ""
          ) : (type === "Transfer" ? (<div>
            {(invoiceData.BookingStatus === 3 && agent) ||
              ((user !== null) &&
                (invoiceData.BookingStatus === 3 && user?.UserID === 1)) ? (
              type === "Transfer" ? (
                <PDFDownloadLink
                  document={
                    <TransferInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"invoice"}
                    />
                  }
                  fileName="Transfer_Invoice.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black", marginBottom: 15 }}>
                        {" "}
                        <img src={invoice} style={{ width: 13 }} /> Invoice
                      </p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null}
            {/* {([4].includes(invoiceData.BookingStatus) && agent) ||
              ((user !== null) &&
                ([4].includes(invoiceData.BookingStatus) && user?.UserID === 1)) ? (
              type === "Transfer" ? (
                <PDFDownloadLink
                  document={
                    <TransferInvoiceDoc
                      invoiceData={invoiceData}
                      logo={agent ? agentLogo : logo}
                      type={"credit_note"}
                    />
                  }
                  fileName="Transfer_Credit_Note.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Button>Loading document... </Button>
                    ) : (
                      <p className="invoice-btn" style={{ padding: 15, border: "1px solid #d9d9d9", color: "black" }}>Credit Note</p>
                    )
                  }
                </PDFDownloadLink>
              ) : null
            ) : null} */}
          </div>) : null

          )}
        </div>
      </div>

      <div className="ticket-options">
        <p onClick={() => printTicket()}>
          <PrinterOutlined />{" "}
          {type === "Flight"
            ? "Print E-Ticket"
            : type === "Hotel"
              ? "Print E-Voucher"
              : "Print E-Ticket"}
        </p>
      </div>

      <div className="mb-3">
        <Collapse accordion expandIcon={() => <MailOutlined />}>
          <Panel
            header={
              type === "Flight"
                ? "Email E-Ticket"
                : type === "Hotel"
                  ? "Email E-Voucher"
                  : "Email E-Ticket"
            }
            key="1"
          >
            <div className="trip-type-selector">
              <div className="trip-type-selector">
                <Button
                  block
                  style={{ marginBottom: 10 }}
                  onClick={() => {
                    toggleEmailModal();

                    setWithFare(1);
                  }}
                >
                  With Price
                </Button>

                <Button
                  block
                  onClick={() => {
                    toggleEmailModal();
                    setWithFare(0);
                  }}
                >
                  Without Price
                </Button>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>

      

      {type === "Cruise"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
        {
        type === "Bus"
          ? ticketData.cancellable && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
          : null
      }
      {type === "Holiday"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "Holiday"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleReshedule(type)}>
              <FileExcelOutlined /> Reshedule E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "CharterFlight"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "CharterFlight"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleReshedule(type)}>
              <FileExcelOutlined /> Reshedule E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "Cruise"
        ? ticketData.Cancallable && (
          <div className="ticket-options">
            <p onClick={() => handleReshedule(type)}>
              <FileExcelOutlined /> Reshedule E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "Flight"
        ? ticketData.BookingStatus !== "CANCELLED" &&
        ticketData?.BookingStatus === "CONFIRMED" &&
        ticketData?.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel("Flight")}>
              <FileExcelOutlined /> Cancel / Reschedule
            </p>
          </div>
        )
        : null}

      {/* {type === "Hotel" */}
      {type === "Hotel" && user
        ? (ticketData.BookingStatus != 3 && ticketData.BookingStatus != 9 && ticketData.BookingStatus != 6) &&
        ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {/* {console.log(ticketData,agent,user,"famil")} */}
      {type === "Hotel" && user
        ? ticketData.BookingStatus == 9 &&
        (
          <div className="ticket-options">
            <p onClick={() => handleStatus(type)}>
              <FileExcelOutlined /> Get Cancel Status
            </p>
          </div>
        )
        : null}
      {type === "Car"
        ? ticketData.BookingStatus === 6 || ticketData.BookingStatus === 4
          ? null
          : moment(ticketData.JourneyDate) > moment() && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
        : null}
      {/* {type === "Transfer"
        ? ticketData.BookingStatus === 6 || ticketData.BookingStatus === 4
          ? null
          : moment(ticketData.JourneyDate) > moment() && (
            <div className="ticket-options">
              <p onClick={() => handleCancel(type)}>
                <FileExcelOutlined /> Cancel E-Ticket
              </p>
            </div>
          )
        : null} */}
      {type === "Transfer"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {type === "Activities"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}

      {type === "BuildPackage"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {agent ? (
        <>
          {/* {ticketData.cancellable ? (
            <div className="ticket-options">
              <p onClick={() => togglePstMrkModal()}>
                <DollarOutlined /> Post Markup
              </p>
            </div>
          ) : null}  */}

          {/* {type === "Hotel" &&
            ticketData?.BookingStatus === 6 &&
            ticketData?.PurchaseType === "Block" ? (
            <div className="ticket-options">
              {pgData.visible ? (
                <PaymentGateway data={pgData.data} />
              ) : (
                <p onClick={() => confirmHotelBooking()}>
                  <DollarOutlined /> Confirm Booking
                </p>
              )}
            </div>
          ) : null} */}

          {/* <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Post Markup</strong>
                </h6>
              </div>,
            ]}
            width={250}
            className="promo-modal-header"
            visible={pstMrkModalVisible}
            onOk={togglePstMrkModal}
            onCancel={togglePstMrkModal}
            footer={[
              <div>
                <Button key="close" onClick={togglePstMrkModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={pstMrkForm.submit}
                >
                  Add
                </Button>
              </div>,
            ]}
          >
            <Form
              form={pstMrkForm}
              layout="vertical"
              onFinish={submitPostMarkup}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },

                      {
                        validator: (_, value) => {
                          if (Number(value) > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Please Enter valid Amount");
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal> */}
        </>
      ) : null}

      <Modal
        title="User Invoice"
        visible={userInvoiceVisible}
        onOk={() => setUserinvoiceVisible(false)}
        onCancel={() => setUserinvoiceVisible(false)}
        width={"75%"}
      >
        <Invoice />
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Enter The Email Address</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={emailModalVisible}
        onOk={toggleEmailModal}
        onCancel={toggleEmailModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleEmailModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={emailForm.submit}
            >
              Send
            </Button>
          </div>,
        ]}
      >
        <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  { required: true, message: "Required!" },
                  { type: "email", message: "Email is not a valid email" },
                ]}
              >
                <Input placeholder="Enter The Email Address" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>SMS E-Ticket</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={smsModalVisible}
        onOk={toggleSmsModal}
        onCancel={toggleSmsModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleSmsModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={smsForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form form={smsForm} layout="vertical" onFinish={sendSms}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Mobile No."
                name="Mobile"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Mobile Number Required",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  className="number-specing"
                  placeholder="Enter Mobile number"
                  autoComplete="off"
                  autoFocus
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default TicketSidebar;
