import * as ReactBoostrap from "react-bootstrap";
import "./Travelitinerary.scss";
import APIClient from "../../helpers/ApiClient";
import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import UpdatedComponent from "../ExportExcel";
import { Upload, Image } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Input, Modal, Select, Menu } from "antd";

const Travelitinerary = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [list, setList] = React.useState([]);
  const [Details, setDetails] = useState([]);
  const [formLayout, setFormLayout] = React.useState("vertical");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const add = () => {
    ANTD.message.success("Buses Markup Added Successfully...", 3);
    setModalVisible(false);
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Card>
              <ANTD.Row className="travel-ticket-iti">
                <ANTD.Col md={24} xs={24}>
                  <div>
                    <img
                      className="person-img"
                      src={
                        require("../../assets/images/travel-iti.png").default
                      }
                      alt="First slide"
                    />
                    <h3 className="travel-iti-heading">My Travel Itinerary </h3>
                    <p>Status-Confirmed</p>
                    <p>RT-70418-084 </p>
                    <p>INR 22389.38</p>

                    <img
                      className="round-ban"
                      src={
                        require("../../assets/images/travel-round-ban.png")
                          .default
                      }
                      alt="First slide"
                    />
                    <p className="create-itineary">
                      Crafted by : akash chhabra{" "}
                    </p>
                    <p className="create-itineary">
                    Outc@test.in, 9999292007, 9999292007
                    </p>
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <ul className="timeline timeline-left">
                    <li className="timeline-inverted timeline-item">
                      <div className="timeline-badge warning">
                        <span className="font-12">18Apr</span>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4 className="timeline-title">
                            Dubai Burj Khalifa Tour{" "}
                          </h4>
                          <p>Tour Date : Sun 08 Apr 2018</p>
                        </div>
                        <div className="timeline-body">
                          <ANTD.Row>
                            <ANTD.Col md={4} xs={24}>
                              <img
                                className="hotel-tag-1"
                                src={
                                  require("../../assets/images/kalifa-ban.png")
                                    .default
                                }
                                alt="First slide"
                              />
                            </ANTD.Col>
                            <ANTD.Col md={20} xs={24}>
                              <p className="hotel-list-para">
                                There is no perfect way to get up close and
                                personal with the world’s tallest structure than
                                with Burj Khalifa tour. Make your way to
                                Downtown Dubai and gain access to Burj Khalifa
                                via Dubai Mall. Then, climb to one of the
                                world’s fastest elevator to enjoy an exciting
                                ride way up to 124th floor. Get sweeping 360
                                degree views of Dubai’s mind blowing landscape
                                from its advanced Observation Deck, At the Top.
                                Capture some amazing pictures and also learn
                                about interesting facts about Dubai’s history as
                                well as this alluring structure on your visit
                                here.Special Note:Sharing transfers is available
                                only from Deira, Bur Dubai.
                              </p>
                            </ANTD.Col>
                            <ANTD.Col md={14} xs={24}>
                              <ul className="hotel-list-prime">
                                <li>
                                  <p className="wt-hotel">Tour Option :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    124th + 125th Floor Tickets (Prime Hours){" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Transfer Option :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    {" "}
                                    Without Transfers
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Duration :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">1 Hour</p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Time :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">17:00:00</p>
                                </li>
                                <li>
                                  <p className="wt-hotel">No. of Pax :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    Adult 6, Child 1, Infant 0
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Amount :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">INR 2239.38</p>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                        </div>
                      </div>
                    </li>
                    <li className="timeline-inverted timeline-item">
                      <div className="timeline-badge warning">
                        <span className="font-12">18Apr</span>
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4 className="timeline-title">
                            Dubai Burj Khalifa Tour{" "}
                          </h4>
                          <p>Tour Date : Sun 08 Apr 2018</p>
                        </div>
                        <div className="timeline-body">
                          <ANTD.Row>
                            <ANTD.Col md={4} xs={24}>
                              <img
                                className="hotel-tag-1"
                                src={
                                  require("../../assets/images/kalifa-ban.png")
                                    .default
                                }
                                alt="First slide"
                              />
                            </ANTD.Col>
                            <ANTD.Col md={20} xs={24}>
                              <p className="hotel-list-para">
                                There is no perfect way to get up close and
                                personal with the world’s tallest structure than
                                with Burj Khalifa tour. Make your way to
                                Downtown Dubai and gain access to Burj Khalifa
                                via Dubai Mall. Then, climb to one of the
                                world’s fastest elevator to enjoy an exciting
                                ride way up to 124th floor. Get sweeping 360
                                degree views of Dubai’s mind blowing landscape
                                from its advanced Observation Deck, At the Top.
                                Capture some amazing pictures and also learn
                                about interesting facts about Dubai’s history as
                                well as this alluring structure on your visit
                                here.Special Note:Sharing transfers is available
                                only from Deira, Bur Dubai.
                              </p>
                            </ANTD.Col>
                            <ANTD.Col md={14} xs={24}>
                              <ul className="hotel-list-prime">
                                <li>
                                  <p className="wt-hotel">Tour Option :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    124th + 125th Floor Tickets (Prime Hours){" "}
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Transfer Option :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    {" "}
                                    Without Transfers
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Duration :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">1 Hour</p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Time :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">17:00:00</p>
                                </li>
                                <li>
                                  <p className="wt-hotel">No. of Pax :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">
                                    Adult 6, Child 1, Infant 0
                                  </p>
                                </li>
                                <li>
                                  <p className="wt-hotel">Amount :</p>
                                </li>
                                <li>
                                  <p className="wt-hotel2">INR 2239.38</p>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ANTD.Col>
              </ANTD.Row>
            </ANTD.Card>

            <ANTD.Card className="card-hotel-book">
              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <h5>TERMS & CONDITIONS - TOUR</h5>
                  <ul className="user-hotel-data">
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                    <li>
                      Transfer options are made available for the Tour if the
                      With Transfer option is been selected at the time of
                      Booking.{" "}
                    </li>
                  </ul>
                </ANTD.Col>
                <ANTD.Col md={24} xs={24}>
                  <p className="end-travel-trip">----End Of Your Trip----</p>
                </ANTD.Col>

                <ANTD.Col md={24} xs={24}>
                  <img
                    className="hotel-tag-travel"
                    src={
                      require("../../assets/images/travel-tic-world.png")
                        .default
                    }
                    alt="First slide"
                  />
                </ANTD.Col>
              </ANTD.Row>
            </ANTD.Card>
          </Content>
        </div>
      </div>
    </div>
  );
};

export default Travelitinerary;
