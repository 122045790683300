import React from "react";
import { Button, Card, Checkbox, Form, Spin } from "antd";
import "./HotelCheckout.scss";
import { LoadingOutlined } from "@ant-design/icons";
import PayGateway from "../../../helpers/PayGateway";

const HotelBookPayCard = (props) => {
  const [form] = Form.useForm();
  
  const goTo = () => {
    if (props.bookpaycardinfo === "hotel-review") {
      props.processPayGateway(1);
    } else if (props.bookpaycardinfo === "hotel-checkout") {
      props.redirectToPreview();
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#ffffff" }} spin />
  );

  return (

    <div className="ht-check">
      {/* <Card bordered={false} className="hotel-card-wrapper book-pay-card"> */}
      {props.pgIsLoading ? (
        <PayGateway
          blockApiReq={props.blockApiReq}
          loadingSpin={props.loadingSpin}
        />
      ) : (
        <Form form={form} onFinish={goTo}>
          {props.bookpaycardinfo === "hotel-checkout" ? (
            <div className="book-pay-tc">
              <Form.Item
                name="remember"
                rules={[
                  {
                    validator: (rule, value) => {
                      return value
                        ? Promise.resolve()
                        : Promise.reject("Accept Terms & Conditions");
                    },
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox>
                  I Agree To All The{" "}
                  <a
                    href="/termsofconditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{color:"#003b95"}}
                  >
                    {" Terms & Conditions"}{" "}
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacypolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{color:"#003b95"}}
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </Checkbox>
              </Form.Item>
            </div>
          ) : null}
          <div className=" d-flex flex-wrap" style={{ justifyContent: "center" }}>
            <div className="book-pay-btn mr-2 mb-1 mt-1">
              {props.holdLoading ? "" : props.loadingSpin ? <Spin tip="Loading..." style={{
                primaryColor: "#bd0c21", secondaryColor: "#35459c"
              }} /> : null}
              <Button
                disabled={props.loadingSpin}
                className="btn-book btn-md"
                htmlType="submit"
                style={{ background: props.loadingSpin ?  "#ffa80a" : "lineargradient(to right, #0370a9, #08acda)",}}
              >
                {props?.holdLoading ? "" : props?.loadingSpin ? (
                  <Spin indicator={antIcon} style={{
                    primaryColor: "#bd0c21", secondaryColor: "#35459c"
                  }} />
                ) : props?.bookpaycardinfo === "hotel-checkout" ? (
                  "Continue"
                ) : (
                  "Pay and Book"
                )}
              </Button>
            </div>
            {/* 
            {props.bookpaycardinfo === "hotel-review" &&
              props.purchaseType === "Block" &&
              props.agent ? (
              <div className="bookpaybtn mb-1 mt-1">
                {props.holdLoading ? (
                  <Button className="btn btn-md">
                    <Spin indicator={antIcon} style={{ color: '#35459c' }}/>
                  </Button>
                ) : (
                  <Button
                    className="btn btn-md"
                    onClick={() => props.processPayGateway(2)}
                  >
                    Hold Hotel
                  </Button>
                )}
              </div>
            ) : null} */}
          </div>
        </Form>
      )}
      {/* </Card> */}
    </div>
  );
};

export default HotelBookPayCard;
